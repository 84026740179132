export const tabs = [
  {
    title: 'Финансовые операции',
    route: {
      name: 'operation.index',
    },
  },
  {
    title: 'Зарплаты',
    route: {
      name: 'payroll.index',
    },
  },
];
