const DRAFT = 'draft';
const IN_PROGRESS = 'in_progress';
const COMPLETED = 'completed';
const DELETED = 'deleted';

export default {
  DRAFT,
  IN_PROGRESS,
  COMPLETED,
  DELETED,
};
