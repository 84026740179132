<script setup>
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/AuthStore.js';
import AppIcon from '@/components/AppIcon.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import IconChevronRight from '@/components/Icons/IconChevronRight.vue';
import { computed } from 'vue';
import { menu } from '@/data/menu.js';
import AppTooltip from '@/components/AppTooltip.vue';
import IconChevronLeft from '@/components/Icons/IconChevronLeft.vue';
import IconEmployee from '@/components/Icons/IconEmployee.vue';
import { useSidebarStore } from '@/store/SidebarStore.js';
import IconDoor from '@/components/Icons/IconDoor.vue';
import AppDropdown from '@/components/AppDropdown.vue';
import IconChat from '@/components/Icons/IconChat.vue';
import IconTelegramOutline from '@/components/Icons/IconTelegramOutline.vue';
import { useChat } from '@/use/useChat.js';
import { useEmployee } from '@/use/useEmployee.js';
const { getAllowedPathes } = useAuthStore();

const menuItems = computed(() => {
  return getAllowedPathes(menu);
});

const authStore = useAuthStore();
const sidebar = useSidebarStore();

const openTelegramChannel = () => {
  window.open('https://t.me/morspro_fitting_service', '_blank', 'noreferrer');
};

const openHelpChat = () => {
  useChat().openChat();
};

const { employeeShortTitle } = useEmployee();
</script>

<template>
  <transition name="slide">
    <aside
      class="z-20 sticky top-0 flex-none bg-[#F5F5F5] h-screen w-[146px] py-[24px] px-[12px] rounded-r-[6px] hidden md:flex flex-col justify-between transition-width duration-150"
      :class="{ '!w-[68px]': !sidebar.expanded }"
    >
      <div class="flex flex-col overflow-y-auto">
        <div class="relative">
          <div class="bg-white rounded-full w-[32px] h-[32px] flex items-center justify-center">
            <AppIcon
              :icon="IconLogo"
              :width="24"
              :height="24"
              class="text-[#EC4899]"
            />
          </div>

          <AppIcon
            :icon="sidebar.expanded ? IconChevronLeft : IconChevronRight"
            :width="12"
            :height="12"
            class="absolute cursor-pointer right-0 top-1/2 -m-[5px] text-[#CBD3E4] hover:text-violet-primary"
            @click="sidebar.toggle()"
          />
        </div>

        <div
          class="select-none mt-[32px] flex flex-col gap-y-[24px]"
          :class="{ 'w-[32px] items-center': !sidebar.expanded }"
        >
          <router-link
            v-for="item in menuItems"
            :key="item.url"
            :to="item.url"
          >
            <div class="flex gap-x-[8px] items-center hover:text-violet-primary cursor-pointer">
              <AppTooltip
                :title="item.label"
                :hide="sidebar.expanded"
              >
                <AppIcon
                  :icon="item.icon"
                  :width="16"
                  :height="16"
                />
              </AppTooltip>

              <div
                v-if="sidebar.expanded"
                class="text-sm whitespace-nowrap"
              >
                {{ item.label }}
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="flex flex-col gap-[8px]">
        <AppDropdown direction="top">
          <div class="flex gap-x-[8px] items-center cursor-pointer group">
            <div class="flex-none bg-white rounded-full w-[32px] h-[32px] flex items-center justify-center">
              <AppIcon
                :icon="IconEmployee"
                :width="14"
                :height="14"
                class="text-black-primary"
              />
            </div>
            <div
              v-if="sidebar.expanded"
              class="text-xs group-hover:text-violet-primary"
            >
              {{ employeeShortTitle(authStore.employee) }}
            </div>
          </div>

          <template #inner>
            <router-link
              :to="{ name: 'auth.logout' }"
              class="flex text-xs gap-x-[6px] items-center py-[4px] px-[12px] text-red-notify cursor-pointer hover:bg-[#FEF7F7]"
            >
              <div class="flex-none w-[20px] h-[20px] bg-[#F5F5F5] rounded-full"><AppIcon :icon="IconDoor" /></div>
              <div>Выйти</div>
            </router-link>
          </template>
        </AppDropdown>

        <div
          id="custom-chat-button"
          class="flex gap-[8px] items-center group cursor-pointer select-none"
        >
          <div class="flex-none bg-black-primary rounded-full w-[32px] h-[32px] flex items-center justify-center">
            <AppIcon
              :icon="IconChat"
              :width="14"
              :height="14"
              class="text-white"
            />
          </div>
          <div
            v-if="sidebar.expanded"
            class="text-xs group-hover:text-violet-primary cursor-pointer"
          >
            Поддержка
          </div>
        </div>

        <div class="flex gap-[8px] items-center group cursor-pointer select-none">
          <div class="flex-none bg-black-primary rounded-full w-[32px] h-[32px] flex items-center justify-center">
            <AppIcon
              :icon="IconTelegramOutline"
              :width="14"
              :height="14"
              class="text-white"
            />
          </div>
          <div
            v-if="sidebar.expanded"
            class="text-xs group-hover:text-violet-primary cursor-pointer"
            @click="openTelegramChannel()"
          >
            Новости
          </div>
        </div>
      </div>

      <teleport to="#app">
        <div
          class="z-20 md:hidden fixed border-t border-[#CBD3E4] bottom-0 bg-[#F5F5F5] py-[19px] px-[24px] inset-x-0 flex gap-x-[32px] overflow-y-auto"
        >
          <router-link
            v-for="item in menuItems"
            :key="item.url"
            :to="item.url"
          >
            <AppIcon
              :icon="item.icon"
              :width="22"
              :height="22"
            />
          </router-link>

          <AppIcon
            class="cursor-pointer flex-none"
            :icon="IconChat"
            :width="22"
            :height="22"
            @click="openHelpChat()"
          />
        </div>
      </teleport>
    </aside>
  </transition>
</template>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
</style>
