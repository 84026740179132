<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { formatMoney } from '@/use/useFormat.js';
import { useQueryClient } from '@tanstack/vue-query';
import { orderDiscountUpdate } from '@/api/order.js';

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
});

const discounts = [
  {
    title: '0%',
    value: 0,
  },
  {
    title: '5%',
    value: 5,
  },
  {
    title: '10%',
    value: 10,
  },
  {
    title: '15%',
    value: 15,
  },
  {
    title: '20%',
    value: 20,
  },
  {
    title: '50%',
    value: 50,
  },
  {
    title: '100%',
    value: 100,
  },
];

const queryClient = useQueryClient();

const discountChange = async (discount) => {
  try {
    await orderDiscountUpdate({ orderId: props.order.orderId, discount });
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'order'),
    });
  }
};
</script>

<template>
  <div class="flex flex-col gap-y-[12px]">
    <div>Скидка</div>
    <div class="flex gap-x-[45px] items-center">
      <div class="w-[130px]">
        <AppSelect
          data-test="payment-discount"
          :model-value="props.order.discountPercent"
          :options="discounts"
          option-label="title"
          option-value="value"
          placeholder=""
          @changed="discountChange"
        />
      </div>
      <div class="grow flex flex-wrap gap-x-[10px] items-center">
        <span class="font-medium">К оплате: {{ formatMoney(props.order.price - props.order.amountPaid) }}</span>
        <span
          v-if="props.order.discount > 0"
          class="text-gray-primary"
        >
          (Скидка {{ formatMoney(props.order.discount) }})
        </span>
      </div>
    </div>
  </div>
</template>
