<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2983_12579)">
      <path
        d="M8.7793 0.5C4.6393 0.5 1.2793 3.86 1.2793 8C1.2793 12.14 4.6393 15.5 8.7793 15.5C12.9193 15.5 16.2793 12.14 16.2793 8C16.2793 3.86 12.9193 0.5 8.7793 0.5ZM2.7793 8C2.7793 7.5425 2.8393 7.0925 2.9368 6.665L6.5218 10.25V11C6.5218 11.825 7.1968 12.5 8.0218 12.5V13.9475C5.0743 13.5725 2.7793 11.0525 2.7793 8ZM13.1968 12.05C13.0018 11.4425 12.4468 11 11.7718 11H11.0218V8.75C11.0218 8.3375 10.6843 8 10.2718 8H5.7718V6.5H7.2718C7.6843 6.5 8.0218 6.1625 8.0218 5.75V4.25H9.5218C10.3468 4.25 11.0218 3.575 11.0218 2.75V2.4425C13.2193 3.3275 14.7793 5.4875 14.7793 8C14.7793 9.56 14.1718 10.985 13.1968 12.05Z"
        fill="#626AE9"
      />
    </g>
    <defs>
      <clipPath id="clip0_2983_12579">
        <rect
          width="17"
          height="17"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
