import http from '@/libs/http.js';

export const getCompany = (companyId) => {
  return http.get('/api/v2/client/company/' + companyId);
};

export const companyCreate = (
  clientId,
  { type, inn, title, kpp, ogrn, address, directorTitle, phone, bic, correspondentAccount, beneficiaryAccount }
) => {
  return http.post('/api/v2/client/company', {
    clientId,
    type,
    inn,
    title,
    kpp,
    ogrn,
    address,
    directorTitle,
    phone,
    bic,
    correspondentAccount,
    beneficiaryAccount,
  });
};

export const companyUpdate = (
  companyId,
  { type, inn, title, kpp, ogrn, address, directorTitle, phone, bic, correspondentAccount, beneficiaryAccount }
) => {
  return http.put('/api/v2/client/company', {
    companyId,
    type,
    inn,
    title,
    kpp,
    ogrn,
    address,
    directorTitle,
    phone,
    bic,
    correspondentAccount,
    beneficiaryAccount,
  });
};
