<script setup>
import { computed, onUnmounted, ref } from 'vue';
import { DateTime } from 'luxon';
import IconCheckCircle from '@/components/Icons/IconCheckCircle.vue';
import AppIcon from '@/components/AppIcon.vue';

const props = defineProps({
  updatedAt: {
    type: String,
    required: true,
  },
});

const currentTime = ref(DateTime.now());
const interval = setInterval(() => {
  currentTime.value = DateTime.now();
}, 1000);

onUnmounted(() => {
  clearInterval(interval);
});

const isSavedJustNow = computed(() => {
  const updatedAt = DateTime.fromISO(props.updatedAt);
  const diff = currentTime.value.diff(updatedAt, 'seconds').seconds;

  return diff < 3;
});

const savedTitle = computed(() => {
  const updatedAt = DateTime.fromISO(props.updatedAt);
  const diff = currentTime.value.diff(updatedAt, 'seconds');

  if (diff.days > 0) {
    return updatedAt.toFormat('d MMMM yyyy, HH:mm');
  }

  if (diff.seconds < 10) {
    return 'только что';
  }

  return 'в ' + updatedAt.toFormat('HH:mm');
});
</script>

<template>
  <div class="flex items-center gap-x-[8px]">
    <span class="text-gray-primary">Сохранено {{ savedTitle }}</span>
    <Transition>
      <AppIcon
        v-if="isSavedJustNow"
        class="text-violet-primary"
        :icon="IconCheckCircle"
      />
    </Transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
