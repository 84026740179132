import { createToaster } from '@meforma/vue-toaster';

const toaster = createToaster({
  /* options */
});

export const useToast = () => {
  const show = (msg) => {
    toaster.show(msg);
  };

  const error = (msg) => {
    toaster.error(msg);
  };

  return { show, error };
};
