<template>
  <svg
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="animate-spin"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 0C14.7439 0 19 4.25083 19 9.5C19 14.7439 14.7439 19 9.5 19C4.25083 19 0 14.7439 0 9.5C0 4.25083 4.25083 0 9.5 0ZM9.5 2.48359C13.3725 2.48359 16.5164 5.62573 16.5164 9.49824C16.5164 13.3708 13.3725 16.5129 9.5 16.5129C5.62749 16.5129 2.48534 13.3708 2.48534 9.49824C2.48534 5.62748 5.62749 2.48359 9.5 2.48359Z"
      fill="#E0E4EC"
      fill-opacity="0.4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.35807 18.9998V16.5162C5.42857 16.2855 2.71429 13.5712 2.4881 9.63672H0C0.0718105 14.7002 4.07143 18.9998 9.35807 18.9998Z"
      fill="white"
    />
  </svg>
</template>
