import http from '@/libs/http.js';

export const getEmployees = ({ roleId, statusId, assignedAsExecutor, include } = {}) => {
  return http.get('/api/v2/employee', {
    params: {
      roleId,
      statusId,
      assignedAsExecutor,
      include,
    },
  });
};

export const getEmployee = (employeeId) => {
  return http.get('/api/v2/employee/' + employeeId);
};

export const updateEmployee = (
  employeeId,
  {
    firstName,
    lastName,
    patronymicName,
    roleId,
    statusId,
    assignedAsExecutor,
    position,
    percentOfCompletedOrders,
    minimumPayPerShift,
    sumMinimumAndPercent,
    roles,
  }
) => {
  return http.put('/api/v2/employee/' + employeeId, {
    firstName,
    lastName,
    patronymicName,
    roleId,
    statusId,
    assignedAsExecutor,
    position,
    percentOfCompletedOrders,
    minimumPayPerShift,
    sumMinimumAndPercent,
    roles,
  });
};

export const getEmployeesAccruals = () => {
  return http.get('/api/v2/business/employee-accrual');
};

export const getEmployeesPayrolls = () => {
  return http.get('/api/v2/business/employee-payroll');
};

export const fireEmployee = (employeeId, reason) => {
  return http.post('/api/v2/employee/fire', {
    employeeId,
    reason,
  });
};

export const unfireEmployee = (employeeId) => {
  return http.post('/api/v2/employee/unfire', {
    employeeId,
  });
};
