<script setup>
import AppButton from '@/components/AppButton.vue';
import ShiftCurrentExpenses from '@/modules/shift/components/ShiftCurrentExpenses.vue';
import { computed, ref, toRef, watch } from 'vue';
import { useShiftQuery } from '@/query/useShiftQuery.js';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import AppTable from '@/components/AppTable.vue';
import { formatMoney, plural } from '@/use/useFormat.js';
import { useEmployee } from '@/use/useEmployee.js';
import ShiftEmployeesModal from '@/modules/shift/components/ShiftEmployeesModal.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import OrderStatus from '@/enums/OrderStatus.js';
import AppStatsCard from '@/components/AppStatsCard.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconClock from '@/components/Icons/IconClock.vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/shift/shift-tabs.js';
import ShiftOpenModal from '@/modules/shift/components/ShiftOpenModal.vue';
import { useOrdersQuery } from '@/query/useOrderQuery.js';
import { useAuthStore } from '@/store/AuthStore.js';

const props = defineProps({
  shiftId: {
    type: [Number, String],
    required: true,
  },
});

const { hasAccess } = useAuthStore();

const router = useRouter();
const shiftInclude = ['expenses', 'employees'];

const currentShiftNotFound = ref(false);

const {
  data: shift,
  isLoading: isShiftLoading,
  isError,
  error,
} = useShiftQuery(
  { shiftId: toRef(props, 'shiftId'), include: shiftInclude },
  { select: (response) => response?.data?.data }
);

watch(isError, () => {
  if (error.value?.response?.status === 404) {
    currentShiftNotFound.value = true;
  }
});

const ordersInclude = ['account', 'client', 'car.mark', 'car.model', 'employees', 'payments.account'];

const shiftId = computed(() => {
  return shift.value?.shiftId;
});

const { data: orders, isLoading: isOrdersLoading } = useOrdersQuery(
  {
    status: [OrderStatus.COMPLETED, OrderStatus.DELETED],
    shiftId: shiftId,
    include: ordersInclude,
  },
  {
    enabled: computed(() => !!shiftId.value),
    select: (response) => response?.data?.data,
  }
);

const { data: accountData } = useAccountsQuery({ isArchived: false });
const accounts = computed(() => {
  return accountData.value?.data;
});

watch(shift, () => {
  if (shift.value) {
    currentShiftNotFound.value = false;
  }
});

const isLoading = computed(() => {
  return isShiftLoading?.value || isOrdersLoading?.value;
});

const sortedOrders = computed(() => {
  if (!orders.value || !Array.isArray(orders.value)) {
    return [];
  }

  return [...orders.value].sort((a, b) => b.orderId - a.orderId);
});

const completedOrders = computed(() => orders.value?.filter((order) => order.status === OrderStatus.COMPLETED));

const totalIncome = computed(() => {
  if (!completedOrders.value) {
    return null;
  }

  return Object.values(completedOrders.value).reduce((total, order) => {
    return total + order.price;
  }, 0);
});

const totalIncomes = computed(() => {
  if (!completedOrders.value) {
    return null;
  }

  if (!accounts.value) {
    return null;
  }

  let accountIncomes = [];

  accounts.value.forEach((account) => {
    const accountTotal = Object.values(completedOrders.value).reduce((accountSum, order) => {
      const orderSum = order.payments.reduce((orderTotal, payment) => {
        if (payment.accountId !== account.accountId) {
          return orderTotal;
        }

        return orderTotal + payment.amount;
      }, 0);

      return accountSum + orderSum;
    }, 0);

    // скрывваем счета в архиве с балансом 0
    if (account.isArchived && accountTotal === 0) {
      return;
    }

    if (accountTotal === 0) {
      return;
    }

    accountIncomes.push({
      ...account,
      total: accountTotal,
    });
  });

  return accountIncomes;
});

const accountCashbox = computed(() => {
  return accounts.value?.find((account) => account.isCashbox);
});

const isOpen = computed(() => {
  return shift.value?.finishedAt === null;
});

const queryClient = useQueryClient();
const resetShiftQuery = async () => {
  await queryClient.invalidateQueries({
    predicate: (query) => query.queryKey.some((key) => key === 'shift'),
  });
};

const routeToOrder = (orderId) => {
  router.push({ name: 'order.show', params: { orderId } });
};

const shiftClosed = async () => {
  await router.push({ name: 'shifts.show', params: { shiftId: shift.value.shiftId } });
  await resetShiftQuery();
};
const { employeeShortTitle } = useEmployee();

const appShiftEmployeesModalShowed = ref(false);
const appShiftEmployeesModalInit = () => {
  appShiftEmployeesModalShowed.value = true;
};

const columns = [
  {
    template: 'id',
    label: '№',
    width: '2fr',
  },
  {
    field: 'car.title',
    label: 'Автомобиль',
    width: '3fr',
    template: 'car',
  },
  {
    template: 'pay',
    label: 'Сумма',
    width: '2fr',
  },
  {
    field: (order) => {
      if (!Array.isArray(order.employees) || order.employees.length === 0) {
        return;
      }

      return order.employees.map((employee) => {
        return employeeShortTitle(employee);
      });
    },
    template: 'employees',
    label: 'Исполнитель',
    width: '4fr',
  },
  {
    template: 'date',
    label: 'Дата',
    width: '2fr',
  },
  {
    template: 'status',
    label: 'Статус',
    width: '2fr',
  },
];

const tabsWithCurrent = computed(() => {
  if (props.shiftId === 'current' || !shift.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Просмотр смены',
      route: {
        name: 'shift.show',
        params: {
          shiftId: shift.value.shiftId,
        },
      },
    },
  ];
});

const shiftOpenModalShow = ref(false);
</script>

<template>
  <CabinetLayout
    :tabs="tabsWithCurrent"
    :loading="isLoading && !isError"
    :hide-in-loading="true"
  >
    <div
      v-if="currentShiftNotFound"
      class="flex flex-col gap-y-[18px] items-center justify-center grow"
    >
      <div class="text-gray-primary">Нет открытой смены</div>
      <div>
        <AppButton
          v-if="hasAccess('create-Shift')"
          outline
          @click="shiftOpenModalShow = true"
          >Открыть смену</AppButton
        >

        <ShiftOpenModal
          v-if="shiftOpenModalShow"
          @closed="shiftOpenModalShow = false"
          @opened="shiftOpenModalShow = false"
        />
      </div>
    </div>
    <div
      v-else
      class="grow flex flex-col"
    >
      <div class="flex justify-between flex-wrap gap-[12px] items-center">
        <div class="flex flex-wrap gap-[12px]">
          <div
            v-if="isOpen && hasAccess('view-any-Operation')"
            class="flex gap-[18px] items-center"
          >
            <div class="font-medium text-lg">В кассе</div>
            <AppStatsCard class="flex items-center gap-[6px] grow md:grow-0">
              <div class="flex gap-[4px] items-center">
                <span class="text-lg">
                  {{ formatMoney(accountCashbox?.balance) }}
                </span>
              </div>
            </AppStatsCard>
          </div>

          <AppStatsCard class="w-full md:w-auto min-w-[264px]">
            <ShiftCurrentExpenses
              :shift="shift"
              :allow-edit="hasAccess('create-Operation')"
              @expense-created="resetShiftQuery"
            />
          </AppStatsCard>

          <AppStatsCard class="w-full md:w-auto min-w-[264px] flex items-center gap-x-[12px]">
            <div>На смене ({{ shift.employees.length }})</div>
            <div class="flex gap-[8px]">
              <div
                v-for="employee in shift.employees"
                :key="employee.employeeId"
                class="py-[4px] px-[6px] bg-[#EDECEC] text-violet-primary text-sm rounded-[6px]"
              >
                {{ employeeShortTitle(employee) }}
              </div>
            </div>
            <div class="ml-[6px]">
              <AppIcon
                v-if="isOpen && hasAccess('update-Operation')"
                class="cursor-pointer text-black hover:text-violet-primary"
                :icon="IconPencil"
                @click="appShiftEmployeesModalInit()"
              />
            </div>

            <ShiftEmployeesModal
              v-if="appShiftEmployeesModalShowed"
              :shift-id="props.shiftId"
              @updated="appShiftEmployeesModalShowed = false"
              @cancel="appShiftEmployeesModalShowed = false"
            />
          </AppStatsCard>
        </div>
        <div>
          <AppButton
            v-if="isOpen && hasAccess('delete-Shift')"
            class="w-full md:w-auto"
            @click="router.push({ name: 'shift.close' })"
            >Закрыть смену</AppButton
          >

          <AppButton
            v-if="!isOpen && hasAccess('print-Shift')"
            outline
            @click="router.push({ name: 'shift.print', params: { shiftId: props.shiftId } })"
            >Печать отчета</AppButton
          >
        </div>
      </div>

      <div class="mt-[24px] text-lg flex gap-[12px] items-center">
        <span>Смена №{{ shift.numberId }}</span>
        <span class="text-gray-primary text-sm">
          (от {{ DateTime.fromISO(shift.createdAt).toFormat('d MMMM yyyy H:mm') }})
        </span>
        <div class="select-none flex gap-[6px] items-center text-sm font-normal">
          <template v-if="isOpen">
            <span class="w-[6px] h-[6px] rounded-full bg-green-primary"></span>
            <span class="text-green-primary">Открыта</span>
          </template>

          <template v-if="!isOpen">
            <span class="w-[6px] h-[6px] rounded-full bg-red-notify"></span>
            <span class="text-red-notify">Закрыта</span>
          </template>
        </div>
      </div>

      <AppTable
        class="mt-[24px]"
        :columns="columns"
        :data="sortedOrders"
        row-class="cursor-pointer table-row"
        hide-headers-when-empty
        @row-clicked="(order) => routeToOrder(order.orderId)"
      >
        <template #row-id="{ raw: order }">
          {{ order.orderId }}
        </template>

        <template #row-car="{ raw: order }">
          <div class="flex flex-col gap-[2px]">
            <div>{{ order.car?.title ?? '—' }}</div>
            <div class="text-gray-primary text-sm">{{ order.car?.regNumber }}</div>
          </div>
        </template>

        <template #row-employees="{ row: employees }">
          <div class="flex flex-wrap gap-[6px] font-normal">
            <div
              v-for="employee in employees"
              :key="employee"
              class="text-gray-primary"
            >
              <span>{{ employee }}</span>
            </div>
          </div>
        </template>

        <template #row-pay="{ raw: order }">
          <div class="flex flex-col gap-[4px]">
            <div>{{ formatMoney(order.price) }}</div>
            <div
              v-if="order.payments?.length > 0"
              class="text-sm md:text-sm text-gray-primary flex flex-wrap gap-x-[4px]"
            >
              <template v-if="order.payments.length === 1">
                <span>{{ order.payments[0].account.title }}</span>
              </template>

              <template v-else>
                <span
                  v-for="payment in order.payments"
                  :key="payment.paymentId"
                  >{{ payment.account.title }} {{ formatMoney(payment.amount) }}
                </span>
              </template>
            </div>
          </div>
        </template>

        <template #row-date="{ raw: order }">
          <div
            class="flex flex-col gap-[4px]"
            :class="{ deleted: order.status === OrderStatus.DELETED }"
          >
            <div>
              {{ DateTime.fromISO(order.createdAt).toFormat('d MMMM yyyy') }}
            </div>
            <div class="flex gap-[4px] items-center">
              <div>
                <AppIcon
                  class="text-[#AEAEAE]"
                  :icon="IconClock"
                  :width="12"
                  :height="12"
                />
              </div>
              <div class="text-gray-primary text-sm">{{ DateTime.fromISO(order.createdAt).toFormat('HH:mm') }}</div>
            </div>
          </div>
        </template>

        <template #row-status="{ raw: order }">
          <div>
            <span
              v-if="order.status === OrderStatus.COMPLETED"
              class="text-green-primary"
            >
              Выполнен
            </span>

            <span
              v-if="order.status === OrderStatus.DELETED"
              class="text-red-notify"
            >
              Удален
            </span>
          </div>
        </template>

        <template #empty>
          <div class="flex flex-col gap-y-[18px] items-center justify-center grow">
            <div class="text-gray-primary">Нет выполненных либо отмененных заказов</div>
            <div>
              <AppButton
                v-if="isOpen && hasAccess('create-Order')"
                outline
                @click="router.push({ name: 'order.new' })"
                >Создать заказ</AppButton
              >
            </div>
          </div></template
        >
      </AppTable>
    </div>

    <template
      v-if="completedOrders?.length > 0"
      #footer-sticky
    >
      <div class="py-[16px] md:py-0 md:h-[70px] flex flex-wrap gap-[12px] justify-between items-center">
        <div class="flex flex-wrap gap-[12px] items-center">
          <div class="font-medium">
            {{ completedOrders?.length }} {{ plural(completedOrders?.length, ['заказ', 'заказа', 'заказов']) }} за смену
          </div>
          <div class="font-medium text-gray-primary">/ Сумма: {{ formatMoney(totalIncome) }}</div>
          <div class="text-gray-primary">
            /
            <span
              v-for="(account, index) in totalIncomes"
              :key="account.accountId"
            >
              <span v-if="index !== 0">, </span>{{ account.title }}: {{ formatMoney(account.total) }}</span
            >
          </div>
        </div>
      </div>
    </template>
  </CabinetLayout>
</template>
