import http from '@/libs/http.js';

export const getWorkplaces = () => {
  return http.get('/api/v2/workplace');
};

export const storeWorkplace = (title) => {
  return http.post('/api/v2/workplace', { title });
};

export const updateWorkplace = (workplaceId, { index }) => {
  return http.put(`/api/v2/workplace/${workplaceId}`, { index });
};

export const destroyWorkplace = (workplaceId) => {
  return http.delete('/api/v2/workplace/' + workplaceId);
};
