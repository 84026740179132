<script setup>
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import { ref, watch } from 'vue';
import { createAuthCode, getCsrf } from '@/api/auth.js';
import AuthType from '@/enums/AuthType.js';
import useVuelidate from '@vuelidate/core';
import { useToast } from '@/use/useToast.js';

const emit = defineEmits(['code-requested']);

const phone = ref(null);
const loading = ref(false);
const vuelidate = await useVuelidate();

watch(phone, () => {
  if (phone.value && phone.value.toString().length === 11) {
    login();
  }
});

const login = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    const csrfResponse = await getCsrf();
    const createAuthCodeResponse = await createAuthCode(AuthType.LOGIN, phone.value);

    if (createAuthCodeResponse.data?.messengerId) {
      emit('code-requested', createAuthCodeResponse.data.id, createAuthCodeResponse.data.messengerId, phone.value);
    }
  } catch (error) {
    if (error.response?.data?.errors) {
      useToast().error(error.response?.data?.message);
    }
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <div class="flex flex-col px-[16px] md:px-0 w-full md:w-[410px]">
    <div class="text-xl lg:text-2xl font-medium text-center">Вход в кабинет</div>

    <AppInput
      v-model="phone"
      data-test="phone"
      mask="phone"
      placeholder="Введите номер телефона"
      class="mt-[24px]"
      autofocus
    />

    <div class="mt-[56px] flex justify-center">
      <AppButton
        :disabled="loading"
        :loading="loading"
        @click="login()"
        >Войти</AppButton
      >
    </div>
  </div>
</template>
