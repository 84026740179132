<script setup>
import AppModal from '@/components/AppModal.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, onMounted } from 'vue';
import AppScrollable from '@/components/AppScrollable.vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { roleStore, roleUpdate } from '@/api/roles.js';
import AppField from '@/components/AppField.vue';
import { useArrayUtils } from '@/use/useArrayUtils.js';

const props = defineProps({
  role: {
    type: Object,
    required: false,
    default: null,
  },
  permissions: {
    type: Object,
    required: true,
    default: null,
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const queryClient = useQueryClient();
const { includesArray } = useArrayUtils();
const loading = ref(false);

const permissionsData = computed(() => props.permissions?.permissions);
const permissionsLabels = computed(() => props.permissions?.labels);
const permissionsPattern = computed(() => props.permissions?.template);
const vuelidate = await useVuelidate();

const title = ref(null);
const setPermissions = ref(null);
const classPermissions = ref(null);

const setClassPermissions = () => {
  return Object.entries(permissionsPattern.value).map((item) => {
    return includesArray(setPermissions.value, item.pop()) ? item.pop() : null;
  });
};

const setGroupPermissions = (state, permissions) => {
  if (state) {
    return Array.from(new Set([...setPermissions.value, ...permissions]));
  }
  return setPermissions.value.filter((item) => !permissions.includes(item));
};

onMounted(() => {
  title.value = props.role ? props.role?.name : '';
  // todo refactoring
  // eslint-disable-next-line no-unsafe-optional-chaining
  setPermissions.value = props.role ? [...props.role?.permissions] : [];
  classPermissions.value = permissionsPattern.value ? setClassPermissions() : [];
});

const close = () => {
  emit('closed');
};

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    await roleStore({ title: title.value, permissions: setPermissions.value });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['roles', 'store'].indexOf(key) !== -1),
    });
    useToast().show('Группа успешно добавлена.');
    emit('saved');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const update = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }
  loading.value = true;

  try {
    await roleUpdate(props.role.id, { title: title.value, permissions: setPermissions.value });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['roles', 'update'].indexOf(key) !== -1),
    });
    useToast().show('Группа успешно обновлена.');
    emit('saved');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const modalTitle = computed(() => {
  return props.role ? 'Редактирование групп пользователей' : 'Добавление групп пользователей';
});

const getPermissionDesc = (permission) => {
  return permissionsData.value.find((el) => el.name === permission)?.description;
};
</script>

<template>
  <AppModal
    :show="isShow"
    width="large"
    @close="close()"
  >
    <template #title>{{ modalTitle }}</template>

    <AppField>
      <div class="text-base text-gray-900 mb-[12px]">Название группы <span style="color: red">*</span></div>
      <AppInput
        v-model="title"
        required
      />
    </AppField>
    <div class="mt-[20px]">
      <div class="text-base text-gray-900 mb-[12px]">
        Права для пользователей группы <span style="color: red">*</span>
      </div>
      <div class="text-sm text-gray-primary">Необходимо выбрать хотя бы одно право доступа</div>
    </div>

    <AppScrollable
      v-if="permissionsData"
      class="mt-[24px] !h-[300px]"
    >
      <ul>
        <li
          v-for="(section, _class) in permissionsPattern"
          :key="_class"
          class="mb-[24px] ml-[5px]"
        >
          <AppCheckbox
            v-model="classPermissions"
            class="text-lg"
            :label="permissionsLabels[_class]"
            :true-value="_class"
            :data-test="'permission-group' + '-' + _class"
            @change="setPermissions = setGroupPermissions($event.target.checked, section)"
          />
          <ul
            :ref="_class"
            class="ml-[24px]"
          >
            <li
              v-for="(permission, j) in section"
              :key="j"
              class="mt-[12px]"
            >
              <AppCheckbox
                v-model="setPermissions"
                :label="getPermissionDesc(permission)"
                :true-value="permission"
                :no-wrap-label="true"
                @change="classPermissions = setClassPermissions()"
              />
            </li>
          </ul>
        </li>
      </ul>
    </AppScrollable>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="props.role ? update() : save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
