<script setup>
import { useModelQuery } from '@/query/useCarQuery.js';
import { computed } from 'vue';
import AppSelect from '@/components/AppSelect.vue';

const props = defineProps({
  markId: {
    type: [Number, null],
    required: true,
  },
});

const emit = defineEmits(['model-selected']);

const markId = computed(() => {
  return props.markId;
});

const enabled = computed(() => {
  return markId.value > 0;
});

const { data } = useModelQuery({ markId, enabled });

const models = computed(() => {
  return data?.value?.data ?? [];
});

const selectModel = (modelId) => {
  emit('model-selected', modelId);
};
</script>

<template>
  <div
    class="flex flex-col gap-y-[12px]"
    data-test="model"
  >
    <div>Модель</div>
    <div>
      <AppSelect
        data-test="modelId"
        :disabled="props.markId === null"
        option-value="modelId"
        option-label="title"
        :options="models"
        placeholder="Не выбрана"
        @changed="selectModel"
      />
    </div>
  </div>
</template>
