<script setup>
import AppInput from '@/components/AppInput.vue';
import AppDatepicker from '@/components/AppDatepicker.vue';
import AppSelect from '@/components/AppSelect.vue';
import OperationDirection from '@/enums/OperationDirection.js';
import { computed, ref, watchEffect } from 'vue';
import { useOperationCategoriesQuery } from '@/query/useOperationQuery.js';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import { watchDebounced } from '@vueuse/core';

const props = defineProps({
  amountRangeStart: {
    type: [String, null],
    required: true,
  },
  amountRangeEnd: {
    type: [String, null],
    required: true,
  },
  dateRangeStart: {
    type: [String, null],
    required: true,
  },
  dateRangeEnd: {
    type: [String, null],
    required: true,
  },
  direction: {
    type: [Array, null],
    required: true,
  },
  categoryId: {
    type: Array,
    required: true,
  },
  accountId: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits([
  'update:amountRangeStart',
  'update:amountRangeEnd',
  'update:dateRangeStart',
  'update:dateRangeEnd',
  'update:direction',
  'update:categoryId',
  'update:accountId',
]);

const directions = [
  {
    label: 'Приход',
    value: OperationDirection.INCOME,
  },
  {
    label: 'Расход',
    value: OperationDirection.EXPENSE,
  },
];

const { data: categories, isLoading: isCategoriesLoading } = useOperationCategoriesQuery({
  select: (response) => response?.data?.data,
});

const { data: accounts, isLoading: isAccountsLoading } = useAccountsQuery(
  { isArchived: false },
  { select: (response) => response?.data?.data }
);

const isLoading = computed(() => {
  return isCategoriesLoading.value;
});

const amountRangeStart = ref(props.amountRangeStart);
const amountRangeEnd = ref(props.amountRangeEnd);
const dateRangeStart = ref(null);
const dateRangeEnd = ref(null);
const direction = ref(null);
const categoryId = ref([]);
const accountId = ref([]);

watchDebounced(
  [amountRangeStart, amountRangeEnd, dateRangeStart, dateRangeEnd, direction, categoryId, accountId],
  () => {
    emit('update:amountRangeStart', amountRangeStart.value ? amountRangeStart.value * 100 : null);
    emit('update:amountRangeEnd', amountRangeEnd.value ? amountRangeEnd.value * 100 : null);
    emit('update:dateRangeStart', dateRangeStart.value);
    emit('update:dateRangeEnd', dateRangeEnd.value);
    emit('update:direction', direction.value);
    emit('update:categoryId', categoryId.value);
    emit('update:accountId', accountId.value);
  },
  { debounce: 500, maxWait: 2000 }
);
</script>

<template v-if="categories && accounts">
  <div class="flex flex-wrap gap-[24px]">
    <!-- фильтр по сумме -->
    <div class="flex items-center gap-x-[12px]">
      <div>Сумма</div>
      <div class="flex gap-x-[6px] items-center">
        <AppInput
          v-model="amountRangeStart"
          mask="number"
          placeholder="от"
          class="w-[132px]"
        />
        <div class="w-[12px] h-[1px] bg-gray-secondary"></div>
        <AppInput
          v-model="amountRangeEnd"
          mask="number"
          placeholder="до"
          class="w-[132px]"
        />
      </div>
    </div>

    <!-- фильтр по дате -->
    <div class="flex items-center gap-x-[12px]">
      <div>Период</div>
      <div class="flex gap-x-[6px] items-center">
        <AppDatepicker
          v-model="dateRangeStart"
          placeholder="от"
          class="w-[132px]"
        />
        <div class="w-[12px] h-[1px] bg-gray-secondary"></div>
        <AppDatepicker
          v-model="dateRangeEnd"
          placeholder="до"
          class="w-[132px]"
        />
      </div>
    </div>

    <!-- фильтр по направлению -->
    <AppSelect
      v-model="direction"
      class="w-[172px] lg:grow"
      placeholder="Направление"
      :options="directions"
    />

    <!-- фильтр по статье -->
    <AppSelect
      v-model="categoryId"
      multiple
      class="w-[172px] lg:grow"
      placeholder="Статья"
      :options="categories"
      option-label="title"
      option-value="categoryId"
    />

    <!-- фильтр по счет -->
    <AppSelect
      v-model="accountId"
      multiple
      class="w-[172px] lg:grow"
      placeholder="Счет"
      :options="accounts"
      option-label="title"
      option-value="accountId"
    />
  </div>
</template>
