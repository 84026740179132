const PERCENT = 'percent';
const FIXED = 'fixed';
const PERCENT_AND_FIXED = 'percent_and_fixed';
const PERCENT_OR_FIXED = 'percent_or_fixed';

export default {
  PERCENT,
  FIXED,
  PERCENT_AND_FIXED,
  PERCENT_OR_FIXED,
};
