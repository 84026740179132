<script setup>
import AppModal from '@/components/AppModal.vue';
import { onUnmounted, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { Cropper } from 'vue-advanced-cropper';
import { useUtils } from '@/use/useUtils.js';
const { fetchAuth } = useAuthStore();

import 'vue-advanced-cropper/dist/style.css';
import { uploadBusinessPhoto } from '@/api/business.js';
import { useToast } from '@/use/useToast.js';
import { useAuthStore } from '@/store/AuthStore.js';

const image = ref({
  src: null,
  type: null,
});

const cropper = ref(null);
const file = ref(null);

const reset = () => {
  image.value = {
    src: null,
    type: null,
  };
};

const loadImage = (event) => {
  const { files } = event.target;

  if (files && files[0]) {
    if (image.value.src) {
      URL.revokeObjectURL(image.value.src);
    }
    const blob = URL.createObjectURL(files[0]);

    const reader = new FileReader();
    reader.onload = (e) => {
      image.value = {
        src: blob,
        type: useUtils().getMimeType(e.target.result, files[0].type),
      };
    };

    reader.readAsArrayBuffer(files[0]);
  }
};

onUnmounted(() => {
  if (image.value.src) {
    URL.revokeObjectURL(image.value.src);
  }
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const loading = ref(false);

const close = () => {
  emit('closed');
};

const save = () => {
  const { canvas } = cropper.value.getResult();
  canvas.toBlob(async (blob) => {
    try {
      await uploadBusinessPhoto(blob);
      await fetchAuth();

      useToast().show('Фотография обновлена');
      emit('saved');
    } catch (error) {
      loading.value = false;
      if (error.response) {
        useToast().error(
          error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
        );
      } else {
        throw error;
      }
    }
  }, image.value.type);
};
</script>

<template>
  <AppModal
    :show="isShow"
    @close="close()"
  >
    <template #title>Загрузка новой фотографии</template>

    <div
      class="flex flex-col gap-[40px] items-center p-[40px]"
      v-if="!image.src"
    >
      <div class="text-center">
        Хорошая фотография сделает ваш бизнес более узнаваемым. Вы можете загрузить изображение в формате JPG, GIF, PNG,
        WEBP или HEIC/HEIF.
      </div>
      <div>
        <AppButton @click="$refs.file.click()">
          <input
            ref="file"
            type="file"
            accept="image/*"
            @change="loadImage($event)"
            class="hidden"
          />
          Выбрать файл
        </AppButton>
      </div>
      <div class="text-sm text-center text-gray-primary">
        Если у вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера.
      </div>
    </div>
    <div v-else>
      <Cropper
        :stencil-props="{
          aspectRatio: 1 / 1,
        }"
        ref="cropper"
        class="upload-example__cropper"
        check-orientation
        :src="image.src"
      />
    </div>

    <template
      #actions
      v-if="image.src"
    >
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
