import { useQuery } from '@tanstack/vue-query';
import { getStorage, getStorageByOrder, getStorageQrCode, getStorages } from '@/api/storage.js';
import { unref } from 'vue';

export function useStorageQuery({ orderId, storageId, include } = {}, options = {}) {
  const queryKey = ['storage', storageId, orderId, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      if (orderId) {
        return getStorageByOrder({ orderId, include });
      }

      return getStorage({ storageId, include });
    },
    ...options,
  });
}

export function useStorageQrCodeQuery({ storageId } = {}, options = {}) {
  const queryKey = ['storage', 'qr-code', storageId];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getStorageQrCode(storageId);
    },
    ...options,
  });
}

export function useStoragesQuery({ status, clientId, search, page, perPage, include } = {}, options = {}) {
  const queryKey = ['storage', status, clientId, search, page, perPage, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getStorages({
        status: unref(status),
        clientId: unref(clientId),
        search: unref(search),
        page: unref(page),
        perPage: unref(perPage),
        include: unref(include),
      });
    },
    ...options,
  });
}
