import { defineStore } from 'pinia';

export const useSidebarStore = () => {
  const innerStore = defineStore({
    id: 'sidebar',

    state: () => ({
      expanded: true,
    }),

    actions: {
      toggle() {
        this.expanded = !this.expanded;
      },
    },
  });

  return innerStore();
};
