import { useQuery } from '@tanstack/vue-query';
import { getAccount, getAccounts } from '@/api/account.js';

export const useAccountQuery = ({ accountId } = {}, options = {}) => {
  const queryKey = ['account', { accountId }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAccount(accountId);
    },
    ...options,
  });
};

export const useAccountsQuery = ({ isArchived } = {}, options = {}) => {
  const queryKey = ['account', { isArchived }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAccounts({ isArchived });
    },
    ...options,
  });
};
