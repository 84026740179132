<script setup>
import AppTable from '@/components/AppTable.vue';
import { formatMoney } from '@/use/useFormat.js';
import AppIcon from '@/components/AppIcon.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { orderWorkRemove } from '@/api/order.js';
import AppScrollable from '@/components/AppScrollable.vue';
import { useToast } from '@/use/useToast.js';
import { ref } from 'vue';

const props = defineProps({
  works: {
    type: Array,
    required: true,
  },
});

const columns = [
  {
    template: 'service',
    label: 'Выбранные услуги',
    width: '2fr',
  },
  {
    field: (work) => {
      return formatMoney(work.price);
    },
    label: 'Цена',
    width: '100px',
  },
  {
    field: 'count',
    label: 'Кол-во',
    width: '78px',
  },
  {
    field: (work) => {
      return formatMoney(work.price * work.count);
    },
    label: 'Стоимость',
    width: '100px',
  },
  {
    template: 'actions',
    label: '',
    width: '30px',
  },
];

const getCarClassTitle = (carClass) => {
  switch (carClass) {
    case 'passenger':
      return 'Легковой';

    case 'suv':
      return 'Suv';

    case 'jeep':
      return 'Внедорожник';

    case 'commercial':
      return 'Коммерческий';
  }
};

const queryClient = useQueryClient();
const loading = ref(false);

const removeWork = async (workId) => {
  if (loading.value) {
    return;
  }

  loading.value = true;

  try {
    await orderWorkRemove(workId);
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'order'),
    });
    loading.value = false;
  }
};
</script>

<template>
  <AppScrollable class="grow">
    <AppTable
      :data="props.works"
      :columns="columns"
      class="flex flex-col grow text-sm h-full"
      sticky-headers
    >
      <template #row-service="{ raw: work }">
        <div class="flex gap-x-[5px]">
          <span>{{ work.title }}</span>
          <span
            v-if="work.meta"
            class="text-gray-primary"
            >({{ getCarClassTitle(work.meta?.car_class) }}, R{{ work.meta?.wheel_diameter }})</span
          >
        </div>
      </template>

      <template #row-actions="{ raw: work }">
        <AppIcon
          :width="16"
          :height="16"
          :icon="IconTrash"
          class="text-gray-primary hover:text-red-notify cursor-pointer"
          :class="{ 'cursor-wait': loading }"
          @click="removeWork(work.workId)"
        />
      </template>

      <template #empty>
        <div class="flex grow justify-center items-center">
          <span class="text-gray-primary">Выберите услуги</span>
        </div>
      </template>
    </AppTable>
  </AppScrollable>
</template>
