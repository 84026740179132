<template>
  <svg
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.44712 14.0359L10.0657 13.3426C10.8246 12.492 12.1863 12.6026 12.7981 13.5645C13.3681 14.4606 14.6062 14.6305 15.3964 13.921L16.5176 12.9143M1.48242 14.2251L5.12074 13.492C5.31389 13.4531 5.49124 13.358 5.63052 13.2186L13.7752 5.0694C14.1657 4.67869 14.1655 4.04537 13.7747 3.65498L12.0493 1.93158C11.6586 1.54136 11.0256 1.54163 10.6353 1.93218L2.48972 10.0822C2.35071 10.2213 2.25579 10.3983 2.21684 10.591L1.48242 14.2251Z"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
