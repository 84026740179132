<script setup>
import {
  VueYandexMaps,
  YandexMap,
  YandexMapControls,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapEntity,
  YandexMapGeolocationControl,
  YandexMapMarker,
  YandexMapZoomControl,
} from 'vue-yandex-maps';
import { ref, shallowRef, watch } from 'vue';
import SettingsMainAddressSearch from '@/modules/settings/components/SettingsMainAddressSearch.vue';
/*globals ymaps3*/

const props = defineProps({
  coordinates: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['coordinates-changed']);

const map = shallowRef(null);

const mapCoordinates = ref(props.coordinates.length > 0 ? props.coordinates : [30.314997, 59.938784]);
const coordinates = ref(props.coordinates);
const address = ref();

const onMarkerMoved = (selectedCoordinates) => {
  coordinates.value = selectedCoordinates;
  emit('coordinates-changed', coordinates.value);
  updateAddress();
};

const updateAddress = () => {
  if (!coordinates.value.length) {
    return;
  }

  ymaps3
    .search({
      text: coordinates.value,
      limit: 1,
    })
    .then(function (res) {
      if (!res[0]?.properties) {
        return;
      }

      address.value = res[0].properties.description + ', ' + res[0].properties.name;
    });
};

const updateCoordinates = (query) => {
  if (!query) {
    return;
  }

  ymaps3
    .search({
      text: query,
      limit: 1,
    })
    .then(function (res) {
      if (!res[0]?.geometry?.coordinates) {
        return;
      }

      mapCoordinates.value = res[0].geometry.coordinates;
      coordinates.value = res[0].geometry.coordinates;
      emit('coordinates-changed', coordinates.value);
    });
};

watch(VueYandexMaps.loadStatus, (status) => {
  if (status !== 'loaded') {
    return;
  }

  updateAddress();
});
</script>

<template>
  <div class="w-full h-[300px]">
    <YandexMap
      v-model="map"
      :settings="{
        location: {
          center: mapCoordinates,
          zoom: 17,
        },
      }"
    >
      <YandexMapDefaultFeaturesLayer />
      <YandexMapMarker
        :settings="{
          coordinates: mapCoordinates,
          title: '',
          color: 'red',
          popup: { position: 'top' },
          draggable: true,
          onDragEnd: onMarkerMoved,
        }"
      >
        <div class="w-[30px] h-[30px] bg-red-notify rounded-full"></div>
      </YandexMapMarker>
      <YandexMapDefaultSchemeLayer />
      <YandexMapControls :settings="{ position: 'right' }">
        <YandexMapZoomControl />
      </YandexMapControls>
      <YandexMapControls :settings="{ position: 'left' }">
        <YandexMapGeolocationControl />
      </YandexMapControls>

      <YandexMapEntity class="absolute top-0 left-0 right-0 z-[2000] p-[10px]">
        <SettingsMainAddressSearch
          :address="address"
          @address-changed="updateCoordinates"
        />
      </YandexMapEntity>
    </YandexMap>
  </div>
</template>
