const EMPLOYEE = 'employee';
const ADMIN = 'admin';
const MANAGER = 'manager';
const OWNER = 'owner';

export default {
  EMPLOYEE,
  MANAGER,
  ADMIN,
  OWNER,
};
