<script setup>
import OrderEditPaymentAccount from '@/modules/order/components/OrderEditPaymentAccount.vue';
import { ref, watch, unref } from 'vue';

const emit = defineEmits(['account-selected']);

const accountId = ref(null);

watch(accountId, () => {
  emit('account-selected', { accountId: unref(accountId) });
});
</script>

<template>
  <OrderEditPaymentAccount v-model="accountId" />
</template>
