import { useQuery } from '@tanstack/vue-query';
import { getClient, getClients, getClientStats } from '@/api/client.js';
import { unref } from 'vue';

export function useClientQuery({ clientId, include } = {}, options = {}) {
  const queryKey = ['client', clientId, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getClient({ clientId, include });
    },
    ...options,
  });
}

export function useClientStatsQuery({ clientId } = {}, options = {}) {
  const queryKey = ['client', clientId, 'stats'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getClientStats(clientId);
    },
    ...options,
  });
}

export function useClientsQuery({ searchQuery, include, perPage, page, order, sort, filter } = {}, options = {}) {
  const queryKey = ['client', searchQuery, include, perPage, page, order, sort, filter];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getClients({
        searchQuery: unref(searchQuery),
        include,
        perPage: unref(perPage),
        page: unref(page),
        order: unref(order),
        sort: unref(sort),
        filter: unref(filter),
      });
    },
    ...options,
  });
}
