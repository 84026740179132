<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import AppTable from '@/components/AppTable.vue';
import { formatMoney, formatPhone } from '@/use/useFormat.js';
import { useEmployeesAccrualsQuery, useEmployeesPayrollsQuery, useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import PayrollCreateModal from '@/modules/finance/components/PayrollCreateModal.vue';
import AppButton from '@/components/AppButton.vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/finance/finance-tabs.js';

const include = ['recipient', 'sender', 'category', 'employee'];
const perPage = 30;
const options = { keepPreviousData: true };

const { data: accruals, isLoading: isAccrualsLoading } = useEmployeesAccrualsQuery({
  select: (response) => response?.data?.data,
});
const { data: payrolls, isLoading: isPayrollsLoading } = useEmployeesPayrollsQuery({
  select: (response) => response?.data?.data,
});
const { data: employees, isLoading: isEmployeesLoading } = useEmployeesQuery(
  {},
  { select: (response) => response?.data?.data }
);

const isLoading = computed(() => {
  return isAccrualsLoading.value || isPayrollsLoading.value || isEmployeesLoading.value;
});

const employeesWithAccrualsPayrolls = computed(() => {
  if (isLoading.value || !employees.value) {
    return [];
  }

  return employees.value.map((employee) => {
    return {
      ...employee,
      accrual: accruals.value.find((accrual) => accrual.employeeId === employee.employeeId),
      payroll: payrolls.value.find((payroll) => payroll.employeeId === employee.employeeId),
    };
  });
});

const totalAmountOfCompletedOrders = computed(() => {
  if (!employeesWithAccrualsPayrolls.value) {
    return 0;
  }

  return employeesWithAccrualsPayrolls.value.reduce((total, employee) => {
    return total + (employee.accrual?.amountOfCompletedOrders ?? 0);
  }, 0);
});

const totalSurcharge = computed(() => {
  if (!employeesWithAccrualsPayrolls.value) {
    return 0;
  }

  return employeesWithAccrualsPayrolls.value.reduce((total, employee) => {
    return total + (employee.accrual?.surcharge ?? 0);
  }, 0);
});

const totalAccrual = computed(() => {
  if (!employeesWithAccrualsPayrolls.value) {
    return 0;
  }

  return employeesWithAccrualsPayrolls.value.reduce((total, employee) => {
    return total + (employee.accrual?.total ?? 0);
  }, 0);
});

const totalPayroll = computed(() => {
  if (!employeesWithAccrualsPayrolls.value) {
    return 0;
  }

  return employeesWithAccrualsPayrolls.value.reduce((total, employee) => {
    return total + (employee.payroll?.total ?? 0);
  }, 0);
});

const totalBalance = computed(() => {
  if (!employeesWithAccrualsPayrolls.value) {
    return 0;
  }

  return employeesWithAccrualsPayrolls.value.reduce((total, employee) => {
    return total + (employee.businessEmployee?.balance ?? 0);
  }, 0);
});

const payrollColumns = computed(() => {
  return [
    {
      label: 'Сотрудник',
      field: (employee) => {
        return employee.title ?? formatPhone(employee.phone);
      },
      footer: () => {
        return 'Итого';
      },
    },
    {
      label: '% от заказов',
      field: (employee) => {
        return formatMoney(employee.accrual?.amountOfCompletedOrders ?? 0);
      },
      footer: () => {
        return formatMoney(totalAmountOfCompletedOrders.value);
      },
    },
    {
      label: 'Доплаты',
      field: (employee) => {
        return formatMoney(employee.accrual?.surcharge ?? 0);
      },
      footer: () => {
        return formatMoney(totalSurcharge.value);
      },
    },
    {
      label: 'Итого',
      field: (employee) => {
        return formatMoney(employee.accrual?.total ?? 0);
      },
      footer: () => {
        return formatMoney(totalAccrual.value);
      },
    },
    {
      label: 'Выплачено',
      field: (employee) => {
        return formatMoney(employee.payroll?.total ?? 0);
      },
      footer: () => {
        return formatMoney(totalPayroll.value);
      },
    },
    {
      label: 'Текущий баланс',
      field: (employee) => {
        return formatMoney(employee.businessEmployee.balance);
      },
      footer: () => {
        return formatMoney(totalBalance.value);
      },
    },
    {
      label: 'Действия',
      template: 'actions',
    },
  ];
});

const router = useRouter();
const payrollCreateModalShow = ref(false);
const payrollCreateEmployeeId = ref(null);

const payrollCreateModalOpen = (employeeId) => {
  payrollCreateEmployeeId.value = employeeId;
  payrollCreateModalShow.value = true;
};

const payrollCreateModalClose = () => {
  payrollCreateEmployeeId.value = null;
  payrollCreateModalShow.value = false;
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading"
    :hide-in-loading="true"
  >
    <PayrollCreateModal
      v-if="payrollCreateModalShow"
      :employee-id="payrollCreateEmployeeId"
      @closed="payrollCreateModalClose()"
      @created="payrollCreateModalClose()"
    />

    <AppTable
      class="mt-[24px]"
      :data="employeesWithAccrualsPayrolls"
      :columns="payrollColumns"
      row-data-test="employee-row"
    >
      <template #row-actions="{ raw: employee }">
        <AppButton
          class="text-xs"
          outline
          @click="payrollCreateModalOpen(employee.employeeId)"
          >Выплатить</AppButton
        >
      </template>
    </AppTable>
  </CabinetLayout>
</template>
