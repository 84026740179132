<script setup>
import { computed, ref, toRef } from 'vue';
import { useOrdersQuery } from '@/query/useOrderQuery.js';
import AppTable from '@/components/AppTable.vue';
import { DateTime } from 'luxon';
import { formatMoney } from '@/use/useFormat.js';
import AppPagination from '@/components/AppPagination.vue';
import { useOrder } from '@/use/useOrder.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/order/order-tabs.js';
import OrderStatus from '@/enums/OrderStatus.js';

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const include = ['account', 'car.mark', 'car.model', 'client', 'payments.account'];
const currentPage = ref(1);
const perPage = 30;
const options = { keepPreviousData: true };

const { data, isLoading, isFetching } = useOrdersQuery(
  {
    status: [OrderStatus.IN_PROGRESS, OrderStatus.COMPLETED, OrderStatus.DELETED],
    perPage,
    page: currentPage,
    include,
  },
  options
);

const orders = computed(() => {
  return data?.value?.data;
});

const total = computed(() => {
  return data?.value?.meta?.total | 1;
});

const columns = [
  {
    label: '№',
    field: 'numberId',
    width: '100px',
  },
  {
    label: 'Дата',
    template: 'date',
  },
  {
    label: 'Стоимость',
    template: 'price',
  },
  {
    label: 'Автомобиль',
    template: 'car',
  },
  {
    label: 'Клиент',
    template: 'client',
  },
  {
    label: 'Статус',
    template: 'status',
  },
];

const { goToOrder } = useOrder();
</script>

<template>
  <CabinetLayout
    :loading="isLoading || isFetching"
    :tabs="tabs"
  >
    <div class="flex flex-col gap-y-[24px]">
      <AppTable
        v-if="orders"
        :data="orders"
        :columns="columns"
        row-class="cursor-pointer table-row"
        @row-clicked="goToOrder"
      >
        <template #row-date="{ raw: order }">
          <div
            class="flex gap-x-[10px]"
            :class="{ deleted: order.status === OrderStatus.DELETED }"
          >
            <span>{{ DateTime.fromISO(order.createdAt).toFormat('d MMMM yyyy') }}</span>
            <span class="text-gray-primary">{{ DateTime.fromISO(order.createdAt).toFormat('HH:mm') }}</span>
          </div>
        </template>

        <template #row-price="{ raw: order }">
          <div class="flex flex-col gap-[4px]">
            <div>{{ formatMoney(order.price) }}</div>
            <div
              v-if="order.payments?.length > 0"
              class="text-xs text-gray-primary flex"
            >
              <template v-if="order.payments.length === 1">
                <span>{{ order.payments[0].account.title }}</span>
              </template>

              <template v-else>
                <span
                  v-for="(payment, index) in order.payments"
                  :key="payment.paymentId"
                  ><span v-if="index > 0">, </span> {{ payment.account.title }} {{ formatMoney(payment.amount) }}
                </span>
              </template>
            </div>
          </div>
        </template>

        <template #row-car="{ raw: order }">
          <div class="flex flex-col">
            <div>{{ order.car?.title }}</div>
            <div class="text-gray-primary">{{ order.car?.regNumber }}</div>
          </div>
        </template>

        <template #row-client="{ raw: order }">
          <router-link
            v-if="order.client"
            class="text-violet-primary hover:underline underline-offset-4"
            :to="{ name: 'client.show', params: { clientId: order.client.clientId } }"
            >{{ order.client.title }}</router-link
          >
        </template>

        <template #row-status="{ raw: order }">
          <span v-if="order.status === OrderStatus.IN_PROGRESS">В работе</span>
          <span
            v-if="order.status === OrderStatus.COMPLETED"
            class="text-green-primary"
            >Завершен</span
          >
          <span
            v-if="order.status === OrderStatus.DELETED"
            class="text-red-notify"
            >Удален</span
          >
        </template>
      </AppTable>

      <div class="flex justify-end">
        <AppPagination
          v-model="currentPage"
          :total="total"
          :per-page="perPage"
        />
      </div>
    </div>
  </CabinetLayout>
</template>
