<template>
  <svg
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 6.5H15M4 1.5V3.5M16 1.5V3.5M19 6.5V17.5C19 19.1569 17.6569 20.5 16 20.5H4C2.34315 20.5 1 19.1569 1 17.5V6.5C1 4.84315 2.34315 3.5 4 3.5H16C17.6569 3.5 19 4.84315 19 6.5Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
