<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useConfirmDialog } from '@vueuse/core';
import AppClientEditModal from '@/components/AppClientEditModal.vue';
import { useClientQuery } from '@/query/useClientQuery.js';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
  allowEdit: {
    type: Boolean,
    required: true,
  },
});

const { data: client } = useClientQuery({ clientId: props.clientId }, { select: (response) => response?.data?.data });

const { isRevealed, reveal, confirm } = useConfirmDialog();
</script>

<template>
  <div class="flex flex-col gap-[24px]">
    <div class="text-lg font-medium">Заметка</div>
    <ClientShowCard class="flex justify-between">
      <div>
        {{ client.note ?? '—' }}
      </div>

      <div class="flex-none">
        <AppIcon
          v-if="allowEdit"
          :icon="IconPencil"
          :width="20"
          :height="20"
          class="cursor-pointer hover:text-violet-primary"
          data-test="client-show-edit-note"
          @click="reveal()"
        />

        <AppClientEditModal
          v-if="isRevealed"
          :client-id="props.clientId"
          :fields="['note']"
          @closed="confirm()"
        />
      </div>
    </ClientShowCard>
  </div>
</template>
