<script setup>
import { onUnmounted, watch } from 'vue';
import { app } from '@/main.js';
import { useProgress } from '@marcoschulte/vue3-progress';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.loading,
  () => {
    if (props.loading) {
      app.progresses.push(useProgress().start());
    } else {
      app.progresses.pop()?.finish();
    }
  },
  {
    immediate: true,
  }
);

onUnmounted(() => {
  app.progresses.pop()?.finish();
});
</script>

<template>
  <Teleport to="#app">
    <div>
      <vue3-progress-bar />
    </div>
  </Teleport>
</template>

<style>
.vue3-progress-bar-container {
  @apply fixed top-0 left-0 z-50 w-full;
  transition: all 500ms ease;
  opacity: 0;
}
.vue3-progress-bar-container[active='true'] {
  opacity: 1;
  transition: none;
}

.vue3-progress-bar {
  @apply left-0 h-[2px] w-full bg-indigo-500;
  transition: all 0.2s ease;
  transform: translate3d(-100%, 0, 0);
}
</style>
