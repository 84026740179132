<script setup>
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import { formatMoney } from '@/use/useFormat.js';
import AppButton from '@/components/AppButton.vue';
import SettingsAccountsModal from '@/modules/settings/components/SettingsAccountsModal.vue';
import { ref } from 'vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const { data: accounts, isLoading } = useAccountsQuery({}, { select: (response) => response?.data?.data });

const accountModal = ref(false);
const editableAccountId = ref(null);

const closeModal = () => {
  editableAccountId.value = null;
  accountModal.value = false;
};

const edit = (accountId) => {
  editableAccountId.value = accountId;
  accountModal.value = true;
};
</script>

<template>
  <CabinetLayout
    :loading="isLoading"
    :hide-in-loading="true"
    :tabs="tabs"
  >
    <div class="flex flex-col gap-y-[32px] w-[678px]">
      <div class="flex flex-col">
        <div
          v-for="account in accounts"
          :key="account.accountId"
          class="py-[10px] px-[8px] flex justify-between items-center hover:bg-[#FBFBFD]"
          :class="{ 'text-gray-secondary': account.isArchived }"
        >
          <div class="flex gap-x-[12px]">
            <div>{{ account.title }}</div>
            <div class="text-gray-primary">{{ formatMoney(account.balance) }}</div>
            <div
              v-if="account.isCashbox"
              class="text-violet-primary"
            >
              Счет кассы
            </div>
          </div>
          <div>
            <AppIcon
              v-if="!account.isCashbox"
              :icon="IconPencil"
              class="hover:text-violet-primary cursor-pointer"
              @click="edit(account.accountId)"
            />
          </div>
        </div>
      </div>

      <div>
        <AppButton @click="accountModal = true">Добавить счет</AppButton>
      </div>
      <SettingsAccountsModal
        v-if="accountModal"
        :account-id="editableAccountId"
        @closed="closeModal()"
        @saved="closeModal()"
      />
    </div>
  </CabinetLayout>
</template>
