import { useQuery } from '@tanstack/vue-query';
import { getOrder, getOrders } from '@/api/order.js';
import { unref } from 'vue';

export function useOrderQuery({ orderId, withTrashed, include } = {}, options = {}) {
  const queryKey = ['order', orderId, withTrashed, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getOrder({ orderId: unref(orderId), withTrashed: unref(withTrashed), include: unref(include) });
    },
    ...options,
  });
}

export function useOrdersQuery({ status, shiftId, page, perPage, include } = {}, options = {}) {
  const queryKey = ['order', status, shiftId, page, perPage, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getOrders({
        status: unref(status),
        shiftId: unref(shiftId),
        page: unref(page),
        perPage: unref(perPage),
        include: unref(include),
      });
    },
    ...options,
  });
}
