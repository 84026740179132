<script setup>
import { computed } from 'vue';
import AppIcon from '@/components/AppIcon.vue';
import IconChevronLeft from '@/components/Icons/IconChevronLeft.vue';
import IconChevronRight from '@/components/Icons/IconChevronRight.vue';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },

  total: {
    type: Number,
    required: true,
  },

  perPage: {
    type: Number,
    default: 30,
  },
});

const emit = defineEmits(['update:modelValue']);

const totalPages = computed(() => {
  return Math.ceil(props.total / props.perPage);
});

const pageSelect = (page) => {
  emit('update:modelValue', page);
};

const pageStart = computed(() => {
  if (props.modelValue - 2 > 1) {
    return props.modelValue - 2;
  }

  return 1;
});

const pageEnd = computed(() => {
  if (props.modelValue + 2 > totalPages.value) {
    return totalPages.value;
  }

  return props.modelValue + 2;
});

const pages = computed(() => {
  let pages = [];
  for (let page = pageStart.value; page <= pageEnd.value; page++) {
    pages.push(page);
  }

  return pages;
});

const pagePrevious = computed(() => {
  return props.modelValue - 1 ?? 1;
});

const pageNext = computed(() => {
  return props.modelValue + 1 > totalPages.value ? totalPages.value : props.modelValue + 1;
});
</script>

<template>
  <div
    v-if="pages.length > 1"
    class="flex gap-x-[6px] flex-wrap"
  >
    <div
      class="page-button"
      :class="{ disabled: props.modelValue === 1 }"
      @click="pageSelect(pagePrevious)"
    >
      <AppIcon
        :width="6"
        :height="12"
        :icon="IconChevronLeft"
      />
    </div>

    <div
      v-for="page in pages"
      :key="page"
      class="page-button"
      :class="{ active: page === props.modelValue }"
      @click="pageSelect(page)"
    >
      <span>{{ page }}</span>
    </div>

    <div
      v-if="pageNext"
      class="page-button"
      :class="{ disabled: props.modelValue === totalPages }"
      @click="pageSelect(pageNext)"
    >
      <AppIcon
        :width="6"
        :height="12"
        :icon="IconChevronRight"
      />
    </div>
  </div>
</template>

<style scoped>
.page-button {
  @apply select-none cursor-pointer w-[33px] h-[34px] rounded-[6px] border border-[#CBD3E4] hover:border-[#626AE9] flex items-center justify-center text-sm;
}

.active {
  @apply !bg-[#626AE9] !text-white;
}

.disabled {
  @apply !border-[#CFCFCF] !text-[#CFCFCF] !cursor-default;
}
</style>
