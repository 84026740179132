<script setup>
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import AppSelectButtons from '@/components/AppSelectButtons.vue';
import { computed, ref, watchEffect } from 'vue';
import { DateTime } from 'luxon';
import AppInput from '@/components/AppInput.vue';
import { useWorkPlanQuery, useWorkPlanStartYearQuery } from '@/query/useWorkPlanQuery.js';
import { formatMoney } from '@/use/useFormat.js';
import AppButton from '@/components/AppButton.vue';
import { updateWorkPlan } from '@/api/work-plan.js';
import { useToast } from '@/use/useToast.js';
import { useQueryClient } from '@tanstack/vue-query';

const { data } = useWorkPlanStartYearQuery();

const startYear = computed(() => {
  return data.value?.data?.year;
});

const selectedYear = ref(null);

watchEffect(() => {
  selectedYear.value = startYear?.value;
});

const options = computed(() => {
  return { enabled: !!selectedYear.value, select: (response) => response?.data?.data, keepPreviousData: true };
});
const { data: workPlans, isLoading } = useWorkPlanQuery({ year: selectedYear }, options);

const months = ref([]);

watchEffect(() => {
  months.value = [];

  workPlans.value?.forEach((workPlan) => {
    months.value.push({ ...workPlan, amount: workPlan.amount / 100 });
  });
});

const years = computed(() => {
  if (!startYear?.value) {
    return [];
  }

  const yearsList = [];
  const endYear = DateTime.now().set({ year: startYear.value }).plus({ year: 1 }).year;

  for (let year = startYear.value; year <= endYear; year++) {
    yearsList.push({
      label: year,
      value: year,
    });
  }

  return yearsList;
});

const total = computed(() => {
  return months.value?.reduce((sum, month) => sum + month.amount * 100, 0);
});

const loading = ref(false);
const queryClient = useQueryClient();

const submit = async () => {
  loading.value = true;

  try {
    const workPlanData = months.value.map((workPlan) => {
      return {
        ...workPlan,
        amount: workPlan.amount * 100,
      };
    });

    await updateWorkPlan(workPlanData);
    useToast().show('Изменения сохранены');
  } catch (error) {
    useToast().error(error.response?.data.message);
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['work-plan'].indexOf(key) !== -1),
    });
    loading.value = false;
  }
};
</script>

<template>
  <CabinetLayout
    :loading="isLoading"
    :hide-in-loading="true"
  >
    <template #header>План</template>
    <div class="flex flex-col gap-y-[24px] w-[327px]">
      <AppSelectButtons
        v-if="years && selectedYear"
        v-model="selectedYear"
        :options="years"
      />

      <div class="grid grid-cols-[70px_1fr] gap-[12px] items-center">
        <template
          v-for="workPlan in months"
          :key="workPlan.date"
        >
          <div class="capitalize">{{ DateTime.fromISO(workPlan.date).toFormat('LLLL') }}</div>
          <div>
            <AppInput v-model="workPlan.amount" />
          </div>
        </template>
      </div>

      <div class="font-medium">Итого план на год: {{ formatMoney(total) }}</div>

      <AppButton
        :disabled="loading"
        :loading="loading"
        @click="submit()"
        >Сохранить</AppButton
      >
    </div>
  </CabinetLayout>
</template>
