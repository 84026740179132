<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5385 4.79998C12.4265 2.88701 10.3482 1.59998 7.96781 1.59998C5.26888 1.59998 2.95827 3.25451 2.00425 5.59998M11.5853 5.59998H14.8008V2.39998M2.46308 11.2C3.57503 13.1129 5.65336 14.4 8.03375 14.4C10.7327 14.4 13.0433 12.7454 13.9973 10.4M4.4163 10.4H1.20078V13.6"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
