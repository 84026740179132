import { useQuery } from '@tanstack/vue-query';
import { getWorkPlan, getWorkPlanStartYear } from '@/api/work-plan.js';
import { unref } from 'vue';

export const useWorkPlanQuery = ({ year }, options = {}) => {
  const queryKey = ['work-plan', year];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getWorkPlan(unref(year));
    },
    ...options,
  });
};

export const useWorkPlanStartYearQuery = (options = {}) => {
  const queryKey = ['work-plan'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getWorkPlanStartYear();
    },
    ...options,
  });
};
