import { DateTime } from 'luxon';
import { useEmployee } from '@/use/useEmployee.js';
import { formatPhone } from '@/use/useFormat.js';
const { employeeShortName } = useEmployee();

export const workingColumns = [
  {
    label: 'Телефон',
    field: (employee) => {
      return formatPhone(employee.phone);
    },
  },
  {
    label: 'ФИО',
    field: (employee) => {
      return employeeShortName(employee);
    },
  },
  {
    label: 'Права доступа',
    field: (employee) => {
      return employee.roles ? employee.roles?.map((item) => item.name).join(', ') : 'Не установлено';
    },
  },
  {
    label: 'Условия',
    template: 'terms',
  },
  {
    label: 'Принят',
    field: (employee) => {
      if (!employee.businessEmployee.createdAt) {
        return null;
      }

      return DateTime.fromISO(employee.businessEmployee.createdAt).toFormat('d.LL.y');
    },
  },
  {
    label: 'Последняя активность',
    field: (employee) => {
      if (!employee.lastActivityAt) {
        return null;
      }

      return DateTime.fromISO(employee.lastActivityAt).toFormat('d.LL.y HH:mm');
    },
  },
  {
    template: 'actions-working',
    width: '61px',
  },
];

export const firedColumns = [
  {
    label: 'Телефон',
    field: (employee) => {
      return formatPhone(employee.phone);
    },
  },
  {
    label: 'ФИО',
    field: (employee) => {
      return employeeShortName(employee);
    },
  },
  {
    label: 'Группы пользователя',
    field: (employee) => {
      return employee.roles
        ? employee.roles?.map((item) => item.description ?? item.label).join(', ')
        : 'Не установлено';
    },
  },
  {
    label: 'Условия',
    template: 'terms',
  },
  {
    label: 'Принят',
    field: (employee) => {
      if (!employee.businessEmployee.createdAt) {
        return null;
      }

      return DateTime.fromISO(employee.businessEmployee.createdAt).toFormat('d.LL.y');
    },
  },
  {
    label: 'Уволен',
    template: 'fired',
  },
  {
    template: 'actions-fired',
    width: '61px',
  },
];
