<script setup>
import AppModal from '@/components/AppModal.vue';
import OrderClientAndCar from '@/modules/order/components/OrderClientAndCar.vue';
import { computed, ref, unref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { orderClientAndCarChange } from '@/api/order.js';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed']);

const clientData = ref(null);

const clientSelect = (data) => {
  clientData.value = data;
};

const isFilled = computed(() => {
  if (!clientData.value) {
    return false;
  }

  const isClientSelected = clientData.value.clientId;
  const isCarSelected = clientData.value.carId;
  const isClientFilled = clientData.value.firstName || clientData.value.lastName || clientData.value.phone;
  const isCarFilled = clientData.value.markId && clientData.value.modelId;

  return isClientSelected || isCarSelected || isClientFilled || isCarFilled;
});

const loading = ref(false);
const queryClient = useQueryClient();

const save = async () => {
  loading.value = true;

  try {
    await orderClientAndCarChange(props.orderId, {
      ...unref(clientData),
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['order', 'client'].indexOf(key) !== -1),
    });

    emit('closed');
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <AppModal
    :show="true"
    class="md:!w-[700px]"
    @close="emit('closed')"
  >
    <template #title>Выбор клиента</template>
    <OrderClientAndCar @client-changed="clientSelect" />

    <template #actions>
      <div class="flex justify-center">
        <AppButton
          class="!px-[40px]"
          :disabled="!isFilled || loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
