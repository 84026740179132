const ALL_SEASON = 'all-season';
const SUMMER = 'summer';
const WINTER_STUDDED = 'winter_studded';
const WINTER_STUDLESS = 'winter_studless';

export default {
  ALL_SEASON,
  SUMMER,
  WINTER_STUDDED,
  WINTER_STUDLESS,
};
