<script setup>
import { ref, unref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import OrderClientSelecting from '@/modules/order/components/OrderClientSelecting.vue';
import OrderCarSelecting from '@/modules/order/components/OrderCarSelecting.vue';
import OrderCarNew from '@/modules/order/components/OrderCarNew.vue';
import OrderClientNew from '@/modules/order/components/OrderClientNew.vue';

const emit = defineEmits(['client-changed']);

const props = defineProps({
  allowShowClients: {
    type: Boolean,
    required: true,
  },
  allowCreateClient: {
    type: Boolean,
    required: true,
  },
});

const client = ref(null);
const carId = ref(null);
const clientNewData = ref(null);
const carNewData = ref(null);

const emitSelectedClient = () => {
  emit('client-changed', {
    clientId: client.value?.clientId,
    carId: carId.value,
    ...unref(clientNewData),
    ...unref(carNewData),
  });
};

const clientSelect = (selectedClient) => {
  clientNewData.value = null;
  carNewData.value = null;
  client.value = selectedClient;
  carId.value = null;
  emitSelectedClient();
};

const carSelect = (selectedCarId) => {
  carNewData.value = null;
  carId.value = selectedCarId;
  emitSelectedClient();
};

const clientCreate = (data) => {
  clientNewData.value = data;
  emitSelectedClient();
};

const carCreate = (data) => {
  carNewData.value = data;
  emitSelectedClient();
};
</script>

<template>
  <div class="flex flex-col gap-y-[32px]">
    <div class="flex flex-wrap gap-[12px]">
      <AppButton
        v-if="allowShowClients"
        class="grow"
        :outline="client === 0"
        @click="clientSelect(null)"
        >Существующий клиент</AppButton
      >
      <AppButton
        v-if="allowCreateClient"
        class="grow"
        :outline="client !== 0"
        @click="clientSelect(0)"
        >Новый клиент</AppButton
      >
    </div>

    <div class="flex flex-col gap-y-[24px]">
      <OrderClientSelecting
        v-if="client !== 0"
        :allow-show-clients="allowShowClients"
        @client-selected="clientSelect"
      />

      <OrderCarSelecting
        v-if="client"
        :client="client"
        @car-selected="carSelect"
      />

      <OrderClientNew
        v-if="client === 0"
        @client-new-updated="clientCreate"
      />

      <OrderCarNew
        v-if="client === 0 || carId === 0"
        @car-new-updated="carCreate"
      />
    </div>
  </div>
</template>
