<script setup>
import ReportGeneralFilters from '@/modules/report/components/ReportGeneralFilters.vue';
import { computed, ref } from 'vue';
import AppTable from '@/components/AppTable.vue';
import { useAnalyticsGeneralQuery } from '@/query/useAnalyticsQuery.js';
import { formatMoney } from '@/use/useFormat.js';
import { DateTime } from 'luxon';
import { tabs } from '@/modules/report/report-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const startTime = ref(null);
const endTime = ref(null);
const groupBy = ref(null);

const enabled = computed(() => !!(startTime.value && endTime.value && groupBy.value));

const options = {
  enabled,
  select: (response) => response?.data?.data,
};

const { data: statistics, isLoading, isFetching } = useAnalyticsGeneralQuery({ startTime, endTime, groupBy }, options);

const accountsList = computed(() => {
  const accounts = {};

  statistics.value.map((statistic) => {
    return statistic.incomeAccounts?.map((account) => {
      accounts[account.accountId] = account;
    });
  });

  return Object.values(accounts);
});

const formatDate = (date) => {
  switch (groupBy.value) {
    case 'day':
      return DateTime.fromISO(date).toFormat('d MMMM y');

    case 'month':
      return DateTime.fromISO(date).toFormat('LLLL');
  }
};

const totalIncome = computed(() => {
  if (!statistics.value) {
    return 0;
  }

  return Object.values(statistics.value).reduce((total, statistic) => {
    return total + statistic.income;
  }, 0);
});

const totalExpense = computed(() => {
  if (!statistics.value) {
    return 0;
  }

  return Object.values(statistics.value).reduce((total, statistic) => {
    return total + statistic.expense;
  }, 0);
});

const totalSalary = computed(() => {
  if (!statistics.value) {
    return 0;
  }

  return Object.values(statistics.value).reduce((total, statistic) => {
    return total + statistic.salary;
  }, 0);
});

const statisticsTable = computed(() => {
  const accountsColumns = accountsList.value?.map((account) => {
    return {
      label: account.title,
      field: (statistic) => {
        const amount = statistic.incomeAccounts.find(
          (incomeAccount) => incomeAccount.accountId === account.accountId
        )?.amount;

        return formatMoney(amount);
      },
      footer: () => {
        const accountStatistics = statistics.value.map((statistic) => {
          return statistic.incomeAccounts.find((incomeAccount) => incomeAccount.accountId === account.accountId);
        });

        return formatMoney(
          accountStatistics.reduce((total, item) => {
            if (!item) {
              return total;
            }

            return total + item.amount;
          }, 0)
        );
      },
    };
  });

  const columns = [
    {
      label: 'Дата',
      template: 'date',
      footer: () => {
        return 'Итого';
      },
    },

    ...accountsColumns,

    {
      label: 'Выручка итого',
      field: (statistic) => {
        return formatMoney(statistic.income);
      },
      footer: () => {
        return formatMoney(totalIncome.value);
      },
    },

    {
      label: 'Расходы',
      field: (statistic) => {
        return formatMoney(statistic.expense);
      },
      footer: () => {
        return formatMoney(totalExpense.value);
      },
    },

    {
      label: 'Выплаты зарплат',
      field: (statistic) => {
        return formatMoney(statistic.salary);
      },
      footer: () => {
        return formatMoney(totalSalary.value);
      },
    },

    {
      label: 'Расходы итого',
      field: (statistic) => {
        return formatMoney(statistic.expense + statistic.salary);
      },
      footer: () => {
        return formatMoney(totalExpense.value + totalSalary.value);
      },
    },

    {
      label: 'Прибыль',
      field: (statistic) => {
        return formatMoney(statistic.income - statistic.expense - statistic.salary);
      },
      footer: () => {
        return formatMoney(totalIncome.value - totalExpense.value - totalSalary.value);
      },
    },
  ];

  return columns;
});
</script>

<template>
  <CabinetLayout
    :loading="isLoading || isFetching"
    :tabs="tabs"
  >
    <div class="flex flex-col gap-y-[32px]">
      <!-- @todo доделать и вывести эту статистику -->
      <!--      <ReportGeneralYearlyStatistic />-->
      <ReportGeneralFilters
        v-model:start-time="startTime"
        v-model:end-time="endTime"
        v-model:group-by="groupBy"
      />
      <div class="flex flex-col gap-y-[24px]">
        <div class="text-lg font-medium">Статистика</div>
        <AppTable
          v-if="statistics"
          sticky-headers
          :data="statistics"
          :columns="statisticsTable"
          class="text-sm"
        >
          <template #row-date="{ raw: statistic }">
            <span class="capitalize text-gray-primary">{{ formatDate(statistic.date) }}</span>
          </template>
        </AppTable>
      </div>
    </div>
  </CabinetLayout>
</template>
