<template>
  <svg
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00018 5H11.0002M5.00018 8H11.0002M5.00018 11H8.00018M3.49995 1H12.5001C13.6047 1 14.5002 1.89545 14.5001 3.00004L14.4999 15C14.4999 16.1046 13.6045 17 12.4999 17L3.49986 17C2.39529 17 1.49986 16.1045 1.49987 14.9999L1.49995 2.99999C1.49995 1.89542 2.39538 1 3.49995 1Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
