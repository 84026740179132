<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.96439 17.9645H3.96438C2.85981 17.9645 1.96438 17.069 1.96439 15.9645L1.96446 3.96451C1.96447 2.85995 2.8599 1.96452 3.96446 1.96452H12.9647C14.0693 1.96452 14.9647 2.85995 14.9647 3.96452V7.96452M10.9645 15.207L15.2072 10.9644L18.0356 13.7928L13.793 18.0355H10.9645V15.207Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
