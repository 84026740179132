import { useQuery } from '@tanstack/vue-query';
import { getBookingSettings } from '@/api/booking_settings.js';

export const useBookingSettingsQuery = (options = {}) => {
  const queryKey = ['booking-settings'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getBookingSettings();
    },
    ...options,
  });
};
