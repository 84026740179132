import { useQuery } from '@tanstack/vue-query';
import { getAuth } from '@/api/auth.js';

export function useAuthQuery(options = {}) {
  const queryKey = ['auth'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAuth();
    },
    ...options,
  });
}
