<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2664 14.3999L11.6664 13.1999V8.18216M21.2664 11.9999C21.2664 6.69797 16.9683 2.3999 11.6664 2.3999C6.36447 2.3999 2.06641 6.69797 2.06641 11.9999C2.06641 17.3018 6.36447 21.5999 11.6664 21.5999C15.2198 21.5999 18.3222 19.6693 19.9821 16.7999M18.6329 10.9464L21.0329 13.3464L23.4329 10.9464"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
