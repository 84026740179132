<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useCompanyQuery } from '@/query/useCompanyQuery.js';
import AppModalCompanyEdit from '@/components/AppModalCompanyEdit.vue';
import { ref } from 'vue';

const props = defineProps({
  companyId: {
    type: Number,
    required: true,
  },
  allowEdit: {
    type: Boolean,
    required: true,
  },
});

const { data: company } = useCompanyQuery(
  { companyId: props.companyId },
  { select: (response) => response?.data?.data }
);

const modalEditShow = ref(false);
</script>

<template>
  <div
    v-if="company"
    class="flex flex-col gap-[24px]"
  >
    <div class="text-lg font-medium">Реквизиты</div>
    <ClientShowCard class="flex justify-between">
      <div class="grow grid grid-cols-2 gap-[32px]">
        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Организация</div>
          <div>{{ company.title }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Телефон</div>
          <div>{{ company.phone ?? '—' }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">ИНН</div>
          <div>{{ company.inn }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">КПП</div>
          <div>{{ company.kpp }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">ОГРН</div>
          <div>{{ company.ogrn }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Адрес</div>
          <div>{{ company.address ?? '—' }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Директор</div>
          <div>{{ company.directorTitle ?? '—' }}</div>
        </div>
        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">БИК</div>
          <div>{{ company.bic ?? '—' }}</div>
        </div>
        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Корр. счет</div>
          <div>{{ company.correspondentAccount ?? '—' }}</div>
        </div>
        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Расчетный счет</div>
          <div>{{ company.beneficiaryAccount ?? '—' }}</div>
        </div>
      </div>

      <div class="flex-none">
        <AppIcon
          v-if="allowEdit"
          :icon="IconPencil"
          :width="20"
          :height="20"
          class="cursor-pointer hover:text-violet-primary"
          @click="modalEditShow = true"
        />
      </div>
    </ClientShowCard>

    <AppModalCompanyEdit
      v-if="modalEditShow"
      :company-id="props.companyId"
      @closed="modalEditShow = false"
    />
  </div>
</template>
