<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.59961 3.59995H14.3996M5.59961 1.19995H10.3996M10.7996 14.8H5.19961C4.31595 14.8 3.59961 14.0836 3.59961 13.2L3.23433 4.43326C3.21539 3.97876 3.57874 3.59995 4.03364 3.59995H11.9656C12.4205 3.59995 12.7838 3.97876 12.7649 4.43326L12.3996 13.2C12.3996 14.0836 11.6833 14.8 10.7996 14.8Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
