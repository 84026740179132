<script setup>
import AppModal from '@/components/AppModal.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import { useToast } from '@/use/useToast.js';
import { storageRelease } from '@/api/storage.js';

const props = defineProps({
  storageId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed', 'released']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const release = async () => {
  loading.value = true;

  try {
    await storageRelease(props.storageId);
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['storage'].indexOf(key) !== -1),
    });
    useToast().show('Хранение выдано');
    emit('released');
  } catch (error) {
    loading.value = false;
    useToast().error(
      error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
    );
  }
};
</script>

<template>
  <AppModal
    :show="isShow"
    @close="close()"
  >
    <template #title>Хранение</template>
    Вы точно хотите выдать это хранение?
    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="release()"
          >Подтвердить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
