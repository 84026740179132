import OperationIndexPage from '@/modules/finance/pages/OperationIndexPage.vue';
import OperationShowPage from '@/modules/finance/pages/OperationShowPage.vue';
import PayrollIndexPage from '@/modules/finance/pages/PayrollIndexPage.vue';

export default [
  {
    path: '/operation/',
    name: 'operation.index',
    component: OperationIndexPage,
    meta: { permissions: ['view-any-Operation'] },
  },
  {
    path: '/operation/payroll',
    name: 'payroll.index',
    component: PayrollIndexPage,
    meta: { permissions: ['create-EmployeePayroll'] },
  },
  {
    path: '/operation/show/:operationId',
    name: 'operation.show',
    component: OperationShowPage,
    props(route) {
      const props = { ...route.params };
      props.operationId = +props.operationId;
      return props;
    },
    meta: { permissions: ['view-Operation'] },
  },
];
