import http from '@/libs/http.js';

export const getCompanies = ({ query } = {}) => {
  return http.get('/api/v2/suggestion/company', {
    params: {
      query,
    },
  });
};

export const getCompany = (inn) => {
  return http.get('/api/v2/suggestion/company/' + inn, {
    params: {
      inn,
    },
  });
};

export const getTireBrands = (query = {}) => {
  return http.post('/api/v2/suggestion/tire-brand', {
    query,
  });
};

export const getAddresses = (query = {}) => {
  return http.get('/api/v2/suggestion/address', {
    params: {
      query,
    },
  });
};

export const getGeocode = (query) => {
  return http.get('/api/v2/suggestion/geocode/', {
    params: {
      apikey: import.meta.env.VITE_API_YANDEX_MAP_KEY,
      query: query,
    },
  });
};
