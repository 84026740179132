<script setup>
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import SettingsStorageStockModal from '@/modules/settings/components/SettingsStorageStockModal.vue';
import { useStocksQuery } from '@/query/useStockQuery.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import { plural } from '@/use/useFormat.js';
import SettingsStorageStockRemoveModal from '@/modules/settings/components/SettingsStorageStockRemoveModal.vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const { data: stocks, isLoading } = useStocksQuery({ select: (response) => response?.data?.data });

const stockModal = ref(false);
const stockRemoveModal = ref(false);
const editableStockId = ref(null);
const deletableStockId = ref(null);

const edit = (stockId) => {
  editableStockId.value = stockId;
  stockModal.value = true;
};

const remove = (stockId) => {
  deletableStockId.value = stockId;
  stockRemoveModal.value = true;
};

const closeModal = () => {
  editableStockId.value = null;
  deletableStockId.value = null;
  stockModal.value = false;
  stockRemoveModal.value = false;
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading"
  >
    <div class="w-[535px]">
      <div
        v-for="stock in stocks"
        :key="stock.stockId"
        class="p-[8px] flex justify-between"
      >
        <div class="flex gap-x-[18px]">
          <div>{{ stock.title }}</div>
          <div class="text-gray-primary">
            ({{ stock.rackCount }} {{ plural(stock.rackCount, ['стелаж', 'стелажа', 'стелажей']) }},
            {{ stock.cellCount }} {{ plural(stock.rackCount, ['ячейка', 'ячейки', 'ячеек']) }})
          </div>
        </div>
        <div class="flex gap-x-[12px]">
          <AppIcon
            :icon="IconPencil"
            class="w-[18px] h-[18px] hover:text-violet-primary cursor-pointer"
            @click="edit(stock.stockId)"
          />

          <div class="w-[1px] bg-[#D9D9D9]"></div>
          <AppIcon
            :icon="IconTrash"
            class="w-[18px] h-[18px] hover:text-red-notify cursor-pointer"
            @click="remove(stock.stockId)"
          />
        </div>
      </div>

      <div
        v-if="stocks?.length > 0"
        class="mt-[24px]"
      >
        <AppButton @click="stockModal = true">Добавить склад</AppButton>
      </div>
    </div>

    <div
      v-if="stocks?.length === 0"
      class="mt-[24px] flex flex-col gap-y-[24px] justify-center items-center grow"
    >
      <div>Вы не добавили ни одного склада</div>
      <AppButton @click="stockModal = true">Добавить склад</AppButton>
    </div>

    <SettingsStorageStockModal
      v-if="stockModal"
      :stock-id="editableStockId"
      @closed="closeModal()"
      @saved="closeModal()"
    />

    <SettingsStorageStockRemoveModal
      v-if="stockRemoveModal"
      :stock-id="deletableStockId"
      @closed="closeModal()"
      @removed="closeModal()"
    />
  </CabinetLayout>
</template>
