<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import { serviceRemove } from '@/api/service.js';
import { useServiceQuery } from '@/query/useServiceQuery.js';

const props = defineProps({
  serviceId: {
    type: Number,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['closed', 'removed']);

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const remove = async () => {
  loading.value = true;

  try {
    await serviceRemove(props.serviceId);

    await queryClient.removeQueries({
      predicate: (query) => query.queryKey.some((key) => ['service-' + props.serviceId].indexOf(key) !== -1),
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['service', 'service-category'].indexOf(key) !== -1),
    });

    useToast().show('Услуга удалена');
    emit('removed');
  } catch (error) {
    loading.value = false;

    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const { data: service, isLoading } = useServiceQuery(
  { serviceId: props.serviceId },
  { select: (response) => response?.data?.data }
);
</script>

<template>
  <AppModal
    :loading="isLoading"
    :show="isShow"
    @close="close()"
  >
    <template #title>Удаление услуги</template>

    <div class="text-center text-gray-primary">
      Вы действительно хотите удалить услугу <span class="whitespace-nowrap font-medium">{{ service.title }}</span
      >?
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          red
          outline
          @click="remove()"
          >Удалить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
