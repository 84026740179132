import { useQuery } from '@tanstack/vue-query';
import { getCompany } from '@/api/company.js';

export function useCompanyQuery({ companyId } = {}, options = {}) {
  const queryKey = ['company', companyId];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getCompany(companyId);
    },
    ...options,
  });
}
