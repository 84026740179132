import { useQuery } from '@tanstack/vue-query';
import { getStock, getStocks } from '@/api/stock.js';

export function useStockQuery({ stockId }, options = {}) {
  const queryKey = ['storage', 'stock', stockId];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getStock(stockId);
    },
    ...options,
  });
}

export function useStocksQuery(options = {}) {
  const queryKey = ['storage', 'stock'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getStocks();
    },
    ...options,
  });
}
