import ClientIndexPage from '@/modules/client/pages/ClientIndexPage.vue';
import ClientShowPage from '@/modules/client/pages/ClientShowPage.vue';

export default [
  {
    path: '/client/',
    name: 'client.index',
    component: ClientIndexPage,
    meta: { permissions: ['view-any-Client'] },
  },
  {
    path: '/client/:clientId',
    name: 'client.show',
    props(route) {
      const props = { ...route.params };
      props.clientId = +props.clientId;
      return props;
    },
    component: ClientShowPage,
    meta: { permissions: ['view-Client'] },
  },
];
