<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.64398 6.3778C2.64398 3.62865 4.87261 1.40002 7.62176 1.40002C10.3709 1.40002 12.5995 3.62865 12.5995 6.3778C12.5995 7.09337 12.4485 7.77368 12.1767 8.38859L12.6005 11.3551L10.0582 10.7195C9.33803 11.1246 8.50687 11.3556 7.62176 11.3556M1.40013 9.48891C1.40013 9.93614 1.49448 10.3613 1.66438 10.7457L1.39954 12.5997L2.9883 12.2025C3.43838 12.4556 3.9578 12.6 4.51094 12.6C6.229 12.6 7.62176 11.2071 7.62176 9.48891C7.62176 7.77069 6.229 6.3778 4.51094 6.3778C2.79289 6.3778 1.40013 7.77069 1.40013 9.48891Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
