import http from '@/libs/http.js';

export const getAccounts = ({ isArchived }) => {
  return http.get('/api/v2/account', {
    params: {
      isArchived,
    },
  });
};

export const getAccount = (accountId) => {
  return http.get('/api/v2/account/' + accountId);
};

export const accountStore = ({ title }) => {
  return http.post('/api/v2/account', {
    title,
  });
};

export const accountUpdate = (accountId, { title, index, isArchived }) => {
  return http.put('/api/v2/account', {
    accountId,
    title,
    index,
    isArchived,
  });
};
