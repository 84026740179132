<script setup>
import { watch, ref } from 'vue';
import AppSnackbar from '@/components/AppSnackbar.vue';
import { useAppLastVersionQuery } from '@/query/useAppQuery.js';
import AppIcon from '@/components/AppIcon.vue';
import IconCheck from '@/components/Icons/IconCheck.vue';
import AppButton from '@/components/AppButton.vue';

const currentVersionId = ref(null);

const { data: lastVersion } = useAppLastVersionQuery({
  retry: true,
  refetchInterval: 1000 * 60 * 3,
  refetchOnWindowFocus: false,
  select: (response) => response?.data?.data,
});

watch(lastVersion, () => {
  if (!lastVersion.value) {
    return;
  }

  if (currentVersionId.value === null) {
    currentVersionId.value = lastVersion.value.id;
    return;
  }

  if (currentVersionId.value === lastVersion.value.id) {
    return;
  }

  currentVersionId.value = lastVersion.value.id;
  snackbar.value.show();
});

const snackbar = ref(null);
const loading = ref(false);

const reload = () => {
  loading.value = true;
  location.reload();
};
</script>

<template>
  <AppSnackbar ref="snackbar">
    <div class="flex items-center justify-between gap-x-[24px]">
      <div class="flex gap-x-[12px] items-center">
        <div class="p-[7px]">
          <AppIcon
            :icon="IconCheck"
            :width="12"
            :height="12"
            class="text-white"
          />
        </div>
        <div class="text-white">Появилась новая версия этой страницы</div>
      </div>
      <div class="uppercase text-amber-200">
        <AppButton
          :loading="loading"
          white
          @click="reload()"
          >Обновить</AppButton
        >
      </div>
    </div>
  </AppSnackbar>
</template>
