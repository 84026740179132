<script setup>
import AppModal from '@/components/AppModal.vue';
import { useEmployeeQuery } from '@/query/useEmployeeQuery.js';
import AppInput from '@/components/AppInput.vue';
import { ref, watchEffect, unref, onMounted } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { updateEmployee } from '@/api/employee.js';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';
import AppCheckbox from '@/components/AppCheckbox.vue';
import AppSelect from '@/components/AppSelect.vue';
import { getRoles } from '@/api/roles.js';

const props = defineProps({
  employeeId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close', 'fire']);

const { data: employee, isLoading } = useEmployeeQuery(props.employeeId, {
  select: (response) => response?.data?.data,
});

const close = () => {
  emit('close');
};

const firstName = ref(null);
const lastName = ref(null);
const patronymicName = ref(null);
const phone = ref(null);
const percentOfCompletedOrders = ref(null);
const minimumPayPerShift = ref(null);
const sumMinimumAndPercent = ref(null);
const assignedAsExecutor = ref(null);

const roles = ref([]);
const optionsRoles = ref([]);

watchEffect(() => {
  firstName.value = employee.value?.firstName;
  lastName.value = employee.value?.lastName;
  patronymicName.value = employee.value?.patronymicName;
  phone.value = employee.value?.phone;
  percentOfCompletedOrders.value = employee.value?.businessEmployee.percentOfCompletedOrders;
  minimumPayPerShift.value = employee.value?.businessEmployee.minimumPayPerShift
    ? employee.value?.businessEmployee.minimumPayPerShift / 100
    : 0;
  sumMinimumAndPercent.value = employee.value?.businessEmployee.sumMinimumAndPercent;
  assignedAsExecutor.value = employee.value?.businessEmployee.assignedAsExecutor;
});

const loading = ref(false);
const queryClient = useQueryClient();
const submit = async () => {
  try {
    loading.value = true;

    await updateEmployee(props.employeeId, {
      firstName: unref(firstName),
      lastName: unref(lastName),
      patronymicName: unref(patronymicName),
      phone: unref(phone),
      roles: unref(roles),
      percentOfCompletedOrders: unref(percentOfCompletedOrders),
      minimumPayPerShift: unref(minimumPayPerShift) * 100,
      sumMinimumAndPercent: unref(sumMinimumAndPercent),
      assignedAsExecutor: unref(assignedAsExecutor),
    });

    useToast().show('Изменения сохранены');

    close();
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['employee'].indexOf(key) !== -1),
    });
    loading.value = false;
  }
};
onMounted(async () => {
  const { data } = await getRoles();
  optionsRoles.value = data.data;
  roles.value = employee.value?.roles.map((item) => item.id);
});
</script>

<template>
  <AppModal
    :loading="isLoading"
    :show="true"
    class="md:w-[638px]"
    @close="close()"
  >
    <template #title>Редактирование сотрудника</template>
    <div class="grid grid-cols-2 gap-x-[12px] gap-y-[32px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Фамилия</div>
        <div><AppInput v-model="lastName" /></div>
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Имя</div>
        <div><AppInput v-model="firstName" /></div>
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Отчество</div>
        <div><AppInput v-model="patronymicName" /></div>
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Процент за работу</div>
        <div>
          <AppInput
            v-model="percentOfCompletedOrders"
            data-test="percent-of-completed-orders"
          />
        </div>
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Минимум за смену</div>
        <div>
          <AppInput
            v-model="minimumPayPerShift"
            data-test="minimum-pay-per-shift"
          />
        </div>
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Права доступа</div>
        <div>
          <AppSelect
            v-model="roles"
            :options="optionsRoles"
            option-value="id"
            option-label="name"
            data-test="roles"
            required
            multiple
          />
        </div>
      </div>
      <div class="col-span-2">
        <AppCheckbox
          v-model="sumMinimumAndPercent"
          label="Суммировать минималку и процент от работ"
        />
      </div>
      <div class="col-span-2">
        <AppCheckbox
          v-model="assignedAsExecutor"
          label="Может быть назначен как исполнить"
        />
      </div>
    </div>

    <template #actions>
      <div class="flex flex-col gap-[32px]">
        <div class="grid grid-cols-2 gap-x-[12px]">
          <AppButton
            outline
            :disabled="loading"
            @click="close()"
            >Отмена</AppButton
          >
          <AppButton
            :loading="loading"
            :disabled="loading"
            @click="submit()"
            >Сохранить</AppButton
          >
        </div>

        <div class="text-center">
          <span
            class="text-red-notify hover:underline cursor-pointer"
            @click="emit('fire', props.employeeId)"
            >Уволить сотрудника</span
          >
        </div>
      </div>
    </template>
  </AppModal>
</template>
