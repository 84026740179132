<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import AppTable from '@/components/AppTable.vue';
import { useClientQuery } from '@/query/useClientQuery.js';
import { computed } from 'vue';
import OrderStatus from '@/enums/OrderStatus.js';
import { useBookingQuery } from '@/query/useBookingQuery.js';
import { DateTime } from 'luxon';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const columns = [
  {
    label: 'Дата',
    field: (raw) => {
      return DateTime.fromISO(raw.startTime).toFormat('d MMMM y в HH:mm');
    },
  },
  {
    label: 'Заметка',
    field: 'note',
  },
];

const { data: bookings, isLoading } = useBookingQuery(
  { clientId: props.clientId },
  { select: (response) => response?.data?.data }
);

const bookingsSorted = computed(() => {
  if (!bookings.value) {
    return [];
  }

  const bookingsSorted = [...bookings.value];

  return bookingsSorted.sort((a, b) => b.bookingId - a.bookingId);
});
</script>

<template>
  <div class="flex flex-col gap-[24px]">
    <div class="text-lg font-medium">Запись</div>
    <ClientShowCard>
      <AppTable
        col-class="!text-xs"
        row-class="!text-sm"
        :data="bookingsSorted"
        :columns="columns"
      />
    </ClientShowCard>
  </div>
</template>
