import { useQuery } from '@tanstack/vue-query';
import { getStorage, getStorageByOrder, getStorageQrCode, getStorages } from '@/api/storage.js';
import { unref } from 'vue';
import { getOperation, getOperationCategories, getOperations } from '@/api/operation.js';

export function useOperationQuery({ operationId, include } = {}, options = {}) {
  const queryKey = ['operation', operationId, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getOperation(operationId, { include });
    },
    options,
  });
}

export function useOperationsQuery(
  {
    page,
    perPage,
    amountRangeStart,
    amountRangeEnd,
    dateRangeStart,
    dateRangeEnd,
    direction,
    categoryId,
    accountId,
    include,
  } = {},
  options = {}
) {
  const queryKey = [
    'operation',
    page,
    perPage,
    amountRangeStart,
    amountRangeEnd,
    dateRangeStart,
    dateRangeEnd,
    direction,
    categoryId,
    accountId,
    include,
  ];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getOperations({
        page: unref(page),
        perPage: unref(perPage),
        amountRangeStart: unref(amountRangeStart),
        amountRangeEnd: unref(amountRangeEnd),
        dateRangeStart: unref(dateRangeStart),
        dateRangeEnd: unref(dateRangeEnd),
        direction: unref(direction),
        categoryId: unref(categoryId),
        accountId: unref(accountId),
        include: unref(include),
      });
    },
    ...options,
  });
}

export function useOperationCategoriesQuery(options = {}) {
  const queryKey = ['operation', 'category'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getOperationCategories();
    },
    ...options,
  });
}
