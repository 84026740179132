<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { ref, watch, watchEffect } from 'vue';
import { DateTime } from 'luxon';

const props = defineProps({
  period: {
    type: [String, null],
    required: true,
  },
});

const emit = defineEmits(['update:period']);

const periodOptions = [
  {
    label: 'День',
    value: 'day',
  },
  {
    label: 'Месяц',
    value: 'month',
  },
];

const selectedPeriod = ref('day');

watch(
  selectedPeriod,
  () => {
    emit('update:period', selectedPeriod.value);
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div class="w-[150px]">
    <AppSelect
      v-model="selectedPeriod"
      :options="periodOptions"
      data-test="group-filter"
      placeholder="Период"
    />
  </div>
</template>
