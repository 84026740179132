<script setup>
import IconEmployee from '@/components/Icons/IconEmployee.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import AppButton from '@/components/AppButton.vue';
import ClientCard from '@/modules/client/components/ClientShowCard.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useClientQuery } from '@/query/useClientQuery.js';
import { useConfirmDialog } from '@vueuse/core';
import AppClientEditModal from '@/components/AppClientEditModal.vue';
import { DateTime } from 'luxon';
import { ClientType } from '@/enums/ClientType.js';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const { isRevealed, reveal, confirm } = useConfirmDialog();

const { data: client } = useClientQuery({ clientId: props.clientId }, { select: (response) => response?.data?.data });
</script>

<template>
  <ClientCard class="grow flex flex-wrap gap-y-[12px] justify-between items-center">
    <div class="flex gap-[16px]">
      <div class="flex-none w-[84px] h-[84px] bg-black-primary flex items-center justify-center rounded-full">
        <AppIcon
          :icon="IconEmployee"
          class="text-white w-[44px] h-[44px] stroke-1"
        />
      </div>
      <div class="flex flex-col gap-[12px]">
        <div class="flex items-center gap-[12px] text-xl font-medium">
          <div v-if="client.type === ClientType.INDIVIDUAL">
            {{ client.firstName }} {{ client.patronymicName }} {{ client.lastName }}
          </div>
          <div v-else>{{ client.title }}</div>
          <AppIcon
            v-if="client.type === ClientType.INDIVIDUAL"
            :icon="IconPencil"
            class="cursor-pointer hover:text-violet-primary"
            data-test="client-show-edit-header"
            @click="reveal"
          />

          <AppClientEditModal
            v-if="isRevealed"
            :client-id="props.clientId"
            :fields="['firstName', 'lastName', 'patronymicName']"
            @closed="confirm()"
          />
        </div>
        <div class="flex flex-col gap-[6px]">
          <!-- @todo вывести баланс -->
          <!--          <div class="text-lg">Баланс: <span class="text-[#44B400]">0 руб</span></div>-->
          <div class="text-xs text-gray-primary">
            Дата регистрации: {{ DateTime.fromISO(client.createdAt).toFormat('d.LL.y') }}
          </div>
        </div>
      </div>
    </div>
    <div class="grow flex justify-end">
      <!-- @todo вывести кнопку нового заказа -->
      <!--      <AppButton outline>Новый заказ</AppButton>-->
    </div>
  </ClientCard>
</template>

