<script setup>
import { ref, reactive, watchEffect, watch, computed } from 'vue';
import VOtpInput from 'vue3-otp-input';

const props = defineProps({
  otp: {
    type: String,
    default: '',
  },

  digitCount: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:otp']);

const input = ref(null);
const code = ref('');

const handleOnComplete = (value) => {
  emit('update:otp', value);
};
</script>

<template>
  <VOtpInput
    ref="input"
    v-model:value="code"
    class="flex gap-x-[12px]"
    separator=""
    input-classes="border border-[#CBD3E4] focus:border-[#CBD3E4] rounded-[6px] w-[68px] h-[56px] text-xl placeholder-[#AEAEAE] text-center focus:bg-[#FAFBFE] focus:outline-0 focus:ring-0"
    :num-inputs="4"
    :should-auto-focus="true"
    input-type="letter-numeric"
    :placeholder="['*', '*', '*', '*']"
    @on-complete="handleOnComplete"
  />
</template>
