<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { watch, ref, useSlots, onUnmounted } from 'vue';
import { onClickOutside } from '@vueuse/core';
import AppSpinner from '@/components/AppSpinner.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconCross from '@/components/Icons/IconCross.vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },

  preventClose: {
    type: Boolean,
    default: true,
  },

  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:show', 'close']);

const isOpen = ref(props.show);

watch(
  () => props.show,
  () => {
    isOpen.value = props.show;
  }
);

watch(isOpen, () => {
  emit('update:show', isOpen.value);
});

watch(
  isOpen,
  () => {
    document.body.style.overflowY = isOpen.value ? 'hidden' : 'visible';
    if (!isOpen.value) {
      emit('close');
    }
  },
  {
    immediate: true,
  }
);

onUnmounted(() => {
  document.body.style.overflowY = 'visible';
});

const close = () => {
  isOpen.value = false;
};

const show = () => {
  isOpen.value = true;
};

const toggle = () => {
  isOpen.value = !isOpen.value;
};

defineExpose({
  show,
  close,
  toggle,
});

const window = ref(null);
if (!props.preventClose) {
  onClickOutside(window, () => {
    close();
  });
}

const slots = useSlots();
</script>

<template>
  <Teleport to="#app">
    <Transition>
      <div
        v-if="isOpen"
        tabindex="-1"
        class="fixed inset-0 z-[99998] bg-black bg-opacity-50"
      ></div>
    </Transition>
    <Transition>
      <div
        v-if="isOpen"
        class="fixed px-[10px] py-[80px] md:p-0 inset-x-0 inset-y-4 z-[99999] m-auto flex h-screen items-center justify-center"
      >
        <div
          ref="window"
          class="relative h-full max-h-full w-full max-w-full rounded-lg bg-white shadow lg:w-[600px] md:w-[458px] md:h-auto flex flex-col"
          :class="$attrs['class']"
        >
          <button
            type="button"
            class="absolute top-[16px] right-[16px] z-50 hover:rotate-90 transition-transform delay-50 duration-200"
            @click="close"
          >
            <AppIcon
              :icon="IconCross"
              class="w-[24px] h-[24px] text-gray-primary"
            />
          </button>
          <div
            v-if="props.loading"
            class="flex h-24 items-center justify-center"
          >
            <span><AppSpinner class="h-10 w-10" /></span>
          </div>
          <div
            v-else
            class="flex h-full max-h-full flex-col pb-[24px]"
          >
            <h3
              v-if="slots.title"
              class="mt-[24px] mx-[24px] text-lg text-center text-gray-900 select-none"
            >
              <slot name="title" />
            </h3>

            <div
              v-if="slots.header"
              class="mt-3"
            >
              <slot name="header" />
            </div>

            <div class="z-40 h-full overflow-y-auto py-[5px] px-[5px] mx-[19px] mt-[27px]">
              <slot />
            </div>

            <div
              v-if="slots.actions"
              class="mt-[27px] mx-[24px]"
            >
              <slot name="actions" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
