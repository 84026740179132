<template>
  <AppModal v-model:show="show">
    <template #title>
      <slot name="title" />
    </template>

    <div class="mt-2">
      <p class="text-center text-sm text-gray-500"><slot name="content" /></p>
    </div>

    <div class="mt-4 text-center">
      <button
        type="button"
        class="inline-flex justify-center rounded-md border bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        @click="cancel"
      >
        {{ cancelTitle }}
      </button>

    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/components/AppModal.vue';
export default {
  components: { AppModal },
  props: {
    cancelTitle: {
      default: 'Отмена',
    },
  },
  data() {
    return {
      show: false,
      resolvePromise: null,
      rejectPromise: null,
    };
  },

  methods: {
    initConfirm() {
      this.show = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    cancel() {
      this.show = false;
      this.resolvePromise(false);
    },
  },
};
</script>
