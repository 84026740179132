<script setup>
import AppIcon from '@/components/AppIcon.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import { formatMoney } from '@/use/useFormat.js';
import IconChevronUp from '@/components/Icons/IconChevronUp.vue';
import { ref } from 'vue';
import IconChevronDown from '@/components/Icons/IconChevronDown.vue';
import { orderPaymentRemove } from '@/api/order.js';
import { useQueryClient } from '@tanstack/vue-query';
import { DateTime } from 'luxon';
import { useToast } from '@/use/useToast.js';
import { useAuthStore } from '@/store/AuthStore.js';

const props = defineProps({
  payments: {
    type: Array,
    required: true,
  },
  isOrderPaid: {
    type: Boolean,
    required: true,
  },
});

const { hasAccess } = useAuthStore();
const historyExpanded = ref(props.isOrderPaid);

const queryClient = useQueryClient();

const remove = async (paymentId) => {
  try {
    await orderPaymentRemove(paymentId);
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'order'),
    });
  }
};
</script>

<template>
  <div class="flex flex-col gap-y-[32px]">
    <div class="flex justify-between items-center">
      <div class="text-lg">
        Платежи <span class="text-gray-primary">({{ props.payments.length }})</span>
      </div>
      <div
        v-if="!isOrderPaid"
        class="flex select-none items-center gap-x-[8px] text-violet-primary cursor-pointer"
        @click="historyExpanded = !historyExpanded"
      >
        <template v-if="historyExpanded">
          <AppIcon
            :icon="IconChevronUp"
            :width="9"
          />
          <span>Свернуть</span>
        </template>

        <template v-else>
          <AppIcon
            :icon="IconChevronDown"
            :width="9"
          />
          <span>Развернуть</span>
        </template>
      </div>
    </div>
    <div
      v-if="historyExpanded"
      class="grid grid-cols-[1fr_1fr_2fr_100px] gap-y-[24px] gap-x-[12px] items-center"
    >
      <template
        v-for="payment in props.payments"
        :key="payment.paymentId"
      >
        <div class="text-lg font-medium">{{ formatMoney(payment.amount) }}</div>
        <div class="flex">
          <div class="text-sm rounded-[6px] bg-[#EDECEC] p-[4px]">{{ payment.account.title }}</div>
        </div>
        <div class="text-sm text-gray-primary">
          {{ DateTime.fromISO(payment.createdAt).toFormat('d MMMM yyyy, HH:mm') }}
        </div>
        <div class="flex justify-end">
          <AppIcon
            v-if="hasAccess('delete-OrderPayment')"
            :icon="IconTrash"
            class="hover:text-red-notify cursor-pointer"
            @click="remove(payment.paymentId)"
          />
        </div>
      </template>
    </div>
  </div>
</template>
