<script setup>
import AppPrintTemplate from '@/components/AppPrintTemplate.vue';
import { useStorageQrCodeQuery, useStorageQuery } from '@/query/useStorageQuery.js';
import { computed, ref } from 'vue';
import { formatMoney, formatPhone } from '@/use/useFormat.js';
import { useStorage } from '@/use/useStorage.js';
import { useDocumentTemplateQuery } from '@/query/useDocumentTemplateQuery.js';
import { DateTime } from 'luxon';
import DocumentTemplateName from '@/enums/DocumentTemplateName.js';

const props = defineProps({
  storageId: {
    type: Number,
    required: true,
  },
  temaplateName: {
    type: String,
    required: true,
  },
});

const include = ['items.work', 'order.car', 'order.car.mark', 'order.car.model', 'order.client'];
const { data, isLoading } = useStorageQuery({ storageId: props.storageId, include });
const storage = computed(() => {
  return data.value?.data;
});

const { data: storageQrCode } = useStorageQrCodeQuery(
  { storageId: props.storageId },
  { select: (response) => response?.data?.data }
);

const { data: documentTemplate } = useDocumentTemplateQuery(
  { name: props.temaplateName },
  { select: (response) => response?.data?.data }
);

const { getItemTitle } = useStorage();

const template = ref(null);
const parsedTemplate = computed(() => {
  return template.value?.innerHTML;
});

const text = computed(() => {
  if (!documentTemplate.value) {
    return null;
  }

  let text = documentTemplate.value.text;
  text = text.replaceAll(':contract_number', storage.value.numberId);
  text = text.replaceAll(':contract_date', DateTime.fromISO(storage.value.createdAt).toFormat('d MMMM yyyy'));
  text = text.replaceAll(':client_fio', storage.value.order.client?.title);
  text = text.replaceAll(':client_phone', storage.value.order.client?.phone);
  text = text.replaceAll(':car_title', storage.value.order.car?.title);
  text = text.replaceAll(':car_reg_number', storage.value.order.car?.regNumber);

  const totalPrice = storage.value.items.reduce((totalPrice, item) => {
    return totalPrice + item.work.price * item.work.count;
  }, 0);

  text = text.replaceAll(':storage_total_price', formatMoney(totalPrice));

  const items = storage.value.items.map((item) => {
    return getItemTitle(item) + ' ' + item.count + ' шт.';
  });

  text = text.replaceAll(':items', items.join('<br>'));

  if (parsedTemplate.value) {
    text = text + '###PB###' + parsedTemplate.value;
  }

  return text;
});
</script>

<template>
  <div v-if="storage && storageQrCode">
    <div
      v-if="temaplateName === DocumentTemplateName.STORAGE_RECEIVED"
      ref="template"
      class="hidden"
    >
      <template
        v-for="item in storage.items"
        :key="item.itemId"
      >
        <div
          v-for="index in item.count"
          :key="index"
          class="flex items-center justify-center h-[250px] border-b last:border-0 border-primary-gray"
        >
          <div class="flex flex-col gap-y-[10px]">
            <div class="text-9xl !leading-[110px]">{{ storage.numberId }}</div>
            <div>{{ storage.order.client?.title }} {{ formatPhone(storage.order.client?.phone) }}</div>
            <div>{{ storage.order.car?.title }} {{ storage.order.car?.regNumber }}</div>
            <div>{{ getItemTitle(item) }} {{ item.count }} шт.</div>
          </div>
          <div>
            <img
              class="w-[115px] h-[115px]"
              :src="'data:image/png;base64, ' + storageQrCode.code"
            />
          </div>
        </div>
      </template>
    </div>

    <AppPrintTemplate
      v-if="text"
      :text="text"
    />
  </div>
</template>
