import http from '@/libs/http.js';

export const operationStore = ({ createdAt, direction, categoryId, accountId, amount, description }) => {
  return http.post('/api/v2/operation', {
    createdAt,
    direction,
    categoryId,
    accountId,
    amount,
    description,
  });
};

export const operationExpenseCashboxStore = ({ amount, description }) => {
  return http.post('/api/v2/operation/expense/cashbox', {
    amount,
    description,
  });
};

export const getOperation = (operationId, { include }) => {
  return http.get('/api/v2/operation/' + operationId, { params: { include } });
};

export const getOperations = ({
  include,
  page,
  perPage,
  amountRangeStart,
  amountRangeEnd,
  dateRangeStart,
  dateRangeEnd,
  direction,
  categoryId,
  accountId,
}) => {
  return http.get('/api/v2/operation', {
    params: {
      include,
      page,
      perPage,
      amountRangeStart,
      amountRangeEnd,
      dateRangeStart,
      dateRangeEnd,
      direction,
      categoryId,
      accountId,
    },
  });
};

export const getOperationCategories = () => {
  return http.get('/api/v2/operation/categories');
};

export const operationExpensePayrollStore = ({ employeeId, amount }) => {
  return http.post('/api/v2/operation/expense/payroll', {
    employeeId,
    amount,
  });
};

export const operationRollback = (operationId) => {
  return http.post('/api/v2/operation/rollback', {
    operationId,
  });
};
