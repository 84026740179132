<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { ref, watch, watchEffect } from 'vue';
import { DateTime } from 'luxon';

const props = defineProps({
  /** @var DateTime **/
  startTime: {
    type: [Object, null],
    required: true,
  },
  /** @var DateTime **/
  endTime: {
    type: [Object, null],
    required: true,
  },
});

const emit = defineEmits(['update:startTime', 'update:endTime']);

const periodOptions = [
  {
    label: 'Текущий месяц',
    value: 'currentMonth',
  },
  {
    label: 'Прошлый месяц',
    value: 'previousMonth',
  },
  {
    label: 'Последние три месяца',
    value: 'lastThreeMonths',
  },
  {
    label: 'Текущий год',
    value: 'currentYear',
  },
  {
    label: 'Прошлый год',
    value: 'previousYear',
  },
  {
    label: 'За все время',
    value: 'all',
  },
];

const selectedPeriod = ref('currentMonth');
const startTime = ref(null);
const endTime = ref(null);

watch(
  selectedPeriod,
  () => {
    switch (selectedPeriod.value) {
      case 'currentMonth':
        startTime.value = DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
        endTime.value = DateTime.now();
        break;

      case 'previousMonth':
        startTime.value = DateTime.now()
          .minus({ month: 1 })
          .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
        endTime.value = startTime.value.endOf('month');
        break;

      case 'lastThreeMonths':
        startTime.value = DateTime.now()
          .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
          .minus({ month: 3 });

        endTime.value = DateTime.now();
        break;

      case 'currentYear':
        startTime.value = DateTime.now().startOf('year');
        endTime.value = DateTime.now();
        break;
      case 'previousYear':
        startTime.value = DateTime.now().startOf('year').minus({ year: 1 });
        endTime.value = startTime.value.endOf('year');
        break;

      case 'all':
        startTime.value = DateTime.fromISO('2019-06-01');
        endTime.value = DateTime.now();
        break;
    }
  },
  {
    immediate: true,
  }
);

watchEffect(() => {
  emit('update:startTime', startTime.value);
});

watchEffect(() => {
  emit('update:endTime', endTime.value);
});
</script>

<template>
  <div>
    <AppSelect
      v-model="selectedPeriod"
      :options="periodOptions"
      placeholder="Период"
    />
  </div>
</template>
