<script setup>
import { useShiftQuery } from '@/query/useShiftQuery.js';
import { ref, computed } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { useRouter } from 'vue-router';
import AppModal from '@/components/AppModal.vue';
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import EmployeeStatus from '@/enums/EmployeeStatus.js';
import AppCheckbox from '@/components/AppCheckbox.vue';
import { useEmployee } from '@/use/useEmployee.js';
import { openShift } from '@/api/shift.js';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import { useQueryClient } from '@tanstack/vue-query';
import { formatMoney } from '../../../use/useFormat.js';

const emit = defineEmits(['closed', 'opened']);

const { data: shift, error, isLoading: shiftIsLoading } = useShiftQuery({ shiftId: 'current' });
const { data: availableEmployees, isLoading: employeesIsLoading } = useEmployeesQuery(
  {
    assignedAsExecutor: true,
    statusId: EmployeeStatus.WORKING,
  },
  { select: (response) => response?.data?.data }
);

const isLoading = computed(() => {
  return shiftIsLoading.value && employeesIsLoading.value;
});

const selectedEmployees = ref([]);
const router = useRouter();

// watch(shift, () => {
//   if (shift.value?.shiftId) {
//     router.push({ name: 'shift.show', params: { shiftId: 'current' } });
//   }
// });

const { data: accounts } = useAccountsQuery({ isArchived: false });
const cashboxAccount = computed(() => {
  if (!accounts.value?.data) {
    return;
  }

  return accounts.value.data.find((account) => account.isCashbox);
});

const employees = computed({
  get() {
    if (selectedEmployees.value) {
      return selectedEmployees.value;
    }

    return [];
  },

  set(newValue) {
    selectedEmployees.value = newValue;
  },
});

const { employeeShortTitle } = useEmployee();
const confirmCashboxAmount = ref(false);
const errorMessage = ref(null);
const loading = ref(false);
const queryClient = useQueryClient();

const approveOpenShift = async () => {
  loading.value = true;

  try {
    await openShift({ employees: selectedEmployees.value });
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'shift'),
    });
    await emit('opened');
  } catch (error) {
    if (error.response?.data?.message) {
      errorMessage.value = error.response?.data?.message;
    }
    loading.value = false;
  }
};
</script>

<template>
  <AppModal
    :loading="isLoading"
    :show="true"
    @close="emit('closed')"
  >
    <template #title>Открытие новой смены</template>
    <div class="flex flex-col gap-y-[21px]">
      <div>Сегодня в смене</div>
      <div class="grid grid-cols-2 gap-y-[12px] gap-x-[24px]">
        <div
          v-for="employee in availableEmployees"
          :key="employee.employeeId"
          class="px-[3px]"
          data-test="employeesList"
        >
          <AppCheckbox
            v-model="employees"
            :true-value="employee.employeeId"
            :label="employeeShortTitle({ ...employee })"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col py-[24px]">
      <div class="bg-[#D9D9D9] h-[1px] w-full"></div>
    </div>
    <div class="flex gap-x-[11px] items-center px-[3px]">
      <div>
        <AppCheckbox
          v-model="confirmCashboxAmount"
          data-test="confirmCashboxAmount"
        />
      </div>
      <div class="select-none">Подтверждаю сумму в кассе</div>
      <div class="text-lg text-violet-primary">{{ formatMoney(cashboxAccount?.balance) }}</div>
    </div>
    <div
      v-if="errorMessage"
      class="text-red-notify mt-[24px]"
    >
      {{ errorMessage }}
    </div>
    <template #actions>
      <AppButton
        data-test="shiftOpenButton"
        class="w-full"
        :disabled="loading || !confirmCashboxAmount || selectedEmployees.length === 0"
        :loading="loading"
        @click="approveOpenShift()"
        >Открыть смену</AppButton
      >
    </template>
  </AppModal>
</template>
