<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/AppSelect.vue';
import AppSelectButtons from '@/components/AppSelectButtons.vue';
import OperationDirection from '@/enums/OperationDirection.js';
import { useOperationCategoriesQuery } from '@/query/useOperationQuery.js';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { operationStore } from '@/api/operation.js';
import AppDatepicker from '@/components/AppDatepicker.vue';
import { useAuthStore } from '@/store/AuthStore.js';
import { DateTime } from 'luxon';

const emit = defineEmits(['closed', 'created']);
const { hasAccess } = useAuthStore();


const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);
const vuelidate = useVuelidate();

const close = () => {
  emit('closed');
};

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    await operationStore({
      createdAt: createdAt.value,
      direction: direction.value,
      categoryId: categoryId.value,
      accountId: accountId.value,
      amount: amount.value * 100,
      description: description.value,
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['operation', 'account'].indexOf(key) !== -1),
    });
    useToast().show('Операция добавлена');
    emit('created');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const createdAt = ref(DateTime.now().toISODate());
const direction = ref(OperationDirection.INCOME);
const categoryId = ref(null);
const accountId = ref(null);
const amount = ref(null);
const description = ref(null);

const directions = [
  {
    label: 'Приход',
    value: OperationDirection.INCOME,
  },
  {
    label: 'Расход',
    value: OperationDirection.EXPENSE,
  },
];

const { data: categories, isLoading: isCategoriesLoading } = useOperationCategoriesQuery({
  select: (response) => response?.data?.data,
});

const categoriesFiltered = computed(() => {
  if (!categories.value) {
    return [];
  }

  return categories.value.filter((category) => category.direction === direction.value);
});

watch(direction, () => {
  categoryId.value = null;
});

const { data: accounts, isLoading: isAccountsLoading } = useAccountsQuery(
  { isArchived: false },
  { select: (response) => response?.data?.data }
);
</script>

<template>
  <AppModal
    class="md:w-[584px]"
    :show="isShow"
    :loading="isCategoriesLoading || isAccountsLoading"
    @close="close()"
  >
    <template #title>Новая операция</template>

    <div class="flex flex-col gap-y-[24px]">
      <AppSelectButtons
        v-model="direction"
        class="!grid !grid-cols-2"
        :options="directions"
      />

      <div class="grid grid-cols-2 gap-x-[12px] gap-y-[24px]">
        <div class="flex flex-col gap-y-[12px]">
          <div>Дата</div>
          <div>
            <AppDatepicker
              v-if="hasAccess('update-date-Operation')"
              data-test="operation-create-date"
              v-model="createdAt"
              required
            />
            <AppInput
              v-else
              :placeholder="createdAt"
              disabled
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div>Статья</div>
          <div>
            <AppSelect
              v-model="categoryId"
              :options="categoriesFiltered"
              data-test="operation-create-expend"
              option-label="title"
              option-value="categoryId"
              required
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div>Счет</div>
          <div>
            <AppSelect
              v-model="accountId"
              :options="accounts"
              data-test="operation-create-account"
              option-label="title"
              option-value="accountId"
              required
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div>Сумма</div>
          <div>
            <AppInput
              v-model="amount"
              data-test="operation-create-amount"
              required
            />
          </div>
        </div>

        <div class="col-span-2 flex flex-col gap-y-[12px]">
          <div>Описание</div>
          <div>
            <AppInput
              v-model="description"
              data-test="operation-create-description"
              required
            />
          </div>
        </div>
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Добавить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
