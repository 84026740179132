import http from '@/libs/http.js';
import { unref } from 'vue';

export const getClients = ({ searchQuery, include, perPage, page, order, sort, filter } = {}) => {
  searchQuery = unref(searchQuery);

  return http.get('/api/v2/client', {
    params: {
      searchQuery,
      include,
      perPage,
      page,
      order,
      sort,
      filter,
    },
  });
};

export const getClient = ({ clientId, include }) => {
  return http.get('/api/v2/client/' + clientId, {
    params: { include },
  });
};

export const updateClient = (clientId, { firstName, lastName, patronymicName, phone, email, note }) => {
  return http.put('/api/v2/client', {
    clientId,
    firstName,
    lastName,
    patronymicName,
    phone,
    email,
    note,
  });
};

export const getClientStats = (clientId) => {
  return http.get('/api/v2/client/stats', { params: { clientId } });
};

export const clientCreate = ({ type, firstName, lastName, patronymicName, companyTitle, phone }) => {
  return http.post('/api/v2/client', {
    type,
    firstName,
    lastName,
    patronymicName,
    companyTitle,
    phone,
  });
};
