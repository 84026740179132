import http from '@/libs/http.js';
import AuthType from '@/enums/AuthType.js';

export const createAuthCode = (type, phone) =>
  http.post('/api/v2/auth/code-create', {
    type,
    phone,
  });

export const retryAuthCode = (authId) => http.post('/api/v2/auth/code-retry/' + authId);

export const getCsrf = () => http.get('/sanctum/csrf-cookie?' + new Date().toLocaleString());

export const login = (id, code) =>
  http.post('/api/v2/auth/login', {
    type: AuthType.LOGIN,
    id,
    code,
  });

export const getAuth = () => http.get('/api/v2/auth');

export const switchBusiness = (businessId) =>
  http.post('/api/v2/auth/business-switch', {
    businessId,
  });

export const logout = () => http.post('/api/v2/auth/logout');
