<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { computed, ref } from 'vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const selected = ref(0);
const selectedMultiple = ref([]);
const options = ref([
  {
    title: 'Вася',
    value: 0,
  },
  {
    title: 'Петя',
    value: 1,
  },
  {
    title: 'Миша',
    value: 2,
  },
  {
    title: 'Костя',
    value: 3,
  },
  {
    title: 'Паша',
    value: 4,
  },
]);

const searchQuery = ref(null);

const search = (query) => {
  searchQuery.value = query;
};

const filteredOptions = computed(() => {
  if (!searchQuery.value) {
    return options.value;
  }

  return options.value.filter((option) => option.title.indexOf(searchQuery.value) !== -1);
});
</script>

<template>
  <CabinetLayout>
    <div class="mt-[200px] flex flex-col gap-y-[20px]">
      <AppSelect
        v-model="selected"
        option-label="title"
        option-value="value"
        :options="options"
        placeholder="Выберите человека"
        direction="top"
      />

      <AppSelect
        v-model="selected"
        option-label="title"
        option-value="value"
        :options="options"
        placeholder="Выберите человека"
        direction="bottom"
      />

      <AppSelect
        v-model="selectedMultiple"
        class="w-72"
        option-label="title"
        option-value="value"
        :options="options"
        placeholder="Выберите людей"
        direction="top"
        multiple
      />

      <AppSelect
        v-model="selected"
        class="w-72"
        option-label="title"
        option-value="value"
        :options="filteredOptions"
        placeholder="Выберите людей"
        direction="top"
        searchable
        @search-query-changed="search"
      />

      {{ selectedMultiple }}
    </div></CabinetLayout
  >
</template>
