import SettingsEmployeesPage from '@/modules/settings/pages/SettingsEmployeesPage.vue';
import SettingsMainPage from '@/modules/settings/pages/SettingsMainPage.vue';
import SettingsBookingPage from '@/modules/settings/pages/SettingsBookingPage.vue';
import SettingsServicesPage from '@/modules/settings/pages/SettingsServicesPage.vue';
import SettingsDocumentsPage from '@/modules/settings/pages/SettingsDocumentsPage.vue';
import SettingsAccountsPage from '@/modules/settings/pages/SettingsAccountsPage.vue';
import SettingsEmployeesNewPage from '@/modules/settings/pages/SettingsEmployeesNewPage.vue';
import SettingsWorkPlanPage from '@/modules/settings/pages/SettingsWorkPlanPage.vue';
import SettingsStoragePage from '@/modules/settings/pages/SettingsStoragePage.vue';
import SettingsUsersPage from '@/modules/settings/pages/SettingsUsersPage.vue';

export default [
  {
    path: '/settings/main',
    name: 'settings.main.index',
    component: SettingsMainPage,
    meta: {
      permissions: [
        'settings-Business',
        'settings-Booking',
        'settings-Service',
        'settings-Shift',
        'settings-Account',
        'settings-WorkPlan',
        'settings-Stock',
        'settings-Role',
      ],
    },
  },
  {
    path: '/settings/employees',
    name: 'settings.employees.index',
    component: SettingsEmployeesPage,
    meta: { permissions: ['settings-Employee'] },
  },
  {
    path: '/settings/employees/new',
    name: 'settings.employees.new',
    component: SettingsEmployeesNewPage,
    meta: { permissions: ['settings-Employee'] },
  },
  {
    path: '/settings/booking',
    name: 'settings.booking.index',
    component: SettingsBookingPage,
    meta: { permissions: ['settings-Booking'] },
  },
  {
    path: '/settings/services',
    name: 'settings.services.index',
    component: SettingsServicesPage,
    meta: { permissions: ['settings-Service'] },
  },
  {
    path: '/settings/documents',
    name: 'settings.documents.index',
    component: SettingsDocumentsPage,
    meta: { permissions: ['settings-Shift'] },
  },
  {
    path: '/settings/accounts',
    name: 'settings.accounts.index',
    component: SettingsAccountsPage,
    meta: { permissions: ['settings-Account'] },
  },
  {
    path: '/settings/work-plan',
    name: 'settings.work-plan.index',
    component: SettingsWorkPlanPage,
    meta: { permissions: ['settings-WorkPlan'] },
  },
  {
    path: '/settings/storage',
    name: 'settings.storage.index',
    component: SettingsStoragePage,
    meta: { permissions: ['settings-Stock'] },
  },
  {
    path: '/settings/permissions',
    name: 'settings.permissions.index',
    component: SettingsUsersPage,
    meta: { permissions: ['settings-Role'] },
  },
];
