import { useQuery } from '@tanstack/vue-query';
import { getService, getServices } from '@/api/service.js';

export function useServiceQuery({ serviceId, include } = {}, options = {}) {
  const queryKey = ['service', 'service-' + serviceId, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getService(serviceId, { include });
    },
    ...options,
  });
}

export function useServicesQuery({ include } = {}, options = {}) {
  const queryKey = ['service', include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getServices({ include });
    },
    ...options,
  });
}
