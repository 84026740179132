<script setup>
import AppModal from '@/components/AppModal.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import IconSpinner from '@/components/Icons/IconSpinner.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watchEffect, onBeforeMount } from 'vue';
import AppScrollable from '@/components/AppScrollable.vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/AppSelect.vue';
import useVuelidate from '@vuelidate/core';
import { roleUsersUpdate, roleUsersDelete } from '@/api/roles.js';
import AppField from '@/components/AppField.vue';
import { formatPhone } from '@/use/useFormat.js';

const props = defineProps({
  role: {
    type: Object,
    required: false,
    default: null,
  },
  rolesData: {
    type: Array,
    required: true,
    default: null,
  },
  employeesData: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['closed', 'update']);

const queryClient = useQueryClient();
const loading = ref(false);
const deleteLoading = ref(false);

const vuelidate = await useVuelidate();
const isShow = ref(true);
const title = props.role ? props.role.name : ref(null);
const newEmployee = ref(null);

const close = () => {
  emit('closed');
};

const addToGroupOfRole = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    await roleUsersUpdate({ roleId: props.role.id, userId: newEmployee.value });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['roles', 'users', 'update'].indexOf(key) !== -1),
    });
    useToast().show('Группа успешно обновлена.');
    emit('update');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const deleteFromGroupOfRole = async (user) => {
  deleteLoading.value = true;

  try {
    await roleUsersDelete(props.role.id, user.id);

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['roles', 'users', 'delete'].indexOf(key) !== -1),
    });
    useToast().show('Группа успешно обновлена.');
    emit('update');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    deleteLoading.value = false;
  }
};

const optionsEmployees = computed(() => {
  return props.employeesData ? sortByTitle(props.employeesData) : [];
});

const sortByTitle = (values) => {
  return values
    .slice(0)
    .map((item) => {
      return item.title ? item : { ...item, title: `${item.first_name} ${item.last_name}` };
    })
    .sort((a, b) => (a.title > b.title ? 1 : -1));
};

const getEmployeeTitle = (employee) => {
  const title = employee.title.replaceAll('null', '').trim();
  return title.length ? title : '—';
};
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="!optionsEmployees.length"
    width="large"
    @close="close()"
  >
    <template #title>
      Пользователи группы <b>{{ title }}</b>
    </template>

    <AppField>
      <div class="text-base text-gray-900 mb-[12px]">Добавить в группу</div>
      <AppSelect
        v-model="newEmployee"
        :options="optionsEmployees"
        option-value="employeeId"
        option-label="title"
        placeholder="Выберите пользователя"
        required
      />
    </AppField>

    <AppScrollable
      v-if="role.employees"
      class="mt-[24px] !h-[200px]"
    >
      <ul>
        <li
          v-for="(employee, i) in sortByTitle(role.employees)"
          :key="i"
          class="mt-[14px] flex items-center justify-between"
        >
          <div>{{ getEmployeeTitle(employee) }} ({{ formatPhone(employee.phone) }})</div>
          <div>
            <AppIcon
              v-if="!deleteLoading"
              :icon="IconTrash"
              data-test="role-delete"
              class="hover:text-violet-primary cursor-pointer"
              @click="deleteFromGroupOfRole(employee)"
            />
            <AppIcon
              v-else
              :icon="IconSpinner"
            />
          </div>
        </li>
      </ul>
    </AppScrollable>

    <template #actions>
      <div class="flex items-center justify-center">
        <div
          v-if="errorMessage"
          class="text-rose-500"
        >
          {{ errorMessage }}
        </div>
        <AppButton
          outline
          class="lg:w-1/2"
          :disabled="loading || deleteLoading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          class="ml-[10px] lg:w-1/2"
          :disabled="loading"
          :loading="loading"
          @click="addToGroupOfRole()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
