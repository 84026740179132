import http from '@/libs/http.js';

export const getServices = ({ include }) => {
  return http.get('/api/v2/service', { params: { include } });
};

export const getService = (serviceId, { include }) => {
  return http.get('/api/v2/service/' + serviceId, {
    params: {
      include,
    },
  });
};

export const serviceStore = ({
  categoryId,
  title,
  type,
  priceType,
  priceRangeStart,
  priceRangeEnd,
  employeePriceRate,
  prices,
}) => {
  return http.post('/api/v2/service', {
    categoryId,
    title,
    type,
    priceType,
    priceRangeStart,
    priceRangeEnd,
    employeePriceRate,
    prices,
  });
};

export const serviceRemove = (serviceId) => {
  return http.delete('/api/v2/service', {
    params: {
      serviceId,
    },
  });
};

/**
 * ...
 * Array prices [ { serviceId, carClass, wheelDiameter, isRunFlat, amount }]
 */
export const serviceUpdate = (
  serviceId,
  { categoryId, title, type, priceType, priceRangeStart, priceRangeEnd, employeePriceRate, prices, index }
) => {
  return http.put('/api/v2/service', {
    serviceId,
    categoryId,
    title,
    type,
    priceType,
    priceRangeStart,
    priceRangeEnd,
    employeePriceRate,
    prices,
    index,
  });
};
