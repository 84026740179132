<template>
  <div class="group relative h-full w-full">
    <div class="scrollbar absolute h-full w-full overflow-y-scroll">
      <slot />
    </div>
    <div class="absolute bottom-0 right-4 h-4 w-full bg-gradient-to-t from-white group-hover:hidden"></div>
  </div>
</template>




<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 16px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  @apply bg-violet-primary;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-70;
}
</style>
