<script setup>
import { computed, ref } from 'vue';
import { useServicesQuery } from '@/query/useServiceQuery.js';
import AppButton from '@/components/AppButton.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { orderWorkAdd } from '@/api/order.js';
import AppScrollable from '@/components/AppScrollable.vue';
import OrderEditServicesStorage from '@/modules/order/components/OrderEditServicesStorage.vue';
import { useToast } from '@/use/useToast.js';
import ServiceType from '@/enums/ServiceType.js';
import OrderEditServicesCustomModal from '@/modules/order/components/OrderEditServicesCustomModal.vue';
import ServicePriceType from '@/enums/ServicePriceType.js';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  categoryId: {
    type: Number,
    required: true,
  },
  carClass: {
    type: [String, null],
    required: true,
  },
  wheelDiameter: {
    type: [Number, null],
    required: true,
  },
});

const include = ['category', 'prices'];
const { data: services } = useServicesQuery({ include });

const servicesFiltered = computed(() => {
  if (!services.value) {
    return null;
  }

  const filtered = services?.value?.data?.filter((service) => {
    return service.categoryId === props.categoryId;
  });

  const sortable = [...filtered];

  return sortable?.sort((a, b) => a.index - b.index);
});

const queryClient = useQueryClient();
const loading = ref(false);

const select = async (service, count) => {
  if (
    service.type === ServiceType.FREE ||
    service.priceType === ServicePriceType.RANGE ||
    service.priceType === ServicePriceType.FREE
  ) {
    customModalOpen(service.serviceId, count);
    return;
  }

  loading.value = true;

  try {
    await orderWorkAdd({
      orderId: props.orderId,
      serviceId: service.serviceId,
      count,
      carClass: props.carClass,
      wheelDiameter: props.wheelDiameter,
    });
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'order'),
    });

    loading.value = false;
  }
};

const customModalShow = ref(false);
const customServiceId = ref(null);
const customCount = ref(null);

const customModalOpen = (serviceId, count) => {
  customModalShow.value = true;
  customServiceId.value = serviceId;
  customCount.value = count;
};

const customModalClose = () => {
  customModalShow.value = false;
  customServiceId.value = null;
  customCount.value = null;
};
</script>

<template>
  <AppScrollable>
    <div class="flex flex-col gap-y-[18px]">
      <div
        v-for="service in servicesFiltered"
        :key="service.serviceId"
      >
        <template v-if="service.type === ServiceType.STORAGE">
          <OrderEditServicesStorage
            :order-id="props.orderId"
            :service-id="service.serviceId"
            :car-class="props.carClass"
            :wheel-diameter="props.wheelDiameter"
          />
        </template>

        <div
          v-else
          class="flex justify-between items-center gap-x-[12px]"
        >
          <div>
            <AppButton
              :disabled="loading"
              black
              outline
              class="px-[24px] !disabled:cursor-wait"
              :class="{ 'text-sm': service.title.length > 28 }"
              @click="select(service, 4)"
              >{{ service.title }}</AppButton
            >
          </div>
          <div class="flex gap-x-[6px]">
            <AppButton
              v-for="count in [1, 2, 3, 4]"
              :key="count"
              :disabled="loading"
              class="w-[46px] h-[38px] !disabled:cursor-wait"
              black
              outline
              @click="select(service, count)"
              >{{ count }}</AppButton
            >
          </div>
        </div>
      </div>
    </div>

    <OrderEditServicesCustomModal
      v-if="customModalShow"
      :order-id="props.orderId"
      :category-id="props.categoryId"
      :service-id="customServiceId"
      :car-class="props.carClass"
      :wheel-diameter="props.wheelDiameter"
      :count="customCount"
      @close="customModalClose()"
      @saved="customModalClose()"
    />
  </AppScrollable>
</template>
