import StorageStatus from '@/enums/StorageStatus.js';
import StorageKit from '@/enums/StorageType.js';
import { DateTime } from 'luxon';
import StorageSeasonality from '@/enums/StorageSeasonality.js';

export const useStorage = () => {
  const getTitle = (storage) => {
    const titles = [];
    storage.items.forEach((item) => {
      titles.push(getItemTitle(item));
    });

    return titles.join(', ');
  };

  const getStatusTitle = (storage) => {
    switch (storage.status) {
      case StorageStatus.IN_STOCK:
        return 'На складе';

      case StorageStatus.RELEASED:
        return 'Выдано';
    }
  };

  const getStatusChangedAt = (storage) => {
    switch (storage.status) {
      case StorageStatus.IN_STOCK:
        return DateTime.fromISO(storage.createdAt).toFormat('d MMMM yyyy');

      case StorageStatus.RELEASED:
        return DateTime.fromISO(storage.releasedAt).toFormat('d MMMM yyyy');
    }
  };

  const isStatusInStock = (storage) => {
    return storage.status === StorageStatus.IN_STOCK;
  };

  const isStatusReleased = (storage) => {
    return storage.status === StorageStatus.RELEASED;
  };

  const getItemTitle = (item) => {
    return getItemTypeTitle(item) + ' ' + getItemMarkTitle(item) + ' ' + getItemParametersTitle(item);
  };

  const getItemTypeTitle = (item) => {
    switch (item.type) {
      case StorageKit.TIRES_WITH_DISKS:
        return 'Колеса в сборе';

      case StorageKit.TIRES:
        return 'Шины';

      case StorageKit.DISKS:
        return 'Диски';
    }
  };

  const getItemMarkTitle = (item) => {
    switch (item.type) {
      case StorageKit.TIRES_WITH_DISKS:
        return item.parameters.tireBrand + ' ' + item.parameters.tireModel;

      case StorageKit.TIRES:
        return item.parameters.tireBrand + ' ' + item.parameters.tireModel;

      case StorageKit.DISKS:
        return item.parameters.diskModel;
    }
  };

  const getItemParametersTitle = (item) => {
    switch (item.type) {
      case StorageKit.TIRES_WITH_DISKS:
        return item.parameters.width + '/' + item.parameters.height + '/R' + item.parameters.diameter;

      case StorageKit.TIRES:
        return item.parameters.width + '/' + item.parameters.height + '/R' + item.parameters.diameter;

      case StorageKit.DISKS:
        return 'R' + item.parameters.diameter;
    }
  };

  const getItemAdditionalParametersTitle = (item) => {
    switch (item.type) {
      case StorageKit.TIRES_WITH_DISKS:
      case StorageKit.TIRES:
        return (
          'DOT ' +
          item.parameters.tireDot +
          ' / Глубина протектора ' +
          item.parameters.treadDepth +
          ' / Сезонность ' +
          getSeasonalityTitle(item.parameters.seasonality)
        );
    }
  };

  const getSeasonalityTitle = (seasonality) => {
    switch (seasonality) {
      case StorageSeasonality.ALL_SEASON:
        return 'Всесезонные';

      case StorageSeasonality.WINTER_STUDDED:
        return 'Зимние шипованные';

      case StorageSeasonality.WINTER_STUDLESS:
        return 'Зимние нешипованные';

      case StorageSeasonality.SUMMER:
        return 'Летние';
    }
  };

  return {
    getTitle,
    getItemTitle,
    getStatusTitle,
    getStatusChangedAt,
    isStatusInStock,
    isStatusReleased,
    getItemTypeTitle,
    getItemMarkTitle,
    getItemParametersTitle,
    getItemAdditionalParametersTitle,
  };
};
