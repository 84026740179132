<script setup>
import { useOrderQuery } from '@/query/useOrderQuery.js';
import { computed, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import { DateTime } from 'luxon';
import { plural, formatMoney, formatPhone } from '@/use/useFormat.js';
import { useStorage } from '@/use/useStorage.js';
import { useRouter } from 'vue-router';
import OrderRemoveModal from '@/modules/order/components/OrderRemoveModal.vue';
import IconUpRight from '@/components/Icons/IconUpRight.vue';
import DocumentTemplateName from '@/enums/DocumentTemplateName.js';
import { useAuthStore } from '@/store/AuthStore.js';
import { tabs } from '@/modules/order/order-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});

const include = ['works', 'payments.account', 'storage.items', 'employees', 'shift', 'client', 'car.mark', 'car.model'];
const { data } = useOrderQuery({ orderId: props.orderId, withTrashed: true, include });

const order = computed(() => {
  return data.value?.data;
});
const { hasAccess } = useAuthStore();
const { getItemTypeTitle, getItemParametersTitle, getItemMarkTitle, getStatusTitle } = useStorage();

const router = useRouter();

const orderPrint = () => {
  let routeData = router.resolve({
    name: 'order.print',
    params: { orderId: order.value.orderId },
  });
  window.open(routeData.href, '_blank');
};

const showOrderRemoveModal = ref(false);

const durationInMinutes = computed(() => {
  return order.value.duration ? Math.round(order.value.duration / 60) : null;
});

const isOrderRemoved = computed(() => {
  return order.value.deletedAt !== null;
});

const storagePrint = (templateName) => {
  if (!order.value.storage) {
    return;
  }

  let routeData = router.resolve({
    name: 'storage.print',
    params: { storageId: order.value.storage.storageId, templateName },
  });

  window.open(routeData.href, '_blank');
};

const tabsWithCurrent = computed(() => {
  if (!order.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Просмотр заказа',
      route: {
        name: 'order.show',
        params: {
          orderId: order.value.orderId,
        },
      },
    },
  ];
});
</script>

<template>
  <CabinetLayout
    v-if="order"
    :tabs="tabsWithCurrent"
  >
    <div class="w-[756px] flex flex-col gap-y-[24px]">
      <div
        class="border border-[#CBD3E4] rounded-[6px] px-[14px] pb-[24px] pt-[16px]"
        :class="{ '!border-[#FB2626] !border-opacity-50': isOrderRemoved }"
      >
        <div>Данные о заказе</div>
        <div class="mt-[24px] grid grid-cols-[1fr_2fr] gap-y-[24px]">
          <template v-if="order.shift">
            <div class="text-xs text-gray-primary">Смена</div>
            <div class="flex flex-col gap-y-[12px]">
              <span
                class="text-violet-primary hover:underline underline-offset-2 cursor-pointer"
                @click="router.push({ name: 'shift.show', params: { shiftId: order.shiftId } })"
                >№{{ order.shift.numberId }} от
                {{ DateTime.fromISO(order.shift.createdAt).toFormat('d MMMM yyyy') }}</span
              >
            </div>
          </template>

          <div class="text-xs text-gray-primary">Дата создания</div>
          <div>
            {{ DateTime.fromISO(order.createdAt).toFormat('d MMMM yyyy') }}
            <span class="text-gray-primary">{{ DateTime.fromISO(order.createdAt).toFormat('TT') }}</span>
          </div>

          <template v-if="order.finishedAt">
            <div class="text-xs text-gray-primary">Дата завершения</div>
            <div>
              {{ DateTime.fromISO(order.finishedAt).toFormat('d MMMM yyyy') }}
              <span class="text-gray-primary">{{ DateTime.fromISO(order.finishedAt).toFormat('TT') }}</span>
            </div>

            <div class="text-xs text-gray-primary">Время выполнения</div>
            <div>{{ durationInMinutes }} {{ plural(durationInMinutes, ['минута', 'минуты', 'минут']) }}</div>
          </template>

          <div class="text-xs text-gray-primary">Выполненные работы</div>
          <div class="flex flex-col gap-y-[12px]">
            <div
              v-for="work in order.works"
              :key="work.workId"
              class="flex flex-wrap gap-x-[10px] items-center"
            >
              <span>{{ work.title }}</span>
              <span class="text-gray-primary text-sm"
                >{{ formatMoney(work.price) }} x {{ work.count }} ед. = {{ formatMoney(work.price * work.count) }}</span
              >
            </div>
          </div>

          <div class="text-xs text-gray-primary">Оплата</div>
          <div class="flex flex-col gap-y-[12px]">
            <div
              v-for="payment in order.payments"
              :key="payment.paymentId"
              class="flex gap-x-[10px]"
            >
              <span>{{ formatMoney(payment.amount) }}</span>
              <span class="text-gray-primary">{{ payment.account.title }}</span>
            </div>
          </div>

          <div class="text-xs text-gray-primary">Автомобиль</div>
          <div>
            <template v-if="order.car">
              {{ order.car.title }} <span class="text-gray-primary">{{ order.car.regNumber }}</span>
            </template>
            <template v-else>
              <span class="text-gray-primary">Не указан</span>
            </template>
          </div>

          <div class="text-xs text-gray-primary">Клиент</div>
          <div>
            <div
              v-if="order.client"
              class="flex gap-[6px]"
            >
              <router-link
                class="text-violet-primary hover:underline underline-offset-4"
                :to="{ name: 'client.show', params: { clientId: order.client.clientId } }"
                >{{ order.client.title }}
              </router-link>
              <span class="text-gray-primary">{{ formatPhone(order.client.phone) }}</span>
            </div>
            <template v-else>
              <span class="text-gray-primary">Не указан</span>
            </template>
          </div>

          <div class="text-xs text-gray-primary">Исполнитель</div>
          <div class="flex flex-col gap-y-[12px]">
            <div
              v-for="employee in order.employees"
              :key="employee.employeeId"
            >
              {{ employee.title }}
            </div>
          </div>
        </div>

        <div
          v-if="!isOrderRemoved"
          class="mt-[32px] flex justify-end gap-x-[12px]"
        >
          <div>
            <AppButton
              v-if="hasAccess('print-Order')"
              class="w-full"
              @click="orderPrint()"
              >Распечатать чек</AppButton
            >
          </div>
          <div>
            <AppButton
              v-if="hasAccess('delete-Order')"
              outline
              red
              @click="showOrderRemoveModal = true"
              ><AppIcon :icon="IconTrash"
            /></AppButton>

            <OrderRemoveModal
              v-if="showOrderRemoveModal"
              :order-id="order.orderId"
              @removed="showOrderRemoveModal = false"
              @closed="showOrderRemoveModal = false"
            />
          </div>
        </div>
      </div>

      <div
        v-if="order.storage"
        class="border border-[#CBD3E4] rounded-[6px] px-[14px] pb-[24px] pt-[16px]"
      >
        <div class="flex justify-between">
          <div>
            Хранение №{{ order.storage.numberId }}
            <span class="text-gray-primary text-sm">({{ getStatusTitle(order.storage) }})</span>
          </div>
          <div
            class="text-violet-primary text-sm flex gap-x-[6px] items-center cursor-pointer"
            @click="router.push({ name: 'storage.show', params: { storageId: order.storage.storageId } })"
          >
            <span class="underline-offset-2 hover:underline">К хранению</span>
            <AppIcon
              :icon="IconUpRight"
              :width="10"
            />
          </div>
        </div>

        <div class="mt-[24px] flex flex-col gap-y-[12px]">
          <template
            v-for="storageItem in order.storage.items"
            :key="storageItem.itemId"
          >
            <div class="flex gap-x-[5px]">
              {{ getItemTypeTitle(storageItem) }} {{ storageItem.count }} шт.
              <span class="text-gray-primary">{{ getItemParametersTitle(storageItem) }}</span>
              <span>({{ getItemMarkTitle(storageItem) }})</span>
            </div>
          </template>
        </div>

        <div
          v-if="!isOrderRemoved"
          class="mt-[32px] flex justify-end gap-x-[12px]"
        >
          <div v-if="order.storage">
            <AppButton
              class="w-full"
              outline
              @click="storagePrint(DocumentTemplateName.STORAGE_RECEIVED)"
              >Печать договора хранения</AppButton
            >
          </div>
        </div>
      </div>
    </div>
  </CabinetLayout>
</template>
