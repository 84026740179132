<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import AppSelect from '@/components/AppSelect.vue';
import AppSelectPeriod from '@/components/AppSelectPeriod.vue';
import AppSelectGroupByPeriod from '@/components/AppSelectGroupByPeriod.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconArrowDownload from '@/components/Icons/IconArrowDownload.vue';

const props = defineProps({
  startTime: {
    type: [Object, null],
    required: true,
  },
  endTime: {
    type: [Object, null],
    required: true,
  },
  groupBy: {
    type: [String, null],
    required: true,
  },
});

const emit = defineEmits(['update:startTime', 'update:endTime', 'update:groupBy']);

const startTime = ref(null);
const endTime = ref(null);
const groupBy = ref(null);

watchEffect(() => {
  emit('update:startTime', startTime.value);
  emit('update:endTime', endTime.value);
  emit('update:groupBy', groupBy.value);
});
</script>

<template>
  <div class="flex justify-end">
    <div class="flex flex-wrap gap-[12px] items-center">
      <div class="font-medium">Период</div>
      <div class="md:w-[300px]">
        <AppSelectPeriod
          v-model:start-time="startTime"
          v-model:end-time="endTime"
          data-test="report-general-period"
        />
      </div>
      <div class="hidden md:block h-[18px] w-[1px] bg-[#D9D9D9]"></div>
      <div class="font-medium">Группировать</div>
      <AppSelectGroupByPeriod v-model:period="groupBy" />
      <!-- @todo доделать выгрузку -->
      <!--      <div class="h-[18px] w-[1px] bg-[#D9D9D9]"></div>-->
      <!--      <div class="flex gap-x-[8px] items-center">-->
      <!--        <AppIcon-->
      <!--          :icon="IconArrowDownload"-->
      <!--          class="text-violet-primary"-->
      <!--          :width="12"-->
      <!--          :height="14"-->
      <!--        />-->
      <!--        <div>Выгрузить</div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
