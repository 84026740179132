import http from '@/libs/http.js';

export const getServiceCategories = (include) => {
  return http.get('/api/v2/service-category', {
    params: {
      include,
    },
  });
};

export const getServiceCategory = (categoryId, { include }) => {
  return http.get('/api/v2/service-category/' + categoryId, {
    params: {
      include,
    },
  });
};

export const serviceCategoryUpdate = (categoryId, { index, title }) => {
  return http.put('/api/v2/service-category', {
    categoryId,
    title,
    index,
  });
};

export const serviceCategoryRemove = (categoryId) => {
  return http.delete('/api/v2/service-category', {
    params: {
      categoryId,
    },
  });
};

export const serviceCategoryStore = ({ title }) => {
  return http.post('/api/v2/service-category', {
    title,
  });
};
