<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2911 1.70895L6.07045 7.9296M1.90816 4.80393L11.5949 1.44321C12.1915 1.23625 12.7638 1.80858 12.5568 2.40511L9.19612 12.0919C8.96589 12.7555 8.03402 12.7737 7.77806 12.1196L6.23987 8.18865C6.16305 7.99232 6.00773 7.837 5.8114 7.76018L1.88048 6.22199C1.22636 5.96603 1.24456 5.03416 1.90816 4.80393Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
