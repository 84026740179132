const INDIVIDUAL = 'individual';
const COMPANY = 'company';

export const ClientType = {
  INDIVIDUAL,
  COMPANY,
};

export const getClientTypeLabel = (clientType) => {
  switch (clientType) {
    case INDIVIDUAL:
      return 'Физическое лицо';

    case COMPANY:
      return 'Юридическое лицо';

    default:
      throw 'Неизвестный тип клиента';
  }
};

export const getOptions = () => {
  return Object.values(ClientType).map((type) => {
    return {
      label: getClientTypeLabel(type),
      value: type,
    };
  });
};
