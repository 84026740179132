import OrderStatus from '@/enums/OrderStatus.js';
import { useRouter } from 'vue-router';

export const useOrder = () => {
  const router = useRouter();

  const goToOrder = (order, action = null) => {
    if (action === 'show' || order.status === OrderStatus.COMPLETED) {
      router.push({ name: 'order.show', params: { orderId: order.orderId } });
    } else {
      router.push({ name: 'order.edit', params: { orderId: order.orderId } });
    }
  };

  const goToOrderPrint = (order) => {
    let routeData = router.resolve({
      name: 'order.print',
      params: { orderId: order.orderId },
    });
    window.open(routeData.href, '_blank');
  };

  return {
    goToOrder,
    goToOrderPrint,
  };
};
