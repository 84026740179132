<script setup>
import { ref, computed, watchEffect, unref } from 'vue';
import AppSelect from '@/components/AppSelect.vue';
import { useBookingSettingsQuery } from '@/query/useBookingSettingsQuery.js';
import { useQueryClient } from '@tanstack/vue-query';
import { updateBookingSettings } from '@/api/booking_settings.js';
import { useToast } from '@/use/useToast.js';

const queryClient = useQueryClient();
const { data: settingsData } = useBookingSettingsQuery({ select: (response) => response?.data?.data });

const slotInterval = ref(null);
const allowedIntervals = [900, 1800, 2700, 3600];

const options = computed(() => {
  return allowedIntervals.map((interval) => {
    return {
      value: interval,
      label: interval / 60 + ' мин',
    };
  });
});

watchEffect(() => {
  if (!settingsData.value) {
    return;
  }

  slotInterval.value = settingsData.value.slot_interval;
});

const updateSettings = async () => {
  try {
    await updateBookingSettings({
      slotInterval: unref(slotInterval),
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['booking-settings'].indexOf(key) !== -1),
    });
    useToast().show('Настройки обновлены');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>

<template>
  <div class="flex">
    <div class="w-full">
      <label for="app-select">Окно записи</label>
      <AppSelect
        v-if="options.length > 0"
        id="app-select"
        v-model="slotInterval"
        class="mt-[12px]"
        :options="options"
        @changed="updateSettings()"
      />
    </div>
    <div class="w-[32px]">&nbsp;</div>
  </div>
</template>
