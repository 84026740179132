<script setup>
import BookingCreateClientExisting from '@/modules/booking/components/BookingCreateClientExisting.vue';
import AppButton from '@/components/AppButton.vue';
import BookingCreateClientCreate from '@/modules/booking/components/BookingCreateClientCreate.vue';
import { ref } from 'vue';

const emit = defineEmits(['client-selected', 'client-new-updated']);

const selectClient = (clientId) => {
  emit('client-selected', clientId);
};

const updateNewClient = (client) => {
  emit('client-new-updated', client);
};

const isNewClient = ref(false);
</script>

<template>
  <div>
    <div
      v-if="!isNewClient"
      class="flex flex-col gap-y-[9px]"
    >
      <div class="font-medium">Клиент</div>
      <div class="flex gap-x-[9px]">
        <div class="grow">
          <BookingCreateClientExisting @client-selected="selectClient" />
        </div>
        <div>
          <AppButton
            data-test="create-new-client"
            class="border-violet-primary h-[42px]"
            outline
            @click="isNewClient = true"
            >+</AppButton
          >
        </div>
      </div>
    </div>

    <BookingCreateClientCreate
      v-if="isNewClient"
      @client-new-updated="updateNewClient"
      @cancel="isNewClient = false"
    />
  </div>
</template>
