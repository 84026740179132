import { DateTime } from 'luxon';

export const formatMoney = (amount) => {
  if (amount === null || amount === undefined) {
    return;
  }

  return Intl.NumberFormat('Ru-ru', { style: 'currency', currency: 'RUB' }).format(amount / 100);
};

export const formatPhone = (value, digital = false) => {
  if (!value) {
    return;
  }

  value = value.toString();

  const digitalPhone = value.replace(/\D/g, '');
  if (digital) {
    return digitalPhone ? parseInt(digitalPhone) : null;
  }

  const parsedPhone = digitalPhone.match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

  let newValue = '+';

  if (parsedPhone[1]) {
    if (parsedPhone[1] === '8') {
      newValue += 7;
    } else if (parsedPhone[1] === '9') {
      newValue += 7 + '' + parsedPhone[1];
    } else {
      newValue += parsedPhone[1];
    }
  }

  if (parsedPhone[2]) {
    newValue += ` ${parsedPhone[2]}`;
  }

  if (parsedPhone[3]) {
    newValue += ` ${parsedPhone[3]}`;
  }

  if (parsedPhone[4]) {
    newValue += `-${parsedPhone[4]}`;
  }

  if (parsedPhone[5]) {
    newValue += `-${parsedPhone[5]}`;
  }

  return newValue;
};

export const firstLetterToUpperCase = (string, options = { allWords: false }) => {
  if (!string) {
    return;
  }

  const letterToUppercase = function (word) {
    return word[0].toUpperCase() + word.slice(1);
  };

  if (options.allWords) {
    return string
      .split(' ')
      .map((word) => {
        if (word.length === 0) {
          return;
        }

        return letterToUppercase(word);
      })
      .join(' ');
  }

  return letterToUppercase(string);
};

export const plural = (value, words) => {
  value = Math.abs(value) % 100;
  const num = value % 10;

  if (value > 10 && value < 20) {
    return words[2];
  }

  if (num > 1 && num < 5) {
    return words[1];
  }

  if (num === 1) {
    return words[0];
  }

  return words[2];
};

export const dateToHumanTitle = (date) => {
  const dateObject = DateTime.fromISO(date);

  switch (true) {
    case dateObject.hasSame(DateTime.now(), 'day'):
      return 'Сегодня';

    case dateObject.hasSame(DateTime.now().plus({ day: 1 }), 'day'):
      return 'Завтра';

    default:
      return dateObject.toFormat('d MMMM');
  }
};

export const formatSeconds = (seconds) => {
  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const MONTH = DAY * 28;

  const minutes = Math.round(seconds / MINUTE);
  const hours = Math.round(seconds / HOUR);
  const days = Math.round(seconds / DAY);
  const months = Math.round(seconds / MONTH);

  switch (true) {
    case months > 0:
      return months + ' ' + plural(months, ['месяц', 'месяца', 'месяцев']);

    case days > 0:
      return days + ' ' + plural(days, ['день', 'дня', 'дней']);

    case hours > 0:
      return hours + ' ' + plural(hours, ['час', 'часа', 'часов']);

    case minutes > 0:
      return minutes + ' ' + plural(minutes, ['минута', 'минуты', 'минут']);
  }
};
