<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import AppTable from '@/components/AppTable.vue';
import AppPagination from '@/components/AppPagination.vue';
import { plural } from '@/use/useFormat.js';
import { useClientsQuery } from '@/query/useClientQuery.js';
import { clientsColumns } from '@/modules/client/tables/clientsTable.js';
import AppInput from '@/components/AppInput.vue';
import { useConfirmDialog, watchDebounced } from '@vueuse/core';
import { tabs } from '@/modules/client/client-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import AppButton from '@/components/AppButton.vue';
import ClientNewModal from '@/modules/client/components/ClientNewModal.vue';
import { useAuthStore } from '@/store/AuthStore.js';

const include = ['cars.mark', 'cars.model'];
const currentPage = ref(1);
const perPage = 30;
const options = { keepPreviousData: true };
const search = ref(null);
const searchDebounced = ref(null);
const { hasAccess } = useAuthStore();

watchDebounced(
  search,
  () => {
    searchDebounced.value = search.value;
  },
  { debounce: 700, maxWait: 10000 }
);

const { data, isLoading, isFetching } = useClientsQuery(
  { searchQuery: searchDebounced, perPage, page: currentPage, include },
  options
);

const clients = computed(() => {
  return data?.value?.data;
});

const total = computed(() => {
  return data?.value?.meta?.total | 1;
});

const router = useRouter();
const { isRevealed, reveal, confirm, cancel, onReveal, onConfirm, onCancel } = useConfirmDialog();
</script>

<template>
  <CabinetLayout
    :loading="isLoading || isFetching"
    :hide-in-loading="isLoading === true"
    :tabs="tabs"
  >
    <div class="flex flex-wrap gap-[18px] justify-between items-center text-lg">
      <div class="flex gap-x-[12px] items-center">
        <div>{{ total }} {{ plural(total, ['клиент найден', 'клиента найдено', 'клиентов найдено']) }}</div>

        <AppInput
          v-model="search"
          class="md:w-[450px]"
          placeholder="Поиск по гос. номеру, ФИО и телефону"
          data-test="client-search"
        />
      </div>
      <AppButton
        v-if="hasAccess('create-Client')"
        outline
        @click="reveal()"
        >Новый клиент</AppButton
      >
      <ClientNewModal
        v-if="isRevealed"
        @close="cancel()"
      />
    </div>

    <AppTable
      class="mt-[24px]"
      :data="clients"
      :columns="clientsColumns"
      row-class="cursor-pointer"
      @row-clicked="(client) => router.push({ name: 'client.show', params: { clientId: client.clientId } })"
    >
    </AppTable>

    <div class="flex justify-end mt-[24px]">
      <AppPagination
        v-model="currentPage"
        :total="total"
        :per-page="perPage"
      />
    </div>
  </CabinetLayout>
</template>
