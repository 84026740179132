import { useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';
import { getActivityLog } from '@/api/activity_log.js';

export function useActivityLogQuery({ page, perPage } = {}, options = {}) {
  const queryKey = ['activity-log', page, perPage];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getActivityLog({ page: unref(page), perPage: unref(perPage) });
    },
    ...options,
  });
}
