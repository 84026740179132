<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import { computed, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, null],
    required: true,
  },
  excludeAccountId: {
    type: [String, null],
    required: false,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const { data } = useAccountsQuery({ isArchived: false });

const accounts = computed(() => {
  if (props.excludeAccountId) {
    return data?.value?.data.filter((account) => {
      return account.accountId !== props.excludeAccountId;
    });
  }

  return data?.value?.data;
});

const accountSelect = (accountId) => {
  emit('update:modelValue', accountId);
};

const accountId = computed({
  get() {
    return props.modelValue;
  },
  set(id) {
    accountSelect(id);
  },
});

watch(accounts, () => {
  if (accounts.value?.length === 1) {
    accountSelect(accounts.value[0].accountId);
  }
});
</script>

<template>
  <div class="flex flex-col gap-y-[12px]">
    <div>Способ оплаты</div>
    <div>
      <AppSelect
        v-model="accountId"
        data-test="payment-account"
        required
        :options="accounts"
        option-label="title"
        option-value="accountId"
      />
    </div>
  </div>
</template>
