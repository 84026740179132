<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import AppIcon from '@/components/AppIcon.vue';
import AppClientEditModal from '@/components/AppClientEditModal.vue';
import { useConfirmDialog } from '@vueuse/core';
import { useClientQuery } from '@/query/useClientQuery.js';
import { formatPhone } from '@/use/useFormat.js';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
  allowEdit: {
    type: Boolean,
    required: true,
  },
});

const { data: client } = useClientQuery({ clientId: props.clientId }, { select: (response) => response?.data?.data });

const { isRevealed, reveal, confirm } = useConfirmDialog();
</script>

<template v-if="client">
  <div class="flex flex-col gap-[24px]">
    <div class="text-lg font-medium">Персональная информация</div>
    <ClientShowCard class="flex justify-between">
      <div class="grow grid grid-cols-2 gap-[32px]">
        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">Контактный телефон</div>
          <div>{{ client.phone ? formatPhone(client.phone) : '—' }}</div>
        </div>

        <div class="flex flex-col gap-[12px]">
          <div class="text-sm text-gray-primary">E-mail</div>
          <div>{{ client.email ?? '—' }}</div>
        </div>
      </div>

      <div class="flex-none">
        <AppIcon
          v-if="allowEdit"
          :icon="IconPencil"
          :width="20"
          :height="20"
          class="cursor-pointer hover:text-violet-primary"
          data-test="client-show-edit-info"
          @click="reveal()"
        />
      </div>

      <AppClientEditModal
        v-if="isRevealed"
        :client-id="props.clientId"
        :fields="['email', 'phone']"
        @closed="confirm()"
      />
    </ClientShowCard>
  </div>
</template>
