<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  hide: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div class="group/tooltip relative">
    <slot />

    <span
      v-if="!props.hide"
      class="absolute -top-2 left-1/2 z-50 hidden -translate-y-full -translate-x-1/2 rounded-[6px] bg-[#1E1E29] p-[10px] text-center text-xs text-white transition duration-200 group-hover/tooltip:block"
    >
      {{ props.title }}
    </span>
  </div>
</template>
