<script setup>
import { firstLetterToUpperCase } from '@/use/useFormat.js';
import { computed, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import AppIcon from '@/components/AppIcon.vue';
import IconChevronRight from '@/components/Icons/IconChevronRight.vue';
import IconChevronLeft from '@/components/Icons/IconChevronLeft.vue';

const props = defineProps({
  selectedDate: {
    type: String,
    required: true,
  },

  markedDays: {
    type: Array,
    default: new Array(),
  },

  pastDaysDarken: {
    type: Boolean,
    default: true,
  },

  emitWhenMonthChanged: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['selected-date-changed']);

const selectedDateObject = ref(DateTime.fromISO(props.selectedDate));

watch(
  () => props.selectedDate,
  () => {
    selectedDateObject.value = DateTime.fromISO(props.selectedDate);
  }
);

const selectedDateMonthTitle = computed(() => {
  const month = firstLetterToUpperCase(selectedDateObject.value.toFormat('LLLL'));
  const year = selectedDateObject.value.year;
  const currentYear = DateTime.now().year;

  if (year === currentYear) {
    return month;
  }

  return [month, year].join(', ');
});

const firstDayOfTheMonth = computed(() => {
  return selectedDateObject.value.set({ day: 1 }).toFormat('c') - 1;
});

const emptyDaysClass = computed(() => {
  const classProperties = {};

  classProperties[`col-span-${firstDayOfTheMonth.value}`] = true;

  return classProperties;
});

const daysInTheMonth = computed(() => {
  return selectedDateObject.value.daysInMonth;
});

const selectNextMonth = () => {
  selectedDateObject.value = selectedDateObject.value.plus({ month: 1 }).startOf('month');

  if (props.emitWhenMonthChanged) {
    emitSelected();
  }
};

const selectPreviousMonth = () => {
  selectedDateObject.value = selectedDateObject.value.minus({ month: 1 }).startOf('month');

  if (props.emitWhenMonthChanged) {
    emitSelected();
  }
};

const isCurrentDay = (day) => {
  const currentDay = DateTime.now();
  const cursorDay = selectedDateObject.value.set({ day });
  const hasSame = cursorDay.hasSame(currentDay, 'day');

  return hasSame;
};

const isSelectedDay = (day) => {
  const cursorDay = selectedDateObject.value.set({ day });
  const hasSame = cursorDay.hasSame(DateTime.fromISO(props.selectedDate), 'day');

  return hasSame;
};

const setSelectedDay = (day) => {
  selectedDateObject.value = selectedDateObject.value.set({ day });
  emitSelected();
};

const emitSelected = () => {
  const date = selectedDateObject.value.toFormat('y-LL-dd');

  emit('selected-date-changed', date);
};

const dayIsPast = (day) => {
  const nowDate = DateTime.now().startOf('day');
  const dayDate = selectedDateObject.value.set({ day }).startOf('day');
  const isPast = nowDate > dayDate;

  return isPast;
};

const dayIsMarked = (day) => {
  return props.markedDays.indexOf(day) > -1;
};
</script>

<template>
  <div
    class="select-none flex flex-col border bg-white border-[#CBD3E4] rounded-[6px] gap-y-[25px] pt-[25px] pb-[18px] px-[18px]"
  >
    <div class="flex items-center justify-between">
      <AppIcon
        :height="15"
        :icon="IconChevronLeft"
        class="text-gray-primary cursor-pointer"
        @click="selectPreviousMonth()"
      />

      <div class="font-medium">{{ selectedDateMonthTitle }}</div>

      <AppIcon
        :height="15"
        :icon="IconChevronRight"
        class="text-gray-primary cursor-pointer"
        @click="selectNextMonth()"
      />
    </div>

    <div class="flex grid grid-cols-7 gap-x-[14px] gap-y-[10px] text-sm items-center justify-items-center select-none">
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Пн</div>
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Вт</div>
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Ср</div>
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Чт</div>
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Пт</div>
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Сб</div>
      <div class="text-sm font-medium p-[10px] rounded-[6px] bg-[#F3F8FD] uppercase">Вс</div>

      <div
        v-if="firstDayOfTheMonth > 0"
        :class="emptyDaysClass"
      ></div>

      <div
        v-for="day in daysInTheMonth"
        :key="day"
        class="select-none"
      >
        <div
          class="relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-[6px] text-center transition-colors duration-100 hover:bg-indigo-50"
          :class="{
            'bg-emerald-100 font-bold text-emerald-600 hover:bg-emerald-200': isCurrentDay(day) && !isSelectedDay(day),
            'cursor-default bg-indigo-500 font-bold text-white hover:!bg-indigo-500':
              isCurrentDay(day) && isSelectedDay(day),
            'cursor-default bg-indigo-500 font-bold text-white hover:!bg-indigo-500 hover:text-white':
              isSelectedDay(day) && !isCurrentDay(day),
            'text-gray-300': props.pastDaysDarken && dayIsPast(day),
          }"
          @click="setSelectedDay(day)"
        >
          {{ day }}

          <div
            v-if="!dayIsPast(day) && !isSelectedDay(day) && !isCurrentDay(day) && dayIsMarked(day)"
            class="absolute bottom-1 h-1 w-1 rounded bg-indigo-200"
          />
        </div>
      </div>
    </div>
  </div>
</template>
