<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watchEffect } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { updateWorkplace, storeWorkplace } from '@/api/workplace.js';

const props = defineProps({
  workplace: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const vuelidate = await useVuelidate();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    if (props.workplace) {
      await updateWorkplace(props.workplace.workplaceId, {
        options: { title: title.value },
      });
    } else {
      await storeWorkplace(title.value);
    }

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['workplace', 'update', 'store'].indexOf(key) !== -1),
    });
    useToast().show('Успешно выполнено.');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const modalTitle = computed(() => {
  return props.workplace?.workplaceId ? 'Редактирование поста' : 'Новый пост';
});

const title = ref(null);
watchEffect(() => {
  if (!props.workplace) {
    return;
  }

  title.value = props.workplace?.title
});
</script>

<template>
  <AppModal
    :show="isShow"
    @close="close()"
  >
    <template #title>{{ modalTitle }}</template>

    <div class="flex flex-col gap-y-[24px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Название</div>
        <div>
          <AppInput
            v-model="title"
            placeholder="Введите название"
            required
          />
        </div>
      </div>

    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
