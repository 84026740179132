import ReportGeneralPage from '@/modules/report/pages/ReportGeneralPage.vue';
import ReportServicesPage from '@/modules/report/pages/ReportServicesPage.vue';
export default [
  {
    path: '/report/general',
    name: 'report.general',
    component: ReportGeneralPage,
    meta: {
      permissions: ['view-statistic-Account'],
    },
  },
  {
    path: '/report/services',
    name: 'report.services',
    component: ReportServicesPage,
    meta: { permissions: ['view-statistic-Account'] },
  },
];
