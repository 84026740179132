<script setup>
import { ref } from 'vue';
import { getCountByDays } from '@/api/booking.js';
import AppCalendar from '@/components/AppCalendar.vue';
import { DateTime } from 'luxon';

const props = defineProps({
  selectedDate: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['selected-date-changed']);

const markedDays = ref([]);

const fetchCountByDays = async () => {
  // const monthStartTime = this.$dateTime.fromISO(this.selectedDate).startOf('month').toISO();
  // const monthEndTime = this.$dateTime.fromISO(this.selectedDate).endOf('month').toISO();
  // const countByDaysResponse = await getCountByDays(monthStartTime, monthEndTime);
  //
  // this.markedDays = [];
  // countByDaysResponse.data.data.map((item) => {
  //   this.markedDays.push(item.day);
  // });
};

const selectToday = () => {
  const date = DateTime.now().toFormat('y-LL-dd');
  emit('selected-date-changed', date);
};

const selectTomorrow = () => {
  const date = DateTime.now().plus({ day: 1 }).toFormat('y-LL-dd');

  emit('selected-date-changed', date);
};

const select = (date) => {
  emit('selected-date-changed', date);
};
</script>

<template>
  <div class="flex flex-col gap-y-[24px]">
    <AppCalendar
      emit-when-month-changed
      :selected-date="props.selectedDate"
      :marked-days="markedDays"
      @selected-date-changed="select"
    />

    <div class="flex gap-x-[12px] items-center select-none">
      Быстрый доступ:
      <span
        class="cursor-pointer px-[8px] py-[5px] rounded bg-[#EDECEC] text-violet-primary"
        @click="selectToday()"
        >Сегодня</span
      >

      <span
        class="cursor-pointer px-[8px] py-[5px] rounded bg-[#EDECEC] text-violet-primary"
        @click="selectTomorrow()"
        >Завтра</span
      >
    </div>
  </div>
</template>
