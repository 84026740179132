<script setup>
import OrderCarNewMark from '@/modules/order/components/OrderCarNewMark.vue';
import OrderCarNewModel from '@/modules/order/components/OrderCarNewModel.vue';
import AppInput from '@/components/AppInput.vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['car-new-updated']);

const markId = ref(null);
const modelId = ref(null);
const regNumber = ref(null);

watch([markId, modelId, regNumber], () => {
  emit('car-new-updated', {
    markId: markId.value,
    modelId: modelId.value,
    regNumber: regNumber.value,
  });
});

const markSelect = (selectedMarkId) => {
  markId.value = selectedMarkId;
  modelId.value = null;
};

const modelSelect = (selectedModelId) => {
  modelId.value = selectedModelId;
};
</script>

<template>
  <div class="flex flex-col gap-y-[24px]">
    <div class="grid grid-cols-2 gap-[24px]">
      <OrderCarNewMark @mark-selected="markSelect" />
      <OrderCarNewModel
        :mark-id="markId"
        @model-selected="modelSelect"
      />
      <div class="flex flex-col gap-y-[12px]">
        <div>Гос номер</div>
        <AppInput
          v-model="regNumber"
          data-test="regNumber"
        />
      </div>
    </div>
  </div>
</template>
