<script setup>
import { useClientQuery } from '@/query/useClientQuery.js';
import AppModal from '@/components/AppModal.vue';
import AppInput from '@/components/AppInput.vue';
import { ref, watch, watchEffect } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { useToast } from '@/use/useToast.js';
import { updateClient } from '@/api/client.js';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
  fields: {
    type: Array,
    required: false,
  },
});

const emit = defineEmits('closed');

const { data: client } = useClientQuery({ clientId: props.clientId }, { select: (response) => response?.data?.data });

const firstName = ref(null);
const lastName = ref(null);
const patronymicName = ref(null);
const phone = ref(null);
const email = ref(null);
const note = ref(null);

watch(
  client,
  () => {
    if (!client.value) {
      return;
    }

    firstName.value = client.value.firstName;
    lastName.value = client.value.lastName;
    patronymicName.value = client.value.patronymicName;
    phone.value = client.value.phone;
    email.value = client.value.email;
    note.value = client.value.note;
  },
  {
    deep: true,
    immediate: true,
  }
);

const loading = ref(false);
const queryClient = useQueryClient();

const save = async () => {
  loading.value = true;

  try {
    await updateClient(props.clientId, {
      firstName: firstName.value,
      lastName: lastName.value,
      patronymicName: patronymicName.value,
      phone: phone.value,
      email: email.value,
      note: note.value,
    });
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['client', 'order'].includes(key)),
    });
    useToast().show('Информация обновлена');
    emit('closed');
  } catch (error) {
    useToast().error(
      error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
    );

    if (!error?.response?.data?.message) {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const hasField = (field) => {
  if (!props.fields) {
    return true;
  }

  return props.fields.includes(field);
};
</script>

<template>
  <AppModal
    :show="true"
    class="md:w-[584px]"
    @close="emit('closed')"
  >
    <template #title>Редактирование клиента</template>

    <div class="grid grid-cols-2 gap-y-[24px] gap-x-[12px]">
      <div
        v-if="hasField('firstName')"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Имя</div>
        <div>
          <AppInput
            v-if="hasField('firstName')"
            v-model="firstName"
            data-test="client-edit-first-name"
          />
        </div>
      </div>

      <div
        v-if="hasField('lastName')"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Фамилия</div>
        <div>
          <AppInput 
            v-model="lastName"
            data-test="client-edit-last-name"
          />
        </div>
      </div>

      <div
        v-if="hasField('patronymicName')"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Отчество</div>
        <div>
          <AppInput 
            v-model="patronymicName" 
            data-test="client-edit-patronymic-name"
          />
        </div>
      </div>

      <div
        v-if="hasField('phone')"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Телефон</div>
        <div>
          <AppInput
            v-model="phone"
            mask="phone"
            data-test="client-edit-phone"
          />
        </div>
      </div>

      <div
        v-if="hasField('email')"
        class="flex flex-col gap-y-[12px]"
      >
        <div>E-mail</div>
        <div>
          <AppInput 
            v-model="email"
            data-test="client-edit-email"
          />
        </div>
      </div>

      <div
        v-if="hasField('note')"
        class="flex flex-col gap-y-[12px] col-span-2"
      >
        <div>Заметка</div>
        <div>
          <AppInput 
            v-model="note"
            data-test="client-edit-note"
          />
        </div>
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          :disabled="loading"
          outline
          @click="emit('closed')"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>

