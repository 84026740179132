<template>
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9984 10.9999L10.9984 15.7999M10.9984 7.44209V7.3999M1.39844 10.9999C1.39844 5.69797 5.6965 1.3999 10.9984 1.3999C16.3004 1.3999 20.5984 5.69797 20.5984 10.9999C20.5984 16.3018 16.3004 20.5999 10.9984 20.5999C5.6965 20.5999 1.39844 16.3018 1.39844 10.9999Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
