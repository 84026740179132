<script setup>
import AppModal from '@/components/AppModal.vue';
import { ref, watch } from 'vue';
import AppButton from '@/components/AppButton.vue';

import 'vue-advanced-cropper/dist/style.css';
import { useBookingSettingsQuery } from '@/query/useBookingSettingsQuery.js';
import AppInput from '@/components/AppInput.vue';
import { useToast } from '@/use/useToast';
import { updateBookingSettings } from '@/api/booking_settings';
import { useQueryClient } from '@tanstack/vue-query';

const emit = defineEmits(['closed', 'saved']);

const { data: bookingSettings } = useBookingSettingsQuery({ select: (response) => response?.data?.data });

const slug = ref(bookingSettings.value?.slug);

watch(
  bookingSettings,
  () => {
    slug.value = bookingSettings.value?.slug;
  },
  {
    deep: true,
  }
);

const isShow = ref(true);
const loading = ref(false);

const close = () => {
  emit('closed');
};

const queryClient = useQueryClient();

const save = async () => {
  loading.value = true;

  try {
    await updateBookingSettings({ slug: slug.value });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['booking', 'booking-settings'].indexOf(key) !== -1),
    });
    useToast().show('Адрес обновлен');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>

<template>
  <AppModal
    :show="isShow"
    @close="close()"
  >
    <template #title>Короткий адрес</template>

    <div>
      <div class="flex flex-col gap-[10px]">
        <AppInput v-model="slug" />
        <div class="text-sm text-gray-primary">
          Укажите здесь любой подходящий адрес для вашего бизнеса.<br />
          Например, <b>pilki</b>. <br />
          Вы можете использовать латинские буквы, цифры, знак тире и точку.
        </div>
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
