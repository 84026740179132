import { ref, watch } from 'vue';
import { useChat } from '@/use/useChat.js';
import * as Sentry from '@sentry/vue';
import { until, useDocumentVisibility, useStorage, useThrottleFn } from '@vueuse/core';
import EmployeeRole from '@/enums/EmployeeRole.js';
import BusinessSphere from '@/enums/BusinessSphere.js';
import { defineStore } from 'pinia';
import { getAuth } from '@/api/auth.js';
import { useArrayUtils } from '@/use/useArrayUtils.js';
import { useRouter } from 'vue-router';
import { app } from '@/main';
import { menu } from '@/data/menu.js';
import { Settings as luxonSettings } from 'luxon';

export const useAuthStore = defineStore('auth', () => {
  const authorized = ref(null);
  const employee = ref(null);
  const currentBusiness = ref(null);
  const roles = ref(null);
  const permissions = ref(null);
  const businessIdWithSessionStorage = useStorage('business-id', null, sessionStorage);
  const businessIdWithLocalStorage = useStorage('business-id', null);
  const { includesArray } = useArrayUtils();
  const router = useRouter();

  const updateExternalServicesData = () => {
    if (!employee.value) {
      return;
    }

    // Отправляем данные о клиенте в чатру
    useChat().setData({
      employeeId: employee.value.employeeId,
      name: employee.value.title,
      phone: employee.value.phone,
      currentBusinessId: currentBusiness.value?.businessId,
    });

    // Отправляем данные в Sentry
    Sentry.setUser({ id: employee.value.employeeId });

    Sentry.setContext('employee', { ...employee.value });

    if (currentBusiness.value) {
      Sentry.setContext('business', { ...currentBusiness.value });
    }
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const fetchAuth = async () => {
    try {
      const response = await getAuth();
      const data = response.data.data;

      authorized.value = true;
      employee.value = data.employee;
      currentBusiness.value = data.currentBusiness;
      roles.value = data.roles;
      permissions.value = data.permissions;

      if (data.currentBusiness) {
        luxonSettings.defaultZone = 'UTC+' + data.currentBusiness.timezone;
      }

      updateExternalServicesData();
    } catch (error) {
      if ([401, 403].includes(error?.response?.status)) {
        resetAuth();
      } else {
        await delay(3000);
        await fetchAuth();
      }
    }
  };

  const refetchAuth = async () => {
    await fetchAuth();
    return true;
  };

  const resetAuth = async () => {
    authorized.value = false;
    employee.value = null;
    currentBusiness.value = null;
    return true;
  };

  const resetBusiness = async () => {
    businessIdWithSessionStorage.value = null;
    businessIdWithLocalStorage.value = null;
  };

  const getBusinessId = async () => {
    if (businessIdWithSessionStorage.value) {
      return businessIdWithSessionStorage.value;
    }

    return businessIdWithLocalStorage.value;
  };

  const setBusinessId = async (businessId) => {
    businessIdWithSessionStorage.value = businessId;
    businessIdWithLocalStorage.value = businessId;
  };

  const isAuthorized = async () => {
    if (authorized.value === null) {
      await fetchAuth();
    }

    await until(authorized).not.toBeNull();

    return authorized.value;
  };

  const businessSwitch = async (selectedBusinessId) => {
    await setBusinessId(selectedBusinessId);
  };

  const isOwner = () => {
    return authorized.value && currentBusiness.value?.businessEmployee?.roleId === EmployeeRole.OWNER;
  };

  const hasAccess = (permission) => {
    if (!permission) return false; //true;

    if (authorized.value) {
      if (typeof permission === 'string' || permission instanceof String) {
        return permissions.value?.includes(permission);
      }
      if (permission instanceof Array) {
        return includesArray(permissions.value, permission);
      }
    }

    return false;
  };

  const isAllowGranted = (permissions, withNotify = true) => {
    if (!hasAccess(permissions)) {
      if (withNotify) {
        app.$toast.error(
          `Извините. У Вас отсутствует доступ для выполнения данного действия. Обратитесь к руководству за его получением.`
        );
      }

      return false;
    }

    return true;
  };

  const tryPermissionByRouteName = (name) => {
    const route = router.options.routes.find((route) => route.name == name);
    if (route && route.meta?.permissions) {
      return isAllowGranted(route.meta.permissions, false);
    }

    return true;
  };

  const isSimraceClub = () => {
    return currentBusiness.value?.sphereId === BusinessSphere.SIMRACE_CLUB;
  };

  const isTireFitting = () => {
    return currentBusiness.value?.sphereId === BusinessSphere.TIRE_FITTING;
  };

  const getAllowedPathes = (pathes) => {
    const routes = router.options.routes;
    return pathes.filter((item) => {
      const route = routes.find((route) => route.name == item.name);
      const hasAnyPermissionForAllowGranted = item.allowedPermission && isAllowGranted(item.allowedPermission, false);
      return item.position !== 'bottom' && (hasAccess(route?.meta?.permissions) || hasAnyPermissionForAllowGranted);
    });
  };

  const getHomeAction = () => {
    switch (true) {
      case isSimraceClub():
        return { name: 'booking.current' };

      case isTireFitting():
        const pathes = getAllowedPathes(menu);
        if (pathes[0]) {
          return { path: pathes[0].url };
        }
      //return { name: 'shift.show', params: { shiftId: 'current' } };

      default:
        return { name: 'auth.business-switch' };
    }
  };

  /**
   * Обновляем данные авторизации каждые 10 минут
   */
  const autoFetchAuth = () => {
    const fetchAuthThrottled = useThrottleFn(
      () => {
        fetchAuth();
      },
      1000 * 60 * 10
    );

    const documentVisibility = useDocumentVisibility();
    watch(documentVisibility, () => {
      if (documentVisibility.value === 'visible') {
        fetchAuthThrottled();
      }
    });

    fetchAuthThrottled();

    const handleInterval = setInterval(() => {
      if (!authorized.value) {
        clearInterval(handleInterval);
      }
      if (documentVisibility.value === 'visible') {
        fetchAuthThrottled();
      }
    }, 1000 * 60);
  };

  autoFetchAuth();

  return {
    isAuthorized,
    fetchAuth,
    refetchAuth,
    resetAuth,
    resetBusiness,
    isOwner,
    hasAccess,
    isSimraceClub,
    isTireFitting,
    getHomeAction,
    employee,
    currentBusiness,
    businessSwitch,
    getBusinessId,
    isAllowGranted,
    getAllowedPathes,
    tryPermissionByRouteName,
  };
});
