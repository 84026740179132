<script setup>
import { computed, watch } from 'vue';
import { useServiceCategoriesQuery } from '@/query/useServiceCategoryQuery.js';

const props = defineProps({
  categoryId: {
    type: [Number, null],
    required: true,
  },
});

const emit = defineEmits(['category-changed']);

const { data } = useServiceCategoriesQuery();

const categories = computed(() => {
  return data?.value?.data;
});

const isValueSelected = (value) => {
  return props.categoryId === value;
};

const select = (value) => {
  emit('category-changed', value);
};

watch(
  () => categories,
  () => {
    if (props.categoryId === null && categories?.value?.length > 0) {
      select(categories.value[0].categoryId);
    }
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <div class="mb-[13px] relative flex gap-x-[32px] select-none">
    <div
      v-for="category in categories"
      :key="category.categoryId"
      class="flex flex-col gap-y-[12px] relative"
      :class="{ 'cursor-pointer': !isValueSelected(category.categoryId) }"
      @click="select(category.categoryId)"
    >
      <div
        class="text-lg"
        :class="{
          'text-violet-primary font-medium': isValueSelected(category.categoryId),
        }"
      >
        {{ category.title }}
      </div>
      <div
        v-if="isValueSelected(category.categoryId)"
        class="absolute z-10 h-[3px] w-full bg-violet-primary -bottom-[12px] rounded-[2px]"
      ></div>
    </div>
    <div class="absolute w-full -bottom-[11px] bg-[#CBD3E4] h-[1px]"></div>
  </div>
</template>
