<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { operationExpensePayrollStore } from '@/api/operation.js';
import { useEmployeeQuery } from '@/query/useEmployeeQuery.js';
import { formatMoney } from '@/use/useFormat.js';
import AppIcon from '@/components/AppIcon.vue';
import IconInfoCircle from '@/components/Icons/IconInfoCircle.vue';

const props = defineProps({
  employeeId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed', 'created']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);
const vuelidate = useVuelidate();

const close = () => {
  emit('closed');
};

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    await operationExpensePayrollStore({
      employeeId: props.employeeId,
      amount: amount.value * 100,
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['operation', 'accounts', 'employee'].indexOf(key) !== -1),
    });
    useToast().show('Выплата сделана');
    emit('created');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const amount = ref(0);

const { data: employee, isLoading } = useEmployeeQuery(props.employeeId, {
  select: (response) => response?.data?.data,
});

watch(employee, () => {
  if (employee.value?.businessEmployee?.balance > 0) {
    amount.value = Math.floor(employee.value?.businessEmployee.balance / 100);
  }
});
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="isLoading"
    @close="close()"
  >
    <template #title>Выплата зарплаты</template>

    <div class="flex flex-col gap-y-[24px]">
      <div class="flex justify-between">
        <div class="font-medium">{{ employee.title }}</div>
        <div class="text-gray-primary">Баланс: {{ formatMoney(employee.businessEmployee.balance) }}</div>
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div class="text-sm">Сумма к выплате</div>
        <div>
          <AppInput
            v-model="amount"
            autofocus
            data-test="operation-payroll-create"
          />
        </div>
      </div>
    </div>

    <Transition name="slide-fade">
      <div
        v-if="amount * 100 > employee.businessEmployee.balance"
        class="text-sm mt-[24px] text-gray-primary flex gap-[6px]"
      >
        <AppIcon
          :icon="IconInfoCircle"
          :width="12"
          :height="12"
          class="flex-none mt-[3px]"
        />
        <div>
          Вам не нужно беспокоиться, если вы хотите выплатить сотруднику больше, чем есть на его счету (авансом) — мы
          сможем зафиксировать эту транзакцию.
        </div>
      </div>
    </Transition>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px] mt-[24px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          data-test="operation-payroll-save"
          @click="save()"
          >Выплатить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
