<template>
  <svg
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2076 16.316V3.68402C11.2076 3.13174 10.7599 2.68402 10.2076 2.68402H7.66396C7.11168 2.68402 6.66396 3.13174 6.66396 3.68402V16.316M11.2076 16.316L11.2065 8.82618C11.2064 8.27383 11.6541 7.82602 12.2065 7.82602H14.75C15.3023 7.82602 15.75 8.27374 15.75 8.82602V15.316C15.75 15.8683 15.3023 16.316 14.75 16.316H11.2076ZM11.2076 16.316H6.66396M6.66396 16.316V12.816C6.66396 12.2637 6.21625 11.816 5.66396 11.816H3.25C2.69772 11.816 2.25 12.2637 2.25 12.816V15.316C2.25 15.8683 2.69772 16.316 3.25 16.316H6.66396Z"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
