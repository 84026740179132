<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { useMarkQuery } from '@/query/useCarQuery.js';
import { computed, ref } from 'vue';

const emit = defineEmits(['mark-selected']);

const { data } = useMarkQuery();

const marks = computed(() => {
  return data?.value?.data ?? [];
});

const searchQuery = ref(null);

const search = (query) => {
  if (!query) {
    searchQuery.value = null;
    return;
  }
  searchQuery.value = String(query).toLowerCase();
};

const filteredMarks = computed(() => {
  if (!searchQuery.value) {
    return marks.value;
  }

  return marks.value.filter((model) => model.title.toLowerCase().indexOf(searchQuery.value) !== -1);
});

const selectMark = (markId) => {
  emit('mark-selected', markId);
};
</script>

<template>
  <div
    class="flex flex-col gap-y-[12px]"
    data-test="mark"
  >
    <div>Марка</div>
    <div>
      <AppSelect
        data-test="markId"
        option-value="markId"
        option-label="title"
        :options="filteredMarks"
        placeholder="Не выбрана"
        searchable
        @changed="selectMark"
        @search-query-changed="search"
      />
    </div>
  </div>
</template>
