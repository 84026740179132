<template>
  <svg
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 9.40475C16.0561 10.1938 17 12.1806 17 13.4047C17 13.7861 16.7218 14.0952 16.3785 14.0952H16M12 6.1704C12.6832 5.77518 13.1429 5.03651 13.1429 4.19047C13.1429 3.34443 12.6832 2.60575 12 2.21054M1.62148 14.0952H12.569C12.9122 14.0952 13.1905 13.7861 13.1905 13.4047C13.1905 11.0075 11.1878 9.06424 7.09524 9.06424C3.00272 9.06424 1 11.0075 1 13.4047C1 13.7861 1.27824 14.0952 1.62148 14.0952ZM9.38095 4.19047C9.38095 5.45283 8.3576 6.47618 7.09524 6.47618C5.83287 6.47618 4.80952 5.45283 4.80952 4.19047C4.80952 2.9281 5.83287 1.90475 7.09524 1.90475C8.3576 1.90475 9.38095 2.9281 9.38095 4.19047Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
