<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5991 5.30004L1.39968 5.29976L1.39844 5.3001M20.5991 5.30004L20.5984 18.6161C20.5984 19.8775 19.5565 20.9001 18.2712 20.9001H3.72571C2.44039 20.9001 1.39844 19.8775 1.39844 18.6161V5.3001M20.5991 5.30004L16.7499 1.45157C16.5249 1.22653 16.2196 1.1001 15.9014 1.1001H6.09549C5.77723 1.1001 5.47201 1.22653 5.24697 1.45157L1.39844 5.3001M14.5984 8.9001C14.5984 10.8883 12.9867 12.5001 10.9984 12.5001C9.01021 12.5001 7.39844 10.8883 7.39844 8.9001"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
