<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import { useOperationQuery } from '@/query/useOperationQuery.js';
import { operationRollback } from '@/api/operation.js';

const props = defineProps({
  operationId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const rollback = async () => {
  loading.value = true;

  try {
    await operationRollback(props.operationId);
    await router.push({ name: 'operation.index' });
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['operation', 'account'].indexOf(key) !== -1),
    });
    useToast().show('Операция отменена');
    emit('closed');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const { data: operation, isLoading } = useOperationQuery(
  { operationId: props.operationId },
  { select: (response) => response?.data?.data }
);
</script>

<template>
  <AppModal
    class="md:w-[584px]"
    :show="isShow"
    :loading="isLoading"
    @close="close()"
  >
    <template #title>Отмена операции</template>

    <div
      v-if="operation.meta?.order_id"
      class="text-center"
    >
      Это операция пополнения в заказе. Ее можно отменить только внутри заказа (раздел Платежи) или удалив заказ.
    </div>

    <div
      v-else
      class="text-center"
    >
      Вы уверены, что хотите отменить эту операцию?
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Закрыть</AppButton
        >
        <AppButton
          v-if="operation.meta?.order_id"
          :disabled="loading"
          :loading="loading"
          @click="router.push({ name: 'order.show', params: { orderId: operation.meta?.order_id } })"
          >Перейти к заказу</AppButton
        >

        <AppButton
          v-else
          :disabled="loading"
          :loading="loading"
          outline
          red
          @click="rollback()"
          >Подтвердить отмену</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
