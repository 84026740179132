<script setup>
import { computed, ref } from 'vue';
import { useSuggestionCompaniesQuery } from '@/query/useSuggestionQuery.js';
import { useDebounceFn } from '@vueuse/core';
import AppInput from '@/components/AppInput.vue';

const emit = defineEmits(['company-selected']);

const options = ref([]);
const query = ref(null);
const title = ref(null);

const enabled = computed(() => {
  return !!query.value;
});

const {
  data: companies,
  isLoading,
  isFetching,
} = useSuggestionCompaniesQuery({ query }, { enabled, select: (response) => response?.data?.data });

const search = useDebounceFn((searchQuery) => {
  query.value = searchQuery;
}, 500);

const companySelect = (company) => {
  emit('company-selected', company.inn);
  title.value = company.title;
  query.value = false;
};
</script>

<template>
  <div class="flex flex-col gap-[12px]">
    <div>Наименование</div>
    <AppInput
      v-model="title"
      :loading="enabled && (isLoading || isFetching)"
      placeholder="Начните вводить название или ИНН"
      :suggestions="companies"
      @suggestion-query-updated="search"
      @suggestion-selected="companySelect"
    >
      <template #suggestion="{ suggestion: company }">
        <div class="flex flex-col gap-y-[4px]">
          <div v-if="company.title">{{ company.title }}</div>
          <div class="text-gray-primary">ИНН: {{ company.inn }}</div>
          <div class="text-gray-primary">{{ company.city }}</div>
        </div>
      </template>
    </AppInput>
  </div>
</template>
