let yandexMetrikaInstalled = false;
const YM_COUNTER_ID = 91054466;

export const useYandexMetrika = () => {
  const loadScript = (src, onload) => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    script.async = true;
    script.src = src;

    head.appendChild(script);

    script.onload = onload;
  };

  const isEnabled = () => {
    return process.env.NODE_ENV === 'production' && !import.meta.env.VITE_YANDEX_METRIKA_DISABLED;
  };

  const init = () => {
    if (!isEnabled()) {
      return;
    }

    window.ym(YM_COUNTER_ID, 'init', {
      defer: true,
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    });
  };

  const hit = ({ url, referer }) => {
    if (!isEnabled()) {
      return;
    }

    window.ym(YM_COUNTER_ID, 'hit', url, { referer });
  };

  const setEmployeeId = (employeeId) => {
    if (!isEnabled()) {
      return;
    }

    window.ym(YM_COUNTER_ID, 'setEmployeeID', employeeId);
  };

  const setBusinessId = (businessId) => {
    if (!isEnabled()) {
      return;
    }

    window.ym(YM_COUNTER_ID, 'employeeParams', {
      businessId,
    });
  };

  const goal = (id) => {
    if (!isEnabled()) {
      return;
    }

    window.ym(YM_COUNTER_ID, 'reachGoal', id);
  };

  if (!yandexMetrikaInstalled && isEnabled()) {
    yandexMetrikaInstalled = true;

    window.ym =
      window.ym ||
      function () {
        window.ym.a = window.ym.a || [];
        window.ym.a.push(arguments);
      };

    loadScript('https://mc.yandex.ru/metrika/tag.js', () => {
      init();
    });
  }

  return {
    hit,
    goal,
    setEmployeeId,
    setBusinessId,
  };
};
