<template>
  <svg
    class="animate-[spin_0.9s_steps(11,end)_infinite]"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_888_3612)">
      <path
        opacity="0.5"
        d="M24.5001 39.9113C23.1566 39.9113 22.1292 40.9387 22.1292 42.2823V46.629C22.1292 47.9726 23.1566 49 24.5001 49C25.8437 49 26.8711 47.9726 26.8711 46.629V42.2823C26.8711 41.0177 25.8437 39.9113 24.5001 39.9113Z"
        fill="#626AE9"
      />
      <path
        d="M24.5001 3.52859e-05C23.1566 3.52859e-05 22.1292 1.02745 22.1292 2.371V6.71778C22.1292 8.06133 23.1566 9.08875 24.5001 9.08875C25.8437 9.08875 26.8711 8.06133 26.8711 6.71778V2.371C26.8711 1.02745 25.8437 3.52859e-05 24.5001 3.52859e-05Z"
        fill="#626AE9"
      />
      <path
        opacity="0.6"
        d="M15.5681 37.5406C14.7778 37.5406 13.9874 37.9358 13.5132 38.7261L11.3794 42.4406C10.7471 43.5471 11.1423 45.0487 12.2487 45.6809C13.3552 46.3132 14.8568 45.918 15.4891 44.8116L17.6229 41.0971C18.2552 39.9906 17.86 38.489 16.7536 37.8567C16.4374 37.6987 15.9632 37.5406 15.5681 37.5406Z"
        fill="#626AE9"
      />
      <path
        opacity="0.05"
        d="M35.5647 3.00238C34.7743 3.00238 33.984 3.39754 33.5098 4.18787L31.376 7.90239C30.7437 9.00884 31.1389 10.5105 32.2453 11.1427C33.3518 11.775 34.8534 11.3798 35.4856 10.2734L37.6195 6.55884C38.2518 5.45238 37.8566 3.95077 36.7501 3.31851C36.355 3.08141 35.9598 3.00238 35.5647 3.00238Z"
        fill="#626AE9"
      />
      <path
        opacity="0.7"
        d="M9.08843 31.0609C8.69327 31.0609 8.29811 31.14 7.90294 31.3771L4.18843 33.5109C3.08198 34.1432 2.68682 35.6448 3.31908 36.7513C3.95134 37.8577 5.45295 38.2529 6.5594 37.6206L10.2739 35.4867C11.3804 34.8545 11.7755 33.3529 11.1433 32.2464C10.6691 31.4561 9.87875 31.0609 9.08843 31.0609Z"
        fill="#626AE9"
      />
      <path
        opacity="0.1"
        d="M43.7055 11.0644C43.3104 11.0644 42.9152 11.1434 42.5201 11.3805L38.7265 13.5144C37.6201 14.1466 37.2249 15.6482 37.8572 16.7547C38.4894 17.8611 39.991 18.2563 41.0975 17.624L44.812 15.4902C45.9184 14.8579 46.3136 13.3563 45.6813 12.2499C45.2862 11.4595 44.4959 11.0644 43.7055 11.0644Z"
        fill="#626AE9"
      />
      <path
        opacity="0.8"
        d="M6.71774 22.129H2.37097C1.02742 22.129 0 23.1565 0 24.5C0 25.8436 1.02742 26.871 2.37097 26.871H6.71774C8.06129 26.871 9.08871 25.8436 9.08871 24.5C9.08871 23.1565 7.98226 22.129 6.71774 22.129Z"
        fill="#626AE9"
      />
      <path
        opacity="0.2"
        d="M46.6291 22.129H42.2823C40.9388 22.129 39.9114 23.1565 39.9114 24.5C39.9114 25.8436 40.9388 26.871 42.2823 26.871H46.6291C47.9727 26.871 49.0001 25.8436 49.0001 24.5C49.0001 23.1565 47.9727 22.129 46.6291 22.129Z"
        fill="#626AE9"
      />
      <path
        opacity="0.9"
        d="M5.29408 11.0644C4.50376 11.0644 3.71344 11.4595 3.23924 12.2499C2.60699 13.3563 3.00215 14.8579 4.1086 15.4902L7.82311 17.624C8.92957 18.2563 10.4312 17.8611 11.0634 16.7547C11.6957 15.6482 11.3005 14.1466 10.1941 13.5144L6.47957 11.3805C6.16344 11.1434 5.76828 11.0644 5.29408 11.0644Z"
        fill="#626AE9"
      />
      <path
        opacity="0.3"
        d="M39.912 31.0583C39.1217 31.0583 38.3314 31.4534 37.8572 32.2437C37.2249 33.3502 37.6201 34.8518 38.7265 35.4841L42.441 37.6179C43.5475 38.2502 45.0491 37.855 45.6813 36.7486C46.3136 35.6421 45.9184 34.1405 44.812 33.5083L41.0975 31.3744C40.7023 31.1373 40.3072 31.0583 39.912 31.0583Z"
        fill="#626AE9"
      />
      <path
        d="M13.4342 3.00238C13.0391 3.00238 12.6439 3.08141 12.2487 3.31851C11.1423 3.95077 10.7471 5.45238 11.3794 6.55884L13.5132 10.2734C14.1455 11.3798 15.6471 11.775 16.7536 11.1427C17.86 10.5105 18.2552 9.00884 17.6229 7.90239L15.4891 4.18787C15.0149 3.39754 14.2245 3.00238 13.4342 3.00238Z"
        fill="#626AE9"
      />
      <path
        opacity="0.4"
        d="M33.4306 37.5404C33.0354 37.5404 32.6402 37.6194 32.2451 37.8565C31.1386 38.4887 30.7435 39.9904 31.3757 41.0968L33.5096 44.8113C34.1418 45.9178 35.6435 46.3129 36.7499 45.6807C37.8563 45.0484 38.2515 43.5468 37.6193 42.4404L35.4854 38.7258C35.0112 38.0145 34.2209 37.5404 33.4306 37.5404Z"
        fill="#626AE9"
      />
    </g>
    <defs>
      <clipPath id="clip0_888_3612">
        <rect
          width="49"
          height="49"
          fill="white"
          transform="matrix(1 0 0 -1 0 49)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
