import http from '@/libs/http.js';

export const getStocks = () => http.get('/api/v2/stock');
export const getStock = (stockId) => http.get('/api/v2/stock/' + stockId);

export const stockStore = ({ title, rackCount, cellCount }) =>
  http.post('/api/v2/stock', {
    title,
    rackCount,
    cellCount,
  });

export const stockUpdate = (stockId, { title, rackCount, cellCount }) =>
  http.put('/api/v2/stock', {
    stockId,
    title,
    rackCount,
    cellCount,
  });

export const stockRemove = (stockId) => http.delete('/api/v2/stock', { params: { stockId } });
