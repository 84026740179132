<script setup>
import { computed, ref } from 'vue';
import { useServiceQuery, useServicesQuery } from '@/query/useServiceQuery.js';
import { useQueryClient } from '@tanstack/vue-query';
import { orderWorkAdd } from '@/api/order.js';
import { useToast } from '@/use/useToast.js';
import AppModal from '@/components/AppModal.vue';
import ServiceType from '@/enums/ServiceType.js';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import ServicePriceType from '@/enums/ServicePriceType.js';
import { formatMoney } from '@/use/useFormat.js';
import AppIcon from '@/components/AppIcon.vue';
import IconInfoCircle from '@/components/Icons/IconInfoCircle.vue';
import useVuelidate from '@vuelidate/core';

const props = defineProps({
  serviceId: {
    type: Number,
    required: true,
  },
  orderId: {
    type: Number,
    required: true,
  },
  categoryId: {
    type: Number,
    required: true,
  },
  carClass: {
    type: [String, null],
    required: true,
  },
  wheelDiameter: {
    type: [Number, null],
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close', 'saved']);

const include = ['category', 'prices'];
const { data: service, isLoading } = useServiceQuery(
  { serviceId: props.serviceId, include },
  { select: (response) => response?.data?.data }
);

const queryClient = useQueryClient();
const loading = ref(false);

const select = async (serviceId, count) => {
  loading.value = true;

  try {
    await orderWorkAdd({
      orderId: props.orderId,
      serviceId,
      count,
      carClass: props.carClass,
      wheelDiameter: props.wheelDiameter,
    });
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'order'),
    });

    loading.value = false;
  }
};

const close = () => {
  emit('close');
};

const modalTitle = computed(() => {
  let title = null;

  switch (service.value.type) {
    case ServiceType.BASE:
      title = service.value.title;
      break;

    case ServiceType.FREE:
      title = 'Свободная услуга';
      break;
  }

  return title;
});

const count = ref(props.count);
const price = ref(null);
const title = ref(null);

const vuelidate = await useVuelidate();

const workStore = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    await orderWorkAdd({
      orderId: props.orderId,
      serviceId: props.serviceId,
      count: count.value,
      title: title.value,
      price: price.value * 100,
    });
    emit('saved');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'order'),
    });

    loading.value = false;
  }
};
</script>

<template>
  <AppModal
    :show="true"
    :loading="isLoading"
    @close="close()"
  >
    <template #title>{{ modalTitle }}</template>
    <div class="flex flex-col gap-y-[24px]">
      <div
        v-if="service.type === ServiceType.FREE"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Название услуги</div>
        <div>
          <AppInput
            v-model="title"
            data-test="service-title"
            first-letter-upper-case
            placeholder="Введите название"
            required
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-[12px]">
        <div>Количество</div>
        <div class="flex gap-x-[6px]">
          <AppButton
            v-for="index in [1, 2, 3, 4]"
            :key="index"
            class="w-[46px]"
            :outline="index != count"
            :black="index != count"
            @click="count = index"
            >{{ index }}</AppButton
          >
          <AppInput
            v-model="count"
            data-test="service-count"
            mask="number"
            required
            placeholder="Укажите количество"
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-[12px]">
        <div>Стоимость</div>
        <div>
          <AppInput
            v-model="price"
            data-test="service-price"
            mask="number"
            required
            placeholder="Укажите стоимость устуги"
          />
        </div>
        <div
          v-if="service.priceType === ServicePriceType.RANGE"
          class="flex items-center gap-[8px] text-gray-primary"
        >
          <AppIcon :icon="IconInfoCircle" />
          <div class="text-xs">
            Цена должна быть в диапазоне от <b>{{ formatMoney(service.priceRangeStart) }}</b> до
            <b>{{ formatMoney(service.priceRangeEnd) }}</b>
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <div class="flex justify-center">
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="workStore()"
          >Добавить услугу к заказу</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
