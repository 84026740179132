<script setup>
import { DateTime } from 'luxon';
import { useBookingQuery } from '@/query/useBookingQuery.js';
import AppIcon from '@/components/AppIcon.vue';
import IconCalendar from '@/components/Icons/IconCalendar.vue';
import { dateToHumanTitle } from '@/use/useFormat.js';

const startTime = DateTime.now().toISO();
const limit = 3;
const { data: bookings } = useBookingQuery({ startTime, limit });
</script>

<template>
  <div v-if="bookings?.meta?.total > 0">
    <div class="flex flex-col">
      <div class="flex gap-x-[8px] items-center">
        <AppIcon
          :icon="IconCalendar"
          :width="22"
          :height="22"
          class="text-violet-primary"
        />
        <div class="text-lg font-medium">
          Ближайшие записи
          <span class="text-gray-primary">({{ bookings.meta.total }})</span>
        </div>
      </div>

      <div
        v-for="booking in bookings?.data"
        :key="booking.bookingId"
        class="mt-[24px] pb-[14px] flex flex-col gap-y-[14px] border-b border-[#CBD3E4]"
      >
        <div class="flex">
          <div class="rounded-[8px] bg-[#EDECEC] py-[5px] px-[8px]">
            {{ dateToHumanTitle(booking.startTime) }} в
            {{ DateTime.fromISO(booking.startTime).toFormat('HH:mm') }}
          </div>
        </div>

        <div class="flex gap-x-[14px] text-sm font-medium">
          <div>{{ booking.workplace.title }}</div>
          <div
            v-if="booking.client"
            class="text-violet-primary"
          >
            <router-link
              :to="{
                name: 'client.show',
                params: { clientId: booking.client.clientId },
              }"
              class="text-indigo-500 underline-offset-2 hover:underline"
            >
              {{ booking.client.title }}
            </router-link>
          </div>
          <div
            v-else
            class="italic"
          >
            Анонимный клиент
          </div>
        </div>

        <div class="text-sm font-medium">{{ booking.note }}</div>
      </div>
    </div>
  </div>
</template>
