<script setup>
import { useStorageQuery } from '@/query/useStorageQuery.js';
import { computed } from 'vue';
import { useStorage } from '@/use/useStorage.js';
import AppIcon from '@/components/AppIcon.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import { removeStorageItem } from '@/api/storage.js';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});

const { data: storage } = useStorageQuery({ orderId: props.orderId });

const storageItems = computed(() => {
  return storage.value?.data?.items ?? [];
});

const queryClient = useQueryClient();

const removeItem = async (itemId) => {
  try {
    await removeStorageItem(itemId);
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['order', 'storage'].indexOf(key) !== -1),
    });
  }
};
</script>

<template>
  <div
    v-if="storageItems.length > 0"
    class="flex flex-col gap-y-[32px]"
  >
    <div>Хранение</div>

    <div class="grid grid-cols-[minmax(1fr, 3fr)_50px] gap-y-[12px]">
      <div
        v-for="item in storageItems"
        :key="item.id"
        class="flex justify-between"
      >
        <div class="flex gap-x-[12px] items-center">
          <span class="text-lg font-medium">{{ useStorage().getItemTypeTitle(item) }} {{ item.count }} шт.</span>
          <span class="text-gray-primary">{{ useStorage().getItemParametersTitle(item) }} </span>
          <span>({{ useStorage().getItemMarkTitle(item) }})</span>
        </div>

        <div>
          <AppIcon
            :icon="IconTrash"
            class="text-gray-primary hover:text-violet-primary cursor-pointer"
            :width="15"
            :height="15"
            @click="removeItem(item.itemId)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
