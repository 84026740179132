<template>
  <svg
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.26282 9.11151C8.65577 9.24249 9.08051 9.03012 9.2115 8.63716C9.34249 8.24421 9.13012 7.81947 8.73716 7.68848L8.26282 9.11151ZM6.39999 7.69999H5.64999C5.64999 8.02282 5.85656 8.30942 6.16282 8.41151L6.39999 7.69999ZM7.14999 4.77298C7.14999 4.35877 6.8142 4.02298 6.39999 4.02298C5.98577 4.02298 5.64999 4.35877 5.64999 4.77298H7.14999ZM8.73716 7.68848L6.63716 6.98848L6.16282 8.41151L8.26282 9.11151L8.73716 7.68848ZM7.14999 7.69999V4.77298H5.64999V7.69999H7.14999ZM11.25 6.99999C11.25 9.67857 9.07857 11.85 6.39999 11.85V13.35C9.90699 13.35 12.75 10.507 12.75 6.99999H11.25ZM6.39999 11.85C3.72141 11.85 1.54999 9.67857 1.54999 6.99999H0.0499878C0.0499878 10.507 2.89298 13.35 6.39999 13.35V11.85ZM1.54999 6.99999C1.54999 4.32141 3.72141 2.14999 6.39999 2.14999V0.649994C2.89298 0.649994 0.0499878 3.49299 0.0499878 6.99999H1.54999ZM6.39999 2.14999C9.07857 2.14999 11.25 4.32141 11.25 6.99999H12.75C12.75 3.49299 9.90699 0.649994 6.39999 0.649994V2.14999Z"
      fill="currentColor"
    />
  </svg>
</template>
