import http from '@/libs/http.js';

export const getRoles = () => {
  return http.get('/api/v2/roles', {});
};

export const getPermissions = () => {
  return http.get('/api/v2/roles/permissions', {});
};

export const roleStore = ({ title, permissions }) => {
  return http.post('/api/v2/roles', {
    title,
    permissions,
  });
};

export const roleUpdate = (roleId, { title, permissions }) => {
  return http.put(`/api/v2/roles/${roleId}`, {
    title,
    permissions,
  });
};

export const roleDelete = (roleId) => {
  return http.delete('/api/v2/roles/' + roleId);
};

export const roleUsersUpdate = ({ roleId, userId }) => {
  return http.post('/api/v2/roles/users/', {
    roleId,
    userId,
  });
};

export const roleUsersDelete = (roleId, userId) => {
  return http.delete(`/api/v2/roles/${roleId}/users/${userId}`);
};
