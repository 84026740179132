<script setup>
import { useStoragesQuery } from '@/query/useStorageQuery.js';
import { computed, ref, toRef } from 'vue';
import AppTable from '@/components/AppTable.vue';
import { useStorage } from '@/use/useStorage.js';
import StorageStatus from '@/enums/StorageStatus.js';
import AppInput from '@/components/AppInput.vue';
import { plural, formatPhone } from '@/use/useFormat.js';
import AppPagination from '@/components/AppPagination.vue';
import { useRouter } from 'vue-router';
import { watchDebounced } from '@vueuse/core';
import { tabs } from '@/modules/storage/storage-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const include = ['order.car.mark', 'order.car.model', 'order.client', 'items'];
const currentPage = ref(1);
const perPage = 30;
const options = { keepPreviousData: true };
const search = ref(null);
const searchDebounced = ref(null);

watchDebounced(
  search,
  () => {
    searchDebounced.value = search.value;
  },
  { debounce: 500, maxWait: 10000 }
);

const { data, isLoading, isFetching } = useStoragesQuery(
  { status: toRef(props, 'status'), search: searchDebounced, perPage, page: currentPage, include },
  options
);

const storages = computed(() => {
  return data?.value?.data;
});

const total = computed(() => {
  const totalCount = data?.value?.meta?.total;
  return typeof totalCount === 'undefined' ? 0 : totalCount;
});

const columns = [
  {
    label: '№',
    field: 'numberId',
    width: '100px',
  },
  {
    label: 'Статус',
    template: 'status',
    width: '2fr',
  },
  {
    label: 'Наименование',
    template: 'title',
    width: '3fr',
  },
  {
    label: 'Автомобиль',
    template: 'car',
    width: '2fr',
  },
  {
    label: 'Клиент',
    width: '1fr',
    template: 'client',
  },
];

const { getItemTitle, getStatusTitle, getStatusChangedAt } = useStorage();
const router = useRouter();

const statusTitle = computed(() => {
  let title = null;

  switch (props.status) {
    case StorageStatus.IN_STOCK:
      title = 'на складе';
      break;

    case StorageStatus.RELEASED:
      title = 'выдано';
      break;
  }

  return title;
});
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading || isFetching"
    :hide-in-loading="isLoading === true"
  >
    <div class="flex flex-col gap-[22px] grow">
      <div class="flex flex-wrap gap-[18px] justify-between">
        <div class="flex flex-wrap gap-[18px] items-center text-lg">
          <div v-if="!(isLoading || isFetching) && !search">
            {{ total }} {{ plural(total, ['позиция', 'позиции', 'позиций']) }} {{ statusTitle }}
          </div>
        </div>
        <AppInput
          v-model="search"
          class="md:w-[450px]"
          placeholder="Поиск по номер хранения, гос. номеру, ФИО и телефону"
        />
      </div>

      <AppTable
        :data="storages"
        :columns="columns"
        row-class="cursor-pointer"
        @row-clicked="(storage) => router.push({ name: 'storage.show', params: { storageId: storage.storageId } })"
      >
        <template #row-status="{ raw: storage }">
          <div class="flex gap-x-[12px] items-center">
            <span>{{ getStatusTitle(storage) }}</span>
            <span class="text-gray-primary">{{ getStatusChangedAt(storage) }}</span>
          </div></template
        >

        <template #row-title="{ raw: storage }">
          <div class="flex flex-col gap-y-[5px]">
            <div
              v-for="storageItem in storage.items"
              :key="storageItem.id"
            >
              {{ getItemTitle(storageItem) }}
            </div>
          </div>
        </template>

        <template #row-car="{ raw: storage }">
          <div class="flex flex-col gap-y-[2px]">
            <div>{{ storage.order?.car?.title }}</div>
            <div class="text-gray-primary text-sm">{{ storage.order?.car?.regNumber }}</div>
          </div>
        </template>

        <template #row-client="{ raw: storage }">
          <div class="flex flex-col gap-y-[2px]">
            <div>
              <router-link
                v-if="storage.order?.client"
                class="text-violet-primary hover:underline underline-offset-4"
                :to="{ name: 'client.show', params: { clientId: storage.order.client.clientId } }"
                >{{ storage.order.client.title }}</router-link
              >
            </div>
            <div class="text-gray-primary text-sm">{{ formatPhone(storage.order?.client?.phone) }}</div>
          </div>
        </template>
      </AppTable>

      <div class="flex justify-end">
        <AppPagination
          v-model="currentPage"
          :total="total"
          :per-page="perPage"
        />
      </div>
    </div>
  </CabinetLayout>
</template>
