<script setup>
import AuthLayout from '@/layouts/AuthLayout.vue';
import AppInput from '@/components/AppInput.vue';
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import useVuelidate from '@vuelidate/core';
import { useQueryClient } from '@tanstack/vue-query';
import { storeBusiness } from '@/api/business.js';
import BusinessSphere from '@/enums/BusinessSphere.js';
import { useYandexMetrika } from '@/use/useYandexMetrika.js';
import { useAuthStore } from '@/store/AuthStore.js';
import { useRouter } from 'vue-router';
import { useToast } from '@/use/useToast.js';
import AppIcon from '@/components/AppIcon.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import IconPreloader from '@/components/Icons/IconPreloader.vue';

const yandexMetrika = useYandexMetrika();
yandexMetrika.goal('registration_accept_phone');

const authStore = useAuthStore();
const router = useRouter();
const preparing = ref(false);
const vuelidate = await useVuelidate();
const queryClient = useQueryClient();

const submit = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  preparing.value = true;
  setTimeout(() => {
    preparing.value = false;
  }, 2000);

  try {
    const business = await storeBusiness({
      sphereId: BusinessSphere.TIRE_FITTING,
      title: title.value,
    });

    yandexMetrika.goal('registration_create_business');

    await authStore.businessSwitch(business.data?.data?.businessId);
    await authStore.refetchAuth();
    await queryClient.resetQueries();

    switch (true) {
      case authStore.employee.firstName === null:
        await router.push({ name: 'auth.set-name' });
        break;

      case authStore.employee.telegramId === null:
        await router.push({ name: 'auth.connect-telegram' });
        break;

      default:
        await router.push({ name: 'home.index' });
    }
  } catch (error) {
    if (error.response?.data?.message) {
      useToast().error(error.response.data.message);
    }

    loading.value = false;
  }
};

const title = ref('Мой шиномонтаж');
const loading = ref(false);
</script>

<template>
  <AuthLayout class="flex flex-col items-center">
    <div class="mt-[68px]">
      <AppIcon
        :icon="IconLogo"
        :width="69"
        :height="69"
        class="text-[#EC4899]"
      />
    </div>
    <div class="flex flex-col gap-y-[24px] px-[16px] md:px-0 w-full md:w-[410px] mt-[124px] lg:mt-[146px]">
      <div class="text-xl lg:text-2xl font-medium text-center">
        <span v-show="!loading">Новый филиал</span>
        <span v-show="loading">Почти все готово!</span>
      </div>
      <template v-if="!loading">
        <div>
          <AppInput
            v-model="title"
            data-test="title"
            class="w-full"
            required
          />
        </div>
        <div class="text-xs text-gray-primary text-center">Название сможете изменить в любой момент</div>

        <AppButton
          class="mt-[32px]"
          @click="submit()"
          >Создать</AppButton
        >
      </template>

      <template v-if="loading">
        <div class="flex flex-col items-center mt-[12px]">
          <AppIcon
            :icon="IconPreloader"
            :width="44"
            :height="44"
          />

          <div class="mt-[32px] text-gray-primary text-xs">Настраиваем Mors под ваш бизнес</div>
        </div>
      </template>
    </div>
  </AuthLayout>
</template>
