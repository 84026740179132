<script setup>
import AppButton from '@/components/AppButton.vue';
import { orderWheelDiameterUpdate } from '@/api/order.js';
import { ref, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  wheelDiameter: {
    type: [Number, null],
    required: true,
  },
});

const emit = defineEmits(['wheel-diameter-changed']);

const selectedWheelDiameter = ref(null);

watch(
  () => props.wheelDiameter,
  () => {
    selectedWheelDiameter.value = props.wheelDiameter;
  },
  {
    immediate: true,
  }
);

const queryClient = useQueryClient();

const select = async (value) => {
  selectedWheelDiameter.value = value;
  emit('wheel-diameter-changed', selectedWheelDiameter.value);

  await orderWheelDiameterUpdate(props.orderId, value);

  await queryClient.invalidateQueries({
    predicate: (query) => query.queryKey.some((key) => ['order'].indexOf(key) !== -1),
  });
};

const isValueSelected = (value) => {
  return selectedWheelDiameter.value === value;
};

const diameters = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
</script>

<template>
  <div class="flex flex-wrap gap-[12px]">
    <AppButton
      v-for="diameter in diameters"
      :key="diameter"
      :black="!isValueSelected(diameter)"
      :outline="!isValueSelected(diameter)"
      @click="select(diameter)"
      >R{{ diameter }}</AppButton
    >
  </div>
</template>
