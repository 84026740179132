import http from '@/libs/http.js';

export const getWorkPlan = (year) => {
  return http.get('/api/v2/work-plan', {
    params: { year },
  });
};

export const getWorkPlanStartYear = () => {
  return http.get('/api/v2/work-plan/start-year');
};

export const updateWorkPlan = (workPlan) => {
  return http.put('/api/v2/work-plan', {
    workPlan,
  });
};
