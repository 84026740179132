<template>
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79999 15.3843C1.79999 12.5527 4.1657 10.2571 8.99999 10.2571C13.8343 10.2571 16.2 12.5527 16.2 15.3843C16.2 15.8348 15.8713 16.2 15.4659 16.2H2.53411C2.12866 16.2 1.79999 15.8348 1.79999 15.3843Z"
      stroke="currentColor"
    />
    <path
      d="M11.7 4.5C11.7 5.99117 10.4912 7.2 8.99999 7.2C7.50882 7.2 6.29999 5.99117 6.29999 4.5C6.29999 3.00883 7.50882 1.8 8.99999 1.8C10.4912 1.8 11.7 3.00883 11.7 4.5Z"
      stroke="currentColor"
    />
  </svg>
</template>
