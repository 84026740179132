import http from '@/libs/http.js';

export const getStorages = ({ status, clientId, search, perPage, page, include } = {}) =>
  http.get('/api/v2/storage', {
    params: {
      status,
      clientId,
      search,
      perPage,
      page,
      include,
    },
  });

export const storeStorageItem = ({
  orderId,
  serviceId,
  storageType,
  storageCount,
  carClass,
  wheelDiameter,
  tireWidth,
  tireHeight,
  tireSeasonality,
  tireDepth,
  tireBrand,
  tireModel,
  tireDot,
  diskModel,
}) =>
  http.post('/api/v2/storage/item', {
    orderId,
    serviceId,
    storageType,
    storageCount,
    carClass,
    wheelDiameter,
    tireWidth,
    tireHeight,
    tireSeasonality,
    tireDepth,
    tireBrand,
    tireModel,
    tireDot,
    diskModel,
  });

export const removeStorageItem = (storageItemId) =>
  http.delete('/api/v2/storage/item', {
    params: {
      storageItemId,
    },
  });

export const getDocuments = (storageId) => http.get('/api/v1/storage/' + storageId + '/documents');

export const getStorage = ({ storageId, include }) =>
  http.get('/api/v2/storage/' + storageId, {
    params: {
      include,
    },
  });

export const getStorageByOrder = ({ orderId, include }) =>
  http.get('/api/v2/storage/order/' + orderId, {
    params: {
      include,
    },
  });

export const storageRelease = (storageId) => http.post('/api/v2/storage/release', { storageId });

export const getStorageQrCode = (storageId) => http.get('/api/v2/storage/' + storageId + '/qr-code');
export const storageStockUpdate = (storageId, { stockId, rackId, cellId }) =>
  http.put('/api/v2/storage/stock', { storageId, stockId, rackId, cellId });
