import http from '@/libs/http.js';

export const storeBusiness = ({ sphereId, title, city, address }) => {
  return http.post('/api/v2/business', {
    sphereId,
    title,
    city,
    address,
  });
};

export const assignEmployee = ({ phone, roles }) => {
  return http.post('/api/v2/business/assign-employee', {
    phone,
    roles,
  });
};

export const updateBusiness = ({
  title,
  city,
  address,
  latitude,
  longitude,
  openTime,
  closeTime,
  timezone,
  phone,
} = {}) => {
  return http.put('/api/v2/business', {
    title,
    city,
    address,
    latitude,
    longitude,
    openTime,
    closeTime,
    timezone,
    phone,
  });
};

export const uploadBusinessPhoto = (photo) => {
  const formData = new FormData();
  formData.append('photo', photo);

  return http.post('/api/v2/business/photo', formData);
};
