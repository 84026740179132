import http from '@/libs/http.js';

export const getBookingSettings = () => {
  return http.get('/api/v2/booking/settings');
};

export const updateBookingSettings = ({
  allowBooking,
  domain,
  slotInterval,
  allowChooseWorkplace,
  allowMultipleSlots,
  yandexMetrikaId,
  telegramChatId,
  slug,
}) => {
  return http.put('/api/v2/booking/settings', {
    allowBooking: allowBooking === undefined ? undefined : !!allowBooking,
    domain,
    slotInterval,
    allowChooseWorkplace: allowChooseWorkplace === undefined ? undefined : !!allowChooseWorkplace,
    allowMultipleSlots: allowMultipleSlots === undefined ? undefined : !!allowMultipleSlots,
    yandexMetrikaId,
    telegramChatId,
    slug,
  });
};
