<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import { stockRemove } from '@/api/stock.js';

const props = defineProps({
  stockId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed', 'removed']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const remove = async () => {
  loading.value = true;

  try {
    await stockRemove(props.stockId);
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['storage'].indexOf(key) !== -1),
    });
    emit('removed');
    useToast().show('Склад удален');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <AppModal
    :show="isShow"
    @close="close()"
  >
    <template #title>Удаление склада</template>

    Вы уверены, что хотите удалить склад?

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          outline
          red
          :disabled="loading"
          :loading="loading"
          @click="remove()"
          >Удалить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
