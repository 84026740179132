<script setup>
import AppButton from '@/components/AppButton.vue';
import { ref, watch } from 'vue';
import { orderCarClassUpdate } from '@/api/order.js';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  carClass: {
    type: [String, null],
    required: true,
  },
});

const emit = defineEmits(['car-class-changed']);

const selectedCarClass = ref(null);

watch(
  () => props.carClass,
  () => {
    selectedCarClass.value = props.carClass;
  },
  {
    immediate: true,
  }
);

const isValueSelected = (value) => {
  return selectedCarClass.value === value;
};

const queryClient = useQueryClient();

const select = async (value) => {
  selectedCarClass.value = value;
  emit('car-class-changed', selectedCarClass);

  await orderCarClassUpdate(props.orderId, value);

  await queryClient.invalidateQueries({
    predicate: (query) => query.queryKey.some((key) => ['order'].indexOf(key) !== -1),
  });
};

const carClasses = [
  {
    value: 'passenger',
    label: 'Легковой',
  },
  {
    value: 'suv',
    label: 'Suv',
  },
  {
    value: 'jeep',
    label: 'Внедорожник',
  },
  {
    value: 'commercial',
    label: 'Коммерческий',
  },
];
</script>

<template>
  <div class="flex flex-wrap gap-[12px]">
    <AppButton
      v-for="type in carClasses"
      :key="type.value"
      class="w-[170px]"
      :black="!isValueSelected(type.value)"
      :outline="!isValueSelected(type.value)"
      @click="select(type.value)"
      >{{ type.label }}</AppButton
    >
  </div>
</template>

