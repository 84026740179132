import { DateTime } from 'luxon';
import { computed, ref, unref, watch } from 'vue';

export const useBookingTable = (initSelectedIntervals, bookedIntervals, openTime, closeTime, workplaces) => {
  const FRAME_INTERVAL_MINUTES = 15;
  const DEFAULT_FRAME_SELECT_COUNT = 4;
  const frameHoveredData = ref(false);
  const selectedIntervals = ref(initSelectedIntervals.value);

  watch(
    initSelectedIntervals,
    () => {
      selectedIntervals.value = initSelectedIntervals.value;
    },
    {
      deep: true,
    }
  );

  const workplaceTimeFrames = computed(() => {
    const openTimeObject = DateTime.fromISO(unref(openTime));
    const closeTimeObject = DateTime.fromISO(unref(closeTime));

    const frames = [];
    for (
      let cursorTime = openTimeObject;
      cursorTime < closeTimeObject;
      cursorTime = cursorTime.plus({ minutes: FRAME_INTERVAL_MINUTES })
    ) {
      frames.push(cursorTime.toISO());
    }

    return frames;
  });

  const frameHover = (workplaceId, timestamp) => {
    if (isSelected(workplaceId, timestamp)) {
      return false;
    }

    const workplaceIndex = getWorkplaceIndex(workplaceId);
    const frameIndex = getFrameIndex(timestamp);

    const allowFramesSelectCount = calculateAllowFramesSelectCount(workplaceId, timestamp);

    const duration = allowFramesSelectCount * FRAME_INTERVAL_MINUTES;

    const timeStart = DateTime.fromISO(timestamp).toFormat('HH:mm');
    const timeEnd = DateTime.fromISO(timestamp).plus({ minutes: duration }).toFormat('HH:mm');

    frameHoveredData.value = {
      workplaceId,
      timestamp,
      workplaceIndex,
      frameIndex,
      allowFramesSelectCount,
      duration,
      timeStart,
      timeEnd,
    };
  };

  const frameLeave = () => {
    frameHoveredData.value = false;
  };

  const selectInterval = (workplaceId, timestamp, duration) => {
    // если сразу до выбранного интервала есть выбранные фреймы - то меняем timestamp интвервала
    const previousFrameTimestamp = getPreviousFrameTimestamp(timestamp);
    const isPreviousFrameSelected = isSelected(workplaceId, previousFrameTimestamp);

    frameLeave();

    if (isPreviousFrameSelected) {
      selectedIntervals.value = selectedIntervals.value.map((interval) => {
        if (interval.workplaceId === workplaceId && includedInInterval(interval, previousFrameTimestamp)) {
          return {
            ...interval,
            duration: interval.duration + duration,
          };
        }

        return interval;
      });
    }

    // если сразу за выбранным интервалом есть выбранные фреймы - то меняем duration интвервала
    const nextFrameTimestamp = increaseTimestamp(timestamp, duration);
    const isNextFrameSelected = isSelected(workplaceId, nextFrameTimestamp);

    if (!isPreviousFrameSelected && isNextFrameSelected) {
      selectedIntervals.value = selectedIntervals.value.map((interval) => {
        if (interval.workplaceId === workplaceId && includedInInterval(interval, nextFrameTimestamp)) {
          return {
            ...interval,
            timestamp: timestamp,
            duration: interval.duration + duration,
          };
        }

        return interval;
      });
    }

    // если ничего вышеперечисленного - просто добавляем интервал
    if (!isPreviousFrameSelected && !isNextFrameSelected) {
      selectedIntervals.value.push({
        workplaceId,
        timestamp,
        duration,
      });

      return true;
    }
  };

  const unselectInterval = (unselectedInterval) => {
    frameLeave();
    selectedIntervals.value = selectedIntervals.value.filter((interval) => {
      return (
        interval.workplaceId !== unselectedInterval.workplaceId || interval.timestamp !== unselectedInterval.timestamp
      );
    });
  };

  const increaseIntervalDuration = (workplaceId, timestamp) => {
    selectedIntervals.value = selectedIntervals.value.map((interval) => {
      if (interval.workplaceId === workplaceId && interval.timestamp === timestamp) {
        const nextFrameTimestamp = increaseTimestamp(interval.timestamp, interval.duration);

        if (nextFrameTimestamp >= unref(closeTime)) {
          return interval;
        }

        const isNextFrameSelected = isSelected(workplaceId, nextFrameTimestamp);
        const isNextFrameBooked = isBooked(workplaceId, nextFrameTimestamp);

        if (isNextFrameSelected || isNextFrameBooked) {
          return interval;
        }

        return {
          ...interval,
          duration: interval.duration + FRAME_INTERVAL_MINUTES,
        };
      }

      return interval;
    });
  };

  const decreaseIntervalDuration = (workplaceId, timestamp) => {
    selectedIntervals.value = selectedIntervals.value.map((interval) => {
      if (interval.workplaceId === workplaceId && interval.timestamp === timestamp) {
        const timestampStartObject = DateTime.fromISO(timestamp);
        const timestampEndObject = timestampStartObject.plus({ minutes: interval.duration - FRAME_INTERVAL_MINUTES });

        if (timestampEndObject.diff(timestampStartObject, 'minutes') < FRAME_INTERVAL_MINUTES) {
          return interval;
        }

        return {
          ...interval,
          duration: interval.duration - FRAME_INTERVAL_MINUTES,
        };
      }

      return interval;
    });
  };

  const increaseIntervalTimestamp = (workplaceId, timestamp) => {
    selectedIntervals.value = selectedIntervals.value.map((interval) => {
      if (interval.workplaceId === workplaceId && interval.timestamp === timestamp) {
        const timestampStartObject = DateTime.fromISO(timestamp).plus({ minutes: FRAME_INTERVAL_MINUTES });
        const timestampEndObject = timestampStartObject.plus({
          minutes: interval.duration - FRAME_INTERVAL_MINUTES,
        });

        if (timestampEndObject.diff(timestampStartObject, 'minutes') < FRAME_INTERVAL_MINUTES) {
          return interval;
        }

        return {
          ...interval,
          timestamp: timestampStartObject.toISO(),
          duration: interval.duration - FRAME_INTERVAL_MINUTES,
        };
      }

      return interval;
    });
  };

  const decreaseIntervalTimestamp = (workplaceId, timestamp) => {
    if (getPreviousFrameTimestamp(timestamp) < unref(openTime)) {
      return;
    }

    selectedIntervals.value = selectedIntervals.value.map((interval) => {
      if (interval.workplaceId === workplaceId && interval.timestamp === timestamp) {
        const previousFrameTimestamp = getPreviousFrameTimestamp(timestamp);
        const isPreviousFrameSelected = isSelected(workplaceId, previousFrameTimestamp);
        const isPreviousFrameBooked = isBooked(workplaceId, previousFrameTimestamp);

        if (isPreviousFrameSelected || isPreviousFrameBooked) {
          return interval;
        }

        return {
          ...interval,
          timestamp: previousFrameTimestamp,
          duration: interval.duration + FRAME_INTERVAL_MINUTES,
        };
      }

      return interval;
    });
  };

  const getIntervalCol = (interval) => {
    const workplaceIndex = getWorkplaceIndex(interval.workplaceId);

    return workplaceIndex + 2;
  };

  const getIntervalRow = (interval) => {
    return getFrameIndex(interval.timestamp) + 2;
  };

  const getIntervalSpan = (interval) => {
    return interval.duration / FRAME_INTERVAL_MINUTES;
  };

  const getIntervalStartTime = (interval) => {
    return DateTime.fromISO(interval.timestamp).toFormat('HH:mm');
  };

  const getIntervalEndTime = (interval) => {
    return DateTime.fromISO(interval.timestamp).plus({ minutes: interval.duration }).toFormat('HH:mm');
  };

  const getWorkplaceIndex = (workplaceId) => {
    return workplaces?.findIndex((workplace) => {
      return workplace.workplaceId === workplaceId;
    });
  };

  const getFrameIndex = (timestamp) => {
    return workplaceTimeFrames.value.findIndex((frame) => {
      return frame === timestamp;
    });
  };

  const isSelected = (workplaceId, timestamp) => {
    return !!selectedIntervals.value.find((interval) => {
      return interval.workplaceId === workplaceId && includedInInterval(interval, timestamp);
    });
  };

  const isBooked = (workplaceId, timestamp) => {
    return !!bookedIntervals?.value?.find((interval) => {
      return interval.workplaceId === workplaceId && includedInInterval(interval, timestamp);
    });
  };

  const includedInInterval = (interval, timestamp) => {
    const selectionTimestampObject = DateTime.fromISO(timestamp);

    const intervalTimestampStartObject = DateTime.fromISO(interval.timestamp);
    const intervalTimestampEndObject = DateTime.fromISO(interval.timestamp).plus({ minutes: interval.duration });

    return (
      selectionTimestampObject >= intervalTimestampStartObject && selectionTimestampObject < intervalTimestampEndObject
    );
  };

  const calculateAllowFramesSelectCount = (workplaceId, timestamp, allowFramesSelectCount = 1) => {
    if (allowFramesSelectCount === DEFAULT_FRAME_SELECT_COUNT) {
      return allowFramesSelectCount;
    }

    const nextFrameTimestamp = getNextFrameTimestamp(timestamp);
    const isNextFrameSelected = isSelected(workplaceId, nextFrameTimestamp);
    const isNextFrameBooked = isBooked(workplaceId, nextFrameTimestamp);

    if (isNextFrameSelected || isNextFrameBooked) {
      return allowFramesSelectCount;
    }

    return calculateAllowFramesSelectCount(workplaceId, nextFrameTimestamp, allowFramesSelectCount + 1);
  };

  const getNextFrameTimestamp = (timestamp, duration = FRAME_INTERVAL_MINUTES) => {
    return DateTime.fromISO(timestamp).plus({ minutes: duration }).toISO();
  };

  const getPreviousFrameTimestamp = (timestamp) => {
    return DateTime.fromISO(timestamp).minus({ minutes: FRAME_INTERVAL_MINUTES }).toISO();
  };

  const increaseTimestamp = (timestamp, minutes) => {
    return DateTime.fromISO(timestamp).plus({ minutes }).toISO();
  };

  return {
    workplaceTimeFrames,
    frameHover,
    frameLeave,
    frameHoveredData,
    selectedIntervals,
    isSelected,
    getIntervalCol,
    getIntervalRow,
    getIntervalSpan,
    getIntervalStartTime,
    getIntervalEndTime,
    selectInterval,
    unselectInterval,
    increaseIntervalDuration,
    decreaseIntervalDuration,
    increaseIntervalTimestamp,
    decreaseIntervalTimestamp,
  };
};
