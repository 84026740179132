import http from '@/libs/http.js';

export const getDocumentTemplate = (name) => http.get('/api/v2/document/template/' + name);

export const updateDocumentTemplate = ({ name, text, pageFormat, pageMargins }) =>
  http.put('api/v1/document-template', {
    name,
    text,
    pageFormat,
    pageMargins,
  });
