import OperationDirection from '@/enums/OperationDirection.js';

export const useOperation = () => {
  const getDirectionTitle = (direction) => {
    switch (direction) {
      case OperationDirection.INCOME:
        return 'Поступление';

      case OperationDirection.EXPENSE:
        return 'Расход';
    }
  };

  const getAccountTitle = (operation) => {
    switch (operation.direction) {
      case OperationDirection.INCOME:
        return operation.recipient.title;

      case OperationDirection.EXPENSE:
        return operation.sender.title;
    }
  };

  return {
    getDirectionTitle,
    getAccountTitle,
  };
};
