import { createRouter, createWebHistory } from 'vue-router';
import { useYandexMetrika } from '@/use/useYandexMetrika.js';
import shiftRouter from '@/modules/shift/shift-router.js';
import bookingRouter from '@/modules/booking/booking-router.js';
import orderRouter from '@/modules/order/order-router.js';
import uiRouter from '@/modules/ui/ui-router.js';
import storageRouter from '@/modules/storage/storage-router.js';
import settingsRouter from '@/modules/settings/settings-router.js';
import financeRouter from '@/modules/finance/finance-router.js';
import reportRouter from '@/modules/report/report-router.js';
import authRouter from '@/modules/auth/auth-router.js';
import homeRouter from '@/modules/home/home-router.js';
import clientRouter from '@/modules/client/client-router.js';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
  ...homeRouter,
  ...shiftRouter,
  ...bookingRouter,
  ...orderRouter,
  ...storageRouter,
  ...uiRouter,
  ...settingsRouter,
  ...financeRouter,
  ...reportRouter,
  ...authRouter,
  ...clientRouter,
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
});

const yandexMetrika = useYandexMetrika();

router.afterEach(async (to, from) => {
  yandexMetrika.hit({ url: to.fullPath, referer: from.fullPath });
});

export default router;
