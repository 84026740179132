<script setup>
import AppTable from '@/components/AppTable.vue';
import AppButton from '@/components/AppButton.vue';
import { ref } from 'vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const data = [
  { id: 1, first_name: 'Jesse', last_name: 'Simmons', date: '2016-10-15 13:43:27', gender: 'Male' },
  { id: 2, first_name: 'John', last_name: 'Jacobs', date: '2016-12-15 06:00:53', gender: 'Male' },
  { id: 3, first_name: 'Tina', last_name: 'Gilbert', date: '2016-04-26 06:26:28', gender: 'Female' },
  { id: 4, first_name: 'Clarence', last_name: 'Flores', date: '2016-04-10 10:28:46', gender: 'Male' },
  { id: 5, first_name: 'Anne', last_name: 'Lee', date: '2016-12-06 14:38:38', gender: 'Female' },
];

const columns = [
  {
    field: 'id',
    label: 'ID',
    width: '40',
    numeric: true,
  },
  {
    field: 'first_name',
    label: 'First Name',
    minWidth: '40px',
    maxWidth: '2fr',
  },
  {
    field: 'last_name',
    label: 'Last Name',
  },
  {
    field: 'date',
    label: 'Date',
    centered: true,
  },
  {
    field: 'gender',
    label: 'Gender',
  },
];

const loading = ref(false);
</script>

<template>
  <CabinetLayout>
    <AppButton @click="loading = !loading">Switch Loading</AppButton>
    <AppTable
      class="mt-2"
      :loading="loading"
      :data="data"
      :columns="columns"
    />
  </CabinetLayout>
</template>
