import { useQuery } from '@tanstack/vue-query';
import { getLastVersion } from '@/api/app.js';

export function useAppLastVersionQuery(options = {}) {
  const queryKey = ['app', 'last-version'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getLastVersion();
    },
    ...options,
  });
}
