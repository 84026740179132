<script setup>
import { computed, ref } from 'vue';
import { useServiceCategoriesQuery } from '@/query/useServiceCategoryQuery.js';
import AppButton from '@/components/AppButton.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconEquals from '@/components/Icons/IconEquals.vue';
import { Sortable } from 'sortablejs-vue3';
import AppIcon from '@/components/AppIcon.vue';
import { serviceCategoryUpdate } from '@/api/service_category.js';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';
import { serviceUpdate } from '@/api/service.js';
import SettingsServiceCategoryModal from '@/modules/settings/components/SettingsServiceCategoryModal.vue';
import SettingsServiceModal from '@/modules/settings/components/SettingsServiceModal.vue';
import { getServicePriceTitle } from '@/use/useServices.js';
import IconTrash from '@/components/Icons/IconTrash.vue';
import SettingsServiceRemoveModal from '@/modules/settings/components/SettingsServiceRemoveModal.vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const include = ['services.prices'];
const { data: categories, isLoading } = useServiceCategoriesQuery(
  { include },
  { select: (response) => response?.data?.data }
);

const sortedCategories = computed(() => {
  if (!categories.value) {
    return [];
  }

  const sorted = JSON.parse(JSON.stringify(categories.value));

  sorted.forEach((category) => {
    const services = [...category.services];
    category.services = services?.sort((a, b) => a.index - b.index);
  });

  return sorted.sort((a, b) => a.index - b.index);
});

const sortableCategoryOptions = computed(() => {
  return {
    animation: 700,
    handle: '.category-move',
  };
});

const sortableServiceOptions = computed(() => {
  return {
    animation: 150,
    handle: '.service-move',
    group: 'services',
  };
});

const queryClient = useQueryClient();
const loading = ref(false);

const changeCategoryIndex = async (element) => {
  const categoryId = element.item.dataset.categoryId;
  try {
    await serviceCategoryUpdate(categoryId, { index: element.newIndex });
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['service', 'service-category', 'order'].indexOf(key) !== -1),
    });
    useToast().show('Позиция изменена');
  } catch (error) {
    const items = element.from.querySelectorAll(':scope > div'); // You can change this if needed (example: draggable: '.my-item' change to :scope > .my-item)
    element.from.insertBefore(element.item, items[element.oldIndex + (element.oldIndex > element.newIndex)]);

    useToast().error(
      error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
    );

    if (!error?.response?.data?.message) {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const changeServiceIndex = async (element) => {
  const fromCategoryId = element.from.dataset.categoryId;
  const toCategoryId = element.to.dataset.categoryId;
  const serviceId = element.item.dataset.serviceId;
  if (fromCategoryId !== toCategoryId) {
    element.item.remove();
  }

  try {
    await serviceUpdate(serviceId, { categoryId: toCategoryId, index: element.newIndex });
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['service', 'service-category', 'order'].indexOf(key) !== -1),
    });
    useToast().show('Позиция изменена');
  } catch (error) {
    const items = element.from.querySelectorAll(':scope > div'); // You can change this if needed (example: draggable: '.my-item' change to :scope > .my-item)
    element.from.insertBefore(element.item, items[element.oldIndex + (element.oldIndex > element.newIndex)]);

    useToast().error(
      error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
    );

    if (!error?.response?.data?.message) {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const serviceCategoryModalShow = ref(false);
const editableCategoryId = ref(null);

const serviceCategoryModalOpen = (categoryId = null) => {
  editableCategoryId.value = categoryId;
  serviceCategoryModalShow.value = true;
};

const serviceCategoryModalClose = () => {
  serviceCategoryModalShow.value = false;
  editableCategoryId.value = null;
};

const serviceModalShow = ref(false);
const editableServiceId = ref(null);
const creatableCategoryId = ref(null);

const serviceModalOpen = (serviceId = null, categoryId = null) => {
  editableServiceId.value = serviceId;
  creatableCategoryId.value = categoryId;
  serviceModalShow.value = true;
};

const serviceModalClose = () => {
  serviceModalShow.value = false;
  editableServiceId.value = null;
  creatableCategoryId.value = null;
};

const serviceRemoveModalShow = ref(false);
const removableServiceId = ref(null);

const serviceRemoveModalOpen = (serviceId) => {
  serviceRemoveModalShow.value = true;
  removableServiceId.value = serviceId;
};

const serviceRemoveModalClose = () => {
  serviceRemoveModalShow.value = false;
  removableServiceId.value = null;
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading"
    :hide-in-loading="true"
  >
    <template #header>Услуги</template>
    <div class="flex flex-col gap-y-[32px] max-w-[842px]">
      <div>
        <AppButton
          outline
          @click="serviceCategoryModalOpen()"
          >Новая категория</AppButton
        >
        <SettingsServiceCategoryModal
          v-if="serviceCategoryModalShow"
          :category-id="editableCategoryId"
          @closed="serviceCategoryModalClose()"
          @saved="serviceCategoryModalClose()"
        />
      </div>

      <Sortable
        v-if="sortedCategories"
        :options="sortableCategoryOptions"
        :list="sortedCategories"
        item-key="categoryId"
        class="flex flex-col gap-y-[32px]"
        @end="changeCategoryIndex"
      >
        <template #item="{ element: category }">
          <div
            class="flex flex-col bg-white"
            :data-category-id="category.categoryId"
          >
            <div class="flex justify-between items-center text-black-primary py-[10px] px-[8px] hover:bg-[#FBFBFD]">
              <div class="flex gap-x-[23px] items-center">
                <AppIcon
                  :icon="IconEquals"
                  :width="16"
                  :height="16"
                  class="cursor-move category-move"
                />
                <div class="font-medium">{{ category.title }}</div>
              </div>
              <AppIcon
                :width="17"
                :height="17"
                :icon="IconPencil"
                class="hover:text-violet-primary cursor-pointer"
                @click="serviceCategoryModalOpen(category.categoryId)"
              />
            </div>

            <Sortable
              item-key="serviceId"
              :list="category.services"
              :options="sortableServiceOptions"
              class="flex flex-col"
              :data-category-id="category.categoryId"
              @end="changeServiceIndex"
            >
              <template #item="{ element: service }">
                <div
                  :data-service-id="service.serviceId"
                  class="flex justify-between items-center text-gray-primary py-[10px] px-[8px] hover:bg-[#FBFBFD] bg-white"
                >
                  <div class="flex gap-x-[23px] items-center">
                    <AppIcon
                      :icon="IconEquals"
                      :width="16"
                      :height="16"
                      class="cursor-move service-move"
                    />
                    <div>{{ service.title }}</div>
                  </div>
                  <div class="flex gap-x-[17px]">
                    <div>
                      {{ getServicePriceTitle(service) }}
                    </div>
                    <div class="flex gap-x-[12px] items-center">
                      <AppIcon
                        :width="17"
                        :height="20"
                        :icon="IconPencil"
                        class="hover:text-violet-primary cursor-pointer"
                        @click="serviceModalOpen(service.serviceId)"
                      />
                      <div class="w-[1px] h-[16px] bg-gray-secondary"></div>
                      <AppIcon
                        :width="17"
                        :height="20"
                        :icon="IconTrash"
                        class="hover:text-red-notify cursor-pointer"
                        @click="serviceRemoveModalOpen(service.serviceId)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </Sortable>

            <div class="mt-[12px]">
              <AppButton
                outline
                @click="serviceModalOpen(null, category.categoryId)"
                >Добавить услугу</AppButton
              >
            </div>
          </div>
        </template>
      </Sortable>

      <SettingsServiceModal
        v-if="serviceModalShow"
        class="md:w-[894px]"
        :service-id="editableServiceId"
        :category-id="creatableCategoryId"
        @closed="serviceModalClose()"
        @saved="serviceModalClose()"
      />

      <SettingsServiceRemoveModal
        v-if="serviceRemoveModalShow"
        :service-id="removableServiceId"
        @closed="serviceRemoveModalClose()"
        @removed="serviceRemoveModalClose()"
      />
    </div>
  </CabinetLayout>
</template>
