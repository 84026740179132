import { useQuery } from '@tanstack/vue-query';
import { getWorkplaces } from '@/api/workplace.js';

export const useWorkplacesQuery = (options = {}) => {
  const queryKey = ['workplace'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getWorkplaces();
    },
    ...options,
  });
};
