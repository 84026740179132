<template>
  <svg
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 17L14.9282 13V5L8 1L1.07179 5V13L8 17ZM8 17V9.5M8 9.5L1.5 5.5M8 9.5L14.5 5.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
