<script setup>
import AppIcon from '@/components/AppIcon.vue';
import IconSpinner from '@/components/Icons/IconSpinner.vue';
const props = defineProps({
  outline: {
    type: Boolean,
    default: false,
  },

  red: {
    type: Boolean,
    default: false,
  },

  black: {
    type: Boolean,
    default: false,
  },

  white: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <button
    :disabled="props.disabled"
    class="button-class"
    :class="{
      outline: props.outline,
      red: props.red,
      black: props.black,
      white: props.white,
    }"
  >
    <div class="flex items-center justify-center">
      <div v-if="loading">
        <AppIcon :icon="IconSpinner" />
      </div>
      <slot v-else />
    </div>
  </button>
</template>

<style lang="scss" scoped>
.button-class {
  @apply select-none cursor-pointer disabled:cursor-default px-[24px] py-[8px] rounded-[6px] border-violet-primary hover:border-[#777FFE] active:border-[#5860DF] disabled:border-gray-secondary bg-violet-primary hover:bg-[#777FFE] active:bg-[#5860DF] disabled:bg-gray-secondary text-white border outline-none;
}

.outline {
  @apply bg-white hover:bg-white disabled:bg-transparent text-black hover:text-[#777FFE] active:text-[#5860DF] disabled:text-gray-secondary;
}

.outline.red {
  @apply border-red-notify text-red-notify bg-white hover:bg-[#FCEEEE] hover:bg-opacity-50;
}

.red:not(.outline) {
  @apply border-red-notify bg-red-notify;
}

.outline.black {
  @apply border-[#CBD3E4] disabled:border-[#CFCFCF] text-black-primary disabled:text-[#CFCFCF] bg-white hover:bg-[#F3F5F7] hover:bg-opacity-50;
}

.black:not(.outline) {
  @apply border-black-primary bg-black-primary;
}

.white:not(.outline) {
  @apply border-white bg-white text-black-primary hover:bg-[#EFEFEF];
}
</style>
