<template>
  <svg
    viewBox="0 0 67 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.5 66C51.7254 66 66.5 51.2254 66.5 33C66.5 14.7746 51.7254 0 33.5 0C15.2746 0 0.5 14.7746 0.5 33C0.5 51.2254 15.2746 66 33.5 66Z"
      fill="url(#paint0_linear_1251_4103)"
    />
    <path
      d="M22.5726 35.6557L26.4038 45.9737L43.2085 22.5515L22.5726 35.6557Z"
      fill="#C2DAEC"
    />
    <path
      d="M26.7953 37.9626L26.447 46.0603L35.3283 38.5286L26.7953 37.9626Z"
      fill="#9FCDE2"
    />
    <path
      d="M47.3442 18.6334L14.1701 31.2587C13.2123 31.607 13.2558 32.9566 14.2136 33.2614L22.7901 36.1347L42.2941 23.5529L26.839 37.9632L38.8548 47.0621C40.1173 48.0199 41.9023 47.3669 42.2941 45.8432L48.7809 19.8524C48.955 19.0252 48.1279 18.2851 47.3442 18.6334Z"
      fill="#ECF8FD"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1251_4103"
        x1="10.1839"
        y1="9.69839"
        x2="56.826"
        y2="56.3404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00AFE5" />
        <stop
          offset="1"
          stop-color="#0094D4"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
