<script setup>
import { computed, ref } from 'vue';
import { useSuggestionTireBrandsQuery } from '@/query/useSuggestionQuery.js';
import { useDebounceFn } from '@vueuse/core';
import AppInput from '@/components/AppInput.vue';

const emit = defineEmits(['brand-selected']);

const options = ref([]);
const query = ref(null);
const title = ref(null);

const enabled = computed(() => {
  return !!query.value;
});

const {
  data: brands,
  isLoading,
  isFetching,
} = useSuggestionTireBrandsQuery({ query }, { enabled, select: (response) => response?.data?.data });

const search = useDebounceFn((searchQuery) => {
  query.value = searchQuery;
}, 100);

const brandSelect = (brand) => {
  emit('brand-selected', brand);
  title.value = brand.title;
  query.value = false;
};
</script>

<template>
  <AppInput
    v-model="title"
    :loading="enabled && (isLoading || isFetching)"
    placeholder="Бренд шины"
    :suggestions="brands"
    @suggestion-query-updated="search"
    @suggestion-selected="brandSelect"
  >
    <template #suggestion="{ suggestion: brand }">
      <div class="flex flex-col gap-y-[4px]">
        {{ brand.title }}
      </div>
    </template>
  </AppInput>
</template>
