import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';
import { getAnalyticsGeneral } from '@/api/analytics.js';

export const useAnalyticsGeneralQuery = ({ startTime, endTime, groupBy }, options = {}) => {
  const queryKey = ['analytics', startTime, endTime, groupBy];

  if (options.keepPreviousData) {
    options.placeholderData = keepPreviousData;
    delete options.keepPreviousData;
  }

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAnalyticsGeneral(unref(startTime), unref(endTime), unref(groupBy));
    },
    ...options,
  });
};
