<script setup>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import AppSpinner from '@/components/AppSpinner.vue';
import { computed, nextTick, onMounted, watch, ref } from 'vue';
import { firstLetterToUpperCase, formatPhone } from '@/use/useFormat.js';
import { useElementSize } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: false,
  },

  /**
   * Маска инпута
   */
  mask: {
    type: [Boolean, String],
    validator(value) {
      return [false, 'phone', 'number', 'money'].includes(value);
    },
    default: false,
  },

  /**
   * Плейсхолдер
   */
  placeholder: {
    type: [Number, String],
    default: '',
  },

  /**
   * Переводить первую букву в заглавную
   */
  firstLetterUpperCase: {
    type: Boolean,
    default: false,
  },

  /**
   * Переводить все буквы в заглавные
   */
  upperCase: {
    type: Boolean,
    default: false,
  },

  /**
   * Переводить все буквы в строчные
   */
  lowerCase: {
    type: Boolean,
    default: false,
  },

  /**
   * Сделать инпут неактивным
   */
  disabled: {
    type: Boolean,
    default: false,
  },

  variantIsError: {
    type: Boolean,
    default: false,
  },

  maxLength: {
    type: [Number, Boolean],
    required: false,
    default: false,
  },

  minLength: {
    type: Number,
    required: false,
    default: 0,
  },

  autofocus: {
    type: Boolean,
    default: false,
  },

  required: {
    type: Boolean,
    default: false,
  },

  lazy: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  suggestions: {
    type: Array,
    default: new Array(),
  },

  readonly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'suggestion-selected', 'suggestion-query-updated']);

const inputValue = ref(null);
const inputRef = ref(null);
const isFocused = ref(false);

const showErrorBorder = computed(() => {
  return props.variantIsError || vuelidate.value.$error;
});

watch(
  () => props.modelValue,
  () => {
    inputValue.value = props.modelValue;
  },
  {
    immediate: true,
  }
);

const onChange = (event) => {
  if (props.lazy) {
    inputValue.value = event.target.value;
  }
};

const onInput = (event) => {
  emit('suggestion-query-updated', event.target.value);

  if (!props.lazy) {
    inputValue.value = event.target.value;
  }
};

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      inputRef.value.focus();
    });
  }
});

const applyMask = () => {
  if (inputValue.value) {
    switch (props.mask) {
      case 'phone':
        inputValue.value = formatPhone(inputValue.value);
        break;

      case 'number':
        inputValue.value = inputValue.value.toString().replace(/[^0-9]+/g, '');
        break;
    }
  }
};

const applyFirstLetterUpperCase = () => {
  if (props.firstLetterUpperCase) {
    inputValue.value = firstLetterToUpperCase(inputValue.value);
  }
};

const applyUpperCase = () => {
  if (props.upperCase) {
    inputValue.value = String(inputValue.value).toUpperCase();
  }
};

const applyLowerCase = () => {
  if (props.lowerCase) {
    inputValue.value = inputValue.value.toLowerCase();
  }
};

const applyMaxLength = () => {
  if (props.maxLength) {
    inputValue.value = String(inputValue.value).substring(0, props.maxLength);
  }
};

const prepareValue = () => {
  if (inputValue.value) {
    applyMask();
    applyFirstLetterUpperCase();
    applyUpperCase();
    applyLowerCase();
    applyMaxLength();
  }
};

const emitModelValue = () => {
  let value = inputValue.value;

  if (props.mask === 'phone') {
    value = formatPhone(value, true);
  }
  emit('update:modelValue', value);
};

watch(
  inputValue,
  () => {
    prepareValue();
    emitModelValue(inputValue.value);
  },
  {
    immediate: true,
  }
);

let rules = {};

if (props.required) {
  rules['inputValue'] = {
    required,
  };
}

if (props.minLength > 0) {
  rules['inputValue'] = { minLength: minLength(props.minLength) };
}

const vuelidate = useVuelidate(rules, { inputValue });
const container = ref(null);

const { width: containerWidth } = useElementSize(container);
</script>

<template>
  <div
    ref="container"
    class="relative"
    v-bind="$attrs"
  >
    <input
      ref="inputRef"
      :value="inputValue"
      class="input-class peer"
      :placeholder="placeholder"
      :class="{
        '!border-rose-400': showErrorBorder,
      }"
      :disabled="disabled"
      :readonly="readonly"
      type="text"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @change="onChange"
      @input="onInput"
    />

    <div
      v-if="props.required"
      class="pointer-events-none absolute inset-y-0 right-3 h-full text-sm items-center justify-center text-gray-primary hidden peer-placeholder-shown:flex"
    >
      обязательно
    </div>

    <div
      v-if="loading"
      class="absolute inset-y-0 right-3"
    >
      <div class="flex h-full w-full items-center justify-center">
        <AppSpinner class="h-[20px] w-[20px]" />
      </div>
    </div>

    <div
      v-if="props.suggestions.length > 0 && isFocused"
      class="fixed w-[var(--containerWidth)] mt-[8px] mb-[8px] z-10 rounded-[6px] bg-white py-[18px] flex flex-col gap-y-[8px] border border-[#CBD3E4] shadow-xl max-h-60 overflow-y-auto scrollbar"
      :style="{ '--containerWidth': containerWidth + 'px' }"
    >
      <div
        v-for="suggestion in props.suggestions"
        :key="suggestion"
        class="py-[12px] px-[30px] hover:bg-[#FAFBFE] cursor-pointer flex select-none gap-x-[5px]"
        @mousedown="emit('suggestion-selected', suggestion)"
      >
        <slot
          name="suggestion"
          :suggestion="suggestion"
        >
          {{ suggestion.label }}
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-class {
  @apply w-full h-[42px] border-[#CBD3E4] focus:border-[#AEAEAE] text-black-primary placeholder-[#AEAEAE] rounded-[6px] outline-none bg-white shadow-sm transition duration-100 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50;
}
</style>
