<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { useClientsQuery } from '@/query/useClientQuery.js';
import { formatPhone } from '@/use/useFormat.js';
import { computed, ref } from 'vue';

const emit = defineEmits(['client-selected']);

const props = defineProps({
  allowShowClients: {
    type: Boolean,
    required: true,
  },
});

const searchQuery = ref(null);
const perPage = 10;
const include = ['cars'];
const { data } = useClientsQuery({ perPage, include, searchQuery }, { keepPreviousData: true });

const clients = computed(() => {
  return data?.value?.data ?? [];
});

const search = (query) => {
  searchQuery.value = query;
};

const clientSelect = (clientId) => {
  const client = clients.value.find((client) => client.clientId === clientId);
  emit('client-selected', client);
};

const getCarsTitles = (cars) => {
  let carsTitles = [];

  cars.forEach(function (car) {
    carsTitles.push(car.mark.title + ' ' + car.model.title + ' (' + car.regNumber + ')');
  });

  return carsTitles.join(', ');
};
</script>

<template>
  <div class="flex flex-col gap-y-[18px]">
    <div>Выберите клиента</div>
    <div>
      <AppSelect
        v-if="allowShowClients"
        data-test="clientId"
        placeholder="Клиент не выбран (Анонимный)"
        placeholder-focused="Начните вводить ФИО, Телефон, Гос.номер"
        option-value="clientId"
        :options="clients"
        searchable
        @search-query-changed="search"
        @changed="clientSelect"
      >
        <template #default="{ option }">
          <div class="grid grid-cols-[1fr_1fr_1fr] gap-x-[10px]">
            <div v-if="option.title">{{ option.title }}</div>
            <div
              v-else
              class="text-gray-primary"
            >
              Клиент без имени
            </div>
            <div class="text-gray-primary">{{ getCarsTitles(option.cars) }}</div>
            <div class="text-gray-primary">{{ formatPhone(option.phone) }}</div>
          </div>
        </template>

        <template #selected="{ selected: client }">
          <div class="flex gap-x-[10px]">
            {{ client.title }} <span class="text-gray-primary">{{ formatPhone(client.phone) }}</span>
          </div>
        </template>
      </AppSelect>
    </div>
  </div>
</template>
