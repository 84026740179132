import { computed, ref } from 'vue';
import { useArrayUtils } from '@/use/useArrayUtils.js';
import ServicePriceType from '@/enums/ServicePriceType.js';
import { formatMoney } from '@/use/useFormat.js';
const { min, max } = useArrayUtils();

export const getServicePriceTitle = computed(() => {
  return (service) => {
    switch (true) {
      case service.priceType === ServicePriceType.FIXED:
        return 'от ' + formatMoney(getServiceMinPrice(service)) + ' до ' + formatMoney(getServiceMaxPrice(service));

      case service.priceType === ServicePriceType.RANGE:
        return 'от ' + formatMoney(service.priceRangeStart) + ' до ' + formatMoney(service.priceRangeEnd);

      case service.priceType === ServicePriceType.FREE:
        return 'свободная цена';
    }
  };
});

const getServiceMinPrice = (service) => {
  if (!service.prices?.length) {
    return 0;
  }
  const minPrice = min(service.prices, 'price');

  return minPrice.price;
};

const getServiceMaxPrice = (service) => {
  if (!service.prices?.length) {
    return 0;
  }
  const maxPrice = max(service.prices, 'price');
  return maxPrice.price;
};
