<script setup>
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
});
</script>

<template>
  <div>
    <div
      v-if="props.loading"
      class="min-h-[5px] h-full w-1/2 animate-pulse rounded-full bg-gray-300"
    ></div>
    <slot v-else />
  </div>
</template>
