import http from '@/libs/http.js';

export const openShift = ({ employees }) => {
  return http.post('/api/v2/shift/open', {
    employees,
  });
};

export const closeShift = () => {
  return http.post('/api/v2/shift/close');
};

export const updateShift = (shiftId, { employees }) => {
  return http.put('/api/v1/shift/' + shiftId, {
    employees,
  });
};

export const getCurrentShift = () => {
  return http.get('/api/v2/shift/current');
};

export const getShift = ({ shiftId, include }) => {
  return http.get('/api/v2/shift/' + shiftId, {
    params: { include },
  });
};

export const getShifts = ({ include, page, perPage }) => {
  return http.get('/api/v2/shift', {
    params: { include, page, perPage },
  });
};

export const shiftEmployeesSync = (shiftId, { employeesId }) => {
  return http.put('/api/v2/shift/employees', {
    shiftId,
    employeesId,
  });
};

export const getStatsByDays = (startTime, endTime) => {
  return http.get('/api/v1/shift/stats-by-days', {
    params: {
      startTime,
      endTime,
    },
  });
};

export const printShiftReport = (shiftId) => {
  http
    .get('/api/v1/shift/' + shiftId + '/print', {
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/octetstream',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = shiftId + '.pdf';
      link.click();
      URL.revokeObjectURL(link.href);
    });
};

export const getEmployeesAccrualsCalculate = () => {
  return http.get('/api/v2/shift/employees-accruals-calculate');
};
