<script setup>
import AppModal from '@/components/AppModal.vue';
import AppSelectButtons from '@/components/AppSelectButtons.vue';
import { ClientType, getOptions as clientTypeOptions } from '@/enums/ClientType.js';
import { ref } from 'vue';
import ClientNewModalLegal from '@/modules/client/components/ClientNewModalLegal.vue';
import ClientNewModalIndividual from '@/modules/client/components/ClientNewModalIndividual.vue';
const emit = defineEmits(['close']);

const clientType = ref(ClientType.INDIVIDUAL);
</script>

<template>
  <AppModal
    :show="true"
    class="md:!w-[1028px]"
    @close="emit('closed')"
  >
    <template #title> Новый клиент </template>

    <div class="flex flex-col gap-[24px]">
      <AppSelectButtons
        v-model="clientType"
        :options="clientTypeOptions()"
      />

      <component
        :is="clientType === ClientType.INDIVIDUAL ? ClientNewModalIndividual : ClientNewModalLegal"
        @close="emit('close')"
      />
    </div>
  </AppModal>
</template>
