<script setup>
import { ref, unref, watch } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { storeOrder } from '@/api/order.js';
import { useRouter } from 'vue-router';
import { useToast } from '@/use/useToast.js';
import OrderClientAndCar from '@/modules/order/components/OrderClientAndCar.vue';
import { useShiftQuery } from '@/query/useShiftQuery.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/order/order-tabs.js';
import { useAuthStore } from '@/store/AuthStore.js';

// @todo доделать возможность указания дефолтного clientId и carId
const props = defineProps({
  clientId: {
    type: [Number, null],
    required: false,
    default: null,
  },
  carId: {
    type: [Number, null],
    required: false,
    default: null,
  },
});

const router = useRouter();
const { hasAccess } = useAuthStore();

const { isError: shiftResponseIsError, isLoading, error: shiftResponseError } = useShiftQuery({ shiftId: 'current' });

const currentShiftNotFound = ref(false);

watch(shiftResponseIsError, () => {
  if (shiftResponseError.value?.response?.status === 404) {
    currentShiftNotFound.value = true;
  }
});

const loading = ref(false);

const clientData = ref(null);

const createOrder = async () => {
  loading.value = true;

  try {
    const order = await storeOrder({
      ...unref(clientData),
    });

    await router.push({ name: 'order.edit', params: { orderId: order.data?.data?.orderId } });
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    loading.value = false;
  }
};

const clientSelect = (data) => {
  clientData.value = data;
};
</script>

<template>
  <CabinetLayout
    :loading="isLoading"
    :hide-in-loading="true"
    :tabs="tabs"
  >
    <div
      v-if="currentShiftNotFound"
      class="flex flex-col gap-y-[18px] items-center justify-center grow"
    >
      <div class="text-gray-primary">Откройте смену, чтобы создать заказ</div>
      <div>
        <AppButton
          outline
          @click="router.push({ name: 'shift.open' })"
          >Перейти к сменам</AppButton
        >
      </div>
    </div>

    <div
      v-else
      class="md:w-[603px] flex flex-col gap-[24px]"
    >
      <OrderClientAndCar
        :allow-create-client="hasAccess('create-order-Client')"
        :allow-show-clients="hasAccess('view-any-order-Client')"
        @client-changed="clientSelect"
      />

      <div class="flex flex-col gap-y-[24px]">
        <div class="flex justify-end">
          <AppButton
            :disabled="loading"
            :loading="loading"
            @click="createOrder()"
            >Далее</AppButton
          >
        </div>
      </div>
    </div>
  </CabinetLayout>
</template>
