import axios from 'axios';
import { app } from '@/main';
import router from '@/routes';
import { useAuthStore } from '@/store/AuthStore.js';

const http = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${import.meta.env.VITE_APP_API_URL}/`,
  Accept: 'application/json',
  timeout: 30000,
});

http.interceptors.request.use(async (config) => {
  const authStore = useAuthStore();
  config.headers['business-id'] = await authStore.getBusinessId();
  return config;
});

// Handler error
http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isUnauthorizedQuery = [401, 419].includes(error.response.status);
    const isForbiddenQuery = [403].includes(error.response.status);
    const isValidationError = error.response.status === 422;
    const isNotFoundError = error.response.status === 404;

    const authStore = useAuthStore();

    switch (true) {
      case isUnauthorizedQuery:
        await authStore.resetAuth();
        router.push('/auth/login');
        break;

      case isValidationError:
      case isNotFoundError:
        break;

      case isForbiddenQuery:
        app.$toast.error(
          `Извините. У Вас отсутствует доступ для выполнения данного действия. Обратитесь к руководству за его получением.`
        );
        break;

      default:
        app.$toast.error(`Ошибка: ${error.message}`);
    }

    throw error;
  }
);

// Progress bar
http.interceptors.request.use((config) => {
  // app.progresses.push(useProgress().start());
  return config;
});

// Autosend cookies
http.defaults.withCredentials = true;

export default http;
