<script setup>
import { computed, ref } from 'vue';
import AppRadio from '@/components/AppRadio.vue';

const props = defineProps({
  client: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['car-selected']);

const cars = computed(() => {
  return props.client?.cars;
});

const carId = ref(null);

const carSelect = (carId) => {
  emit('car-selected', carId);
};

const createNewCar = () => {
  carId.value = 0;
  emit('car-selected', 0);
};
</script>

<template>
  <div class="flex flex-col gap-y-[12px]">
    <div>Автомобиль</div>

    <div
      class="flex justify-between"
      data-test="cars"
    >
      <AppRadio
        v-model="carId"
        :options="cars"
        option-label="title"
        option-value="carId"
        @changed="carSelect"
      >
        <template #option="{ option }"> {{ option.title }} {{ option.regNumber }}</template>
      </AppRadio>

      <div
        class="text-violet-primary cursor-pointer hover:underline underline-offset-4 select-none"
        @click="createNewCar()"
      >
        Добавить автомобиль
      </div>
    </div>
  </div>
</template>
