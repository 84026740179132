<script setup>
import { computed, ref } from 'vue';
import AppPrintTemplate from '@/components/AppPrintTemplate.vue';

import { useShiftQuery } from '@/query/useShiftQuery.js';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import { DateTime } from 'luxon';
import { formatMoney } from '@/use/useFormat.js';
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';

const props = defineProps({
  shiftId: {
    type: Number,
    required: true,
  },
});

const include = [
  'orders.payments.account',
  'orders.client',
  'orders.car.mark',
  'orders.car.model',
  'orders.employees',
  'orders.payments.account',
  'expenses',
  'employees',
  'accruals',
];

const { data: shift, error } = useShiftQuery(
  { shiftId: props.shiftId, include },
  { select: (response) => response?.data?.data }
);
const { data: employees } = useEmployeesQuery({}, { select: (response) => response?.data?.data });
const { data: accounts } = useAccountsQuery({ isArchived: false }, { select: (response) => response?.data?.data });

const employeesWithAccruals = computed(() => {
  if (!shift.value?.accruals || !employees.value) {
    return null;
  }

  return shift.value.accruals.map((accrual) => {
    const employee = employees.value.find((employee) => employee.employeeId === accrual.employee.employeeId);

    return {
      ...accrual,
      minimumPayPerShift: employee.businessEmployee.minimumPayPerShift,
      percentOfCompletedOrders: employee.businessEmployee.percentOfCompletedOrders,
    };
  });
});

const totalIncome = computed(() => {
  if (!shift.value?.orders) {
    return null;
  }

  return Object.values(shift.value.orders).reduce((total, order) => {
    return total + order.price;
  }, 0);
});

const totalIncomes = computed(() => {
  if (!shift.value?.orders) {
    return null;
  }

  if (!accounts.value) {
    return null;
  }

  let accountIncomes = [];

  accounts.value.forEach((account) => {
    const accountTotal = Object.values(shift.value.orders).reduce((accountSum, order) => {
      const orderSum = order.payments.reduce((orderTotal, payment) => {
        if (payment.accountId !== account.accountId) {
          return orderTotal;
        }

        return orderTotal + payment.amount;
      }, 0);

      return accountSum + orderSum;
    }, 0);

    // скрывваем счета в архиве с балансом 0
    if (account.isArchived && accountTotal === 0) {
      return;
    }

    if (accountTotal === 0) {
      return;
    }

    accountIncomes.push({
      ...account,
      total: accountTotal,
    });
  });

  return accountIncomes;
});

const template = ref(null);
const parsedTemplate = computed(() => {
  return template.value?.innerHTML;
});
</script>

<template>
  <div v-if="shift">
    <div
      ref="template"
      class="hidden"
    >
      <div class="flex">
        <span>Отчет за смену №{{ shift.numberId }}</span>
      </div>

      <div class="mt-[12px] text-xs grid grid-cols-4">
        <div>Открыта</div>
        <div class="text-gray-primary">
          {{ DateTime.fromISO(shift.createdAt).toFormat('d MMMM yyyy H:mm') }}
        </div>
        <div>Касса на момент открытия</div>
        <div>{{ formatMoney(shift.cashboxAtOpening) }}</div>

        <div>Закрыта</div>
        <div class="text-gray-primary">{{ DateTime.fromISO(shift.finishedAt).toFormat('d MMMM yyyy H:mm') }}</div>
        <div>Касса на момент закрытия</div>
        <div>
          {{ formatMoney(shift.cashboxAtClosing) }}
        </div>
      </div>

      <div class="mt-[25px]">
        <span class="text-sm font-medium">Выполненные заказы</span>

        <div class="mt-[10px] grid grid-cols-4 gap-y-[10px] text-xs">
          <div class="text-gray-primary">№</div>
          <div class="text-gray-primary">Автомобиль</div>
          <div class="text-gray-primary">Выручка</div>
          <div class="text-gray-primary">Счет</div>

          <template
            v-for="order in shift.orders"
            :key="order.orderId"
          >
            <div class="text-sm">{{ order.numberId }}</div>
            <div class="text-sm">{{ order.car?.title }}</div>
            <div class="text-sm">{{ formatMoney(order.price) }}</div>
            <div class="text-sm">
              <span
                v-for="payment in order.payments"
                :key="payment.paymentId"
                >{{ payment.account.title }} {{ formatMoney(payment.amount) }}
              </span>
            </div>
          </template>
        </div>
      </div>

      <div class="mt-[25px]">
        <span class="text-sm font-medium">Начисления</span>

        <div class="mt-[10px] grid grid-cols-6 gap-y-[10px] gap-x-[5px] text-xs">
          <div class="text-gray-primary">Имя</div>
          <div class="text-gray-primary">Условия</div>
          <div class="text-gray-primary">Выполнено работ</div>
          <div class="text-gray-primary">Начислено</div>
          <div class="text-gray-primary">Доплаты</div>
          <div class="text-gray-primary">Итого</div>

          <template
            v-for="accrual in employeesWithAccruals"
            :key="accrual.employee.employeeId"
          >
            <div>{{ accrual.employee.title }}</div>
            <div>
              <div>{{ accrual.percentOfCompletedOrders }}% от работ</div>
              <div
                v-if="accrual.minimumPayPerShift > 0"
                class="text-gray-primary text-2xs"
              >
                минимум {{ formatMoney(accrual.minimumPayPerShift) }} за смену
              </div>
            </div>
            <div class="text-sm">{{ formatMoney(accrual.totalOfCompletedOrders) }}</div>
            <div class="text-sm">{{ formatMoney(accrual.amountOfCompletedOrders) }}</div>
            <div class="text-sm">{{ formatMoney(accrual.surcharge) }}</div>
            <div class="text-sm">{{ formatMoney(accrual.total) }}</div>
          </template>
        </div>
      </div>

      <div class="mt-[25px] w-1/2">
        <span class="text-sm font-medium">Расходы</span>

        <div class="mt-[10px] grid grid-cols-2 gap-y-[10px] text-xs">
          <div class="text-gray-primary">Сумма</div>
          <div class="text-gray-primary">Описание</div>

          <template
            v-for="expense in shift.expenses"
            :key="expense.operationid"
          >
            <div>{{ formatMoney(expense.amount) }}</div>
            <div>{{ expense.description }}</div>
          </template>
        </div>
      </div>

      <div class="mt-[25px]">
        <div class="text-sm font-medium">Выручка</div>
        <div class="mt-[10px] flex justify-between">
          <div class="flex flex-wrap text-sm">
            <div
              v-for="(account, index) in totalIncomes"
              :key="account.accountId"
            >
              <span
                v-if="index > 0"
                class="mr-[10px]"
                >,</span
              >
              {{ account.title }}: {{ formatMoney(account.total) }}
            </div>
          </div>
          <div class="font-medium text-sm">Итого: {{ formatMoney(totalIncome) }}</div>
        </div>
      </div>

      <div class="mt-[25px]">
        <div class="text-sm font-medium">Смену сдал</div>
        <div class="mt-[40px] grid gap-x-[80px] gap-y-[10px] grid-cols-2">
          <div class="border border-t-[#313131]"></div>
          <div class="border border-t-[#313131]"></div>
          <div class="text-gray-primary">Ф.И.О.</div>
          <div class="text-gray-primary">Подпись</div>
        </div>
      </div>
    </div>

    <AppPrintTemplate
      v-if="parsedTemplate"
      :text="parsedTemplate"
    />
  </div>
</template>
