<script setup>
import AppInput from '@/components/AppInput.vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['client-new-updated']);

const firstName = ref(null);
const lastName = ref(null);
const phone = ref(null);

watch([firstName, lastName, phone], () => {
  emit('client-new-updated', {
    firstName: firstName.value,
    lastName: lastName.value,
    phone: phone.value,
  });
});
</script>

<template>
  <div class="flex flex-col gap-y-[24px]">
    <div class="grid grid-cols-2 gap-[24px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Имя</div>
        <AppInput
          v-model="firstName"
          data-test="firstName"
        />
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Фамилия</div>
        <AppInput
          v-model="lastName"
          data-test="lastName"
        />
      </div>
      <div class="flex flex-col gap-y-[12px]">
        <div>Телефон</div>
        <AppInput
          v-model="phone"
          mask="phone"
          data-test="phone"
        />
      </div>
    </div>
  </div>
</template>
