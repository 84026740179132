<script setup>
import { ref } from 'vue';

const props = defineProps({
  opened: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(props.opened);

const close = () => {
  isOpen.value = false;
};

const show = () => {
  isOpen.value = true;
};

const toggle = () => {
  isOpen.value = !isOpen.value;
};

defineExpose({
  show,
  close,
  toggle,
});
</script>

<template>
  <Transition>
    <div
      v-if="isOpen"
      class="pointer-events-none fixed left-0 right-0 z-50 flex items-center justify-center"
    >
      <div class="pointer-events-auto mt-[32px] rounded-[6px] bg-black-primary py-[8px] px-[12px] text-white">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
