import { DateTime } from 'luxon';
import { formatPhone } from '@/use/useFormat.js';

export const clientsColumns = [
  {
    label: 'Имя',
    field: (client) => {
      return client.title;
    },
  },
  {
    label: 'Телефон',
    field: (client) => {
      return formatPhone(client.phone);
    },
  },
  {
    label: 'Дата регистрации',
    field: (client) => {
      return DateTime.fromISO(client.createdAt).toFormat('d.LL.y');
    },
  },
];
