export const useArrayUtils = () => {
  const min = (elements, property) => {
    return elements.reduce(function (previousValue, currentItem) {
      return previousValue[property] < currentItem[property] ? previousValue : currentItem;
    });
  };

  const max = (elements, property) => {
    return elements.reduce(function (previousValue, currentItem) {
      return previousValue[property] > currentItem[property] ? previousValue : currentItem;
    });
  };

  const includesArray = (arr, data) => {
    return data.some((e) => arr.includes(e));
    // return !data.some((e) => !arr.includes(e));
  };

  return {
    min,
    max,
    includesArray,
  };
};
