<script setup>
import AppCompanySearch from '@/components/AppCompanySearch.vue';
import { computed, ref, watch } from 'vue';
import { useSuggestionCompanyQuery } from '@/query/useSuggestionQuery.js';
import AppInput from '@/components/AppInput.vue';
import { CompanyFormType, getOptions as formTypeOptions } from '@/enums/CompanyFormType.js';
import AppSelect from '@/components/AppSelect.vue';
import AppButton from '@/components/AppButton.vue';
import { ClientType } from '@/enums/ClientType.js';
import { clientCreate } from '@/api/client.js';
import { companyCreate } from '@/api/company.js';
import { useToast } from '@/use/useToast.js';
import { useRouter } from 'vue-router';

const emit = defineEmits(['close']);

const formType = ref(null);
const inn = ref(null);
const title = ref(null);
const kpp = ref(null);
const ogrn = ref(null);
const address = ref(null);
const directorTitle = ref(null);
const phone = ref(null);
const bic = ref(null);
const correspondentAccount = ref(null);
const beneficiaryAccount = ref(null);
const enabled = computed(() => !!inn.value);

const { data: company } = useSuggestionCompanyQuery({ inn }, { enabled, select: (response) => response?.data?.data });

watch(
  company,
  () => {
    if (!company.value) {
      return false;
    }

    formType.value = String(company.value.formType).toLowerCase();
    inn.value = company.value.inn;
    title.value = company.value.title;
    kpp.value = company.value.kpp;
    ogrn.value = company.value.ogrn;
    address.value = company.value.address;
    directorTitle.value = company.value.directorTitle;
    phone.value = company.value.phone;
  },
  {
    deep: true,
  }
);

const companySelect = (selectedInn) => {
  inn.value = selectedInn;
};

const router = useRouter();

const create = async () => {
  try {
    const client = await clientCreate({
      type: ClientType.COMPANY,
      companyTitle: company.value.title,
    });

    await companyCreate(client.data?.data?.clientId, {
      title: company.value.title,
      type: formType.value,
      inn: inn.value,
      kpp: kpp.value,
      ogrn: ogrn.value,
      address: address.value,
      directorTitle: directorTitle.value,
      phone: phone.value,
      bic: bic.value,
      correspondentAccount: correspondentAccount.value,
      beneficiaryAccount: beneficiaryAccount.value,
    });

    await router.push({ name: 'client.show', params: { clientId: client.data?.data?.clientId } });

    useToast().show('Клиент создан');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>

<template>
  <div class="grid grid-cols-3 gap-x-[12px] gap-y-[24px]">
    <AppCompanySearch
      class="col-span-3"
      @company-selected="companySelect"
    />

    <div class="flex flex-col gap-[12px]">
      <div>Тип организации</div>
      <div>
        <AppSelect
          v-model="formType"
          :options="formTypeOptions()"
        />
      </div>
    </div>

    <div class="flex flex-col gap-[12px]">
      <div>ИНН</div>
      <div><AppInput v-model="inn" /></div>
    </div>

    <div
      v-if="formType === CompanyFormType.LEGAL"
      class="flex flex-col gap-[12px]"
    >
      <div>КПП</div>
      <div><AppInput v-model="kpp" /></div>
    </div>

    <div class="flex flex-col gap-[12px]">
      <div>{{ formType === CompanyFormType.INDIVIDUAL ? 'ОГРНИП' : 'ОГРН' }}</div>
      <div><AppInput v-model="ogrn" /></div>
    </div>

    <div class="flex flex-col gap-[12px] col-span-2">
      <div>Адрес</div>
      <div><AppInput v-model="address" /></div>
    </div>

    <div class="flex flex-col gap-[12px]">
      <div>ФИО Руководителя</div>
      <div><AppInput v-model="directorTitle" /></div>
    </div>

    <div class="flex flex-col gap-[12px]">
      <div>Телефон</div>
      <div><AppInput v-model="directorPhone" /></div>
    </div>

    <div class="col-start-1 flex flex-col gap-[12px]">
      <div>БИК</div>
      <div><AppInput v-model="bic" /></div>
    </div>

    <div class="flex flex-col gap-[12px]">
      <div>Корр. счет</div>
      <div><AppInput v-model="correspondentAccount" /></div>
    </div>

    <div class="flex flex-col gap-[12px]">
      <div>Расчетный счет</div>
      <div><AppInput v-model="beneficiaryAccount" /></div>
    </div>

    <div class="flex gap-[12px]">
      <AppButton
        outline
        @click="emit('close')"
        >Отмена</AppButton
      >
      <AppButton @click="create">Создать</AppButton>
    </div>
  </div>
</template>
