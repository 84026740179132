<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { onClickOutside, useElementSize } from '@vueuse/core';
import AppIcon from '@/components/AppIcon.vue';
import IconChevronDown from '@/components/Icons/IconChevronDown.vue';
import IconCheck from '@/components/Icons/IconCheck.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import AppSpinner from '@/components/AppSpinner.vue';

const props = defineProps({
  modelValue: {
    type: [Number, String, Array],
    default: null,
  },
  options: {
    type: Array,
    required: true,
  },
  optionValue: {
    type: String,
    default: 'value',
  },
  optionLabel: {
    type: String,
    default: 'label',
  },
  placeholder: {
    type: String,
    default: 'Не выбран',
  },
  placeholderFocused: {
    type: String,
    default: 'Начните вводить ',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'bottom',
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  saving: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'search-query-changed', 'changed']);

const searchQuery = ref(null);
const searchInput = ref(null);

watch(searchQuery, () => {
  emit('search-query-changed', searchQuery.value);
});

const getOptionValue = (option) => {
  return option[props.optionValue];
};

const getOptionLabel = (option) => {
  if (!option) {
    return;
  }

  return option[props.optionLabel];
};

const selected = ref(props.multiple ? [] : null);

watch(
  () => props.modelValue,
  () => {
    if (props.multiple && !Array.isArray(props.modelValue)) {
      if (props.modelValue) {
        selected.value.push(props.modelValue);
      }
      return;
    }
    selected.value = props.modelValue;
  },
  { immediate: true }
);

const isFocused = ref(null);

const focus = () => {
  if (props.disabled) {
    return;
  }

  isFocused.value = true;

  if (props.searchable) {
    nextTick(() => {
      searchInput.value.focus();
    });
  }
};

watch(isFocused, () => {
  if (!isFocused.value) {
    searchQuery.value = null;
  }
});

const cachedOptions = ref([]);
const cacheOption = (value) => {
  const cachedOption = cachedOptions.value.find((option) => {
    return option[props.optionValue] === value;
  });

  if (cachedOption) {
    return;
  }

  const option = getOption(value);
  cachedOptions.value.push(option);
};

const select = (value) => {
  if (props.disabled) {
    return;
  }

  if (props.multiple && !isValueSelected(value)) {
    selected.value.push(value);
  }

  if (!props.multiple) {
    selected.value = value;
  }

  emitSelectedValue();
  cacheOption(value);

  if (!props.multiple) {
    isFocused.value = false;
  }
};

const unselect = (value) => {
  if (props.multiple) {
    selected.value = selected.value.filter((selectedValue) => selectedValue !== value);
  } else {
    selected.value = null;
  }

  emitSelectedValue();

  if (!props.multiple) {
    isFocused.value = false;
  }
};

const hasSelected = computed(() => {
  if (props.multiple) {
    return selected.value && selected.value.length > 0;
  } else {
    return selected.value !== null && selected.value !== undefined;
  }
});

const isValueSelected = (value) => {
  if (!hasSelected.value) {
    return false;
  }

  if (props.multiple) {
    return selected.value.indexOf(value) !== -1;
  } else {
    return selected.value === value;
  }
};

const toggle = (value) => {
  if (props.saving) {
    return;
  }

  if (isValueSelected(value)) {
    unselect(value);
  } else {
    select(value);
  }
};

const getOptions = (values) => {
  if (values instanceof Array) {
    return values.map((value) => {
      return getOption(value);
    });
  }

  return getOption(values);
};

const getOption = (value) => {
  const cachedOption = getOptionFromCached(value);

  if (cachedOption) {
    return cachedOption;
  }

  return props.options.find((option) => getOptionValue(option) === value);
};

const getOptionFromCached = (value) => {
  return cachedOptions.value.find((option) => getOptionValue(option) === value);
};

const emitSelectedValue = () => {
  emit('update:modelValue', selected.value);
  emit('changed', selected.value);
};

const getSelectedLabel = computed(() => {
  if (props.multiple) {
    return selected.value
      .map((value) => {
        const option = getOption(value);
        return getOptionLabel(option);
      })
      .join(', ');
  }

  return getOptionLabel(getOption(selected.value));
});

const container = ref(null);
onClickOutside(container, () => {
  isFocused.value = false;
});

const showErrorBorder = computed(() => {
  return vuelidate.value.$error;
});

const { width: containerWidth } = useElementSize(container);

let rules = {};

if (props.required) {
  rules['selected'] = {
    required,
  };
}

const vuelidate = useVuelidate(rules, { selected });
</script>
<template>
  <div
    id="app-select"
    ref="container"
    class="relative select-none"
  >
    <div
      v-if="!isFocused || !props.searchable"
      class="flex items-center h-[42px] w-full px-[12px] border border-[#CBD3E4] text-black-primary rounded-[6px] bg-white shadow-sm"
      :class="{
        'cursor-text': props.searchable,
        'cursor-default': !props.searchable,
        '!cursor-not-allowed': props.disabled,
        '!border-rose-400': showErrorBorder,
      }"
      @click="focus()"
    >
      <span v-if="hasSelected">
        <slot
          name="selected"
          :selected="getOptions(selected)"
        >
          {{ getSelectedLabel }}
        </slot>
      </span>
      <span
        v-else
        class="text-[#AEAEAE]"
        >{{ props.placeholder }}</span
      >
    </div>

    <input
      v-if="isFocused && props.searchable"
      ref="searchInput"
      v-model="searchQuery"
      name="search"
      type="text"
      class="h-[42px] w-full border border-[#CBD3E4] focus:border-[#AEAEAE] text-black-primary placeholder-[#AEAEAE] outline-none rounded-[6px] outline-none bg-white shadow-sm transition duration-100 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
      :class="{ '!placeholder-black-primary': hasSelected && !isFocused }"
      :placeholder="placeholderFocused"
      @click="focus()"
    />

    <div
      v-if="props.loading"
      class="absolute right-[30px] inset-y-[2px] bg-white flex items-center justify-center w-[30px]"
      @click="focus()"
    >
      <AppSpinner class="w-[20px] h-[20px]" />
    </div>

    <div
      class="absolute right-[2px] inset-y-[2px] bg-white flex items-center justify-center w-[30px]"
      @click="focus()"
    >
      <AppIcon
        class=""
        :width="10"
        :height="6"
        :icon="IconChevronDown"
      />
    </div>

    <div
      v-if="isFocused && !props.loading"
      class="z-10 w-[var(--containerWidth)] rounded-[6px] bg-white py-[18px] flex flex-col gap-y-[8px] border border-[#CBD3E4] shadow-xl max-h-60 overflow-y-auto scrollbar"
      :class="{
        'absolute bottom-[calc(100%-1px)] mb-[8px]': props.direction === 'top',
        'fixed mt-[8px]': props.direction === 'bottom',
      }"
      :style="{ '--containerWidth': containerWidth + 'px' }"
    >
      <div v-if="props.options?.length > 0">
        <div
          v-for="option in props.options"
          :key="getOptionValue(option)"
          class="py-[12px] px-[30px] hover:bg-[#FAFBFE] cursor-pointer flex select-none gap-x-[5px]"
          :class="{
            '!text-[#626AE9]': isValueSelected(getOptionValue(option)),
            'cursor-wait': props.saving,
          }"
          :data-checked="isValueSelected(getOptionValue(option))"
          @click="toggle(getOptionValue(option))"
        >
          <div class="grow">
            <slot
              name="default"
              :option="option"
            >
              {{ getOptionLabel(option) }}
            </slot>
          </div>
          <div class="self-center text-[#626AE9]">
            <AppIcon
              v-if="isValueSelected(getOptionValue(option))"
              :icon="IconCheck"
              :width="12"
              :height="11"
            />
          </div>
        </div>
      </div>
      <slot
        v-else
        name="notfound"
        :search-query="searchQuery"
      >
        <div class="px-[10px] text-gray-primary">Ничего не найдено</div>
      </slot>

      <slot name="last" />
    </div>
  </div>
</template>

<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 16px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  @apply bg-gray-200;
  /*@apply rounded-md border-2 border-white bg-indigo-50;*/
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-70;
}
</style>
