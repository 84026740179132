import { DateTime } from 'luxon';
import { formatMoney } from '@/use/useFormat.js';
import { useOperation } from '@/use/useOperation.js';
const { getDirectionTitle, getAccountTitle } = useOperation();

export const operationsColumns = [
  {
    label: '№',
    width: '100px',
    field: (operation) => {
      return operation.operationId;
    },
  },
  {
    label: 'Направление',
    field: (operation) => {
      return getDirectionTitle(operation.direction);
    },
  },
  {
    label: 'Статья',
    field: (operation) => {
      return operation.category.title;
    },
  },
  {
    label: 'Счет',
    field: (operation) => {
      return getAccountTitle(operation);
    },
  },
  {
    label: 'Сумма',
    field: (operation) => {
      return formatMoney(operation.amount);
    },
  },
  {
    label: 'Дата',
    field: (operation) => {
      return DateTime.fromISO(operation.createdAt).toFormat('d.LL.y HH:mm');
    },
  },
  {
    label: 'Автор',
    field: (operation) => {
      return operation.employee?.title;
    },
  },
  {
    label: 'Описание',
    field: (operation) => {
      return operation.description;
    },
  },
];
