<script setup>
/* global ymaps3 */
import { ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import AppInput from '@/components/AppInput.vue';

const props = defineProps({
  address: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['address-changed']);

const query = ref(null);
const isLoading = ref(false);

watch(
  () => props.address,
  () => {
    query.value = props.address;
  },
  {
    immediate: true,
  }
);

const addresses = ref([]);

const search = useDebounceFn((searchQuery) => {
  isLoading.value = true;

  ymaps3
    .suggest({ text: searchQuery, limit: 10 })
    .then((res) => {
      addresses.value = res;
    })
    .finally(() => {
      isLoading.value = false;
    });
}, 500);

const addressSelect = (address) => {
  query.value = address.value;
  emit('address-changed', address.value);
};
</script>

<template>
  <AppInput
    v-model="query"
    :loading="isLoading"
    placeholder="Введите адрес"
    :suggestions="addresses"
    @suggestion-query-updated="search"
    @suggestion-selected="addressSelect"
  >
    <template #suggestion="{ suggestion: address }">
      <div class="flex flex-col gap-y-[4px]">
        {{ address.value }}
      </div>
    </template>
  </AppInput>
</template>
