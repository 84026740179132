import StorageStatus from '@/enums/StorageStatus.js';

export const tabs = [
  {
    title: 'На складе',
    route: {
      name: 'storage.index.in-stock',
    },
  },
  {
    title: 'Выданы',
    route: {
      name: 'storage.index.released',
    },
  },
];
