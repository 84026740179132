<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import { useStocksQuery } from '@/query/useStockQuery.js';
import { useStorageQuery } from '@/query/useStorageQuery.js';
import AppSelect from '@/components/AppSelect.vue';
import { storageStockUpdate } from '@/api/storage.js';

const props = defineProps({
  storageId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const save = async () => {
  loading.value = true;

  try {
    await storageStockUpdate(props.storageId, {
      stockId: stockId.value,
      rackId: rackId.value,
      cellId: cellId.value,
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['storage'].indexOf(key) !== -1),
    });
    useToast().show('Место хранения сохранено');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const stockId = ref(null);
const rackId = ref(null);
const cellId = ref(null);

const { data: storage, isLoading: isStorageLoading } = useStorageQuery(
  { storageId: props.storageId },
  { select: (response) => response?.data?.data }
);

const { data: stocks, isLoading: isStocksLoading } = useStocksQuery({ select: (response) => response?.data?.data });

watch(
  storage,
  () => {
    if (!storage.value) {
      return;
    }

    stockId.value = storage.value.stockId;
    rackId.value = storage.value.rackId;
    cellId.value = storage.value.cellId;
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(stockId, () => {
  if (stockId.value) {
    return;
  }

  rackId.value = null;
  cellId.value = null;
});

const racks = computed(() => {
  if (!stockId.value) {
    return [];
  }

  const stock = stocks.value.find((stock) => stock.stockId === stockId.value);
  const racks = [...new Array(stock.rackCount)];

  return racks.map((index, value) => {
    return {
      rackId: value + 1,
    };
  });
});

const cells = computed(() => {
  if (!stockId.value) {
    return [];
  }

  const stock = stocks.value.find((stock) => stock.stockId === stockId.value);
  const cells = [...new Array(stock.cellCount)];

  return cells.map((index, value) => {
    return {
      cellId: value + 1,
    };
  });
});
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="isStocksLoading || isStorageLoading"
    @close="close()"
  >
    <template #title>Место хранения</template>

    <div class="flex flex-col gap-y-[32px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Выберите склад</div>
        <div>
          <AppSelect
            v-model="stockId"
            :options="stocks"
            option-label="title"
            option-value="stockId"
          />
        </div>
      </div>

      <div
        v-if="stockId"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Выберите стелаж</div>
        <div>
          <AppSelect
            v-model="rackId"
            :options="racks"
            option-label="rackId"
            option-value="rackId"
            :disabled="racks.length === 0"
          />
        </div>
      </div>

      <div
        v-if="stockId"
        class="flex flex-col gap-y-[12px]"
      >
        <div>Выберите ячейку</div>
        <div>
          <AppSelect
            v-model="cellId"
            :options="cells"
            option-label="cellId"
            option-value="cellId"
            :disabled="cells.length === 0"
          />
        </div>
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
