<script setup>
import AppModal from '@/components/AppModal.vue';
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { useToast } from '@/use/useToast.js';
import { orderRemove } from '@/api/order.js';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed', 'removed']);

const close = () => {
  emit('closed');
};

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const remove = async () => {
  loading.value = true;

  try {
    await orderRemove(props.orderId);
    await router.push({ name: 'order.show', params: { orderId: props.orderId } });
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['order'].indexOf(key) !== -1),
    });
    useToast().show('Заказ успешно удален');
    emit('removed');
  } catch (error) {
    loading.value = false;
    useToast().error(error?.response?.data?.message);
  }
};
</script>

<template>
  <AppModal
    :show="isShow"
    @close="close()"
  >
    <template #title>Удаление заказа</template>
    Вы точно хотите удалить заказ? <br /><br />Вместе с заказом будут удалены все платежи и хранения, которые ему
    принадлежат.
    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          red
          outline
          :disabled="loading"
          :loading="loading"
          @click="remove()"
          >Удалить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
