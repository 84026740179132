<template>
  <svg
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.21618 4.10002L1.80078 7.56164M1.80078 7.56164L5.21618 11.0233M1.80078 7.56164L12.5316 7.56164C14.558 7.56164 16.2008 9.2044 16.2008 11.2308V11.2308C16.2008 13.2573 14.558 14.9 12.5316 14.9L9.00078 14.9"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
