<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20039 14.4001H4.00038C3.11673 14.4001 2.40038 13.6837 2.40039 12.8001L2.40045 3.20009C2.40046 2.31644 3.1168 1.6001 4.00045 1.6001H11.2006C12.0843 1.6001 12.8006 2.31644 12.8006 3.2001V7.6001M13.6006 12.7717L11.9645 14.4001M11.9645 14.4001L10.4006 12.8453M11.9645 14.4001V10.4001M5.20064 4.8001H10.0006M5.20064 7.2001H10.0006M5.20064 9.6001H7.60064"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
