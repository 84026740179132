<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import AppTable from '@/components/AppTable.vue';
import { useClientQuery } from '@/query/useClientQuery.js';
import OrderStatus from '@/enums/OrderStatus.js';
import { formatMoney } from '@/use/useFormat.js';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const columns = [
  {
    label: 'Автомобиль',
    field: 'title',
  },
  {
    label: 'Сумма заказов',
    field: (row) => {
      const ordersAmount = row.orders.reduce((total, order) => {
        if (order.status !== OrderStatus.COMPLETED) {
          return total;
        }

        return total + order.price;
      }, 0);

      return formatMoney(ordersAmount);
    },
  },
  // {
  //   label: 'Действие',
  //   template: 'actions',
  // },
];

const { data: client, isLoading } = useClientQuery(
  { clientId: props.clientId, include: ['cars.mark', 'cars.model', 'cars.orders'] },
  { select: (response) => response?.data?.data }
);
</script>

<template>
  <div class="flex flex-col gap-[24px]">
    <div class="text-lg font-medium">Автомобили</div>
    <ClientShowCard>
      <AppTable
        col-class="!text-xs"
        row-class="!text-sm"
        :loading="isLoading"
        :data="client?.cars ?? []"
        :columns="columns"
      >
        <template #row-car="{ raw: car }">
          {{ car.title }} <span class="text-gray-primary">{{ car.regNumber }}</span>
        </template>

        <!--        <template #row-actions>-->
        <!--          <router-link-->
        <!--            class="text-violet-primary hover:underline"-->
        <!--            :to="{ name: 'order.new' }"-->
        <!--            >Новый заказ</router-link-->
        <!--          >-->
        <!--        </template>-->
      </AppTable>

      <!-- @todo сделать создание нового автомобиля -->
      <!--      <AppButton outline>Новый автомобиль</AppButton>-->
    </ClientShowCard>
  </div>
</template>
