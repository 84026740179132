<script setup>
import IconCopy from '@/components/Icons/IconCopy.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useClipboard } from '@/use/useClipboard.js';
import { computed, ref } from 'vue';
import { useBookingSettingsQuery } from '@/query/useBookingSettingsQuery.js';
import IconPencil from '@/components/Icons/IconPencil.vue';
import SettingsBookingSlugModal from '@/modules/settings/components/SettingsBookingSlugModal.vue';

const emit = defineEmits(['close']);

const { data: bookingSettings } = useBookingSettingsQuery({ select: (response) => response?.data?.data });

const bookingDomain = import.meta.env.VITE_BOOKING_URL;

const bookingUrl = computed(() => {
  return bookingDomain + '/' + bookingSettings.value?.slug + '/';
});

const copyToClipboard = () => {
  useClipboard().copyToClipboard(bookingUrl.value);
};

const slugModal = ref(false);

const openModal = () => {
  slugModal.value = true;
};

const closeModal = () => {
  slugModal.value = false;
  emit('close');
};
</script>

<template>
  <div class="mb-[12px]">Ссылка на вашу онлайн-запись</div>
  <div class="flex gap-[10px] items-center">
    <div>
      <AppIcon
        :width="17"
        :height="20"
        :icon="IconCopy"
        class="hover:text-red-notify cursor-pointer text-violet-primary"
        @click="copyToClipboard()"
      />
    </div>
    <div class="text-xl">{{ bookingUrl }}</div>
    <div>
      <AppIcon
        :icon="IconPencil"
        @click="openModal()"
        class="hover:text-violet-primary cursor-pointer"
      />
    </div>

    <SettingsBookingSlugModal
      v-if="slugModal"
      class="md:w-[894px]"
      @closed="closeModal()"
      @saved="closeModal()"
    />
  </div>
</template>
