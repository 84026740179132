<script setup>
import AuthLayout from '@/layouts/AuthLayout.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import { ref } from 'vue';
import AuthPhone from '@/modules/auth/components/AuthPhone.vue';
import { useYandexMetrika } from '@/use/useYandexMetrika.js';
import { useAuthStore } from '@/store/AuthStore.js';
import { useRouter } from 'vue-router';
import AuthCode from '@/modules/auth/components/AuthCode.vue';

const codeRequested = (dataId, dataMessengerId, dataPhone) => {
  authId.value = dataId;
  messengerId.value = dataMessengerId;
  phone.value = dataPhone;
  isCodeRequested.value = true;
};

const resetPhone = () => {
  authId.value = null;
  messengerId.value = null;
  isCodeRequested.value = false;
};

const yandexMetrika = useYandexMetrika();
const authStore = useAuthStore();
const router = useRouter();

const codeAccepted = async ({ messengerId }) => {
  if (authStore.employee?.employeeId) {
    yandexMetrika.setEmployeeId(authStore.employee.employeeId);
  }

  switch (true) {
    // case authStore.employee?.firstName === null:
    //   await router.push({ name: 'auth.set-name' });
    //   break;

    case authStore.employee?.telegramId === null:
      await router.push({ name: 'auth.connect-telegram' });
      break;

    default:
      await router.push({ name: 'home.index', force: true });
  }
};

const authId = ref(null);
const phone = ref(null);
const messengerId = ref(null);
const isCodeRequested = ref(false);
</script>

<template>
  <AuthLayout class="flex flex-col items-center">
    <div class="mt-[68px]">
      <AppIcon
        :icon="IconLogo"
        :width="69"
        :height="69"
        class="text-[#EC4899]"
      />
    </div>

    <AuthPhone
      v-if="!isCodeRequested"
      class="mt-[100px] md:mt-[124px] lg:mt-[146px]"
      @code-requested="codeRequested"
    />

    <AuthCode
      v-if="isCodeRequested"
      class="mt-[100px] md:mt-[124px] lg:mt-[146px]"
      :auth-id="authId"
      :messenger-id="messengerId"
      :phone="phone"
      @reset-phone="resetPhone"
      @code-accepted="codeAccepted"
    />
  </AuthLayout>
</template>
