import http from '@/libs/http.js';

export const getAnalyticsGeneral = (startTime, endTime, groupBy) =>
  http.get('/api/v2/analytics/general', {
    params: {
      startTime,
      endTime,
      groupBy,
    },
  });
