import { useQuery } from '@tanstack/vue-query';
import { getEmployee, getEmployees, getEmployeesAccruals, getEmployeesPayrolls } from '@/api/employee.js';
import { unref } from 'vue';

export const useEmployeeQuery = (employeeId, options = {}) => {
  const queryKey = ['employee', { employeeId }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getEmployee(unref(employeeId));
    },
    ...options,
  });
};

export const useEmployeesQuery = ({ roleId, statusId, assignedAsExecutor, include } = {}, options = {}) => {
  const queryKey = ['employee', { roleId, statusId, assignedAsExecutor, include }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getEmployees({
        roleId: unref(roleId),
        statusId: unref(statusId),
        assignedAsExecutor: unref(assignedAsExecutor),
        include,
      });
    },
    ...options,
  });
};

export function useEmployeesAccrualsQuery(options = {}) {
  const queryKey = ['employee', 'accruals'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getEmployeesAccruals();
    },
    ...options,
  });
}

export function useEmployeesPayrollsQuery(options = {}) {
  const queryKey = ['employee', 'payrolls', 'accruals'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getEmployeesPayrolls();
    },
    ...options,
  });
}
