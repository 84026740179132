<script setup>
import { computed, ref } from 'vue';
import { formatMoney } from '@/use/useFormat.js';
import { useOperationQuery } from '@/query/useOperationQuery.js';
import { useOperation } from '@/use/useOperation.js';
import OperationDirection from '@/enums/OperationDirection.js';
import { DateTime } from 'luxon';
import AppButton from '@/components/AppButton.vue';
import { useAuthStore } from '@/store/AuthStore.js';
import OperationRollbackModal from '@/modules/finance/components/OperationRollbackModal.vue';
import { tabs } from '@/modules/finance/finance-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { useOrderQuery } from '@/query/useOrderQuery.js';

const props = defineProps({
  operationId: {
    type: Number,
    required: true,
  },
});
const { hasAccess } = useAuthStore();

const include = ['employee', 'category', 'recipient', 'sender', 'shift'];
const { data: operation, isLoading } = useOperationQuery(
  { operationId: props.operationId, include },
  { select: (response) => response?.data?.data }
);

const { getDirectionTitle } = useOperation();

const account = computed(() => {
  let operationAccount = null;

  switch (operation.value.direction) {
    case OperationDirection.INCOME:
      operationAccount = operation.value.recipient;
      break;

    case OperationDirection.EXPENSE:
      operationAccount = operation.value.sender;
      break;
  }

  return operationAccount;
});

const authStore = useAuthStore();
const operationRollbackModal = ref(false);

const tabsWithCurrent = computed(() => {
  if (!operation.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Просмотр операции',
      route: {
        name: 'operation.show',
        params: {
          operationId: operation.value.operationId,
        },
      },
    },
  ];
});

const enabled = computed(() => {
  return !!operation.value?.meta?.order_id;
});

const orderId = computed(() => {
  return operation.value?.meta?.order_id;
});

const { data: order } = useOrderQuery({ orderId }, { enabled, select: (response) => response?.data?.data });
</script>

<template>
  <CabinetLayout
    :loading="isLoading"
    :hide-in-loading="true"
    :tabs="tabsWithCurrent"
  >
    <div class="w-[453px] flex flex-col gap-y-[24px]">
      <div class="border border-[#CBD3E4] rounded-[6px] px-[14px] py-[24px]">
        <div>Данные операции №{{ operation.operationId }}</div>

        <div class="grid grid-cols-[1fr_2fr] mt-[32px] gap-y-[24px]">
          <div class="text-xs text-gray-primary">Направление</div>
          <div class="flex gap-x-[10px]">
            <span>{{ getDirectionTitle(operation.direction) }}</span>
          </div>

          <div class="text-xs text-gray-primary">Статья</div>
          <div class="flex gap-x-[10px]">
            <span>{{ operation.category.title }}</span>
          </div>

          <div class="text-xs text-gray-primary">Счет</div>
          <div class="flex gap-x-[10px]">
            <span>{{ account.title }}</span>
          </div>

          <div class="text-xs text-gray-primary">Сумма</div>
          <div class="flex gap-x-[10px]">
            <span>{{ formatMoney(operation.amount) }}</span>
          </div>

          <template v-if="operation.shiftId">
            <div class="text-xs text-gray-primary">Смена</div>
            <div class="flex gap-x-[10px]">
              <span
                >{{ operation.shift.shiftId }} от DateTime.fromISO(operation.shift.createdAt).toFormat('d.LL.y
                HH:mm')</span
              >
            </div>
          </template>

          <template v-if="operation.employee">
            <div class="text-xs text-gray-primary">Автор</div>
            <div class="flex gap-x-[10px]">
              <span>{{ operation.employee.title }}</span>
            </div>
          </template>

          <div class="text-xs text-gray-primary">Дата</div>
          <div class="flex gap-x-[10px]">
            <span>{{ DateTime.fromISO(operation.createdAt).toFormat('d.LL.y HH:mm') }}</span>
          </div>

          <div class="text-xs text-gray-primary">Описание</div>
          <div class="flex gap-x-[10px]">
            <span
              v-if="order"
              class="text-violet-primary hover:underline"
              >Оплата заказа
              <router-link :to="{ name: 'order.show', params: { orderId: order.orderId } }">
                №{{ order.numberId }}
              </router-link></span
            >
            <span v-else>{{ operation.description }}</span>
          </div>

          <div
            v-if="hasAccess('delete-Operation')"
            class="col-span-2 flex justify-end"
          >
            <AppButton
              outline
              red
              @click="operationRollbackModal = true"
              >Отменить операцию</AppButton
            >
          </div>

          <OperationRollbackModal
            v-if="operationRollbackModal"
            :operation-id="operation.operationId"
            @closed="operationRollbackModal = false"
          />
        </div>
      </div>
    </div>
  </CabinetLayout>
</template>
