export const tabs = [
  {
    title: 'Сводный отчет',
    route: {
      name: 'report.general',
    },
  },
  {
    title: 'Отчет по услугам',
    route: {
      name: 'report.services',
    },
  },
];
