import { useQuery } from '@tanstack/vue-query';
import { getEmployeesAccrualsCalculate, getShift, getShifts } from '@/api/shift.js';
import { unref } from 'vue';

export function useShiftQuery({ shiftId, include } = {}, options = {}) {
  const queryKey = ['shift', 'employees', 'orders', shiftId, include];

  const defaultOptions = {
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
  };

  options = {
    ...defaultOptions,
    ...options,
  };

  return useQuery({
    queryKey,
    queryFn: () => {
      return getShift({ shiftId: unref(shiftId), include });
    },
    ...options,
  });
}

export function useShiftsQuery({ include, page, perPage } = {}, options = {}) {
  const queryKey = ['shift', 'employees', 'orders', page, perPage, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getShifts({ include, page: unref(page), perPage: unref(perPage) });
    },
    ...options,
  });
}

export function useShiftEmployeesAccrualsCalculateQuery(options = {}) {
  const queryKey = ['shift', 'order', 'employees', 'accruals', 'calculate'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getEmployeesAccrualsCalculate();
    },
    ...options,
  });
}
