<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import AppTable from '@/components/AppTable.vue';
import { useClientQuery } from '@/query/useClientQuery.js';
import { formatMoney } from '@/use/useFormat.js';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import OrderStatus from '@/enums/OrderStatus.js';
import router from '@/routes.js';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const columns = [
  {
    width: '100px',
    label: '№',
    field: 'numberId',
  },
  {
    label: 'Дата',
    template: 'date',
  },
  {
    label: 'Сумма',
    field: (row) => formatMoney(row.price),
  },
  {
    label: 'Автомобиль',
    field: (row) => {
      return row?.car?.title;
    },
  },
  {
    label: 'Статус',
    template: 'status',
  },
];

const { data: client, isLoading } = useClientQuery(
  { clientId: props.clientId, include: ['orders.car.mark', 'orders.car.model'] },
  { select: (response) => response?.data?.data }
);

const orders = computed(() => {
  if (!client.value?.orders) {
    return [];
  }

  const filteredOrders = client.value.orders.filter(
    (order) => order.status === OrderStatus.COMPLETED || order.status === OrderStatus.IN_PROGRESS
  );

  return filteredOrders.sort((a, b) => b.numberId - a.numberId);
});

const goToOrder = (order) => {
  switch (order.status) {
    case OrderStatus.COMPLETED:
      router.push({ name: 'order.show', params: { orderId: order.orderId } });
      break;

    case OrderStatus.IN_PROGRESS:
      router.push({ name: 'order.edit', params: { orderId: order.orderId } });
      break;
  }
};
</script>

<template>
  <div class="flex flex-col gap-[24px]">
    <div class="text-lg font-medium">Заказы</div>
    <ClientShowCard>
      <AppTable
        sticky-headers
        :data="orders"
        :columns="columns"
        col-class="!text-xs"
        row-class="cursor-pointer !text-sm"
        class="overflow-y-auto max-h-[200px]"
        @row-clicked="goToOrder"
      >
        <template #row-date="{ raw: order }">
          <span class="text-gray-primary">{{ DateTime.fromISO(order.createdAt).toFormat('d MMMM y') }}</span>
        </template>

        <template #row-status="{ raw: order }">
          <span
            v-if="order.status === OrderStatus.COMPLETED"
            class="text-green-primary"
            >Завершен</span
          >

          <span v-if="order.status === OrderStatus.IN_PROGRESS">В работе</span>
        </template>
      </AppTable>
    </ClientShowCard>
  </div>
</template>
