<template>
  <svg
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1144_4090)">
      <path
        d="M48.7133 58.1574C48.556 59.7089 48.1236 61.1849 47.4662 62.5366C45.4958 66.5916 41.5078 69.5231 36.8561 69.9794C32.6715 70.3901 28.8313 68.8417 26.1406 66.1447C25.3433 65.2667 24.6734 64.2612 28.5262 63.3769C36.6816 61.5689 44.2944 55.7074 47.8091 48.0899C51.3238 40.4709 49.5656 31.6795 44.2944 25.8196C36.095 17.0283 20.2812 21.1305 18.5231 33.4387C14.4234 28.1642 18.5231 21.7174 23.2093 18.2006C30.2372 13.5114 39.6097 14.6837 46.0509 19.9582C56.5935 28.7496 56.5935 43.9861 50.1522 54.4816C49.0781 56.4769 48.8045 57.4367 48.7117 58.159L48.7133 58.1574Z"
        fill="currentColor"
      />
      <path
        d="M11.0706 16.0449C15.0115 11.1685 19.6962 8.23699 25.5619 5.83419C28.6991 4.5675 29.5059 3.68631 30.2969 2.97193C31.8773 1.54001 34.0443 0.746945 36.3906 1.00186C38.1755 1.1954 39.7936 2.01364 41.0171 3.22999C42.2406 4.44633 43.0677 6.05921 43.2722 7.84518C29.6537 6.47934 13.8399 9.9962 9.15365 24.6474C7.98209 30.5073 8.56866 36.3687 10.9102 41.6432C5.05396 36.3687 5.63896 26.992 8.77467 19.8214C9.39583 18.572 10.157 17.3069 11.0706 16.0465V16.0449Z"
        fill="currentColor"
      />
      <path
        d="M29.1663 28.0618C28.4822 28.1625 27.3106 27.5756 27.8956 26.9903C33.7519 21.1304 41.9528 26.4049 44.881 32.2648C47.2241 37.5393 46.0525 43.4007 43.1244 48.6752C38.4381 56.8796 29.0672 59.8111 19.6962 58.0519C9.7403 55.7073 3.29748 48.0883 0.954346 38.7115C7.98216 52.1905 27.8972 62.1541 38.4397 46.3306C42.5394 39.8838 40.7829 30.5071 32.3696 28.5795C31.0927 28.2176 29.9746 28.0949 29.1678 28.0618H29.1663Z"
        fill="currentColor"
      />
      <path
        d="M69.9917 31.7046C70.4808 36.6864 67.485 41.053 63.1573 42.6533C61.7152 43.1867 60.1238 43.4133 58.4647 43.2512C56.5965 35.7801 56.01 28.161 50.7387 21.7158C43.1243 12.3391 30.8252 11.7522 20.2827 15.8559C30.8252 6.47923 48.3956 11.7537 55.7772 22.6017C57.301 22.2445 58.9444 22.1139 60.6113 22.2839C65.5334 22.7858 69.5089 26.7763 69.9917 31.7031V31.7046Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1144_4090">
        <rect
          width="69.0909"
          height="69.0909"
          fill="white"
          transform="translate(0.954346 0.95459)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
