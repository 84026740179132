<script setup>
import AppCalendar from '@/components/AppCalendar.vue';
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { onClickOutside } from '@vueuse/core';
import AppIcon from '@/components/AppIcon.vue';
import IconCross from '@/components/Icons/IconCross.vue';
import { useToast } from '@/use/useToast.js';

const props = defineProps({
  modelValue: {
    type: [String, null],
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Выберите дату',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: [String, null],
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const currentValue = computed(() => {
  if (props.modelValue) {
    return props.modelValue;
  }

  return DateTime.now().toISODate();
});

const selectDate = (selectedDate) => {
  emit('update:modelValue', selectedDate);
  calendarShow.value = false;
};

const calendarShow = ref(false);
const datepicker = ref(null);

onClickOutside(datepicker, () => {
  calendarShow.value = false;
});

const inputValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(newValue)) {
      emit('update:modelValue', newValue);
    }
  },
});
</script>

<template>
  <div
    ref="datepicker"
    class="relative"
  >
    <div class="relative">
      <input
        v-model="inputValue"
        class="input-class"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :data-test="dataTest"
        type="text"
        @click="calendarShow = true"
        @focus="calendarShow = true"
      />

      <AppIcon
        v-if="inputValue"
        :icon="IconCross"
        :height="20"
        :width="20"
        class="absolute top-1/2 -mt-[10px] right-[10px] cursor-pointer"
        @click="selectDate(null)"
      />
    </div>
    <div
      v-if="calendarShow"
      class="z-10 fixed mt-[8px] w-[379px]"
    >
      <AppCalendar
        :past-days-darken="false"
        :selected-date="currentValue"
        @selected-date-changed="selectDate"
      />
    </div>
  </div>
</template>

<style scoped>
.input-class {
  @apply w-full h-[42px] border-[#CBD3E4] focus:border-[#AEAEAE] text-black-primary placeholder-[#AEAEAE] rounded-[6px] outline-none bg-white shadow-sm transition duration-100 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50;
}
</style>
