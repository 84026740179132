<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2059 6.5V4.75C12.2059 4.28587 12.02 3.84075 11.689 3.51256C11.3581 3.18437 10.9092 3 10.4412 3H4.26471C3.79668 3 3.34782 3.18437 3.01687 3.51256C2.68592 3.84075 2.5 4.28587 2.5 4.75V15.25C2.5 15.7141 2.68592 16.1592 3.01687 16.4874C3.34782 16.8156 3.79668 17 4.26471 17H10.4412C10.9092 17 11.3581 16.8156 11.689 16.4874C12.02 16.1592 12.2059 15.7141 12.2059 15.25V13.5M6.91176 10H17.5M17.5 10L14.8529 7.375M17.5 10L14.8529 12.625"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
