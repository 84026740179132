import { formatPhone } from '@/use/useFormat.js';
import EmployeeRole from '@/enums/EmployeeRole.js';
import EmployeeStatus from '@/enums/EmployeeStatus.js';
import EmployeePayrollPeriod from '@/enums/EmployeePayrollPeriod.js';
import EmployeePayrollType from '@/enums/EmployeePayrollType.js';

export const useEmployee = () => {
  const employeeShortName = ({ firstName, lastName }) => {
    if (firstName || lastName) {
      if (typeof firstName === 'string' && firstName.length > 0 && lastName) {
        firstName = firstName.substring(0, 1) + '.';
      }

      return [firstName, lastName].join(' ');
    }

    return null;
  };

  const employeeShortTitle = ({ firstName, lastName, phone }) => {
    const shortName = employeeShortName({ firstName, lastName });

    if (shortName) {
      return shortName;
    }

    return formatPhone(phone);
  };

  const employeeRoleTitle = (roleId) => {
    switch (roleId) {
      case EmployeeRole.OWNER:
        return 'Владелец';

      case EmployeeRole.MANAGER:
        return 'Управляющий';

      case EmployeeRole.ADMIN:
        return 'Администратор';

      case EmployeeRole.EMPLOYEE:
        return 'Сотрудник';
    }
  };

  const employeeStatusTitle = (statusId) => {
    switch (statusId) {
      case EmployeeStatus.WORKING:
        return 'Работает';

      case EmployeeStatus.FIRED:
        return 'Уволен';
    }
  };

  const employeePayrollPeriodTitle = (period) => {
    switch (period) {
      case EmployeePayrollPeriod.SHIFT:
        return 'Каждую смену';

      case EmployeePayrollPeriod.MONTH:
        return 'Раз в месяц';
    }
  };

  const employeePayrollTypeTitle = (type) => {
    switch (type) {
      case EmployeePayrollType.PERCENT:
        return 'Процент от выработки';

      case EmployeePayrollType.FIXED:
        return 'Фиксированная';

      case EmployeePayrollType.PERCENT_AND_FIXED:
        return 'Фиксированная и процент от выработки';

      case EmployeePayrollType.PERCENT_OR_FIXED:
        return 'Фиксированная или процент от выработки (что больше)';
    }
  };

  return {
    employeeShortTitle,
    employeeShortName,
    employeeRoleTitle,
    employeeStatusTitle,
    employeePayrollPeriodTitle,
    employeePayrollTypeTitle,
  };
};
