<script setup>
import { ref, watch } from 'vue';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import AppModal from '@/components/AppModal.vue';
import { useCompanyQuery } from '@/query/useCompanyQuery.js';
import { companyUpdate } from '@/api/company.js';
import { useToast } from '@/use/useToast.js';
import { CompanyFormType, getOptions as formTypeOptions } from '@/enums/CompanyFormType.js';
import AppSelect from '@/components/AppSelect.vue';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  companyId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closed']);

const title = ref(null);
const type = ref(null);
const inn = ref(null);
const kpp = ref(null);
const ogrn = ref(null);
const address = ref(null);
const directorTitle = ref(null);
const phone = ref(null);
const bic = ref(null);
const correspondentAccount = ref(null);
const beneficiaryAccount = ref(null);

const { data: company } = useCompanyQuery(
  { companyId: props.companyId },
  { select: (response) => response?.data?.data }
);

watch(
  company,
  () => {
    title.value = company.value.title;
    type.value = company.value.type;
    inn.value = company.value.inn;
    kpp.value = company.value.kpp;
    ogrn.value = company.value.ogrn;
    address.value = company.value.address;
    directorTitle.value = company.value.directorTitle;
    phone.value = company.value.phone;
    bic.value = company.value.bic;
    correspondentAccount.value = company.value.correspondentAccount;
    beneficiaryAccount.value = company.value.beneficiaryAccount;
  },
  {
    deep: true,
    immediate: true,
  }
);

const loading = ref(false);
const queryClient = useQueryClient();

const save = async () => {
  try {
    await companyUpdate(props.companyId, {
      title: title.value,
      type: type.value,
      inn: inn.value,
      kpp: kpp.value,
      ogrn: ogrn.value,
      address: address.value,
      directorTitle: directorTitle.value,
      phone: phone.value,
      bic: bic.value,
      correspondentAccount: correspondentAccount.value,
      beneficiaryAccount: beneficiaryAccount.value,
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['client', 'company'].includes(key)),
    });
    useToast().show('Данные обновлены');
    emit('closed');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>

<template>
  <AppModal
    :show="true"
    class="md:!w-[1028px]"
  >
    <div class="grid grid-cols-3 gap-x-[12px] gap-y-[24px]">
      <div class="flex flex-col gap-[12px]">
        <div>Наименование</div>
        <div><AppInput v-model="title" /></div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>Тип организации</div>
        <div>
          <AppSelect
            v-model="type"
            :options="formTypeOptions()"
          />
        </div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>ИНН</div>
        <div><AppInput v-model="inn" /></div>
      </div>

      <div
        v-if="type === CompanyFormType.LEGAL"
        class="flex flex-col gap-[12px]"
      >
        <div>КПП</div>
        <div><AppInput v-model="kpp" /></div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>{{ type === CompanyFormType.INDIVIDUAL ? 'ОГРНИП' : 'ОГРН' }}</div>
        <div><AppInput v-model="ogrn" /></div>
      </div>

      <div class="flex flex-col gap-[12px] col-span-2">
        <div>Адрес</div>
        <div><AppInput v-model="address" /></div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>ФИО Руководителя</div>
        <div><AppInput v-model="directorTitle" /></div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>Телефон</div>
        <div>
          <AppInput
            v-model="phone"
            mask="phone"
          />
        </div>
      </div>

      <div class="col-start-1 flex flex-col gap-[12px]">
        <div>БИК</div>
        <div><AppInput v-model="bic" /></div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>Корр. счет</div>
        <div><AppInput v-model="correspondentAccount" /></div>
      </div>

      <div class="flex flex-col gap-[12px]">
        <div>Расчетный счет</div>
        <div><AppInput v-model="beneficiaryAccount" /></div>
      </div>
    </div>

    <template #actions>
      <div class="flex gap-[12px]">
        <AppButton
          outline
          @click="emit('closed')"
          >Отмена</AppButton
        >
        <AppButton @click="save">Сохранить</AppButton>
      </div>
    </template>
  </AppModal>
</template>
