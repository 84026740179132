<script setup>
import AuthLayout from '@/layouts/AuthLayout.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import { switchBusiness } from '@/api/auth.js';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/AuthStore.js';
import AppScrollable from '@/components/AppScrollable.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import IconPreloader from '@/components/Icons/IconPreloader.vue';
import { useToast } from '@/use/useToast.js';
import EmployeeStatus from '@/enums/EmployeeStatus.js';

const queryClient = useQueryClient();

const loading = ref(false);
const selectedBusinessId = ref(null);
const router = useRouter();
const authStore = useAuthStore();

const selectBusiness = async (businessId) => {
  selectedBusinessId.value = businessId;
  loading.value = true;

  try {
    await authStore.businessSwitch(businessId);
    await switchBusiness(businessId);
    await authStore.refetchAuth();
    await queryClient.resetQueries();

    if (authStore.employee?.firstName === null) {
      return await router.push({ name: 'auth.set-name' });
    }

    await router.push({ name: 'home.index' });
  } catch (error) {
    if (error.response?.data?.message) {
      useToast().error(error.response.data.message);
    } else {
      throw error;
    }
    selectedBusinessId.value = null;
    loading.value = false;
  }
};

if (authStore.employee?.businesses?.length === 1) {
  const business = authStore.employee?.businesses[0];
  selectBusiness(business.businessId);
}

const availableBusinesses = computed(() => {
  if (!authStore.employee.businesses?.length) {
    return [];
  }

  return authStore.employee.businesses.filter(
    (business) => business.businessEmployee.statusId === EmployeeStatus.WORKING
  );
});
</script>

<template>
  <AuthLayout class="flex flex-col items-center">
    <div class="mt-[68px]">
      <AppIcon
        :icon="IconLogo"
        :width="69"
        :height="69"
        class="text-[#EC4899]"
      />
    </div>

    <div
      v-if="authStore.employee"
      class="flex flex-col px-[16px] md:px-0 w-full md:w-[410px] mt-[100px] md:mt-[124px] lg:mt-[146px]"
    >
      <div v-if="availableBusinesses">
        <div class="text-xl lg:text-2xl font-medium text-center">Выберите филиал</div>

        <AppScrollable class="mt-[24px] !h-[400px]">
          <div class="flex flex-col gap-y-[8px]">
            <div
              v-for="business in availableBusinesses"
              :key="business.businessId"
              class="border border-[#CBD3E4] rounded-[6px] p-[10px] bg-white hover:bg-[#FAFBFE] cursor-pointer"
              @click="selectBusiness(business.businessId)"
            >
              <div
                class="flex justify-between"
                :class="{ 'opacity-20': loading && business.businessId !== selectedBusinessId }"
              >
                <div class="flex flex-col gap-y-[4px]">
                  <div class="text-sm">{{ business.title }}</div>
                  <div class="text-xs text-gray-primary">
                    <div>{{ business.city }}</div>
                    <div>{{ business.address }}</div>
                  </div>
                </div>
                <div class="flex items-center pr-[12px]">
                  <AppIcon
                    v-if="business.businessId === selectedBusinessId"
                    :icon="IconPreloader"
                  />
                </div>
              </div>
            </div>
          </div>
        </AppScrollable>
        <div class="mt-[32px]">
          <router-link
            v-if="authStore.isOwner()"
            :to="{ name: 'auth.business-create' }"
            class="text-sm text-violet-primary font-medium text-center cursor-pointer"
          >
            Регистрация нового филиала
          </router-link>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-y-[20px] items-center"
      >
        <div class="text-xl font-medium text-center">У вас нет доступа ни к одной компании!</div>
        <div class="font-light text-center">
          Если вы сотрудник, то свяжитесь с вашим руководителем и сообщите, чтобы он дал вам доступ к компании. Если вы
          руководитель и впервые у нас на сервисе - создайте компанию.
        </div>
        <AppButton
          class="mt-[26px]"
          @click="router.push({ name: 'auth.business-create' })"
          >Создать компанию</AppButton
        >
      </div>
    </div>
  </AuthLayout>
</template>
