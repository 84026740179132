<script setup>
import SettingsBookingPhotoModal from '@/modules/settings/components/SettingsBusinessPhotoModal.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  business: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const business = computed(() => {
  return props.business;
});

const photoModal = ref(false);

const openModal = () => {
  photoModal.value = true;
};

const closeModal = () => {
  photoModal.value = false;
  emit('close');
};
</script>

<template>
  <div class="flex flex-col gap-[12px]">
    <div>
      <label for="app-select">Фотография</label>
    </div>
    <div class="flex gap-[50px] items-center">
      <div class="w-[100px] h-[100px] bg-gray-secondary rounded-full flex items-center justify-center">
        <img
          class="rounded-full"
          :src="business.photo"
          v-if="business && business.photo"
        />
        <div
          v-else
          class="text-sm text-white"
        >
          нет фото
        </div>
      </div>
      <div>
        <span
          class="text-violet-primary cursor-pointer hover:underline underline-offset-2"
          @click="openModal()"
          >Изменить фотографию</span
        >
      </div>
    </div>

    <SettingsBookingPhotoModal
      v-if="photoModal"
      class="md:w-[894px]"
      @closed="closeModal()"
      @saved="closeModal()"
    />
  </div>
</template>
