<script setup>
import AppSelect from '@/components/AppSelect.vue';
import StorageType from '@/enums/StorageType.js';
import { computed, ref, unref } from 'vue';
import StorageSeasonality from '@/enums/StorageSeasonality.js';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import useVuelidate from '@vuelidate/core';
import { storeStorageItem } from '@/api/storage.js';
import { useToast } from '@/use/useToast.js';
import { useQueryClient } from '@tanstack/vue-query';
import TireBrandSelect from '@/modules/order/components/TireBrandSelect.vue';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  serviceId: {
    type: Number,
    required: true,
  },
  carClass: {
    type: String,
    required: true,
  },
  wheelDiameter: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['cancel', 'created']);

const storageTypes = [
  {
    label: 'Хранение в сборе',
    value: StorageType.TIRES_WITH_DISKS,
  },
  {
    label: 'Шины',
    value: StorageType.TIRES,
  },
  {
    label: 'Диски',
    value: StorageType.DISKS,
  },
];

const storageCounts = computed(() => {
  const counts = [];

  for (let count = 1; count <= 4; count++) {
    counts.push({
      label: count + ' шт.',
      value: count,
    });
  }

  return counts;
});

const wheelDiameters = computed(() => {
  const diameters = [];

  for (let diameter = 13; diameter <= 22; diameter++) {
    diameters.push({
      label: 'R' + diameter,
      value: diameter,
    });
  }

  return diameters;
});

const tireWidths = computed(() => {
  const widths = [];

  for (let width = 115; width <= 355; width += 5) {
    widths.push({
      label: width,
      value: width,
    });
  }

  return widths;
});

const tireHeights = computed(() => {
  const heights = [];

  for (let height = 20; height <= 100; height += 5) {
    heights.push({
      label: height,
      value: height,
    });
  }

  return heights;
});

const tireSeasonalities = [
  {
    label: 'Всесезонные',
    value: StorageSeasonality.ALL_SEASON,
  },
  {
    label: 'Летние',
    value: StorageSeasonality.SUMMER,
  },
  {
    label: 'Зимние шипованные',
    value: StorageSeasonality.WINTER_STUDDED,
  },
  {
    label: 'Зимние нешипованные',
    value: StorageSeasonality.WINTER_STUDLESS,
  },
];

const tireDepths = computed(() => {
  const depths = [];

  for (let i = 1; i <= 12; i += 1) {
    depths.push({
      label: i + 'мм',
      value: i,
    });
  }

  return depths;
});

const storageType = ref(null);
const storageCount = ref(null);
const tireWidth = ref(null);
const tireHeight = ref(null);
const tireSeasonality = ref(null);
const tireDepth = ref(null);
const tireBrand = ref(null);
const tireModel = ref(null);
const tireDot = ref(null);
const diskModel = ref(null);

const isTypeWheels = computed(() => {
  return storageType.value === StorageType.TIRES_WITH_DISKS || storageType.value === StorageType.TIRES;
});

const isTypeDisks = computed(() => {
  return storageType.value === StorageType.DISKS;
});

const vuelidate = await useVuelidate();
const loading = ref(false);
const queryClient = useQueryClient();

const brandSelected = (brand) => {
  tireBrand.value = brand.title;
};

const saveStorage = async () => {
  if (!(await vuelidate.value.$validate())) {
    return false;
  }

  loading.value = true;

  try {
    await storeStorageItem({
      orderId: props.orderId,
      serviceId: props.serviceId,
      carClass: props.carClass,
      storageType: unref(storageType),
      storageCount: unref(storageCount),
      wheelDiameter: props.wheelDiameter,
      tireWidth: unref(tireWidth),
      tireHeight: unref(tireHeight),
      tireSeasonality: unref(tireSeasonality),
      tireDepth: unref(tireDepth),
      tireBrand: unref(tireBrand),
      tireModel: unref(tireModel),
      tireDot: unref(tireDot),
      diskModel: unref(diskModel),
    });

    emit('created');
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['order', 'storage'].indexOf(key) !== -1),
    });
    loading.value = false;
  }
};
</script>

<template>
  <div class="flex flex-col gap-y-[21px]">
    <div>Новое хранение</div>
    <div class="grid grid-cols-2 gap-[12px]">
      <div class="last:col-span-2">
        <AppSelect
          v-model="storageType"
          :options="storageTypes"
          placeholder="Выберите тип"
          required
        />
      </div>

      <div v-if="storageType">
        <AppSelect
          v-model="storageCount"
          :options="storageCounts"
          placeholder="Количество"
          required
        />
      </div>

      <div v-if="isTypeWheels">
        <AppSelect
          v-model="tireWidth"
          :options="tireWidths"
          placeholder="Ширина"
          required
        />
      </div>

      <div v-if="isTypeWheels">
        <AppSelect
          v-model="tireHeight"
          :options="tireHeights"
          placeholder="Высота"
          required
        />
      </div>

      <div v-if="isTypeWheels">
        <AppSelect
          v-model="tireSeasonality"
          :options="tireSeasonalities"
          placeholder="Сезонность"
          required
        />
      </div>

      <div v-if="isTypeWheels">
        <AppSelect
          v-model="tireDepth"
          :options="tireDepths"
          placeholder="Остаток"
          required
        />
      </div>

      <div v-if="isTypeWheels">
        <TireBrandSelect
          required
          @brand-selected="brandSelected"
        />
      </div>

      <div v-if="isTypeWheels">
        <AppInput
          v-model="tireModel"
          placeholder="Модель шины"
        />
      </div>

      <div v-if="isTypeWheels">
        <AppInput
          v-model="tireDot"
          placeholder="DOT"
          required
        />
      </div>

      <div v-if="isTypeDisks">
        <AppInput
          v-model="diskModel"
          required
          placeholder="Модель дисков"
        />
      </div>
    </div>

    <div class="flex justify-end gap-x-[12px]">
      <AppButton
        :disabled="loading || !storageType"
        :loading="loading"
        @click="saveStorage()"
        >Добавить</AppButton
      >

      <AppButton
        outline
        @click="emit('cancel')"
      >
        Отмена
      </AppButton>
    </div>
  </div>
</template>
