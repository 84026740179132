<script setup>
const props = defineProps({
  icon: {
    type: [String, Object],
    required: true,
  },
  width: {
    type: Number,
    default: 18,
  },
  height: {
    type: Number,
    default: 18,
  },
  color: {
    type: String,
    default: '#626AE9',
  },
});
</script>

<template>
  <component
    :is="props.icon"
    :width="props.width"
    :height="props.height"
  ></component>
</template>
