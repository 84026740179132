import http from '@/libs/http.js';

export const getBookings = ({ startTime, endTime, clientId, sort, order, limit } = {}) => {
  return http.get('/api/v2/booking', {
    params: {
      startTime,
      endTime,
      clientId,
      sort,
      order,
      limit,
    },
  });
};

export const storeBooking = ({
  intervals,
  clientId,
  clientFirstName,
  clientLastName,
  clientPhone,
  clientSource,
  note,
}) => {
  return http.post('/api/v2/booking', {
    intervals,
    clientId,
    clientFirstName,
    clientLastName,
    clientPhone,
    clientSource,
    note,
  });
};

export const deleteBooking = (bookingId) => {
  return http.delete('/api/v2/booking', {
    params: {
      bookingId,
    },
  });
};

export const getCountByDays = (startTime, endTime) => {
  return http.get('/api/v1/booking/count-by-days', {
    params: {
      startTime,
      endTime,
    },
  });
};
