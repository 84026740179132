const INDIVIDUAL = 'individual';
const LEGAL = 'legal';

export const CompanyFormType = {
  INDIVIDUAL,
  LEGAL,
};

export const getLegalFormTypeLabel = (formType) => {
  switch (formType) {
    case INDIVIDUAL:
      return 'Индивидуальный предприниматель';

    case LEGAL:
      return 'Юридическое лицо';

    default:
      throw 'Неизвестная форма компании';
  }
};

export const getOptions = () => {
  return Object.values(CompanyFormType).map((type) => {
    return {
      label: getLegalFormTypeLabel(type),
      value: type,
    };
  });
};
