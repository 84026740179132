<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import AppTable from '@/components/AppTable.vue';
import { useStoragesQuery } from '@/query/useStorageQuery.js';
import { computed } from 'vue';
import { useStorage } from '@/use/useStorage.js';
import router from '@/routes.js';
const { getItemTypeTitle, getItemParametersTitle, getStatusChangedAt, getStatusTitle } = useStorage();

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const columns = [
  {
    label: 'Наименование',
    template: 'title',
  },
  {
    label: 'Статус',
    template: 'status',
  },
];

const { data: storages, isLoading } = useStoragesQuery(
  { clientId: props.clientId, include: ['items'] },
  { select: (response) => response?.data?.data }
);

const sortedStorages = computed(() => {
  if (!storages.value) {
    return [];
  }

  const sortedStorages = [...storages.value];

  return sortedStorages.sort((a, b) => b.storageID - a.storageID);
});
</script>

<template>
  <div class="flex flex-col gap-[24px]">
    <div class="text-lg font-medium">Хранения</div>
    <ClientShowCard>
      <AppTable
        :data="sortedStorages"
        :columns="columns"
        col-class="!text-xs"
        row-class="cursor-pointer !text-sm"
        @row-clicked="(storage) => router.push({ name: 'storage.show', params: { storageId: storage.storageId } })"
      >
        <template #row-title="{ raw: storage }">
          <div class="flex flex-wrap gap-y-[4px] gap-x-[12px]">
            <template
              v-for="storageItem in storage.items"
              :key="storageItem.itemId"
            >
              <div class="flex gap-x-[12px]">
                <span>{{ getItemTypeTitle(storageItem) }} {{ storageItem.count }} шт.</span>
                <span class="text-gray-primary">{{ getItemParametersTitle(storageItem) }}</span>
              </div>
            </template>
          </div>
        </template>

        <template #row-status="{ raw: storage }">
          <div class="flex gap-x-[12px] items-center">
            <span>{{ getStatusTitle(storage) }}</span>
            <span class="text-gray-primary">{{ getStatusChangedAt(storage) }}</span>
          </div>
        </template>
      </AppTable>
    </ClientShowCard>
  </div>
</template>
