<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none">
      <path
        d="M7.00002 16.2C7.00002 16.2 12.6348 11.1913 12.6348 7.43478C12.6348 4.32278 10.112 1.8 7.00002 1.8C3.88801 1.8 1.36523 4.32278 1.36523 7.43478C1.36523 11.1913 7.00002 16.2 7.00002 16.2Z"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M8.80025 7.20012C8.80025 8.19423 7.99436 9.00012 7.00025 9.00012C6.00613 9.00012 5.20025 8.19423 5.20025 7.20012C5.20025 6.206 6.00613 5.40012 7.00025 5.40012C7.99436 5.40012 8.80025 6.206 8.80025 7.20012Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
