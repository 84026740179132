export const tabs = [
  {
    title: 'Новый заказ',
    route: {
      name: 'order.new',
    },
  },
  {
    title: 'Заказы',
    route: {
      name: 'order.index',
    },
  },
];
