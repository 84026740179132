<script setup>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  innerClass: {
    type: String,
    default: '',
  },
  direction: {
    type: String,
    default: 'bottom',
  },
});

const show = ref(false);
const dropdown = ref(null);

onClickOutside(dropdown, () => {
  show.value = false;
});

const toggle = () => {
  show.value = !show.value;
};

const innerClasses = computed(() => {
  let classes = props.innerClass;

  if (props.direction === 'top') {
    classes = classes + ' absolute bottom-[calc(100%-1px)] mb-[8px]';
  } else {
    classes = classes + ' fixed mt-[8px]';
  }

  return classes;
});
</script>

<template>
  <div
    ref="dropdown"
    class="relative select-none"
  >
    <div @click="toggle()">
      <slot :is-show="show" />
    </div>

    <div
      v-if="show"
      class="absolute bg-white rounded-[6px] border border-[#CBD3E4] drop-shadow-md py-[8px] max-h-[300px] overflow-y-auto"
      :class="innerClasses"
    >
      <slot name="inner" />
    </div>
  </div>
</template>
