<template>
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58317 17H3.58317C2.4786 17 1.58317 16.1045 1.58318 15L1.58325 3.00002C1.58326 1.89545 2.47869 1.00003 3.58325 1.00003H12.5835C13.688 1.00003 14.5835 1.89546 14.5835 3.00003V8.50003M10.5835 14.1667L12.4168 16L16.4168 11.9999M5.08349 5.00003H11.0835M5.08349 8.00003H11.0835M5.08349 11H8.08349"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
