import { useQuery } from '@tanstack/vue-query';
import { getBookings } from '@/api/booking.js';
import { unref } from 'vue';

export const useBookingQuery = ({ startTime, endTime, clientId, sort, limit } = {}, options = {}) => {
  const queryKey = ['booking', { startTime, endTime, clientId, sort, limit }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getBookings({ startTime: unref(startTime), endTime: unref(endTime), clientId, sort, limit });
    },
    ...options,
  });
};
