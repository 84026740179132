<script setup>
import { computed, ref, watch } from 'vue';
import AppButton from '@/components/AppButton.vue';

const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  optionValue: {
    type: String,
    default: 'value',
  },
  optionLabel: {
    type: String,
    default: 'label',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'changed']);

const selected = ref(props.multiple ? [] : null);

watch(
  () => props.modelValue,
  () => {
    selected.value = props.modelValue;
  },
  { immediate: true }
);

const getOptionValue = (option) => {
  return option[props.optionValue];
};

const getOptionLabel = (option) => {
  return option[props.optionLabel];
};

const select = (value) => {
  if (props.multiple && !isValueSelected(value)) {
    selected.value.push(value);
  }

  if (!props.multiple) {
    selected.value = value;
  }

  emitSelectedValue();
};

const unselect = (value) => {
  if (props.multiple) {
    selected.value = selected.value.filter((selectedValue) => selectedValue !== value);
  } else {
    selected.value = null;
  }

  emitSelectedValue();
};

const isSelected = computed(() => {
  if (props.multiple) {
    return selected.value && selected.value.length > 0;
  } else {
    return selected.value !== null && selected.value !== undefined;
  }
});

const isValueSelected = (value) => {
  if (props.multiple) {
    return selected.value.indexOf(value) !== -1;
  } else {
    return selected.value && selected.value === value;
  }
};

const toggle = (value) => {
  if (isValueSelected(value) && props.multiple) {
    unselect(value);
  } else {
    select(value);
  }
};

const getOption = (value) => {
  return props.options.find((option) => getOptionValue(option) === value);
};

const emitSelectedValue = () => {
  emit('update:modelValue', selected.value);
  emit('changed', selected.value);
};

const getSelectedLabel = computed(() => {
  if (selected.value && props.multiple) {
    return selected.value
      .map((value) => {
        const option = getOption(value);
        return getOptionLabel(option);
      })
      .join(', ');
  }

  return getOptionLabel(getOption(selected.value));
});
</script>

<template>
  <div class="flex flex-wrap gap-[12px] select-none">
    <AppButton
      v-for="option in props.options"
      :key="getOptionValue(option)"
      class="grow"
      :outline="!isValueSelected(getOptionValue(option))"
      @click="toggle(getOptionValue(option))"
    >
      <slot
        name="option"
        :option="option"
      >
        <span>{{ getOptionLabel(option) }}</span>
      </slot>
    </AppButton>
  </div>
</template>
