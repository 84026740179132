<script setup>
import AppIcon from '@/components/AppIcon.vue';
import IconChevronLeft from '@/components/Icons/IconChevronLeft.vue';
import IconChevronRight from '@/components/Icons/IconChevronRight.vue';
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';

const props = defineProps({
  selectedDate: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['selected-date-changed']);

const selectedDateObject = computed(() => {
  return DateTime.fromISO(props.selectedDate);
});

const previousDayObject = computed(() => {
  return selectedDateObject.value.minus({ day: 1 });
});

const nextDayObject = computed(() => {
  return selectedDateObject.value.plus({ day: 1 });
});

const router = useRouter();

const selectPreviousDay = () => {
  return emit('selected-date-changed', previousDayObject.value.toISODate());
};

const selectNextDay = () => {
  return emit('selected-date-changed', nextDayObject.value.toISODate());
};
</script>

<template>
  <div class="flex items-center uppercase font-medium gap-x-[24px]">
    <AppIcon
      :icon="IconChevronLeft"
      class="text-gray-primary cursor-pointer"
      :width="7"
      :height="14"
      data-test="previous-day-link"
      @click="selectPreviousDay()"
    />

    <div class="flex gap-x-[12px]">
      <div
        class="text-gray-primary cursor-pointer"
        @click="selectPreviousDay()"
      >
        {{ previousDayObject.toFormat('EEE') }}
      </div>
      <div>{{ selectedDateObject.toFormat('EEE') }}</div>
      <div
        class="text-gray-primary cursor-pointer"
        @click="selectNextDay"
      >
        {{ nextDayObject.toFormat('EEE') }}
      </div>
    </div>

    <AppIcon
      :icon="IconChevronRight"
      :width="7"
      :height="14"
      class="text-gray-primary cursor-pointer"
      @click="selectNextDay"
    />
  </div>
</template>
