import { createApp } from 'vue';
// import './tailwind.css';
import '@/assets/scss/general.scss';
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';

// Pinia
import { createPinia } from 'pinia';

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// Luxon
import { Settings, DateTime } from 'luxon';

// Toast
import Toaster from '@meforma/vue-toaster';

// Libraries
import router from './routes.js';
import { useChat } from '@/use/useChat.js';

import { createYmaps } from 'vue-yandex-maps';

Settings.defaultZone = 'Europe/Moscow';

const app = createApp(App);
app.use(createPinia());
app.use(VueAxios, axios);
Settings.defaultLocale = 'ru';
app.config.globalProperties.$dateTime = DateTime;
app.use(Toaster);
app.use(Vue3ProgressPlugin);

app.use(VueQueryPlugin);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: '2.0.0',
    app,
    dsn: 'https://bb135840e19c4c4ebfc61c9b288acdf6@o4504101124440064.ingest.sentry.io/4504157717594112',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['https://mors.pro', 'https://app.mors.pro', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });

  // Init chat
  useChat().init();
}

// Яндекс.Карты
app.use(
  createYmaps({
    apikey: import.meta.env.VITE_API_YANDEX_MAP_KEY,
  })
);

app.use(router);
app.mount('#app');

app.progresses = [];

export { app };
