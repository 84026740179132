<script setup>
import ClientShowCard from '@/modules/client/components/ClientShowCard.vue';
import AppIcon from '@/components/AppIcon.vue';
import IconCoinHand from '@/components/Icons/IconCoinHand.vue';
import { computed } from 'vue';
import { formatMoney, formatSeconds } from '@/use/useFormat.js';
import { useClientStatsQuery } from '@/query/useClientQuery.js';
import IconReceipt from '@/components/Icons/IconReceipt.vue';
import IconBag from '@/components/Icons/IconBag.vue';
import IconClockForward from '@/components/Icons/IconClockForward.vue';
import AppSkeleton from '@/components/AppSkeleton.vue';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const { data: stats, isLoading } = useClientStatsQuery(
  { clientId: props.clientId },
  { select: (response) => response?.data?.data }
);

const statsCards = computed(() => {
  return [
    {
      icon: IconCoinHand,
      title: 'Общая сумма заказов',
      value: formatMoney(stats.value?.ordersAmountTotal),
    },
    {
      icon: IconReceipt,
      title: 'Средний чек',
      value: stats.value?.ordersTotal ? formatMoney(stats.value?.ordersAmountTotal / stats.value?.ordersTotal) : '—',
    },
    {
      icon: IconBag,
      title: 'Количество заказов',
      value: stats.value?.ordersTotal,
    },
    {
      icon: IconClockForward,
      title: 'Среднее время между заказами',
      value: stats.value?.ordersBetweenAvgSeconds ? formatSeconds(stats.value?.ordersBetweenAvgSeconds) : '—',
    },
  ];
});
</script>

<template>
  <div class="flex flex-wrap gap-[24px]">
    <ClientShowCard
      v-for="card in statsCards"
      :key="card"
      class="grow flex gap-[12px]"
    >
      <div class="w-[45px] h-[45px] rounded-[10px] bg-violet-primary flex items-center justify-center">
        <AppIcon
          :icon="card.icon"
          class="w-[24px] h-[24px] text-white"
        />
      </div>
      <div class="flex flex-col gap-[6px]">
        <div class="text-xs text-gray-primary">{{ card.title }}</div>
        <div class="text-xl font-medium">
          <AppSkeleton :loading="isLoading">{{ card.value }}</AppSkeleton>
        </div>
      </div>
    </ClientShowCard>
  </div>
</template>

