import OrderNewPage from '@/modules/order/pages/OrderNewPage.vue';
import OrderEditPage from '@/modules/order/pages/OrderEditPage.vue';
import OrderIndexPage from '@/modules/order/pages/OrderIndexPage.vue';
import OrderPrintPage from '@/modules/order/pages/OrderPrintPage.vue';
import OrderShowPage from '@/modules/order/pages/OrderShowPage.vue';

export default [
  {
    path: '/order/',
    name: 'order.index',
    component: OrderIndexPage,
    meta: { permissions: ['view-any-Order'] },
  },
  {
    path: '/order/new',
    name: 'order.new',
    component: OrderNewPage,
    props: (route) => ({ clientId: route.query.clientId, carId: route.query.carId }),
    meta: { permissions: ['create-Order'] },
  },
  {
    path: '/order/edit/:orderId',
    name: 'order.edit',
    component: OrderEditPage,
    props(route) {
      const props = { ...route.params };
      props.orderId = +props.orderId;
      return props;
    },
    meta: { permissions: ['update-Order'] },
  },
  {
    path: '/order/show/:orderId',
    name: 'order.show',
    component: OrderShowPage,
    props(route) {
      const props = { ...route.params };
      props.orderId = +props.orderId;
      return props;
    },
    meta: { permissions: ['view-Order'] },
  },
  {
    path: '/order/print/:orderId',
    name: 'order.print',
    component: OrderPrintPage,
    props(route) {
      const props = { ...route.params };
      props.orderId = +props.orderId;
      return props;
    },
    meta: {
      sidebar: false,
      permissions: ['print-Order'],
    },
  },
];
