<script setup>
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import { computed, ref } from 'vue';
import AppModal from '@/components/AppModal.vue';
import useVuelidate from '@vuelidate/core';
import { useToast } from '@/use/useToast.js';
import { operationExpenseCashboxStore } from '@/api/operation.js';
import OperationDirection from '@/enums/OperationDirection.js';
import { useAccountsQuery } from '@/query/useAccountQuery.js';

const emit = defineEmits(['expense-created', 'cancel']);

const vuelidate = await useVuelidate();

const saveExpense = async () => {
  try {
    if (!(await vuelidate.value.$validate())) {
      return false;
    }

    loading.value = true;
    await operationExpenseCashboxStore({ amount: amount.value * 100, description: description.value });
    useToast().show('Расходная операция добавлена');
    emit('expense-created');
  } catch (err) {
    error.value = err.response?.data?.message;
    setTimeout(() => {
      error.value = null;
    }, 3000);
    loading.value = false;
  }
};

const loading = ref(false);
const error = ref(null);
const amount = ref(null);
const description = ref(null);
const isModalShow = ref(true);

// @todo добавить удаление и редактирование расходов
</script>

<template>
  <AppModal
    v-model:show="isModalShow"
    @close="emit('cancel')"
  >
    <template #title>Добавление расхода</template>

    <div class="flex flex-col gap-y-[12px]">
      <div>
        <AppInput
          v-model="amount"
          placeholder="Введите сумму"
          required
          autofocus
          mask="number"
          :disabled="loading"
        />
      </div>

      <div>
        <AppInput
          v-model="description"
          placeholder="Введите описание"
          :disabled="loading"
          first-letter-upper-case
          required
        />
      </div>
    </div>

    <div class="mt-3 px-3">
      <div
        v-if="error"
        class="text-rose-500"
      >
        {{ error }}
      </div>
    </div>

    <template #actions>
      <div class="flex justify-center">
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="saveExpense()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
