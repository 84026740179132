<script setup>
import { computed, onUnmounted, ref, useSlots, watch, unref } from 'vue';
import { useElementSize, useCssVar, templateRef } from '@vueuse/core';

const props = defineProps({
  /**
   *  Array of Object
   *   field: 'first_name',
   *   label: 'First Name',
   *
   *   width: '40px' or '1fr' or '2fr'
   *
   *   minWidth: '40px',
   *   maxWidth: '2fr',
   */
  columns: {
    type: Array,
    required: true,
  },

  data: {
    type: [Array, null],
    required: true,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  rowClass: {
    type: String,
    default: '',
  },
  colClass: {
    type: String,
    default: '',
  },

  rowDataTest: {
    type: String,
    default: '',
  },

  stickyHeaders: {
    type: Boolean,
    default: false,
  },

  hideHeadersWhenEmpty: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['row-clicked']);

const columnsCount = computed(() => {
  return columns.value.length;
});

const columns = computed(() => {
  return props.columns.filter((item) => item.hidden !== true);
});

const getCellData = (row, columnId) => {
  const columnName = getColumn(columnId).field;

  let data;

  if (typeof columnName === 'function') {
    data = columnName(row);
  } else {
    data = row[columnName];
  }

  return data && data !== '' ? data : '—';
};

const getColumn = (columnId) => {
  return columns.value[columnId - 1];
};

const getColumnFooterData = (columnId) => {
  const footer = getColumn(columnId)?.footer;

  if (!footer) {
    return null;
  }

  if (typeof footer === 'function') {
    return footer();
  }

  return footer && footer !== '' ? footer : '—';
};

const getRowSlotName = (columnId) => {
  const column = getColumn(columnId);
  const fieldName = typeof column.field === 'string' ? column.field : null;
  const template = column.template ?? fieldName;

  return template ? 'row-' + template : null;
};

const cellsContent = ref([]);

const cellSize = ref(false);

watch(
  () => cellsContent,
  () => {
    if (cellsContent.value && cellsContent.value.length === 0) {
      return;
    }

    cellSize.value = useElementSize(cellsContent.value[5]);
  },
  {
    deep: true,
  }
);

onUnmounted(() => {
  unref(cellsContent);
});

const cellFixedSize = ref(0);
const loading = ref(props.loading);

watch(
  () => props.loading,
  () => {
    if (props.loading) {
      cellFixedSize.value = {};
      cellFixedSize.value.height = cellSize.value.height + 'px';
      cellFixedSize.value.width = cellSize.value.width + 'px';
    }

    loading.value = !loading.value;
  }
);

const prepareWidth = (width) => {
  if (/^-?\d+$/.test(width)) {
    width = width + 'px';
  }

  return width;
};

const gridSize = computed(() => {
  const sizes = [];
  columns.value.forEach((column) => {
    let width = column.width;

    if (column.minWidth && column.maxWidth) {
      width = 'minmax(' + prepareWidth(column.minWidth) + ',' + prepareWidth(column.maxWidth) + ')';
    }

    if (!width) {
      width = '1fr';
    }

    width = prepareWidth(width);

    sizes.push(width);
  });

  return {
    '--gridSize': sizes.join(' '),
  };
});

const hasFooter = computed(() => {
  return props.columns.find((column) => !!column.footer);
});

const preparedData = computed(() => {
  if (!props.data || props.data.length === 0) {
    return [];
  }

  let data = [...props.data];

  if (hasFooter.value) {
    const rowsFooter = {
      __type: 'footer',
    };

    data = [...data, rowsFooter];
  }

  return data;
});

const slot = useSlots();
</script>

<template>
  <div class="grow flex flex-col w-full">
    <div
      v-if="!(props.hideHeadersWhenEmpty && preparedData.length === 0)"
      class="md:grid print:sm:grid hidden md:grid-cols-[var(--gridSize)] print:sm:grid-cols-[var(--gridSize)] md:gap-x-[10px] print:sm:gap-x-[10px] mb-[12px]"
      :class="{
        'sticky top-0 bg-white': props.stickyHeaders,
      }"
      :style="gridSize"
    >
      <div
        v-for="column in columns"
        :key="column.field"
        class="text-sm text-gray-primary first:md:pl-[12px] print:sm:pl-[12px]"
        :class="props.colClass"
      >
        {{ column.label }}
      </div>
    </div>

    <div
      v-if="preparedData && preparedData.length > 0"
      class="flex flex-col gap-y-[8px] md:gap-y-0"
    >
      <div
        v-for="rowData in preparedData"
        :key="rowData.id"
        class="grid border border-[#CBD3E4] p-[10px] gap-y-[12px] md:gap-y-0 gap-x-[38px] md:gap-x-[10px] rounded-[6px] md:rounded-0 md:p-0 md:border-0 print:sm:border-0 md:border-b print:sm:border-b border-[#F3F3F3] md:hover:bg-[#FAFBFE] grid-cols-[min-content_auto] transition-colors duration-100 md:grid-cols-[var(--gridSize)] print:sm:grid-cols-[var(--gridSize)]"
        :class="props.rowClass"
        :data-test="props.rowDataTest"
        :style="gridSize"
        @click="emit('row-clicked', rowData)"
      >
        <template
          v-for="(columnId, index) in columnsCount"
          :key="columnId"
        >
          <div class="whitespace-nowrap text-xs text-gray-primary md:hidden print:sm:hidden">
            {{ getColumn(columnId).label }}
          </div>

          <div
            :ref="(el) => cellsContent.push(el)"
            class="box-content black-primary md:py-[13px] flex items-center"
            :class="{ skeleton: loading, 'md:pl-[12px]': index === 0 }"
          >
            <template v-if="!loading">
              <slot
                v-if="!rowData.__type"
                :name="getRowSlotName(columnId)"
                :row="getCellData(rowData, columnId)"
                :raw="rowData"
              >
                {{ getCellData(rowData, columnId) }}
              </slot>
              <span
                v-else
                class="font-medium"
              >
                {{ getColumnFooterData(columnId) }}
              </span>
            </template>

            <template v-else>
              <div class="h-full w-full py-2">
                <div class="h-[5px] w-1/2 animate-pulse rounded-full bg-gray-300"></div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col py-[30px] items-center justify-center grow"
    >
      <slot name="empty"><div class="text-gray-primary">Нет данных</div></slot>
    </div>
  </div>
</template>

<style scoped>
.skeleton {
  height: v-bind('cellFixedSize.height');
  /*width: v-bind('cellFixedSize.width');*/
}
</style>
