<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { serviceStore, serviceUpdate } from '@/api/service.js';
import { useServiceQuery } from '@/query/useServiceQuery.js';
import ServiceType from '@/enums/ServiceType.js';
import AppSelect from '@/components/AppSelect.vue';
import AppTable from '@/components/AppTable.vue';
import ServicePriceType from '@/enums/ServicePriceType.js';
import AppScrollable from '@/components/AppScrollable.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';

const props = defineProps({
  serviceId: {
    type: Number,
    required: false,
    default: null,
  },

  categoryId: {
    type: Number,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const vuelidate = await useVuelidate();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    if (props.serviceId) {
      await serviceUpdate(props.serviceId, {
        title: title.value,
        type: type.value,
        priceRangeStart: priceRangeStart.value * 100,
        priceRangeEnd: priceRangeEnd.value * 100,
        priceType: priceType.value,
        employeePriceRate: employeePriceRate.value,
        prices: newPrices.value.size > 0 ? [...newPrices.value.values()] : null,
      });
    } else {
      await serviceStore({
        categoryId: props.categoryId,
        title: title.value,
        type: type.value,
        priceRangeStart: priceRangeStart.value * 100,
        priceRangeEnd: priceRangeEnd.value * 100,
        priceType: priceType.value,
        employeePriceRate: employeePriceRate.value,
        prices: newPrices.value.size > 0 ? [...newPrices.value.values()] : null,
      });
    }

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['service', 'service-category'].indexOf(key) !== -1),
    });
    useToast().show('Данные сохранены');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const modalTitle = computed(() => {
  return props.serviceId ? 'Редактирование устуги' : 'Новая услуга';
});

const enabled = computed(() => {
  return !!props.serviceId;
});

const include = ['prices'];

const { data: service, isLoading } = useServiceQuery(
  { serviceId: props.serviceId, include },
  { enabled, select: (response) => response?.data?.data }
);

const title = ref(null);
const type = ref(null);
const priceType = ref(null);
const employeePriceRate = ref(100);
const priceRangeStart = ref(null);
const priceRangeEnd = ref(null);
const newPrices = ref(new Map());
const lowerEmployeeRate = ref(false);

watch(
  service,
  () => {
    if (!service.value) {
      return;
    }

    title.value = service.value.title;
    type.value = service.value.type;
    priceType.value = service.value.priceType;
    employeePriceRate.value = service.value.employeePriceRate;
    priceRangeStart.value = service.value.priceRangeStart > 0 ? service.value.priceRangeStart / 100 : 0;
    priceRangeEnd.value = service.value.priceRangeEnd > 0 ? service.value.priceRangeEnd / 100 : 0;

    if (service.value.employeePriceRate !== 100) {
      lowerEmployeeRate.value = true;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

watchEffect(() => {
  if (!service.value) {
    return;
  }

  title.value = service.value.title;
});

watch(type, (newValue, oldValue) => {
  if (oldValue) {
    title.value = null;
    priceType.value = null;
  }
});

const serviceTypes = [
  {
    label: 'Базовая услуга',
    value: ServiceType.BASE,
  },
  {
    label: 'Свободная',
    value: ServiceType.FREE,
  },
  {
    label: 'Хранение',
    value: ServiceType.STORAGE,
  },
];

const priceTypes = computed(() => {
  const types = [];

  if (type.value !== ServiceType.FREE) {
    types.push({
      label: 'Фиксированная',
      value: ServicePriceType.FIXED,
    });
  }

  types.push({
    label: 'Свободная',
    value: ServicePriceType.FREE,
  });

  types.push({
    label: 'Диапазон',
    value: ServicePriceType.RANGE,
  });

  return types;
});

const employeePriceRates = computed(() => {
  const rates = [];

  for (let rate = 0; rate <= 100; rate += 10) {
    rates.push({
      label: rate + '%',
      value: rate,
    });
  }

  return rates;
});

const diameters = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

const carClasses = [
  {
    value: 'passenger',
    label: 'Легковой',
  },
  {
    value: 'suv',
    label: 'Suv',
  },
  {
    value: 'jeep',
    label: 'Внедорожник',
  },
  {
    value: 'commercial',
    label: 'Коммерческий',
  },
];

const carClassedColumns = computed(() => {
  return carClasses.map((carClass) => {
    return {
      label: carClass.label,
      template: carClass.value,
    };
  });
});

const getPrice = (carClass, wheelDiameter) => {
  if (!service.value) {
    return 0;
  }

  const amount = service.value.prices?.find((item) => {
    return item.carClass === carClass && item.wheelDiameter === wheelDiameter;
  })?.price;

  return amount ?? 0;
};

const setPrice = (carClass, diameter, amount) => {
  const key = [carClass, diameter].join(',');
  const data = { carClass, diameter, amount: amount ? amount * 100 : 0 };
  newPrices.value.set(key, data);
};

const priceColumns = computed(() => {
  return [
    {
      label: 'Диаметр',
      template: 'diameter',
    },
    ...carClassedColumns.value,
  ];
});

const priceData = computed(() => {
  return diameters.map((diameter) => {
    const prices = carClasses.map((carClass) => {
      const price = {
        carClass: carClass.value,
        price: Math.random(),
      };

      return price;
    });

    return {
      diameter,
      prices,
    };
  });
});
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="props.serviceId && isLoading"
    :class="{ 'md:!h-full': priceType === ServicePriceType.FIXED }"
    @close="close()"
  >
    <template #title>{{ modalTitle }}</template>

    <div
      class="flex flex-col gap-y-[32px]"
      :class="{ 'h-full': priceType === ServicePriceType.FIXED }"
    >
      <div class="grid grid-cols-2 gap-y-[24px] gap-x-[12px]">
        <div class="flex flex-col gap-y-[12px] last:col-span-2">
          <div>Тип услуги</div>
          <div>
            <AppSelect
              v-model="type"
              data-test="service-type"
              :options="serviceTypes"
              required
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div>Тип цены</div>
          <div>
            <AppSelect
              v-model="priceType"
              data-test="service-price-type"
              :options="priceTypes"
              :disabled="!type"
              required
            />
          </div>
        </div>

        <div
          v-if="type === ServiceType.BASE && priceType"
          class="flex flex-col gap-y-[12px] last:col-span-2"
        >
          <div>Название услуги</div>
          <div>
            <AppInput
              v-model="title"
              data-test="service-title"
              required
            />
          </div>
        </div>
      </div>

      <!-- Фиксированная цена услуги -->
      <template v-if="priceType === ServicePriceType.FIXED">
        <AppScrollable class="h-full">
          <AppTable
            data-test="service-price-table"
            :columns="priceColumns"
            :data="priceData"
          >
            <template #row-diameter="{ raw }">R{{ raw.diameter }}</template>
            <template #row-passenger="{ raw }">
              <AppInput
                :model-value="getPrice('passenger', raw.diameter) / 100"
                @update:modelValue="(price) => setPrice('passenger', raw.diameter, price)"
            /></template>

            <template #row-suv="{ raw }"
              ><AppInput
                :model-value="getPrice('suv', raw.diameter) / 100"
                @update:modelValue="(price) => setPrice('suv', raw.diameter, price)"
            /></template>

            <template #row-jeep="{ raw }"
              ><AppInput
                :model-value="getPrice('jeep', raw.diameter) / 100"
                @update:modelValue="(price) => setPrice('jeep', raw.diameter, price)"
            /></template>

            <template #row-commercial="{ raw }"
              ><AppInput
                :model-value="getPrice('commercial', raw.diameter) / 100"
                @update:modelValue="(price) => setPrice('commercial', raw.diameter, price)"
            /></template>
          </AppTable>
        </AppScrollable>
      </template>

      <!-- Даипозон цены услуги -->
      <template v-if="priceType === ServicePriceType.RANGE">
        <div>Установите ценовой диапазон услуги</div>
        <div class="grid grid-cols-2 gap-y-[24px] gap-x-[12px]">
          <div class="flex flex-col gap-y-[12px]">
            <div>От</div>
            <div>
              <AppInput
                v-model="priceRangeStart"
                data-test="service-price-range-start"
              />
            </div>
          </div>

          <div class="flex flex-col gap-y-[12px]">
            <div>До</div>
            <div>
              <AppInput
                v-model="priceRangeEnd"
                data-test="service-price-range-end"
              />
            </div>
          </div>
        </div>
      </template>

      <!--  Понизить процент сотруднику -->
      <div
        v-if="type && priceType"
        class="flex gap-x-[32px]"
      >
        <AppCheckbox
          v-model="lowerEmployeeRate"
          label="Понизить процент сотруднику"
        />
        <div
          v-if="lowerEmployeeRate"
          class="grid grid-cols-[215px_1fr] items-center gap-x-[12px]"
        >
          <AppSelect
            v-model="employeePriceRate"
            data-test="service-employee-price-rate"
            direction="top"
            :options="employeePriceRates"
          />
          <div class="text-gray-primary text-sm">Процент от суммы услуги из которого будет расчитываться зарплата</div>
        </div>
      </div>
    </div>

    <template #actions>
      <div
        v-if="type && priceType"
        class="grid grid-cols-2 gap-x-[12px]"
      >
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
