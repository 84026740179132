import { useQuery } from '@tanstack/vue-query';
import { getAddresses, getCompanies, getCompany, getGeocode, getTireBrands } from '@/api/suggestion.js';
import { unref } from 'vue';

export function useSuggestionCompaniesQuery({ query }, options = {}) {
  const queryKey = ['suggestion', 'company', query];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getCompanies({ query: unref(query) });
    },
    ...options,
  });
}

export function useSuggestionCompanyQuery({ inn }, options = {}) {
  const queryKey = ['suggestion', 'company', inn];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getCompany(unref(inn));
    },
    ...options,
  });
}
export function useSuggestionTireBrandsQuery({ query }, options = {}) {
  const queryKey = ['suggestion', 'tire-brands', query];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getTireBrands(unref(query));
    },
    ...options,
  });
}

export function useSuggestionAddressQuery({ query }, options = {}) {
  const queryKey = ['suggestion', 'address', query];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAddresses(unref(query));
    },
    ...options,
  });
}

export function useSuggestionGeocodeQuery(query, options = {}) {
  const queryKey = ['suggestion', 'geocode', query];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getGeocode(unref(query));
    },
    ...options,
  });
}
