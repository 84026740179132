<script setup>
import AppModal from '@/components/AppModal.vue';
import AppButton from '@/components/AppButton.vue';
import { ref } from 'vue';
import { deleteBooking } from '@/api/booking.js';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  bookingId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['canceled', 'removed']);
const loading = ref(false);
const queryClient = useQueryClient();

const remove = async (bookingId) => {
  loading.value = true;
  try {
    await deleteBooking(bookingId);
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'booking'),
    });
    emit('removed');
  } finally {
    loading.value = false;
  }
};

const cancel = () => {
  emit('canceled');
};
</script>

<template>
  <AppModal
    :show="true"
    @close="cancel()"
  >
    <template #title>Вы уверены, что хотите удалить запись?</template>
    <template #actions>
      <div class="flex gap-x-[10px]">
        <AppButton
          outline
          class="grow"
          :disabled="loading"
          @click="cancel"
          >Отмена</AppButton
        >
        <AppButton
          class="grow"
          outline
          red
          :loading="loading"
          :disabled="loading"
          @click="remove(props.bookingId)"
          >Удалить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
