import UiAppSelectPage from '@/modules/ui/pages/UiAppSelectPage.vue';
import UiTablePage from '@/modules/ui/pages/UiTablePage.vue';
import LayoutPage from '@/modules/ui/pages/LayoutPage.vue';
import UiAppDatepickerPage from '@/modules/ui/pages/UiAppDatepickerPage.vue';
import UiAppInputPage from '@/modules/ui/pages/UiAppInputPage.vue';
export default [
  {
    path: '/ui',
    children: [
      {
        path: 'app-select',
        name: 'ui.app-select',
        component: UiAppSelectPage,
      },
      {
        path: 'app-input',
        name: 'ui.app-input',
        component: UiAppInputPage,
      },
      {
        path: 'app-datepicker',
        name: 'ui.app-datepicker',
        component: UiAppDatepickerPage,
      },
      {
        path: 'table',
        name: 'ui.table',
        component: UiTablePage,
      },
      {
        path: 'layout',
        name: 'ui.layout',
        component: LayoutPage,
        meta: {
          sidebar: false,
        },
      },
    ],
  },
];
