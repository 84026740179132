<script setup>
import AppInput from '@/components/AppInput.vue';
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { clientCreate } from '@/api/client.js';
import { ClientType } from '@/enums/ClientType.js';
import { useToast } from '@/use/useToast.js';
import { useRouter } from 'vue-router';

const emit = defineEmits(['close']);

const firstName = ref(null);
const lastName = ref(null);
const patronymicName = ref(null);
const phone = ref(null);

const router = useRouter();

const create = async () => {
  try {
    const client = await clientCreate({
      type: ClientType.INDIVIDUAL,
      firstName: firstName.value,
      lastName: lastName.value,
      patronymicName: patronymicName.value,
      phone: phone.value,
    });

    await router.push({ name: 'client.show', params: { clientId: client.data?.data?.clientId } });
    useToast().show('Клиент создан');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>


<template>
  <div class="grid grid-cols-2 gap-[24px]">
    <div class="flex flex-col gap-y-[12px]">
      <div>Имя</div>
      <AppInput
        v-model="firstName"
        data-test="client-new-first-name"
      />
    </div>
    <div class="flex flex-col gap-y-[12px]">
      <div>Фамилия</div>
      <AppInput 
        v-model="lastName" 
        data-test="client-new-last-name"
      />
    </div>
    <div class="flex flex-col gap-y-[12px]">
      <div>Отчество</div>
      <AppInput 
        v-model="patronymicName" 
        data-test="client-new-patronymic-name"
      />
    </div>
    <div class="flex flex-col gap-y-[12px]">
      <div>Телефон</div>
      <AppInput
        v-model="phone"
        mask="phone"
        data-test="client-new-phone"
      />
    </div>

    <div class="flex col-span-2 gap-[12px]">
      <AppButton
        outline
        @click="emit('close')"
        >Отмена</AppButton
      >
      <AppButton @click="create">Создать</AppButton>
    </div>
  </div>
</template>
