<script setup>
import { computed, ref } from 'vue';
import ShiftExpenseModal from '@/modules/shift/components/ShiftExpenseModal.vue';
import { formatMoney } from '@/use/useFormat.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';

const props = defineProps({
  shift: {
    type: Object,
    required: true,
  },
  allowEdit: {
    type: Boolean,
    required: true,
  },
});

const totalExpense = computed(() => {
  return Object.values(props.shift.expenses).reduce((total, expense) => {
    return total + expense.amount;
  }, 0);
});

const emit = defineEmits(['expense-created']);

const isShiftOpen = computed(() => {
  return props.shift.finishedAt === null;
});

const isShowCreateForm = ref(false);
const expenseCreated = () => {
  isShowCreateForm.value = false;
  emit('expense-created');
};
</script>

<template>
  <div class="flex justify-between gap-x-[12px] items-center">
    <div class="flex gap-x-[7px] items-center">
      <span class="text-lg">
        {{ formatMoney(totalExpense) }}
      </span>
      <span class="text-gray-primary">/ Расходы</span>
    </div>
    <AppIcon
      v-if="isShiftOpen && props.allowEdit"
      :icon="IconPencil"
      class="cursor-pointer text-black hover:text-violet-primary"
      @click="isShowCreateForm = true"
    />

    <ShiftExpenseModal
      v-if="isShowCreateForm"
      @expense-created="expenseCreated"
      @cancel="isShowCreateForm = false"
    />
  </div>
</template>
