<script setup>
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import AppDatepicker from '@/components/AppDatepicker.vue';
import { ref } from 'vue';

const selectedDate = ref('2020-01-01');
</script>

<template>
  <CabinetLayout>
    <AppDatepicker v-model="selectedDate" />
  </CabinetLayout>
</template>

