<script setup>
import { ref } from 'vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import AppInput from '@/components/AppInput.vue';
import TireBrandSelect from '@/modules/order/components/TireBrandSelect.vue';

const selected = ref(0);
const suggestions = ref([
  {
    label: 'Москва',
    value: 0,
  },
  {
    label: 'Санк-Петербург',
    value: 1,
  },
  {
    label: 'Владимир',
    value: 2,
  },
  {
    label: 'Казань',
    value: 3,
  },
  {
    label: 'Нижний Новгород',
    value: 4,
  },
]);

const title = ref(null);

const citySelect = (city) => {
  title.value = city.label;
};
</script>

<template>
  <CabinetLayout>
    <div class="mt-[200px] flex flex-col gap-y-[20px]">
      <AppInput
        v-model="title"
        option-label="title"
        option-value="value"
        :suggestions="suggestions"
        placeholder="Укажите город"
        @suggestion-selected="citySelect"
      />

      <TireBrandSelect /></div
  ></CabinetLayout>
</template>
