export const userGroupsColumns = [
  {
    label: '', // 'Наименование',
    template: 'name',
    field: (role) => {
      return role.name;
    },
  },
  {
    template: 'actions-edit',
    width: '31px',
  },
  {
    template: 'actions-user-edit',
    width: '31px',
  },
  {
    template: 'actions-delete',
    width: '31px',
  },
];
