<template>
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5144 7.38644L8.10078 10.8L6.93718 9.63644M9.00078 1.80005C5.02433 1.80005 1.80078 5.0236 1.80078 9.00005C1.80078 12.9765 5.02433 16.2 9.00078 16.2C12.9772 16.2 16.2008 12.9765 16.2008 9.00005C16.2008 5.0236 12.9772 1.80005 9.00078 1.80005Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
