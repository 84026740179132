import { useQuery } from '@tanstack/vue-query';
import { getMarks, getModels } from '@/api/car.js';
import { unref } from 'vue';

export function useMarkQuery() {
  const queryKey = ['mark'];
  const enabled = true;

  return useQuery({
    queryKey,
    queryFn: () => {
      return getMarks();
    },
    enabled,
    select: (marks) => marks.data,
  });
}

export function useModelQuery({ markId, enabled }) {
  const queryKey = ['model', markId];

  if (enabled === undefined) {
    enabled = true;
  }

  return useQuery({
    queryKey,
    queryFn: () => {
      return getModels(unref(markId));
    },
    enabled,
    select: (models) => models.data,
  });
}
