<script setup>
import { ref, onMounted } from 'vue';
import AppInput from '@/components/AppInput.vue';
import AppSelect from '@/components/AppSelect.vue';
import AppButton from '@/components/AppButton.vue';
import { useRouter } from 'vue-router';
import AppIcon from '@/components/AppIcon.vue';
import IconInfoCircle from '@/components/Icons/IconInfoCircle.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import { assignEmployee } from '@/api/business.js';
import { getRoles } from '@/api/roles.js';
import { useToast } from '@/use/useToast.js';
import { useQueryClient } from '@tanstack/vue-query';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';

const phone = ref(null);
const roles = ref([]);
const optionsRoles = ref([]);

const router = useRouter();
const showHelpMessage = ref(false);
const loading = ref(false);
const queryClient = useQueryClient();

const submit = async () => {
  loading.value = true;

  try {
    await assignEmployee({ phone: phone.value, roles: roles.value });
    await router.push({ name: 'settings.employees.index' });
    useToast().show('Сотрудник добавлен');
  } catch (error) {
    const messages = error?.response?.data?.errors ?? {};
    for (const [key, value] of Object.entries(messages)) {
      useToast().error(value);
    }
    // useToast().error();
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['employees'].indexOf(key) !== -1),
    });
    loading.value = false;
  }
};

onMounted(async () => {
  const { data } = await getRoles();
  optionsRoles.value = data.data;
});
</script>

<template>
  <CabinetLayout :tabs="tabs">
    <form
      class="w-[508px] flex flex-col gap-y-[12px]"
      @submit.prevent="submit"
    >
      <div>Новый сотрудник <span style="color: red">*</span></div>
      <div class="flex items-center gap-x-[18px]">
        <AppInput
          v-model="phone"
          mask="phone"
          placeholder="Введите номер телефона"
          class="grow"
          data-test="phone"
          required
        />
        <div class="relative">
          <AppIcon
            :icon="IconInfoCircle"
            class="text-violet-primary w-[20px] h-[20px] cursor-pointer"
            @click="showHelpMessage = !showHelpMessage"
          />

          <AppHelpMessage
            v-if="showHelpMessage"
            @close="showHelpMessage = false"
          >
            Вам нужно указать только номер телефона сотрудника, затем ваш сотрудник уже сам сможет войти под своим
            номером и настроить свои персональные данные.
            <br /><br />
            <span class="text-red-notify">Помните!</span> Наша система работает без паролей. Код авторизации будет
            отправлен вашему сотруднику в момент входа в систему.
          </AppHelpMessage>
        </div>
      </div>
      <div>Права доступа <span style="color: red">*</span></div>
      <div class="group">
        <AppSelect
          v-model="roles"
          :options="optionsRoles"
          option-value="id"
          option-label="name"
          data-test="roles"
          required
          multiple
        />
      </div>

      <div class="flex justify-end gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="router.push({ name: 'settings.employees.index' })"
          >Отмена</AppButton
        >
        <AppButton
          :loading="loading"
          :disabled="loading"
          data-test="employee-create-submit"
          type="submit"
          >Добавить</AppButton
        >
      </div>
    </form>
  </CabinetLayout>
</template>

<style scoped>
.group {
  width: calc(100% - 38px);
}
</style>
