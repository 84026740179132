import { useQuery } from '@tanstack/vue-query';
import { getTelegramConnectLink } from '@/api/telegram.js';

export function useTelegramConnectionLinkQuery(options = {}) {
  const queryKey = ['telegram', 'connection-link'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getTelegramConnectLink();
    },
    ...options,
  });
}
