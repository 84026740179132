<script setup>
import { useRoleQuery, usePermissionsQuery } from '@/query/useRoleQuery.js';
import AppTable from '@/components/AppTable.vue';
import { computed, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppConfirm from '@/components/AppConfirm.vue';
import AppMessageBox from '@/components/AppMessageBox.vue';
import { userGroupsColumns } from '@/modules/settings/tables/userGroupsTable';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import IconSpinner from '@/components/Icons/IconSpinner.vue';
import IconEmployees from '@/components/Icons/IconEmployees.vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import SettingsUserGroupsModal from '@/modules/settings/components/SettingsUserGroupsModal.vue';
import SettingsUsersEditGroupModal from '@/modules/settings/components/SettingsUsersEditGroupModal.vue';
import { useStringUtils } from '@/use/useStringUtils.js';
import { roleDelete } from '@/api/roles.js';
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import EmployeeStatus from '@/enums/EmployeeStatus.js';

const queryClient = useQueryClient();
const confirmDeleteGroupModal = ref(null);
const warningDeleteGroupModal = ref(null);

const options = { keepPreviousData: true };
const { data: rolesData, isLoading, isFetching } = useRoleQuery(options);
const roles = computed(() => rolesData.value?.data);

const { data: permissionsData } = usePermissionsQuery();
const permissions = computed(() => permissionsData.value);

const { data: employeesData } = await useEmployeesQuery({
  assignedAsExecutor: true,
  statusId: EmployeeStatus.WORKING,
});
const employees = computed(() => employeesData.value?.data);

const showUserGroupModal = ref(false);
const editableUserGroup = ref(null);
const showAddUsersInGroupModal = ref(false);
const groupForAddUsersInGroup = ref(null);
const deleteLoading = ref(false);

const closeModal = () => {
  editableUserGroup.value = null;
  showUserGroupModal.value = false;
  groupForAddUsersInGroup.value = null;
  showAddUsersInGroupModal.value = false;
};

const editGroup = (userGroup) => {
  editableUserGroup.value = userGroup;
  showUserGroupModal.value = true;
};

const editUsersInGroup = (userGroup) => {
  groupForAddUsersInGroup.value = userGroup;
  showAddUsersInGroupModal.value = true;
};

const deleteGroup = async (role) => {
  if (role.employees.length) {
    return await warningDeleteGroupModal.value.initConfirm();
  }

  const isConfirmed = await confirmDeleteGroupModal.value.initConfirm();
  if (!isConfirmed) return;

  deleteLoading.value = true;

  try {
    await roleDelete(role.id);
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['roles', 'delete'].indexOf(key) !== -1),
    });
    // emit('removed');
    useToast().show('Группа удалена.');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    deleteLoading.value = false;
  }
};

const declination = (count, strings) => {
  return useStringUtils().declination(count, strings);
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading || isFetching"
    :hide-in-loading="isLoading === true"
  >
    <!-- <div class="flex justify-between">
      <div>
        <span class="mr-2">Группы пользователей</span>
        <span class="text-gray-primary"
          >({{
            roles && roles.length
              ? roles.length + ' ' + declination(roles.length, ['группа', 'группы', 'групп'])
              : 'группы отсутствуют'
          }}
          )</span
        >
      </div>
    </div> -->

    <div>
      <AppTable
        v-if="roles"
        class="text-sm"
        :data="roles"
        :columns="userGroupsColumns"
      >
        <template #row-name="{ raw: role }">
          <div>
            <span class="text-lg mr-2">{{ role.name }}</span>
            <span class="text-sm text-gray-primary">{{
              role.employees
                ? role.employees.length +
                  ' ' +
                  declination(role.employees.length, ['пользователь', 'пользователя', 'пользователей'])
                : 'нет пользователей'
            }}</span>
          </div>
        </template>

        <template #row-actions-edit="{ raw: role }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              :icon="IconPencil"
              :data-test="'role-edit' + '-' + role.label"
              class="hover:text-violet-primary cursor-pointer"
              @click="(showUserGroupModal = true), editGroup(role)"
            />
          </div>
        </template>

        <template #row-actions-user-edit="{ raw: role }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              :icon="IconEmployees"
              :data-test="'role-employees-edit' + '-' + role.label"
              class="hover:text-violet-primary cursor-pointer"
              @click="editUsersInGroup(role)"
            />
          </div>
        </template>

        <template #row-actions-delete="{ raw: role }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              v-if="!deleteLoading"
              :icon="IconTrash"
              :data-test="'role-delete' + '-' + role.label"
              class="hover:text-violet-primary cursor-pointer"
              @click="deleteGroup(role)"
            />
            <AppIcon
              v-else
              :icon="IconSpinner"
            />
          </div>
        </template>
      </AppTable>
      <div class="flex justify-start">
        <AppButton
          class="mt-[24px]"
          @click="showUserGroupModal = true"
        >
          Добавить группу
        </AppButton>
      </div>
      <SettingsUserGroupsModal
        v-if="showUserGroupModal"
        v-model:permissions="permissions"
        :role="editableUserGroup"
        @closed="closeModal()"
        @saved="closeModal()"
      />
      <SettingsUsersEditGroupModal
        v-if="showAddUsersInGroupModal"
        v-model:employees-data="employees"
        v-model:roles-data="roles"
        :role="groupForAddUsersInGroup"
        @closed="closeModal()"
        @update="closeModal()"
      />
      <AppConfirm ref="confirmDeleteGroupModal">
        <template #title>Удаление группы</template>
        <template #content>Вы действительно хотите удалить группу?</template>
      </AppConfirm>

      <AppMessageBox ref="warningDeleteGroupModal">
        <template #title>Ошибка</template>
        <template #content>
          Невозможно удалить группу с активными пользователями. Сначала удалите всех пользователей из группы.
        </template>
      </AppMessageBox>
    </div>
  </CabinetLayout>
</template>
