<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.79922 5.8H13.1992M4.79922 10.6H13.1992M2.75922 1H15.2392C16.1008 1 16.7992 1.80589 16.7992 2.8V19L14.1992 17.2L11.5992 19L8.99922 17.2L6.39922 19L3.79922 17.2L1.19922 19V2.8C1.19922 1.80589 1.89765 1 2.75922 1Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
