<script setup>
import { useBookingTable } from '@/modules/booking/use/useBookingTable.js';
import { toRef, ref, watch } from 'vue';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import { DateTime } from 'luxon';
import BookingCreateClient from '@/modules/booking/components/BookingCreateClient.vue';
import { storeBooking } from '@/api/booking.js';
import { useQueryClient } from '@tanstack/vue-query';
import { useWorkingTime } from '@/modules/booking/use/useWorkingTime.js';
import { dateToHumanTitle } from '@/use/useFormat.js';
import { useToast } from '@/use/useToast.js';

const props = defineProps({
  selectedIntervals: {
    type: Array,
    required: true,
  },
  selectedDate: {
    type: String,
    required: true,
  },
  bookedIntervals: {
    type: Array,
    required: true,
  },
  workplaces: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['selected-intervals-changed', 'client-selected']);

const { openTime, closeTime } = useWorkingTime(toRef(props, 'selectedDate'));

const {
  selectedIntervals,
  increaseIntervalDuration,
  decreaseIntervalDuration,
  increaseIntervalTimestamp,
  decreaseIntervalTimestamp,
} = useBookingTable(toRef(props, 'selectedIntervals'), toRef(props, 'bookedIntervals'), openTime, closeTime);

watch(
  selectedIntervals,
  () => {
    emit('selected-intervals-changed', selectedIntervals.value);
  },
  {
    deep: true,
  }
);

const cancel = () => {
  emit('selected-intervals-changed', []);
};

const clientId = ref(null);
const client = ref(null);

const selectClient = (id) => {
  client.value = null;
  clientId.value = id;
};

const updateNewClient = (updatedClient) => {
  clientId.value = null;
  client.value = updatedClient;
};

const note = ref(null);
const queryClient = useQueryClient();
const loading = ref(false);

const submitBooking = async () => {
  loading.value = true;

  const intervals = selectedIntervals.value.map((interval) => {
    const endTime = DateTime.fromISO(interval.timestamp).plus({ minutes: interval.duration }).toISO();
    return {
      startTime: interval.timestamp,
      endTime,
      workplaceId: interval.workplaceId,
    };
  });

  try {
    await storeBooking({
      intervals,
      clientId: clientId.value,
      clientFirstName: client.value?.firstName,
      clientLastName: client.value?.lastName,
      clientPhone: client.value?.phone,
      clientSource: client.value?.source,
      note: note.value,
    });

    emit('selected-intervals-changed', []);

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'booking'),
    });
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    loading.value = false;
  }
};

const getWorkplaceTitle = (workplaceId) => {
  return props.workplaces?.find((workplace) => workplace.workplaceId === workplaceId).title;
};
</script>

<template>
  <div class="border border-[#CBD3E4] rounded-[6px] p-[24px] flex flex-col gap-y-[24px]">
    <div class="font-medium text-lg">
      Новая запись на <span class="text-violet-primary lowercase">{{ dateToHumanTitle(props.selectedDate) }}</span>
    </div>
    <div class="flex flex-col gap-y-[10px]">
      <div
        v-for="interval in selectedIntervals"
        :key="[interval.timestamp, interval.workplaceId]"
        class="flex gap-x-[33px] items-center justify-between"
      >
        <div class="font-medium">{{ getWorkplaceTitle(interval.workplaceId) }}</div>
        <div>
          <div class="font-medium flex gap-x-[8px] items-center">
            <div>{{ DateTime.fromISO(interval.timestamp).toFormat('HH:mm') }}</div>
            <div class="flex gap-x-[4px] items-center">
              <button
                class="mini-button"
                :disabled="loading"
                data-test="decrease-booking-start-time"
                @click="decreaseIntervalTimestamp(interval.workplaceId, interval.timestamp)"
              >
                -
              </button>
              <button
                class="mini-button"
                :disabled="loading"
                data-test="increase-booking-start-time"
                @click="increaseIntervalTimestamp(interval.workplaceId, interval.timestamp)"
              >
                +
              </button>
            </div>
            <div>
              по {{ DateTime.fromISO(interval.timestamp).plus({ minutes: interval.duration }).toFormat('HH:mm') }}
            </div>
            <div class="flex gap-x-[4px] items-center">
              <button
                data-test="decrease-booking-end-time"
                class="mini-button"
                :disabled="loading"
                @click="decreaseIntervalDuration(interval.workplaceId, interval.timestamp)"
              >
                -
              </button>
              <button
                data-test="increase-booking-end-time"
                class="mini-button"
                :disabled="loading"
                @click="increaseIntervalDuration(interval.workplaceId, interval.timestamp)"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-[#CBD3E4] h-[1px] w-full"></div>
    <div>
      <BookingCreateClient
        @client-selected="selectClient"
        @client-new-updated="updateNewClient"
      />
    </div>
    <div class="flex flex-col gap-y-[12px]">
      <div class="font-medium">Примечание</div>
      <div>
        <AppInput
          v-model="note"
          data-test="note"
          :disabled="loading"
        />
      </div>
    </div>
    <div class="flex gap-x-[18px]">
      <AppButton
        :disabled="loading"
        :loading="loading"
        @click="submitBooking()"
        >Записать клиента</AppButton
      >
      <AppButton
        outline
        :disabled="loading"
        @click="cancel()"
        >Отмена</AppButton
      >
    </div>
  </div>
</template>

<style scoped>
.mini-button {
  @apply w-[22px] h-[22px] text-sm border border-[#CBD3E4] rounded-[6px] font-normal;
}
</style>
