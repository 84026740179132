<script setup>
import AuthLayout from '@/layouts/AuthLayout.vue';
import { useAuthStore } from '@/store/AuthStore.js';
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useYandexMetrika } from '@/use/useYandexMetrika.js';
import { updateEmployee } from '@/api/employee.js';
import AppInput from '@/components/AppInput.vue';
import { useToast } from '@/use/useToast.js';
import AppButton from '@/components/AppButton.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import AppIcon from '@/components/AppIcon.vue';

const authStore = useAuthStore();
const vuelidate = await useVuelidate();
const router = useRouter();
const yandexMetrika = useYandexMetrika();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    await updateEmployee(authStore.employee.employeeId, {
      firstName: firstName.value,
      lastName: lastName.value,
      patronymicName: patronymicName.value,
    });

    yandexMetrika.goal('registration_full_name');

    await authStore.refetchAuth();

    switch (true) {
      case authStore.employee.telegramId === null:
        await router.push({ name: 'auth.connect-telegram' });
        break;

      default:
        await router.push({ name: 'home.index' });
    }
  } catch (error) {
    if (error.response?.data?.message) {
      useToast().error(error.response.data.message);
    }
    loading.value = false;
  }
};

const firstName = ref(authStore.employee.firstName);
const lastName = ref(authStore.employee.lastName);
const patronymicName = ref(authStore.employee.patronymicName);
const loading = ref(false);
</script>

<template>
  <AuthLayout class="flex flex-col items-center">
    <div class="mt-[68px]">
      <AppIcon
        :icon="IconLogo"
        :width="69"
        :height="69"
        class="text-[#EC4899]"
      />
    </div>
    <div
      class="flex flex-col gap-y-[24px] px-[16px] md:px-0 w-full md:w-[410px] mt-[100px] md:mt-[124px] lg:mt-[146px]"
    >
      <div class="text-xl lg:text-2xl font-medium text-center">Укажите ваши данные</div>
      <div class="flex flex-col gap-y-[12px]">
        <div class="font-medium">Фамилия*</div>
        <div>
          <AppInput
            v-model="lastName"
            first-letter-upper-case
            required
            :disabled="loading"
            data-test="lastName"
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-[12px]">
        <div class="font-medium">Имя*</div>
        <div>
          <AppInput
            v-model="firstName"
            first-letter-upper-case
            required
            :disabled="loading"
            data-test="firstName"
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-[12px]">
        <div class="font-medium">Отчество*</div>
        <div>
          <AppInput
            v-model="patronymicName"
            first-letter-upper-case
            required
            :disabled="loading"
            data-test="patronymicName"
          />
        </div>
      </div>

      <div class="flex justify-center">
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить и продолжить</AppButton
        >
      </div>
    </div>
  </AuthLayout>
</template>
