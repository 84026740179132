import { useQuery } from '@tanstack/vue-query';
import { getRoles, getPermissions } from '@/api/roles';

export const useRoleQuery = (options = {}) => {
  const queryKey = ['roles'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getRoles();
    },
    ...options,
  });
};

export const usePermissionsQuery = (options = {}) => {
  const queryKey = ['permissions'];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getPermissions();
    },
    ...options,
  });
};
