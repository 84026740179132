import Chatra from '@chatra/chatra';

let chatraInited = false;

export const useChat = () => {
  const publicKey = 'sKEqmyz2Q4zzm8zss';

  const init = () => {
    chatraInited = true;

    let config = {
      ID: publicKey,
      setup: {
        customWidgetButton: '#custom-chat-button',
        buttonPosition: 'br',
        buttonStyle: 'round',
        buttonSize: 47,
      },
    };

    Chatra('init', config);
  };

  const openChat = () => {
    if (!chatraInited) {
      return;
    }

    Chatra('show');
    Chatra('openChat', true);
  };

  const setData = (data) => {
    if (!chatraInited) {
      return;
    }

    Chatra('updateIntegrationData', data);
  };

  return {
    init,
    setData,
    openChat,
  };
};
