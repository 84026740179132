<script setup>
import { tabs } from '@/modules/client/client-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { computed } from 'vue';
import { useClientQuery } from '@/query/useClientQuery.js';
import ClientShowHeader from '@/modules/client/components/ClientShowHeader.vue';
import ClientShowStats from '@/modules/client/components/ClientShowStats.vue';
import ClientShowInfo from '@/modules/client/components/ClientShowInfo.vue';
import ClientShowCars from '@/modules/client/components/ClientShowCars.vue';
import ClientShowCompany from '@/modules/client/components/ClientShowCompany.vue';
import ClientShowNote from '@/modules/client/components/ClientShowNote.vue';
import ClientShowBooking from '@/modules/client/components/ClientShowBooking.vue';
import ClientShowOrders from '@/modules/client/components/ClientShowOrders.vue';
import ClientShowStorages from '@/modules/client/components/ClientShowStorages.vue';
import { ClientType } from '@/enums/ClientType.js';
import { useAuthStore } from '@/store/AuthStore.js';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const { hasAccess } = useAuthStore();
const { data: client, isLoading } = useClientQuery(
  { clientId: props.clientId },
  { select: (response) => response?.data?.data }
);

const tabsWithCurrent = computed(() => {
  if (!client.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Просмотр клиента',
      route: {
        name: 'client.show',
        params: {
          clientId: client.value.clientId,
        },
      },
    },
  ];
});
</script>

<template>
  <CabinetLayout
    :loading="isLoading"
    :hide-in-loading="true"
    :tabs="tabsWithCurrent"
  >
    <div class="flex flex-col gap-y-[32px]">
      <ClientShowHeader
        :client-id="props.clientId"
        :allow-edit="hasAccess('update-Client')"
        class="col-span-2"
      />

      <ClientShowStats
        class="col-span-2"
        :client-id="props.clientId"
      />

      <div class="flex flex-wrap md:grid md:grid-cols-2 gap-x-[32px]">
        <div class="grow flex flex-col gap-y-[24px]">
          <ClientShowInfo
            v-if="client.type === ClientType.INDIVIDUAL"
            :allow-edit="hasAccess('update-Client')"
            :client-id="props.clientId"
            class="mb-[24px]"
          />

          <ClientShowCompany
            v-if="client.type === ClientType.COMPANY"
            :allow-edit="hasAccess('update-Client')"
            :company-id="client.companyId"
            class="mb-[24px] break-inside-avoid"
          />

          <ClientShowNote
            :client-id="props.clientId"
            :allow-edit="hasAccess('update-Client')"
            class="mb-[24px] break-inside-avoid"
          />

          <ClientShowBooking :client-id="props.clientId" />
        </div>

        <div class="flex grow flex-col gap-y-[24px]">
          <ClientShowCars :client-id="props.clientId" />

          <ClientShowOrders :client-id="props.clientId" />

          <ClientShowStorages :client-id="props.clientId" />
        </div>
      </div>
    </div>
  </CabinetLayout>
</template>
