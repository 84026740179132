export const tabs = [
  {
    title: 'Текущая смена',
    route: {
      name: 'shift.show',
      params: {
        shiftId: 'current',
      },
    },
  },
  {
    title: 'История смен',
    route: {
      name: 'shift.index',
    },
  },
  {
    title: 'Лог активности',
    route: {
      name: 'shift.log',
    },
  },
];
