<script setup>
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/shift/shift-tabs.js';
import AppTable from '@/components/AppTable.vue';
import { computed, ref } from 'vue';
import AppPagination from '@/components/AppPagination.vue';
import { DateTime } from 'luxon';
import { useActivityLogQuery } from '@/query/useActivityLogQuery.js';

const page = ref(1);
const perPage = ref(30);
const { data, isLoading } = useActivityLogQuery({ page, perPage });

const shifts = computed(() => {
  return data?.value?.data;
});

const total = computed(() => {
  return data?.value?.meta?.total | 1;
});

const columns = [
  {
    label: '№',
    field: 'logId',
    width: '50px',
  },
  {
    label: 'Действие',
    template: 'action',
    width: '2fr',
  },
  {
    label: 'Дата',
    field: (log) => {
      return DateTime.fromISO(log.createdAt).toFormat('d MMMM yyyy H:mm');
    },
  },
];
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading"
    :hide-in-loading="true"
  >
    <AppTable
      :data="shifts"
      :columns="columns"
      sticky-headers
    >
      <template #row-action="{ raw: log }">
        <div class="flex gap-[8px]">
          {{ log.text }}
        </div>
      </template>
    </AppTable>

    <div class="flex justify-end mt-[44px]">
      <AppPagination
        v-model="page"
        :total="total"
        :per-page="perPage"
      />
    </div>
  </CabinetLayout>
</template>
