import { useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';
import { getDocumentTemplate } from '@/api/document_template.js';

export const useDocumentTemplateQuery = ({ name }, options = {}) => {
  const queryKey = ['document-template', name];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getDocumentTemplate(unref(name));
    },
    ...options,
  });
};
