<script setup>
import IconCross from '@/components/Icons/IconCross.vue';
import AppIcon from '@/components/AppIcon.vue';

const emit = defineEmits(['close']);

const close = () => {
  emit('close');
};
</script>

<template>
  <div
    class="absolute top-8 right-0 bg-white border border-[#CBD3E4] rounded-[6px] py-[16px] pl-[20px] pr-[53px] shadow-lg w-[458px] z-index"
  >
    <AppIcon
      :icon="IconCross"
      :width="24"
      :height="24"
      class="text-gray-primary absolute right-[16px] cursor-pointer hover:text-black-primary"
      @click="close()"
    />
    <div>
      <slot />
    </div>
  </div>
</template>

<style scoped>
.z-index {
  z-index: 1111 !important;
}
</style>
